import React, { ReactNode } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Route as RouteDef } from '../../model/route/Route';

interface Props extends RouteDef {
  principalRoles: string[];
  redirectNonAuthorized?: string;
  render: (props: any) => ReactNode;
}

class ProtectedRoute extends React.Component<Props, {}> {
  public isAuthorized() {
    const { authorize, principalRoles } = this.props;
    if (authorize !== undefined) {
      const roleIntersection = [...authorize].filter((role) => principalRoles.indexOf(role) !== -1);
      if (authorize.length > 0 && roleIntersection.length === 0) {
        return false;
      }
    }

    return true;
  }

  public render() {
    const { redirectNonAuthorized, principalRoles, path } = this.props;
    if (!path.startsWith('/auth') && principalRoles.length === 0 && !path.startsWith('/public/')) {
      // empty session
      return <Redirect to='/auth' />;
    }
    if (!this.isAuthorized()) {
      if (redirectNonAuthorized) {
        return <Redirect to={redirectNonAuthorized} />;
      }

      return null;
    }

    return <Route {...this.props} />;
  }
}

export default ProtectedRoute;
