export enum Status {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum TrainingStatus {
  ONLINE = 'ONLINE',
  DRAFT = 'DRAFT',
  TRAINING = 'TRAINING',
  ERROR = 'ERROR',
  CREATING = 'CREATING',
  PROCESSING = 'PROCESSING',
  WAITING = 'WAITING',
}

export interface InnerProps {
  trainingStatus: TrainingStatus;
  modified: boolean;
  getBotStatus: () => void;
}
