import React, { FormEvent } from 'react';
import { withAlert } from 'react-alert';
import {injectIntl, IntlShape} from 'react-intl';
import { RouteChildrenProps } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { AppError } from '../../../model/AppError';
import { AsyncState } from '../../../model/AsyncState';
import { HttpRequestType } from '../../../model/http/HttpRequestType';
import { Api, AuthApi } from '../../../api/Server';
import { Request } from '../../../model/http/Request';
import { SessionContext } from '../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../auth/state/Session/SessionStateModel';
import { AppRouteComponentProps } from '../../base/model/route/AppRouteComponentProps';
import { WithAlertProps } from '../../base/model/WithAlertProps';
import { withContext } from '../../lib/component/withContext/withContext.hoc';
import { RegistrationView } from './Registration.view';
import ReactGA from 'react-ga';

interface ComponentProps {
  authenticate: (username: string, password: string) => void;
  call: (Request: Request<{}>, newToken?: string, acceptAudio?: boolean) => void;
  authError: AppError;
  authState: AsyncState;
  authToken: string;
  authRefreshToken: string;
  authExpiresIn: Date;
  authAuthorities: string[];
  statusCode: number;
  status: number;
  callId: string;
  state: AsyncState;
  error: AppError;
}
interface IntlProps {
  intl: IntlShape;
}
type Props = IntlProps& ComponentProps &
SessionStateModel &
RouteChildrenProps &
AppRouteComponentProps &
WithAlertProps &
RouteComponentProps<{ emailToken: string }>;

interface Payload {
  username: string;
  password: string;
}

interface State {
  username: string;
  password: string;
  error: boolean;
  message: string;
  confirmPassword: string;
  validationNameMsg: string;
  showMessage: boolean;
  showRegistration: boolean;
}

export class RegistrationBase extends React.Component<Props, State> {

  public state: State = {
    username: '',
    password: '',
    error: false,
    message: '',
    confirmPassword: '',
    validationNameMsg: '',
    showMessage: false,
    showRegistration: true,
  };

  public render = RegistrationView.bind(this);
  private _loadingBar: any = null;

  constructor(props: Props) {
    super(props);
    this._loadingBar = null;
  }

  public setGA = () => {
    const trackingID = process.env.REACT_APP_GA_TRACKING_ID || '';
    ReactGA.initialize(trackingID);
    ReactGA.pageview('Registration confirmation page view');
  }

  public componentDidMount = async () => {
    this.setGA();
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        error: true,
        message: this.formatMessage('registration.invitation.password.noMatch'),
      });
    } else {
      this.setState({
        error: false,
        showMessage: false,
        showRegistration: false,
      }, () => {
        this.postRegistration();
      });
    }

  }

  public postRegistration = async  () => {
    const { username, password } = this.state;
    const payload: Payload = {
      username, password,
    };
    const request: Request<{}> = {
        method: HttpRequestType.POST,
        payload,
        relativePath: `/public/register/${this.getEmailToken()}`,
        token: 'not needed',
        refreshToken: 'not needed',
        id: 'register',
      };
    await this.props.call(request);
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.state !== this.props.state) {

      switch (this.props.state) {

        case AsyncState.REQUESTED:
          this._loadingBar.continuousStart();
          break;

        case AsyncState.ERROR:
          this._loadingBar.complete();
          this.setState({
            error: true,
            showMessage: true,
            message: this.props.status.toString() + ' ' + this.props.error.message,
          });
          break;

        case AsyncState.SUCCESS:
          this._loadingBar.complete();
          this.redirectAfterSuccess();
          break;
      }
    }
  }

  public getEmailToken() {
    console.log(this.props);
    return this.props.match.params.emailToken;
  }

  public changeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      username: value,
    });
  }

  public changePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      password: value,
    });
  }

  public changeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      confirmPassword: value,
    });
  }

  public hasError(): boolean {
    return this.state.error;
  }

  public setRef = (ref: any) => {
    this._loadingBar = ref;
  }

  public componentWillUnmount() {
    this._loadingBar.complete();
  }
  public formatMessage(id: string) {
    return this.props.intl.formatMessage({id});
  }

  private redirectAfterSuccess() {
    this.props.alert.success(this.formatMessage('registration.invitation.success'));
    setTimeout(() => {
      this.props.history.push(`/auth/login`);
    }, 2000);
  }

}

const RegistrationBaseWithAlert = withAlert<RegistrationBase['props']>()(RegistrationBase);
const RegistrationWithContext = withContext(SessionContext)(RegistrationBaseWithAlert);
const RegistrationCall = Api(RegistrationWithContext);
const RegistrationBaseWithIntl = injectIntl(RegistrationCall);
export const Registration = AuthApi(RegistrationBaseWithIntl);
