import { Layout } from '../base/component/Layout/Layout.component';
import { Roles } from '../base/model/route/Role';
import { Route } from '../base/model/route/Route';
import { AnswerList } from './component/AnswerList/AnswerList.component';
import { EditAnswer } from './component/EditAnswer/EditAnswerLayout.component';
import { AnswerStoreProvider } from './state/AnswerStoreProvider';

const roles = Object.keys(Roles);

const routes: Route[] = [
  {
    path: '/answers',
    default: '/answers/list/?page=0',
    provider: AnswerStoreProvider,
    routes: [
      {
        path: '/answers/list',
        component: AnswerList,
        authorize: roles,
        redirectNonAuthorized: '/unauthorized',
        layout: Layout,
      },
      {
        path: '/answers/create',
        component: EditAnswer,
        authorize: roles,
        redirectNonAuthorized: '/unauthorized',
        layout: Layout,
      },
      {
        path: '/answers/edit/:answerId',
        component: EditAnswer,
        authorize: roles,
        redirectNonAuthorized: '/unauthorized',
        layout: Layout,
      },
    ],
  },
];

export default routes;
