import { Api, RefreshApi } from '../../../../../api/Server';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { AppRouteComponentProps } from '../../../../base/model/route/AppRouteComponentProps';
import { AssistantModel, AssistantParams, EditAssistantStateModel } from '../state/EditAssistantStateModel';
import { ChangeEvent, FormEvent } from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { EditAssistantContext } from '../state/EditAssistantContext';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { InfoAssistantView } from './InfoAssistant.view';
import { Navigation } from '../../../../base/model/route/Navigation';
import { Request } from '../../../../../model/http/Request';
import { Response } from '../../../../../model/http/Response';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { withAlert } from 'react-alert';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { Languages, Type } from '../../EditAssistant/state/EditAssistantStateModel';

enum FormOption {
  GENERALSETTINGS = 'generalsettings',
  TEXTTOSPEECH = 'texttospeech',
  SPEECHTOTEXT = 'speechtotext',
  SENTIMENT = 'sentiment',
}

interface ResponseUpdate extends Response {
  content: AssistantModel;
  timestamp: Date;
}

interface InfoState extends ApiState {
  name: string;
  displayName: string;
  description: string;
  isValid: boolean;
  maxIdleTime: string;
  studioConfidence: string;
  type: Type;
  language: Languages;
  maxData: string;
}

interface Payload {
  name: string;
  displayName: string;
  description: string;
  params: {};
}

interface IntlProps {
  intl: IntlShape;
}

type Props = IntlProps &
  ApiProps<ResponseUpdate> &
  SessionStateModel &
  EditAssistantStateModel &
  RouteComponentProps<Navigation> &
  RouteComponentProps<{ botId: string; settingId: string }> &
  WithAlertProps &
  AppRouteComponentProps;

export class AssistantInfoBase extends ApiBase<ResponseUpdate, Props, InfoState> {
  public render = InfoAssistantView.bind(this);

  constructor(props: Props) {
    super(props);
    const {
      name = '',
      description = '',
      displayName = '',
      maxIdleTime = '',
      studioConfidence=  '',
      maxData = '',
      type = Type.SDK,
      language = Languages.en,
    } = this.props.assistantData || {};
    this.state = {
      name,
      description,
      displayName,
      error: null,
      authError: null,
      isValid: false,
      maxIdleTime,
      studioConfidence,
      maxData,
      type,
      language,
    };

    this.reloadAssistant = this.reloadAssistant.bind(this);
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (nextProps.assistantData !== this.props.assistantData) {
      const {
        name = '',
        description = '',
        displayName = '',
        maxIdleTime = '',
        type = Type.SDK,
        language = Languages.en,
      } = nextProps.assistantData || {};
      this.setState({
        name,
        description,
        maxIdleTime,
        displayName,
        type,
        language,
      });
    }
  }

  public updateAssistant() {
    const params = {
      [`${AssistantParams.MAX_IDLE_TIME}`]: this.state.maxIdleTime,
      [`${AssistantParams.DATA_EXPIRATION_DAYS}`]: this.state.maxData,
      ...(this.state.type === Type.STUDIO && {
        [`${AssistantParams.STUDIO_LANGUAGE}`]: this.state.language,
      }),
    };

    const payload: Payload = {
      name: this.state.name,
      description: this.state.description,
      displayName: this.state.displayName,
      params,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/assistants/${this.props.currentAssistant}/`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };

    this.doCall(request);
  }

  public reloadAssistant() {
    const assistantData = this.props.assistantData !== undefined ? this.props.assistantData : undefined;
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/assistants/uuid/${
        assistantData !== undefined && assistantData !== null ? assistantData.uuid : null
      }`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'reload-assistant',
    };
    this.doCall(request);
  }

  public getMaxIdleTime = (): string => {
    return this.state.maxIdleTime ? this.state.maxIdleTime.toString() : '0';
  }

  public getMaxData = (): string => {
    return this.state.maxData ? this.state.maxData.toString() : '0';
  }

  public changeMaxIdleConversationTime = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      maxIdleTime: value,
    });
  }

  public changeMaxData = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      maxData: value,
    });
  }

  public getName = (): string => {
    return this.state.name;
  }

  public getDisplayName = (): string => {
    return this.state.displayName;
  }

  public getDescription = (): string => {
    return this.state.description;
  }

  public changeNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      name: value,
    });
  }

  public changeDisplayNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      displayName: value,
    });
  }

  public changeDescriptionHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      description: value,
    });
  }

  public setIsFormValid(isValid: boolean) {
    if (this.state.isValid !== isValid) {
      this.setState({
        isValid,
      });
    }
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState(
      {
        error: null,
      },
      () => {
        this.updateAssistant();
      },
    );
  }

  public hasError(): boolean {
    return this.state.error !== null;
  }

  public setResponseData() {
    if (this.props.callId === 'reload-assistant') {
      const { uuid, name, description, displayName, status, maxIdleTime, studioConfidence, maxData, type, language,
        params, trainingStatus } =
        this.props.response.content;
      const assistantData: AssistantModel = {
        uuid,
        name,
        displayName,
        description,
        status,
        maxIdleTime,
        studioConfidence,
        maxData,
        type,
        language,
        params,
        trainingStatus,
      };
      this.props.setAssistantInfo(assistantData);
    } else {
      const assistantInfo: AssistantModel = this.props.response.content;
      this.props.setAssistantInfo(assistantInfo);
      this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
    }
  }

  public getAssistantId() {
    return this.props.match.params.botId;
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public getSectionRoute = (option: FormOption) => {
    return `/bots/edit/${this.getAssistantId()}/info/${option}`;
  }

  public getFormOptionType() {
    return FormOption;
  }

  public getFormActiveClass(option: FormOption) {
    return this.props.location.pathname === this.getSectionRoute(option)
      ? this.formatMessage('active').toLowerCase() : '';
  }
  public isAdmin(): boolean {
    return super.isAdmin(this.props.authorities);
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
  }
}

const InfoAssistantWithIntl = injectIntl(AssistantInfoBase);
const InfoAssistantWithAlert = withAlert<AssistantInfoBase['props']>()(InfoAssistantWithIntl);
const InfoAssistantWithSession = withContext(SessionContext)(InfoAssistantWithAlert);
const InfoAssistantWithContext = withContext(EditAssistantContext)(InfoAssistantWithSession);
const AssistantInfoWithApi = RefreshApi(Api(InfoAssistantWithContext));
export const AssistantInfo = withRouter(AssistantInfoWithApi);
