import React from 'react';
import ConfirmView from './Confirm.view';

interface State {
  show: boolean;
}

interface Props {
  confirmation: string;
  cancelLabel: string;
  okLabel: string;
  show: boolean;
  proceedFunc: () => void;
  title: string;
  cancelFunc: () => void;
  setConfirmVisibility: (show: boolean) => void;
}

export class Confirm extends React.Component<Props, State> {

  public static defaultProps = {
    confirmation: 'You are about to ...',
    cancelLabel: 'Cancel',
    okLabel: 'Ok',
    show: false,
    title: 'Are you sure?',
    cancelFunc: () => false,
  };

  public render = ConfirmView.bind(this);

  public proceedClick = () => {
    this.props.proceedFunc();
    this.props.setConfirmVisibility(false);
  }

  public cancelClick = () => {
    this.props.cancelFunc();
    this.props.setConfirmVisibility(false);

  }
}
export default Confirm;
