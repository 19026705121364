import { Modal } from 'react-bootstrap';
import { notEmpty } from '../../../form/lib/rules';
import { CreateAssistantBase} from './CreateAssistant.component';
import { Languages, Type } from '../EditAssistant/state/EditAssistantStateModel';
import { Engines } from '../../model/engines';
import { Form } from '../../../form/component/Form.component';
import { FormattedMessage } from 'react-intl';
import { Input } from '../../../form/component/Input.component';
import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';

export const CreateAssistantView: ViewFunction<CreateAssistantBase> = function() {
  const formct = 'form-control';
  return (
    <Modal show={this.isModalVisible()} onHide={this.handleClose} className='shadow-sm'>
      <Modal.Header className='' closeButton>
        <Modal.Title as='h5' id={'createNewAssistant'}>
          <FormattedMessage id='createAssistant.createNewAssistant' />
        </Modal.Title>
      </Modal.Header>

      <Form onSubmit={this.onSubmit}>
        {(isValid: boolean) => {
          this.setIsFormValid(isValid);
          return (
            <React.Fragment>
              <Modal.Body>
                {this.hasError() && <div className='alert alert-danger'>{this.state.appError!.message}</div>}
                <div className='form-group'>
                  <div className='form-group'>
                    <label>
                      <FormattedMessage id='type' />
                    </label>
                    <select
                      id={'botType'}
                      className='form-control ml-1'
                      style={{ width: 'auto' }}
                      onChange={this.changeType}
                    >
                      <option id={Type.SDK.toLocaleLowerCase()} value={Type.SDK}>SDK</option>
                      {process.env.REACT_APP_IS_STUDIO_VISIBLE === this.formatMessage('yes')
                        && <option id={Type.STUDIO.toLocaleLowerCase()} value={Type.STUDIO}>Studio</option>}
                    </select>
                  </div>
                  <label htmlFor='name'>
                    <FormattedMessage id='name' />
                  </label>
                  <Input
                    type='text'
                    className={formct}
                    id='name'
                    placeholder={this.formatMessage('createAssistant.enterName')}
                    value={this.state.name}
                    onChange={this.changeName}
                    rules={[notEmpty()]}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='displayName'>
                    <FormattedMessage id='displayName' />
                  </label>
                  <Input
                    type='text'
                    className={formct}
                    id='displayName'
                    placeholder={this.formatMessage('createAssistant.enterName')}
                    value={this.state.displayName}
                    onChange={this.changeDisplayName}
                    rules={[notEmpty()]}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='desc'>
                    <FormattedMessage id='description' />
                  </label>
                  <textarea
                    className={formct}
                    id='desc'
                    placeholder={this.formatMessage('createAssistant.enterDescription')}
                    value={this.state.description}
                    onChange={this.changeDescription}
                  />
                </div>
                {process.env.REACT_APP_IS_WATSON_VISIBLE === this.formatMessage('yes')
                  && this.state.type === Type.SDK  && (
                  <div className={'form-group'}>
                    <label>Engine</label>
                    <select
                      className={'form-control ml-1'}
                      style={{ width: 'auto' }}
                      onChange={this.changeAssistantService}
                    >
                      <option value={Engines.WATSON}>{Engines.WATSON.toString()}</option>
                      <option value={Engines.RASA}>{Engines.RASA.toString()}</option>
                    </select>
                  </div>
                )}
                {this.state.type === Type.STUDIO && (
                  <div className='form-group'>
                    <label>
                      <FormattedMessage id='createAssistant.language' />
                    </label>
                    <select className='form-control ml-1' style={{ width: 'auto' }} onChange={this.changeLanguage}>
                      {Object.keys(Languages).map((item: string, index: number) => {
                        return  <option key={index} value={item}>{item}</option>;
                      })}
                    </select>
                  </div>
                )}
              </Modal.Body>
              <div className='modal-footer'>
                <button
                  id='cancel'
                  type='button'
                  className='btn btn-dark'
                  data-dismiss='modal'
                  onClick={this.handleClose}
                >
                  {this.formatMessage('cancel')}
                </button>
                <button id='save' type='submit' className='btn btn-primary'>
                  {this.formatMessage('saveChanges')}
                </button>
              </div>
            </React.Fragment>
          );
        }}
      </Form>
    </Modal>
  );
};
