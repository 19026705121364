import React from 'react';
import 'react-phone-input-2/lib/style.css';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { CountrySelectBase } from './CountrySelect.component';

export const CountrySelectView: ViewFunction<CountrySelectBase> = function() {
  return (
    <React.Fragment>
      <div>
        <select
          className={`form-control ${this.isCountryEmptyStyle(this.state.countryCode)}`}
          onChange={(e) => this.handleChange(e)}
          onClick={() => this.hasClick()}
        >
          {this.props.numcode ? (
            <option value={this.props.numcode}>{this.props.nicename}</option>
          ) : (
            <option value='choose'>{this.formatMessage('countries.choose')}</option>
          )}
          {this.state.data &&
            this.state.data.map((country) => {
              return (
                <option key={country.numcode} value={country.numcode}>
                  {country.nicename}
                </option>
              );
            })}
        </select>
        {<span className='invalid-feedback-country-select'>{this.isCountryEmptyMessage(this.state.countryCode)}</span>}
      </div>
    </React.Fragment>
  );
};
