import * as React from 'react';
import {injectIntl, IntlShape} from 'react-intl';
import { AnswerType, answerTypeCode } from '../../../model/AnswerType';
import { Languages, languagesCode } from '../../../model/Languages';
import { AnswerFiltersView } from './AnswerFilters.view';

interface State {
  filterLang: string;
  filterType: string;
}

interface Props {
  language: string | null;
  type: string | null;
  onChange: (language: string | null, type: string | null) => void;
  intl: IntlShape;
}

export class AnswerFiltersBase extends React.Component<Props, State> {

  public render = AnswerFiltersView.bind(this);

  public constructor(props: Props) {
    super(props);
    this.state = {
      filterLang: this.props.language || '',
      filterType: this.props.type || '',
    };
  }

  public changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      filterLang: value,
    }, this.executeChangeCallback);
  }

  public changeType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value: string = target.value;

    this.setState({
      filterType: value,
    }, this.executeChangeCallback);
  }

  public getAnswerLanguageCode = (value: Languages) => {
    return value ? languagesCode[value] : '';
  }

  public getAnswerTypeCode = (value: AnswerType) => {
    return value ? answerTypeCode[value] : '';
  }

  public getAnswerLangs(): string[] {
    return Object.values(Languages);
  }

  public getAnswerTypes(): string[] {
    return Object.values(AnswerType);
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({id});
  }

  private executeChangeCallback = () => {
    const language = this.state.filterLang;
    const type = this.state.filterType;
    this.props.onChange(language, type);
  }

}

export const AnswerFilters = injectIntl(AnswerFiltersBase);
