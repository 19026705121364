import * as React from 'react';
import { DatePickerRangeView } from './DatePickerRange.view';

interface DatePickerProps {
  onFromDateChangeHandler: (date: Date | null) => void;
  onToDateChangeHandler: (date: Date | null) => void;
  fromDate: Date | null;
  toDate: Date | null;
}

interface State {
  fromDate: Date | null;
  toDate: Date | null;
}

type Props = DatePickerProps;

export class DatePickerRange extends React.Component<Props, State> {

  public render = DatePickerRangeView.bind(this);

  constructor(props: Props) {
    super(props);
    this.state = {
      fromDate: this.getFromDate(),
      toDate: this.getToDate(),
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const fromDate = this.getFromDate();
    const toDate = this.getToDate();
    if (fromDate !== prevProps.fromDate) {
      this.setState({
        fromDate,
        toDate,
      });
    }
  }

  public onFromDateChangeHandler = (date: Date | null) => {
    this.setState({
      fromDate: date,
    });
    this.props.onFromDateChangeHandler(date);
  }

  public onToDateChangeHandler = (date: Date | null) => {
    date?.setHours(23, 59, 59, 999);
    date?.toISOString();
    this.setState({
      toDate: date,
    });
    this.props.onToDateChangeHandler(date);
  }

  public disableToDate = () => {
    return !this.state.fromDate;
  }

  public getMinToDate = () => {
    const minToDate = this.state.fromDate;
    /* if (this.state.fromDate) {
      minToDate = new Date(this.state.fromDate);
      minToDate.setDate(minToDate.getDate() + 1);
    } */
    return minToDate;
  }

  public getMaxToDate = () => {
    const maxToDate = new Date();
    if (this.state.fromDate) {
      maxToDate.setDate(maxToDate.getDate() + 1);
    }
    return maxToDate;
  }

  public getFromDate = () => {
    return this.props.fromDate;
  }

  public getToDate = () => {
    return this.props.toDate;
  }

}
