import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { PublicLayout } from './PublicLayout.component';

export const PublicLayoutView: ViewFunction<PublicLayout> = function() {
  const { children } = this.props;

  return (
    <div className='container container-login'>
      {children}
    </div>
  );
};
