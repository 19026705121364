import React from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { AppError, AppErrorType, AuthErrorType } from '../../model/AppError';
import { AsyncState } from '../../model/AsyncState';
import { Request, RequestActions } from '../../model/http/Request';
import { Response } from '../../model/http/Response';
import { SessionStateModel } from '../auth/state/Session/SessionStateModel';
import { Navigation } from './model/route/Navigation';
import { Roles } from './model/route/Role';

export interface ApiProps<T extends Response> {
  call: (request: Request<object>, newToken?: string, acceptAudio?: boolean) => void;
  refresh: (key: string) => void;
  state: AsyncState;
  response: T;
  status?: number;
  error?: AppError | null;
  originalRequest?: Request<any> | null;
  refreshError?: AppError | null;
  refreshState: AsyncState;
  newToken?: string;
  newRefreshToken?: string;
  newExpiresIn?: Date;
  callId?: string | number;
  action?: RequestActions;
}

export interface ApiState {
  error: AppErrorType | null;
  authError: AppError | null;
  appError?: AppError | null;
}

type Props<P> = P & RouteComponentProps<Navigation> & SessionStateModel;

export abstract class ApiBase<R extends Response, P extends ApiProps<R>, S extends ApiState> extends React.Component<
  Props<P>,
  S
> {
  public _mounted: boolean = false;

  public componentDidMount() {
    this._mounted = true;
  }

  public componentDidUpdate(prevProps: Readonly<P>): void {
    if (prevProps.error !== this.props.error) {
      this.setState({
        appError: this.props.error,
      });
    }
    if (prevProps.state !== this.props.state) {
      if (this.props.state === AsyncState.ERROR) {
        this.props.loadBarRef.complete();
        this.handleErrorResponse();
      } else if (this.props.state === AsyncState.SUCCESS) {
        this.props.loadBarRef.complete();
        this.handleSuccessResponse();
      } else if (this.props.state === AsyncState.REQUESTED) {
        this.props.loadBarRef.continuousStart();
        this.setState({
          appError: null,
        });
      }
    }
  }

  public componentWillUpdate = async (nextProps: Readonly<Props<P>>) => {
    if (nextProps.refreshState !== this.props.refreshState) {
      // handle the token refresh response
      if (nextProps.refreshState === AsyncState.ERROR) {
        await this.handleTokenRefreshErrorResponse(nextProps);
      } else if (nextProps.refreshState === AsyncState.SUCCESS) {
        await this.handleTokenRefreshSuccessResponse(nextProps);
      }
    }
  }

  public isTokenExpired(expiresIn: Date | null) {
    if (expiresIn) {
      return new Date() > expiresIn;
    } else {
      this.redirectToLogin();
    }
  }

  public emiter(s: string, eventName: string = 'refreshedStatus') {
    const myEvent = new CustomEvent(eventName, {
      detail: s,
      bubbles: true,
      cancelable: true,
      composed: false,
    });
    document.dispatchEvent(myEvent);
  }

  public onEmiter(cb: (n: any) => any , eventName: string = 'refreshedStatus') {
    document.addEventListener(eventName, (event) => {
      cb(event);
    });
  }

  public componentWillUnmount() {
    this._mounted = false;
  }

  protected redirectToLogin(): void {
    this.props.clearSession();
    this.props.history.push('/auth/login');
  }

  protected doCall = async (request: Request<any>, acceptAudio?: boolean) => {
    if (!this.props.token || !this.props.refreshToken) {
      return;
    }
    request.token = this.props.token;
    request.refreshToken = this.props.refreshToken;
    await this.loadAndVerifyToken();
    await this.props.call(request, this.props.newToken, acceptAudio);
  }

  protected abstract setResponseData(): void;
  protected abstract setErrorResponseData(): void;

  protected isAdmin(authorities: string[]): boolean {
    return authorities && authorities.indexOf(Roles.admin) > -1;
  }

  private handleTokenRefreshErrorResponse = async (nextProps: Readonly<Props<P>>) => {
    this.props.setRequiredRefresh(false);
    if (nextProps.refreshError && nextProps.refreshError.type === AuthErrorType.EXPIRED_REFRESH_TOKEN) {
      this.redirectToLogin();
    }
  }

  private handleTokenRefreshSuccessResponse = async (nextProps: Readonly<Props<P>>) => {
    const { newToken, newRefreshToken, newExpiresIn, authorities } = nextProps;
    if (newToken && newRefreshToken && newExpiresIn) {
      // restore cookie with refreshed token
      this.props.setSessionData(newToken!, newRefreshToken!, newExpiresIn!, authorities!, this.props.currentUsername);
      this.props.setRequiredRefresh(false);
    }
    if (this.props.originalRequest) {
      await this.props.call(this.props.originalRequest!, newToken);
    }
  }

  private handleErrorResponse = async () => {
    if (this.props.error) {
      if (this.props.error.type === AuthErrorType.BAD_TOKEN) {
        // Request a token refresh
        if (this.props.refreshToken) {
          this.props.setRequiredRefresh(true);
          await this.props.refresh(`${this.props.refreshToken}`);
        } else {
          this.redirectToLogin();
        }
      } else {
        this.setState({
          error: this.props.error.type,
          appError: this.props.error,
        });
        this.setErrorResponseData();
      }
    }
  }

  private handleSuccessResponse() {
    this.setResponseData();
  }

  private loadAndVerifyToken = async () => {
    if (!this.props.token || !this.props.refreshToken) {
      this.redirectToLogin();
    } else {
      if (!this.props.isRefreshingToken() && this.isTokenExpired(this.props.expiresIn)) {
        this.props.setRequiredRefresh(true);
        await this.props.refresh(`${this.props.refreshToken}`);
      }
    }
  }
}
