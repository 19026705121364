export interface UserModel {
  uuid: string;
  username: string;
  email: string;
  mobileNumber: string;
  twoFactorAuth: boolean;
  firstName: string;
  lastName: string;
  lastLogin: string;
  status: string;
  created: Date;
  updated: Date;
  organization: string;
  organizationUuid: string;
  roles: string[];
  timezone: string;
}

export interface OrganizationModel {
  created: string;
  id: number;
  parameters: Parameters[];
  companyInfo: CompanyInfo;
  primaryHolder: PrimaryHolder;
  uuid: string;
  botsNumber: number;
  status: Status;
  updated: string;
}

export interface CompanyInfo {
  name: string;
  country: Country;
  streetAddress1: string;
  streetAddress2: string;
  zip: string;
  vatId: string;
}

export interface Payload {
  company: {
    countryCode: number;
    name: string;
    streetAddress1: string;
    streetAddress2: string;
    vatId: string;
    zip: string;
  };
  primaryAccountHolder: {
    email: string;
    name: string;
    phone: string;
    timezone: string;
  };
  adminUser?: {
    username: string;
    email: string;
    password: string;
    timezone: string;
  };
  settings?: {
    allowedBots?: string,
    trialStatus: string | undefined,
  };
}

export interface Country {
  nicename: string;
  numcode: number;
}

export interface PrimaryHolder {
  email: string;
  phone: string;
  firstname: string;
  lastname: string;
}

export enum OrganizationParamType {
  TRIAL = 'TRIAL',
  TRIAL_BOT_ALLOWED = 'TRIAL_BOT_ALLOWED',
}

export interface Parameters {
  key: OrganizationParamType;
  value: Status | string;
}

export enum Status {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  INVITED = 'INVITED',
}
