import React from 'react';

export const withContext =
  (Context: React.Context<any>) =>
    <P extends object>(WrappedComponent: React.ComponentType<any>) =>
  class WithContext extends React.Component<P> {
    public render() {
      return (
        <Context.Consumer>
          {(contextValue) => <WrappedComponent {...contextValue} {...this.props} />}
        </Context.Consumer>
      );
    }
  };
