import React, { FormEvent } from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteChildrenProps } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { AppError } from '../../../model/AppError';
import { AsyncState } from '../../../model/AsyncState';
import { HttpRequestType } from '../../../model/http/HttpRequestType';
import { Api, AuthApi } from '../../../api/Server';
import { Request } from '../../../model/http/Request';
import { SessionContext } from '../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../auth/state/Session/SessionStateModel';
import { AppRouteComponentProps } from '../../base/model/route/AppRouteComponentProps';
import { WithAlertProps } from '../../base/model/WithAlertProps';
import { withContext } from '../../lib/component/withContext/withContext.hoc';
import { RegistrationView } from './TrialRegistration.view';
import ReactGA from 'react-ga';

interface ComponentProps {
  authenticate: (username: string, password: string) => void;
  call: (Request: Request<{}>, newToken?: string, acceptAudio?: boolean) => void;
  authError: AppError;
  authState: AsyncState;
  authToken: string;
  authRefreshToken: string;
  authExpiresIn: Date;
  authAuthorities: string[];
  statusCode: number;
  status: number;
  callId: string;
  state: AsyncState;
  error: AppError;
}
interface IntlProps {
  intl: IntlShape;
}
type Props = IntlProps &
  ComponentProps &
  SessionStateModel &
  RouteChildrenProps &
  AppRouteComponentProps &
  WithAlertProps &
  RouteComponentProps<{ emailToken: string }>;

interface Payload {
  company: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  password: string;
  timezone: string;
}

interface State {
  company: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  error: boolean;
  message: string;
  password: string;
  timezone: string;
  confirmPassword: string;
  validationNameMsg: string;
  showMessage: boolean;
  showRegistration: boolean;
}

export class TrialRegistrationBase extends React.Component<Props, State> {
  public state: State = {
    company: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    password: '',
    error: false,
    message: '',
    confirmPassword: '',
    validationNameMsg: '',
    timezone: '',
    showMessage: false,
    showRegistration: process.env.REACT_APP_IS_REGISTRATION_VISIBLE === 'yes',
  };

  public render = RegistrationView.bind(this);
  private _loadingBar: any = null;

  constructor(props: Props) {
    super(props);
    this._loadingBar = null;
  }

  public setGA = () => {
    const trackingID = process.env.REACT_APP_GA_TRACKING_ID || '';
    ReactGA.initialize(trackingID);
    ReactGA.pageview('Registration page view');
  }

  public componentDidMount = async () => {
    this.setGA();
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        error: true,
        message: this.formatMessage('registration.invitation.password.noMatch'),
      });
    } else {
      this.setState(
        {
          error: false,
          showMessage: false,
          showRegistration: false,
        },
        () => {
          this.postRegistration();
        },
      );
    }
  }

  public postRegistration = async () => {
    const { company, email, firstName, lastName, phoneNumber, password, timezone } = this.state;
    const payload: Payload = {
      company,
      email,
      firstName,
      lastName,
      phoneNumber,
      password,
      timezone,
    };
    const request: Request<{}> = {
      method: HttpRequestType.POST,
      payload,
      relativePath: `/public/organization/register`,
      token: 'not needed',
      refreshToken: 'not needed',
      id: 'register',
    };
    await this.props.call(request);
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.state !== this.props.state) {
      switch (this.props.state) {
        case AsyncState.REQUESTED:
          this._loadingBar.continuousStart();
          break;

        case AsyncState.ERROR:
          this._loadingBar.complete();
          this.setState({
            error: true,
            showMessage: true,
            message: this.props.error.message,
          });
          break;

        case AsyncState.SUCCESS:
          this._loadingBar.complete();
          this.redirectAfterSuccess();
          break;
      }
    }
  }

  public changeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      company: value,
    });
  }

  public changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      email: value,
    });
  }

  public changeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      firstName: value,
    });
  }

  public changeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      lastName: value,
    });
  }

  public changePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      phoneNumber: value,
    });
  }

  public changePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      password: value,
    });
  }

  public changeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      confirmPassword: value,
    });
  }

  public handleTimezoneChange = (value: string) => {
    this.setState({ timezone: value });
  }

  public hasError(): boolean {
    return this.state.error;
  }

  public setRef = (ref: any) => {
    this._loadingBar = ref;
  }

  public componentWillUnmount() {
    this._loadingBar.complete();
  }
  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  private redirectAfterSuccess() {
    this.props.alert.success(this.formatMessage('registration.invitation.trialSent'));
    setTimeout(() => {
      this.props.history.push(`/auth/login`);
    }, 1000);
  }
}

const TrialRegistrationBaseWithAlert = withAlert<TrialRegistrationBase['props']>()(TrialRegistrationBase);
const TrialRegistrationWithContext = withContext(SessionContext)(TrialRegistrationBaseWithAlert);
const TrialRegistrationCall = Api(TrialRegistrationWithContext);
const TrialRegistrationBaseWithIntl = injectIntl(TrialRegistrationCall);
export const TrialRegistration = AuthApi(TrialRegistrationBaseWithIntl);
