import { PublicLayout } from '../base/component/PublicLayout/PublicLayout.component';
import { Route } from '../base/model/route/Route';
import { LoginForm } from './component/LoginForm/LoginForm.component';
import FormSendSuccess from './component/LoginForm/FormSendSuccess';
import { ResetPassword } from './component/LoginForm/ResetPassword.component';
import { ResetPasswordForm } from './component/LoginForm/ResetPasswordForm.component';
import { VerifyCodeForm } from './component/VerifyCode/VerifyCodeForm.component';
import {ResetPasswordTokenExpired} from './component/LoginForm/ResetPasswordTokenExpired';

const routes: Route[] = [
  {
    path: '/auth/verifyCode',
    component: VerifyCodeForm,
    layout: PublicLayout,
  },
  {
    path: '/auth/resetPasswordForm',
    component: ResetPasswordForm,
    layout: PublicLayout,
  },
  {
    path: '/auth/resetPassword/:token',
    component: ResetPassword,
    layout: PublicLayout,
  },
  {
    path: '/auth/resetPasswordSuccess/:messageKey',
    component: FormSendSuccess,
    layout: PublicLayout,
  },
  {
    path: '/auth/resetPasswordExpired',
    component: ResetPasswordTokenExpired,
    layout: PublicLayout,
  },
  {
    path: '/auth',
    component: LoginForm,
    layout: PublicLayout,
  },
];

export default routes;
