import React from 'react';
import {ViewFunction} from '../../../../../../../model/component/ViewFunction';
import {BubbleText} from '../bubbleText/BubbleText.component';
import {BubbleVideoBase} from './BubbleVideo.component';

export const BubbleVideoView: ViewFunction<BubbleVideoBase> = function() {
  return (
    <>
      <BubbleText text={this.getTitle()}/>
      <video className='videoClass' autoPlay controls src={this.getSrc()}/>
    </>
  );
};

export default BubbleVideoView;
