import { Api, RefreshApi } from '../../../../../api/Server';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { IntlShape, injectIntl } from 'react-intl';

import { EditAssistantContext } from '../state/EditAssistantContext';
import { EditAssistantStateModel } from '../state/EditAssistantStateModel';
import { Engines } from '../../../model/engines';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Message } from '../test/model/Message';
import { Navigation } from '../../../../base/model/route/Navigation';
import { Request } from '../../../../../model/http/Request';
import { Response } from '../../../../../model/http/Response';
import { RouteComponentProps } from 'react-router';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { Status } from '../../../model/Status';
import { StatusServiceView } from './StatusService.view';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { withAlert } from 'react-alert';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';

interface ResponseUpdate extends Response {
  content: string;
  timestamp: Date;
}

interface PayloadAssistantParams {
  params: {};
}

interface IntlProps {
  intl: IntlShape;
}

interface StatusProps {
  reload: () => void;
  status: Status;
  type: Engines;
  property: string;
  apiKey?: string;
  url?: string;
  serviceUrl?: string;
  tokenUrl?: string;
  asrToken?: string;
  idAppend: string;
}

interface StatusServiceState extends ApiState {
  status: Status;
  showConfirmDialog: boolean;
  messages: Message[];
  statusToChange: string;
}

type Props = IntlProps &
  ApiProps<ResponseUpdate> &
  SessionStateModel &
  EditAssistantStateModel &
  RouteComponentProps<Navigation> &
  WithAlertProps &
  StatusProps;

export class StatusServiceBase extends ApiBase<ResponseUpdate, Props, StatusServiceState> {
  public render = StatusServiceView.bind(this);

  constructor(props: Props) {
    super(props);
    this.state = {
      status: this.props.status,
      authError: null,
      error: null,
      appError: null,
      showConfirmDialog: false,
      messages: [],
      statusToChange: '',
    };
    this.setToggleClass = this.setToggleClass.bind(this);
  }

  public setConfirmVisibility = (show: boolean) => {
    this.setState({ showConfirmDialog: show });
  }

  public setMessages = (dialogueMessages: Message[]) => {
    this.setState({ messages: dialogueMessages });
  }

  public getConfirmDialog(): boolean {
    return this.state.showConfirmDialog;
  }

  public getStatusToChange(): string {
    return this.state.statusToChange;
  }

  public confirm = () => {
    const statusToChange = this.isToggleActive()
      ? this.formatMessage('disable').toLowerCase() : this.formatMessage('enable').toLowerCase();
    this.setState({
      statusToChange,
    });
    this.setConfirmVisibility(true);
  }

  public setToggleClass() {
    const params = {
      [`${this.props.property}`]: !this.isToggleActive(),
    };

    const payload: PayloadAssistantParams = {
      params,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/assistants/${this.props.currentAssistant}/`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: `update-status-${this.props.type.toLowerCase()}`,
    };

    this.doCall(request);
  }

  public confirmCallback = () => {
    this.setToggleClass();
  }

  public getToggleClass(): string {
    if (this.isToggleActive()) {
      return 'fas fa-toggle-on fa-2x on text-success';
    }
    return 'fas fa-toggle-off fa-2x off text-secondary';
  }

  public getToggleTitle(): string {
    if (this.isToggleActive()) {
      return 'Enabled';
    }
    return 'Disabled';
  }

  public isToggleActive(): boolean {
    return this.state.status === Status.ENABLED;
  }

  public setResponseData() {
    this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
    const oldState = this.state.status;
    this.setState({
      status: oldState === Status.ENABLED ? Status.DISABLED : Status.ENABLED,
    });
    this.props.reload();
  }

  public formatMessage(id: string, param?: string) {
    return this.props.intl.formatMessage({ id }, { status: param });
  }

  public isAdmin(): boolean {
    return super.isAdmin(this.props.authorities);
  }

  public componentId(): string {
    return `status_${this.props.idAppend}`;
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }
}

const StatusServiceWithIntl = injectIntl(StatusServiceBase);
const StatusServiceWithAlert = withAlert<StatusServiceBase['props']>()(StatusServiceWithIntl);
const StatusServiceWithSession = withContext(SessionContext)(StatusServiceWithAlert);
const StatusServiceWithContext = withContext(EditAssistantContext)(StatusServiceWithSession);
export const StatusService = RefreshApi(Api(StatusServiceWithContext));
