import { Layout } from '../base/component/Layout/Layout.component';
import { Roles } from '../base/model/route/Role';
import { Route } from '../base/model/route/Route';
import { Dashboard } from './component/Dashboard/Dashboard.component';
import { UserProfile } from '../account/component/UserProfile/UserProfile.component';

const roles = [Roles.admin, Roles.agent, Roles.user];

const routes: Route[] = [
  {
    path: '/dashboard',
    component: Dashboard,
    authorize: roles,
    layout: Layout,
    redirectNonAuthorized: '/unauthorized',
    routes: [],
  },
  {
    path: '/profile',
    component: UserProfile,
    authorize: roles,
    redirectNonAuthorized: '/unauthorized',
    layout: Layout,
    routes: [],
  },
];

export default routes;
