import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { CreateApiKeyBase } from './CreateApiKey.component';
import { CreateApiKeyModal } from './CreateApiKeyModal.component';

export const CreateApiKeyView: ViewFunction<CreateApiKeyBase> = function() {
  return (
    <CreateApiKeyModal
      isVisible={this.state.isModalVisible}
      onClose={this.handleClose}
      onSuccess={this.handleSuccess}
    />
  );
};
