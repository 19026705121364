import { withAlert } from 'react-alert';
import { FormEvent } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { Response } from '../../../../model/http/Response';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { AppRouteComponentProps } from '../../../base/model/route/AppRouteComponentProps';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { OrganizationModel } from './../../model/AccountModel';
import { OrganisationView } from './Organisation.view';

interface ResponseUpdate extends Response {
  content: OrganizationModel;
  timestamp: Date;
}

interface IntlProps {
  intl: IntlShape;
  payload: OrganizationModel;
}

interface State extends ApiState {
  data: OrganizationModel | null;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  streetAddress1: string;
  streetAddress2: string;
  country: number;
  countryNiceName: string;
  zipcode: string;
  vat: string;
  isValid: boolean;
  selectedRole: string;
  showConfirmDialog: boolean;
  countrySubmitError: boolean;
}

type Props = IntlProps &
  ApiProps<ResponseUpdate> &
  LayoutStateModel &
  RouteComponentProps<{ userId: string }> &
  SessionStateModel &
  WithAlertProps &
  AppRouteComponentProps;

export class OrganisationBase extends ApiBase<ResponseUpdate, Props, State> {
  public render = OrganisationView.bind(this);

  public state: State = {
    error: null,
    authError: null,
    data: null,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    streetAddress1: '',
    streetAddress2: '',
    country: 0,
    countryNiceName: '',
    zipcode: '',
    vat: '',
    isValid: false,
    selectedRole: 'user',
    showConfirmDialog: false,
    countrySubmitError: false,
  };

  public componentDidMount = async () => {
    this.props.setPageTitle(this.formatMessage('organization'));
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/organizations/myorganization`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'get-organisation-details',
    };
    await this.doCall(request);
  }

  public setResponseData() {
    if (this.props.callId === 'get-organisation-details') {
      const { content } = this.props.response;
      this.setState({
        data: content,
        firstName: content.primaryHolder.firstname,
        lastName: content.primaryHolder.lastname,
        email: content.primaryHolder.email,
        phone: content.primaryHolder.phone ? content.primaryHolder.phone : '',
        companyName: content.companyInfo.name,
        streetAddress1: content.companyInfo.streetAddress1,
        streetAddress2: content.companyInfo.streetAddress2,
        country: content.companyInfo.country ? content.companyInfo.country.numcode : 0,
        countryNiceName: content.companyInfo.country ? content.companyInfo.country.nicename : '',
        zipcode: content.companyInfo.zip,
        vat: content.companyInfo.vatId,

      });
    }
    if (this.props.callId === 'update-organisatioin') {
      this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
    }
  }

  public handleFormChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const key = e.currentTarget.name as keyof State;
    const value = e.currentTarget.value as string;

    if (Object.keys(this.state).includes(key)) {
      this.setState({
        ...this.state,
        [key]: value,
      });
    }
  }

  public handleCountryChange = (event: any): void => {
    const value: number = event.target.value;
    this.setState({
      country: value,
    });
  }

  public handlePhoneChange = (value: any) => {
    this.setState({
      phone: value,
    });
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public updateUser = async () => {
    const payload = {
      companyName: this.state.companyName,
      streetAddress1: this.state.streetAddress1,
      streetAddress2: this.state.streetAddress2,
      country: this.state.country,
      zipcode: this.state.zipcode,
      vatId: this.state.vat,
      primaryAccountHolder: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/organizations/${this.state.data?.uuid}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'update-organisatioin',
    };
    this.doCall(request);
  }

  public setIsFormValid = (isValid: boolean) => {
    if (this.state.isValid !== isValid) {
      this.setState({
        isValid,
      });
    }
  }

  public checkCountryError = () => {
    if (!this.state.country) {
      this.setState({
        countrySubmitError: true,
      });
    } else {
      this.setState({
        countrySubmitError: false,
      });
    }
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!this.state.countrySubmitError) {
      this.setState(
        {
          error: null,
        },
        () => {
          this.updateUser();
        },
      );
    }
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
  }
}

const OrganisationWithIntl = injectIntl(OrganisationBase);
const OrganisationWithAlert = withAlert<OrganisationBase['props']>()(OrganisationWithIntl);
const OrganisationWithApi = RefreshApi(Api(OrganisationWithAlert));
export const Organisation = withRouter(OrganisationWithApi);
