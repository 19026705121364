import React from 'react';
import { Input } from '../../../form/component/Input.component';
import { notEmpty } from '../../../form/lib/rules';
import {useIntl} from 'react-intl';

export const LoginFormOpener: any = (props: any) => {
  const intl = useIntl();
  return (
    <React.Fragment>
                <div className='form-group'>
                      <Input
                        id='username'
                        type='text'
                        className='form-control'
                        placeholder={intl.formatMessage({ id: 'login.enterUsername' })}
                        value={props.state.username}
                        onChange={props.changeUsername}
                        rules={[notEmpty()]}
                      />
                </div>
                <div className='form-group'>
                <Input
                        id='password'
                        type='password'
                        className='form-control'
                        placeholder={intl.formatMessage({ id: 'login.enterPassword'})}
                        value={props.state.password}
                        onChange={props.changePassword}
                        rules={[notEmpty()]}
                />
                </div>
                <div className='row'>
                  <div className='col-12 text-center'>
                  <button
                          type='submit'
                          id='login'
                          className='btn btn-primary'
                          disabled={!props.isValid}
                  >
                          {intl.formatMessage({id : 'login'})}
                  </button>
                  </div>
                </div>
        </React.Fragment>
  );
};

export default LoginFormOpener;
