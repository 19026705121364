import React from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteChildrenProps } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { AppError, AppErrorType } from '../../../model/AppError';
import { AsyncState } from '../../../model/AsyncState';
import { HttpRequestType } from '../../../model/http/HttpRequestType';
import { Api, AuthApi } from '../../../api/Server';
import { Request } from '../../../model/http/Request';
import { SessionContext } from '../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../auth/state/Session/SessionStateModel';
import { AppRouteComponentProps } from '../../base/model/route/AppRouteComponentProps';
import { WithAlertProps } from '../../base/model/WithAlertProps';
import { withContext } from '../../lib/component/withContext/withContext.hoc';
import { VerifyLinkView } from './VerifyLink.view';

interface ComponentProps {
  authenticate: (username: string, password: string) => void;
  call: (Request: Request<{}>, newToken?: string, acceptAudio?: boolean) => void;
  authError: AppError;
  authState: AsyncState;
  authToken: string;
  authRefreshToken: string;
  authExpiresIn: Date;
  authAuthorities: string[];
  callId: string;
  state: AsyncState;
  status: number;
  error: AppError;
}

interface IntlProps {
  intl: IntlShape;
}

type Props = IntlProps &
ComponentProps &
SessionStateModel &
RouteChildrenProps &
AppRouteComponentProps &
WithAlertProps &
RouteComponentProps<{ emailToken: string }>;

interface State {
  header: string;
  message: string;
  error: AppErrorType | null;
}
export class VerifyLinkBase extends React.Component<Props, State> {

  public state: State = {
    header: '',
    message: this.formatMessage('registration.invitation.verify.link'),
    error: null,
  };
  public render = VerifyLinkView.bind(this);
  private _loadingBar: any = null;

  constructor(props: Props) {
    super(props);
    this._loadingBar = null;
  }

  public componentDidMount = async () => {
    await this.verifyEmailToken();
  }

  public componentWillUnmount() {
    this._loadingBar.complete();
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.state !== this.props.state) {

      switch (this.props.state) {

        case AsyncState.REQUESTED:
          this._loadingBar.continuousStart();
          break;

        case AsyncState.ERROR:
          this._loadingBar.complete();
          if (this.props.status === 404) {
            this.setState({
              header: this.formatMessage('verify.linkExpired.header'),
              message: this.formatMessage('verify.linkExpired.message'),
            });
          } else {
            this.setState({
              header: 'Request Error',
              message: this.props.status.toString() + ' ' + this.props.error.message,
            });
          }
          break;

        case AsyncState.SUCCESS:

          this._loadingBar.complete();
          this.redirectAfterSuccess();
          break;
      }
    }
  }

  public verifyEmailToken = async  () => {
    const request: Request<{}> = {
        method: HttpRequestType.GET,
        payload: null,
        relativePath: `/public/verifyEmailToken/${this.getEmailToken()}`,
        token: 'not needed',
        refreshToken: 'not needed',
        id: 'verifyEmailToken',
      };
    await this.props.call(request);
  }

  public getEmailToken() {
    return this.props.match.params.emailToken;
  }

  public setRef = (ref: any) => {
    this._loadingBar = ref;
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  private redirectAfterSuccess() {
    this.props.alert.success(this.formatMessage('verify.success'));
    setTimeout(() => {
      this.props.history.push(`/public/register/${this.getEmailToken()}`);
    }, 2000);
  }

}

const VerifyLinkBaseWithAlert = withAlert<VerifyLinkBase['props']>()(VerifyLinkBase);
const VerifyLinkBaseWithContext = withContext(SessionContext)(VerifyLinkBaseWithAlert);
const VerifyLinkCall = Api(VerifyLinkBaseWithContext);
const VerifyLinkBaseWithIntl = injectIntl(VerifyLinkCall);
export const VerifyLink = AuthApi(VerifyLinkBaseWithIntl);
