
import { withAlert } from 'react-alert';
import {injectIntl, IntlShape} from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { SessionContext } from '../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps } from '../../../base/ApiComponent';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutContext } from '../../../base/state/Layout/LayoutContext';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { Answer } from '../../model/Answer';
import { AnswerType, answerTypeFormatToDropdown } from '../../model/AnswerType';
import { answerLangFormatToDropdown, Languages } from '../../model/Languages';
import { AnswerContext } from '../../state/AnswerContext';
import { AnswerStateModel } from '../../state/AnswerStateModel';
import { EditAnswerLayoutView } from './EditAnswerLayout.view';
import { AnswerResponse } from './model/AnswerResponse';

interface IntlProps {
  intl: IntlShape;
}

type Props =
  IntlProps
  & ApiProps<AnswerResponse<any>>
  & SessionStateModel
  & LayoutStateModel
  & AnswerStateModel
  & RouteComponentProps<{answerId: string}>
  & WithAlertProps;

export class EditAnswerLayoutBase extends ApiBase<AnswerResponse<any>, Props, never> {

  public render = EditAnswerLayoutView.bind(this);
  public title: string = this.formatMessage('editAnswer');

  public componentDidMount(): void {
    this._mounted = true;
    this.props.setPageTitle(this.title);

    const hash = window.location.hash;
    if (!hash) {
      const request: Request<{}> = {
        method: HttpRequestType.GET,
        payload: null,
        relativePath: `/admin/answers/uuid/${this.getAnswerId()}/`,
        token: this.props.token,
        refreshToken: this.props.refreshToken,
      };
      this.doCall(request);
    }

  }

  public getAnswerId() {
    return this.props.match.params.answerId;
  }

  public getAnswerCode() {
    if (this.props.answer) {
      return this.props.answer.code;
    }
  }

  public getAnswerType() {
    if (this.props.answer) {
      return this.props.answer.type;
    }
  }

  public getAnswerLang() {
    if (this.props.answer) {
      return this.props.answer.language;
    }
  }

  public getTitle() {
    return this.title;
  }

  public getAnswerTypes(): string[] {
    return Object.values(AnswerType).filter((item) => item !== null);
  }

  public getAnswerLangs(): string[] {
    return Object.values(Languages);
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({id});
  }

  protected setResponseData(): void {

    const capitalize = (s: string) => {
      if (typeof s !== 'string') {
        return '';
      }
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    const answerTypeObj: { [key: string]: any } =  {
      text: { text: this.props.response.content.text},
      html: { text: this.props.response.content.text},
      cms: { cmsId : this.props.response.content.cmsId},
      multichoice: {options: this.props.response.content.options},
      image: {title: this.props.response.content.title,
              thumbnailURL: this.props.response.content.thumbnailURL,
              url: this.props.response.content.url},
      video: {title: this.props.response.content.title,
              thumbnailURL: this.props.response.content.thumbnailURL,
              url: this.props.response.content.url},
      youtube: {youtubeId: this.props.response.content.youtubeId,
                title: this.props.response.content.title,
                thumbnailURL: this.props.response.content.thumbnailURL},
      links: {links: this.props.response.content.links},
      command: {name: this.props.response.content.name,
                params: this.props.response.content.params},
    };
    const enumVal: AnswerType = JSON.parse(`"${capitalize(this.props.response.content.type)}"`);

    const answer: Answer = {
      code: this.props.response.content.code,
      uuid: this.props.response.content.uuid,
      language: answerLangFormatToDropdown[this.props.response.content.language],
      type: answerTypeFormatToDropdown[enumVal],
      answerContent: answerTypeObj[this.props.response.content.type],
    };
    this.props.setBreadCrumbsInfo({
      currentPage: this.props.response.content.code,
      pageNumber: null,
    });
    this.props.setAnswer(answer);
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }

}

const EditAnswerWithIntl = injectIntl(EditAnswerLayoutBase);
const EditAnswerWithAlert = withAlert<EditAnswerLayoutBase['props']>()(EditAnswerWithIntl);
const EditAnswerWithLayout = withContext(LayoutContext)(EditAnswerWithAlert);
const EditAnswerWithSession = withContext(SessionContext)(EditAnswerWithLayout);
const EditAnswerContext = withContext(AnswerContext)(EditAnswerWithSession);
const EditAnswerWithApi = RefreshApi(Api(EditAnswerContext));
export const EditAnswer = withRouter(EditAnswerWithApi);
