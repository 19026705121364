import React from 'react';

export const LogsBubbleNumber: any = (props: any) => {
  const answerLength: number = props.item.answers.reduce((acc: number, value: any) => {
    if (value.type !== 'ssml' && value.type !== 'hints') {
      return acc + 1;
    } else {
      return acc;
    }
  }, 0);
  return (
    <small className='float-right'>
      <div
        data-toggle='collapse'
        role='button'
        onClick={props.handleConversationDetail(props.item)}
        aria-expanded='false'
        className='ml-1 links'
      >
        1/{answerLength}
      </div>
    </small>
  );
};
