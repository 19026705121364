import React from 'react';
import { Link } from 'react-router-dom';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import Confirm from '../../../base/component/confirm/Confirm.component';
import { PageSize } from '../../../base/component/PageSize/PageSize.component';
import { Pagination } from '../../../base/component/Pagination/Pagination.component';
import { Search } from '../../../base/component/Search/Search.component';
import { ColumnObj, Table } from '../../../base/component/Table/Table.component';
import { AnswerModel } from '../../model/AnswerModel';
import { CreateAnswer } from '../CreateAnswer/CreateAnswer.component';
import { AnswerListBase } from './AnswerList.component';
import { AnswerFilters } from './Filters/AnswerFilters.component';

export const AnswerListView: ViewFunction<AnswerListBase> = function() {

  const columns: ColumnObj[] = [
    {
      title: `${this.formatMessage('answerList.code')}`,
      propertyName: 'code',
      render: (item: AnswerModel) =>
        (<Link data-action='edit-answer' to={`/answers/edit/${item.uuid}`}>{item.code}</Link>),
    },
    {
      title: `${this.formatMessage('answerList.language')}`,
      propertyName: 'language',
      style: {width: '120px'},
      render: (item: AnswerModel) => (<span>{item.language}</span>),
    },
    {
      title: `${this.formatMessage('answerList.type')}`,
      propertyName: 'type',
      render: (item: AnswerModel) => (<span>{item.type}</span>),
    },
    ];

  return (
    <React.Fragment>
      <Confirm
        show={this.state.showConfirmDialog}
        okLabel={this.formatMessage('ok')}
        cancelLabel={this.formatMessage('cancel')}
        confirmation={this.formatMessage('assistant.studio.answer.confirmDelete')}
        proceedFunc={this.confirmCallback}
        title={this.formatMessage('confirm')}
        setConfirmVisibility={this.setConfirmVisibility}
      />
      <CreateAnswer/>
      <div className='card shadow-sm'>
        <div className='card-body'>
            <div className='row'>
              <Search
                searchHandler={this.searchHandler}
                search={this.getCriteriaFromFilter()}
              />
              <AnswerFilters
                type={this.getTypeFromFilter()}
                language={this.getLanguageFromFilter()}
                onChange={this.onFiltersChangeHandler}
              />
            </div>
            <Table
              tableId={'answers'}
              data={this.getData()}
              columns={columns}
              page={this.getPage()}
              dropDownMenuItems={this.dropDownMenuItems}
            />
          <div className='row mt-3'>
            <div className='col-sm-6'>
              <PageSize changePageSize={this.changePageSizeHandler} size={this.getPageSize()}/>
            </div>
            <div className='col-sm-6'>
              <Pagination
                totalPages={this.getTotalPages()}
                changePaginationHandler={this.changePageHandler}
                page={this.getPage()}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
