import { FormEvent } from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Api, RefreshApi } from '../../../../../api/Server';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../model/http/Request';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { Navigation } from '../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { EditAssistantContext } from '../state/EditAssistantContext';
import { ChannelParameter, ParamType, PageAccessTokenName } from './model/UpdateParams';
import { ChannelModel, ChannelStateModel } from './state/ChannelStateModel';
import { FacebookAPIProviderChannelView } from './FacebookAPIProviderChannel.view';

interface IntlProps {
  intl: IntlShape;
}

interface FacebookAPIResponse extends Response {
  timestamp: Date;
}

interface FacebookAPIState extends ApiState {
  pageAccessToken: string;
  item: ChannelModel | null;
}

interface Payload {
  parameters: ChannelParameter[];
}

type Props = IntlProps &
  ApiProps<any> &
  SessionStateModel &
  ChannelStateModel &
  LayoutStateModel &
  RouteComponentProps<{ botId: string }> &
  RouteComponentProps<Navigation> &
  WithAlertProps;

const requestId = 'update-cm-whatsap-channel';
export class FacebookAPIProviderChannelBase extends ApiBase<
  FacebookAPIResponse,
  Props & ChannelModel,
  FacebookAPIState
> {
  public render = FacebookAPIProviderChannelView.bind(this);

  constructor(props: Props & ChannelModel) {
    super(props);

    this.state = {
      item: null,
      pageAccessToken:
        props.pageAccessToken !== null && props.pageAccessToken !== undefined ? props.pageAccessToken.toString() : '',
      appError: null,
      error: null,
      authError: null,
    };
  }

  public getPageAccessToken(): string {
    return this.state.pageAccessToken !== undefined ? this.state.pageAccessToken : '';
  }

  public setPageAccessToken = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const value = target.value;

    this.setState({
      pageAccessToken: value,
    });
  }

  public getVerifyToken(): string {
    return this.props.verifyToken !== undefined ? this.props.verifyToken : '';
  }

  public submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const pageAccessTokenParam: ChannelParameter = {
      name: PageAccessTokenName,
      type: ParamType.TEXT,
      value: this.getPageAccessToken(),
    };

    const parameters: ChannelParameter[] = [pageAccessTokenParam];

    const payload: Payload = {
      parameters,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/assistants/channel/${this.props.uuid}/assistant/${this.props.currentAssistant}/update`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: requestId,
    };
    this.doCall(request);
  }

  public formatMessage(id: string): string {
    return this.props.intl.formatMessage({ id });
  }

  public buildUrl(): string {
    return `${process.env.REACT_APP_STUDIO_URL}/external/channels/${this.getUuid()}?X-API-Key=${
      this.props.selectedApiKey
    }&appId=<appId>&appSecret=<appSecret>&pageAccessToken=<pageAccessToken>`;
  }

  public getUuid(): string {
    return this.props.uuid;
  }

  public setErrorResponseData() {
    const callId = this.props.callId;
    if (callId === requestId) {
      this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
    }
  }

  public setResponseData() {
    const callId = this.props.callId;
    if (callId === requestId) {
      this.props.alert.success(this.formatMessage('alert.successfullyCreated'));
    }
  }

  public isAdmin(): boolean {
    return this.props.isAdmin;
  }
}

const FacebookAPIProviderChannelIntl = injectIntl(FacebookAPIProviderChannelBase);
const FacebookAPIProviderChannelWithAlert = withAlert<FacebookAPIProviderChannelBase['props']>()(
  FacebookAPIProviderChannelIntl,
);
const FacebookAPIProviderChannelWithSession = withContext(SessionContext)(FacebookAPIProviderChannelWithAlert);
const FacebookAPIProviderChannelWithContext = withContext(EditAssistantContext)(FacebookAPIProviderChannelWithSession);
const FacebookAPIProviderChannelWithApi = RefreshApi(Api(FacebookAPIProviderChannelWithContext));
export const FacebookAPIProviderChannel = withRouter(FacebookAPIProviderChannelWithApi);
