import { FormEvent } from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Api, RefreshApi } from '../../../../../api/Server';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../model/http/Request';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { Navigation } from '../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { EditAssistantContext } from '../state/EditAssistantContext';
import { CmWhatsappProviderView } from './CmWhatsappProviderChannel.view';
import { ChannelParameter, NumberName, ParamType, TokenName } from './model/UpdateParams';
import { ChannelModel, ChannelStateModel } from './state/ChannelStateModel';

interface IntlProps {
  intl: IntlShape;
}

interface CmProviderResponse extends Response {
  timestamp: Date;
}

interface CmProviderState extends ApiState {
  apiKey: string;
  businessNumber: string;
  item: ChannelModel | null;
}

interface Payload {
  parameters: ChannelParameter[];
}

type Props = IntlProps &
  ApiProps<any> &
  SessionStateModel &
  ChannelStateModel &
  LayoutStateModel &
  RouteComponentProps<{ botId: string }> &
  RouteComponentProps<Navigation> &
  WithAlertProps;

const requestId = 'update-cm-whatsap-channel';
export class CmWhatsappProviderBase extends ApiBase<CmProviderResponse, Props & ChannelModel, CmProviderState> {
  public render = CmWhatsappProviderView.bind(this);

  constructor(props: Props & ChannelModel) {
    super(props);

    this.state = {
      item: null,
      apiKey: props.xapiKey !== null && props.xapiKey !== undefined ? props.xapiKey.toString() : '',
      businessNumber:
        props.whatsappPhoneNumber !== null && props.whatsappPhoneNumber !== undefined
          ? props.whatsappPhoneNumber.toString()
          : '',
      appError: null,
      error: null,
      authError: null,
    };
  }

  public getXApiKey(): string {
    return this.state.apiKey;
  }

  public getWhatsappPhoneNumber(): string {
    return this.state.businessNumber;
  }

  public setXApiKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const value = target.value;

    this.setState({
      apiKey: value,
    });
  }

  public setWhatsappPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const value = target.value;

    this.setState({
      businessNumber: value,
    });
  }

  public submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const xApiKeyParam: ChannelParameter = {
      name: TokenName,
      type: ParamType.PASSWORD,
      value: this.getXApiKey(),
    };

    const businessNumberParam: ChannelParameter = {
      name: NumberName,
      type: ParamType.TEXT,
      value: this.getWhatsappPhoneNumber(),
    };

    const parameters: ChannelParameter[] = [xApiKeyParam, businessNumberParam];

    const payload: Payload = {
      parameters,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/assistants/channel/${this.props.uuid}/assistant/${this.props.currentAssistant}/update`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: requestId,
    };
    this.doCall(request);
  }

  public formatMessage(id: string): string {
    return this.props.intl.formatMessage({ id });
  }

  public buildUrl(): string {
    return `${process.env.REACT_APP_STUDIO_URL}/external/channels/${this.props.uuid}?X-API-Key=${this.props.selectedApiKey}`;
  }

  public getUuid(): string {
    return this.props.uuid;
  }

  public setErrorResponseData() {
    const callId = this.props.callId;
    if (callId === requestId) {
      this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
    }
  }

  public setResponseData() {
    const callId = this.props.callId;
    if (callId === requestId) {
      this.props.alert.success(this.formatMessage('alert.successfullyCreated'));
    }
  }

  public isAdmin(): boolean {
    return this.props.isAdmin;
  }
}

const CmWhatsappProviderWithIntl = injectIntl(CmWhatsappProviderBase);
const CmWhatsappProviderWithAlert = withAlert<CmWhatsappProviderBase['props']>()(CmWhatsappProviderWithIntl);
const CmWhatsappProviderWithSession = withContext(SessionContext)(CmWhatsappProviderWithAlert);
const CmWhatsappProviderWithContext = withContext(EditAssistantContext)(CmWhatsappProviderWithSession);
const CmWhatsappProviderWithApi = RefreshApi(Api(CmWhatsappProviderWithContext));
export const CmWhatsappProvider = withRouter(CmWhatsappProviderWithApi);
