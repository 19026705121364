import { Api, RefreshApi } from '../../../../api/Server';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { ChangeEvent, FormEvent } from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import { AssistantParams, Languages, Type } from '../EditAssistant/state/EditAssistantStateModel';
import { CreateAssistantView } from './CreateAssistant.view';
import { Engines } from '../../model/engines';
import { HeaderBtn } from '../../../base/component/PageHeader/model/HeaderBtn';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { Navigation } from '../../../base/model/route/Navigation';
import { Request } from '../../../../model/http/Request';
import { Response } from '../../../../model/http/Response';
import { RouteComponentProps } from 'react-router-dom';
import {
  SessionStateModel,
  OrganizationParamType,
  OrganizationTrial,
} from '../../../auth/state/Session/SessionStateModel';
import { WithAlertProps } from '../../../base/model/WithAlertProps';

interface State extends ApiState {
  name: string;
  description: string;
  isValid: boolean;
  assistantService: Engines;
  displayName: string;
  language: Languages;
  type: Type;
}

interface Payload {
  name: string;
  description: string;
  engineType: Engines;
  params: {};
  displayName: string;
}

interface Content {
  uuid: string;
  name: string;
  description: string;
  displayName: string;
  created: Date;
  updated: Date;
  status: string;
}

interface CallResponse extends Response {
  content: Content & number;
  timestamp: Date;
}

interface CreateAssistProps extends ApiProps<CallResponse> {
  updateList: () => void;
  intl: IntlShape;
}

type Props = CreateAssistProps &
  SessionStateModel &
  LayoutStateModel &
  RouteComponentProps<Navigation> &
  WithAlertProps;

const callSave = 'saveAssistant';
const callCount = 'countAssistants';

export class CreateAssistantBase extends ApiBase<CallResponse, Props, State> {
  public render = CreateAssistantView.bind(this);

  public state: State = {
    name: '',
    description: '',
    error: null,
    authError: null,
    isValid: false,
    appError: null,
    displayName: '',
    assistantService: process.env.REACT_APP_IS_WATSON_VISIBLE === this.formatMessage('yes') ? Engines.WATSON : Engines.RASA,
    language: Languages.en,
    type: Type.SDK,
  };

  constructor(props: Props) {
    super(props);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changeAssistantService = this.changeAssistantService.bind(this);
  }

  public componentDidMount(): void {
    this.countAssistants();
  }

  public componentWillUnmount(): void {
    this.props.removeHeaderBtn('create-new-assistant');
  }

  public isModalVisible(): boolean {
    return this.props.showModal;
  }

  public hasError(): boolean {
    return this.state.appError !== null;
  }

  public handleClose() {
    this.props.setModalVisibility(false);
  }

  public handleOpen() {
    this.setState({
      name: '',
      displayName: '',
      description: '',
      appError: null,
      language: Languages.en,
      type: Type.SDK,
    });

    this.props.setModalVisibility(true);
  }

  public setIsFormValid(isValid: boolean) {
    if (this.state.isValid !== isValid) {
      this.setState({
        isValid,
      });
    }
  }

  public changeName = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      name: value,
    });
  }

  public changeDisplayName = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      displayName: value,
    });
  }

  public changeDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      description: value,
    });
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState(
      {
        error: null,
      },
      () => {
        this.saveAssistant();
      },
    );
  }

  public saveAssistant = () => {
    const payload: Payload = {
      name: this.state.name,
      displayName: this.state.displayName,
      description: this.state.description,
      engineType: this.state.type !== Type.STUDIO ? this.state.assistantService : Engines.STUDIO,
      params: {
        [`${AssistantParams.TYPE}`]: this.state.type,
        ...(this.state.type === Type.STUDIO && { [`${AssistantParams.STUDIO_LANGUAGE}`]: this.state.language }),
      },
    };

    const request: Request<{}> = {
      id: callSave,
      method: HttpRequestType.POST,
      payload,
      relativePath: `/admin/assistants`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };

    this.doCall(request);
  }

  public countAssistants = () => {
    const request: Request<{}> = {
      id: callCount,
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/assistants/botcounter`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };

    this.doCall(request);
  }

  public changeAssistantService = (event: ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value as keyof typeof Engines;
    this.setState({
      assistantService: value as Engines,
    });
  }

  public changeType = (event: ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value as keyof typeof Type;
    this.setState({
      type: value as Type,
    });
  }

  public changeLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value as keyof typeof Languages;
    this.setState({
      language: value as Languages,
    });
  }

  public setResponseData() {
    const callId = this.props.callId;
    if (callId === callSave) {
      this.props.alert.success(this.formatMessage('alert.successfullyCreated'));

      this.setState({
        name: '',
        description: '',
        assistantService: process.env.REACT_APP_IS_WATSON_VISIBLE === this.formatMessage('yes') ? Engines.WATSON : Engines.RASA,
      });

      this.props.history.push('/bots/list/0');
      this.countAssistants();
      this.handleClose();
    }
    if (callId === callCount) {
      const trialProp = this.props.organization?.parameters.find((p) => p.key === OrganizationParamType.TRIAL)?.value;

      const botsAllowed = this.props.organization?.parameters.find(
        (p) => p.key === OrganizationParamType.TRIAL_BOT_ALLOWED,
      )?.value;
      const availability = !(
        trialProp === OrganizationTrial.ENABLED && this.props.response.content >= Number.parseInt(botsAllowed ?? '0', 0)
      );

      const title = this.formatMessage('createAssistant.createNewAssistant');
      const hint = availability ?  this.formatMessage('createAssistant.createNewAssistant') :  this.formatMessage('createAssistant.createNewAssistant.disabled');

      const createAssistantBtn: HeaderBtn = {
        title: title,
        hint: hint,
        callback: this.handleOpen,
        id: 'create-new-assistant',
        enabled: availability
      };

      this.props.addHeaderBtn(createAssistantBtn);

    }
  }

  public formatMessage(id: string): string {
    return this.props.intl.formatMessage({ id });
  }

  protected setErrorResponseData(): void {
    const callId = this.props.callId;
    if (callId === callCount) {
      this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
    }
  }
}

const CreateAssistantWithApi = RefreshApi(Api(CreateAssistantBase));
export const CreateAssistant = injectIntl(CreateAssistantWithApi);
