import moment from 'moment';
import {DateTimeFormat} from '../model/datetime/datetime';

export const dateFormat = (formatStr: string, date: string) => {
  return moment(date).format(formatStr);
};

export const utcDateFormat = (date: string) => {
  if (new Date(date).toString() === 'Invalid Date') {
    return '';
  }

  return moment.utc(date).format(DateTimeFormat.YMD_Hm);
};
