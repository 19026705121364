export enum GenericErrorType {
  GENERIC_ERROR,
  TYPE_ERROR,
}

export enum ConnectionErrorType {
  NETWORK_ERROR,
}

export enum AuthErrorType {
  BAD_CREDENTIALS,
  BAD_TOKEN,
  EXPIRED_REFRESH_TOKEN,
}
export enum SuccessType {
  ACCEPTED,
  OK,
}

export type AppErrorType = GenericErrorType | AuthErrorType | ConnectionErrorType;

export class AppError {
  private _message: string;
  private _code: number;

  get type(): AppErrorType {
    return this.appType;
  }

  set type(value: AppErrorType) {
    this.appType = value;
  }

  get message(): string {
    return this._message;
  }

  set message(value: string) {
    this._message = value;
  }

  get code(): number {
    return this._code;
  }

  set code(value: number) {
    this._code = value;
  }

  private appType: AppErrorType;

  constructor(type: AppErrorType) {
    this.appType = type;
    this._message = '';
    this._code = 0;
  }
}
