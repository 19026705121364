import React from 'react';
import { Link } from 'react-router-dom';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import Routes from '../../../base/component/Routes/Routes';
import { EditAssistantBase } from './EditAssistant.component';
import { AssistantStatus } from './status/Status.component';
import { ImportBot } from './ImportBot.component';
import Confirm from '../../../base/component/confirm/Confirm.component';
interface AssistantSection {
  name: string;
  identifier: string;
}

export const EditAssistantView: ViewFunction<EditAssistantBase> = function() {
  // while the data isn't fully loaded, we don't display anything
  if (!this.props.assistantData) {
    return null;
  }

  const importProps = {
    modalVisibility: this.state.modalVisibility,
    onClose: this.onClose,
    onImport: this.onClickImport,
  };

  return (
    <>
    <Confirm
        show={this.state.showConfirmDialog}
        okLabel={'Ok'}
        cancelLabel={'Cancel'}
        confirmation={this.formatMessage('assistant.studio.import.logs')}
        proceedFunc={this.confirmCallback}
        title={'Confirm'}
        setConfirmVisibility={this.setConfirmVisibility}
    />
      <ImportBot {...importProps} />
      <AssistantStatus {...this.props} />
      <ul className='nav nav-pills mb-3' id='myTabContent' role='tablist'>
        {this.sections.map((section: AssistantSection, idx) => {
          if (this.state.isStudioBot && section.identifier === 'studio') {
            return (
              <li className='nav-item' key={idx}>
                <Link
                  id={`view-${section.identifier}-section`}
                  className={`nav-link shadow-sm mr-1 ${this.getActiveClass(section.identifier)}`}
                  role='tab'
                  aria-controls={section.identifier}
                  aria-selected={this.getAriaSelected(section.identifier)}
                  to={this.getSectionRoute(section.identifier)}
                >
                  {section.name}
                </Link>
              </li>
            );
          } else if (section.identifier !== 'studio') {
            return (
              <li className='nav-item' key={idx}>
                <Link
                  id={`view-${section.identifier}-section`}
                  className={`nav-link shadow-sm mr-1 ${this.getActiveClass(section.identifier)} ${ section.identifier === 'test' && this.state.disableTab ? 'disableTab' : ''}`}
                  role='tab'
                  title={section.identifier === 'test' && this.state.disableTab ? this.getTextTitle() : ''}
                  aria-controls={section.identifier}
                  aria-selected={this.getAriaSelected(section.identifier)}
                  to={this.getSectionRoute(section.identifier)}
                >
                  {section.name}
                </Link>
              </li>
            );
          } else {
            return null;
          }
        })}
      </ul>
      <Routes routes={this.props.routes} />
    </>
  );
};
