import d3 from 'd3';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { renderToString } from 'react-dom/server';
import NVD3Chart from 'react-nvd3';
import moment from 'moment';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { NumberOfConversationsChartBase } from './NumberOfConversationsChart.component';

export const NumberOfConversationsChartView: ViewFunction<NumberOfConversationsChartBase> = function() {
  const setTooltip = (chart: any) => {
    chart.tooltip.contentGenerator((obj: any) => {
      const point = obj.point;
      const date = moment(point.label).format('L');
      const conversationValue = point.value;
      return renderToString(
        <div className='robo-chart-tooltip avg-confidence-chart-tooltip'>
          <span className='date'>{date}</span>
          <span className='confidence-label'>Conversations</span>
          <span className='confidence-value'>{conversationValue}</span>
        </div>,
      );
    });
  };

  const renderStart = (chart: any) => {
    setTooltip(chart);
  };
  return (
    <div>
      <div className='card-chart card shadow-sm'>
        <div className='card-header text-left'>
          <FormattedMessage id='numberOfConversationsChart.numberOfConversations' />
        </div>

        <div className='card-body'>
          {!this.state.loaded && <div style={{ paddingBottom: '328px' }}>loading...</div>}
          {this.state.loaded && (
            <div id='number-conversations' style={{ width: '100%', height: '350px' }}>
              <NVD3Chart
                id='topAssistants'
                datum={this.getChartData}
                type='lineChart'
                x={this.getX}
                y={this.getY}
                staggerLabels={false}
                duration='250'
                height='350'
                showLegend
                margin={{ left: 70, right: 20, top: 10, bottom: 50 }}
                reduceXTicks={false}
                xAxis={{
                  tickFormat: (d: any) => {
                    return d3.time.format(this.getTickFormat())(new Date(d));
                  },
                  axisLabel: this.state.axisLabel,
                }}
                xScale={d3.time.scale()}
                yAxis={{ tickFormat: d3.format('d'), axisLabel: this.formatMessage('statsAssistantChart.axis.label.y')}}
                valueFormat={d3.format(',f')}
                useInteractiveGuideline
                renderStart={renderStart}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NumberOfConversationsChartView;
