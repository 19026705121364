import React from 'react';
import { EditorState } from 'draft-js';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { AddFAQBase } from './AddFAQ.component';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Input } from '../../../../form/component/Input.component';
import { notEmpty } from '../../../../form/lib/rules';
import { ColumnObj, Table } from '../../../../base/component/Table/Table.component';
import { Form } from '../../../../form/component/Form.component';
import Confirm from '../../../../base/component/confirm/Confirm.component';

export const AddFAQView: ViewFunction<AddFAQBase> = function() {
  const createMarkup = (value: EditorState) => {
    return { __html: this.getHtmlString(value) };
  };

  const columns: ColumnObj[] = [
    {
      title: this.formatMessage('text'),
      propertyName: 'label',
      render: (item: string[]) => <p>{item}</p>,
    },
  ];

  return (
    <Modal show={this.isModalVisible()} onHide={this.clearAndClose} className='shadow-sm' dialogClassName='wide-modal'>
        <Confirm
          show={this.state.showConfirmDialogue}
          okLabel={this.formatMessage('ok')}
          cancelLabel={this.formatMessage('cancel')}
          confirmation={this.formatMessage('are.you.sure')}
          proceedFunc={this.confirmCallback}
          title={this.formatMessage('confirm')}
          setConfirmVisibility={this.setConfirmVisibility}
        />
      <Modal.Header className='' closeButton>
        <Modal.Title as='h5'>
          {this.props.FAQToEdit ? <FormattedMessage id='assistant.studio.editstory' /> : <FormattedMessage id='assistant.studio.createnewstory' />}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={this.onSubmit}>
        {(isValid: boolean) => {
          this.setIsFormValid(isValid);
          return (
            <React.Fragment>
              <Modal.Body>
                <div className='form-group'>
                  <label htmlFor='title'>
                    <FormattedMessage id='title' />
                  </label>
                  <Input
                    type='text'
                    className='story-title-description-help form-control'
                    id='story'
                    placeholder={this.formatMessage('assistant.studio.entertitle')}
                    value={this.state.title}
                    onChange={this.changeTitleHandler}
                    rules={[notEmpty()]}
                  />
                </div>
                <div className='story-title-description-help form-group'>
                  <label htmlFor='description'>
                    <FormattedMessage id='description' />
                  </label>
                  <Input
                    type='text'
                    className='form-control'
                    id='description'
                    placeholder={this.formatMessage('assistant.studio.enterdescription')}
                    value={this.state.description}
                    onChange={this.changeDescriptionHandler}
                    rules={[notEmpty()]}
                  />
                </div>
                <div className='form-group story-examples-help '>
                  <label htmlFor='questionexamples'>
                    <FormattedMessage id='assistant.studio.questionexamples' />
                  </label>

                  <div className='row'>
                  <div className='col-6'>
                    <div className='input-group'>
                      <input
                        type='text'
                        className='form-control'
                        id='questionexamples'
                        value={this.state.question}
                        onKeyDown={this._handleKeyDown}
                        placeholder={this.formatMessage('assistant.studio.enterexample')}
                        onChange={this.changeQuestionHandler}
                      />
                      <div className='story-examples-help input-group-append'>
                        <button className='btn btn-light' type='button' id='add' onClick={this.addQuestion}>
                          <FormattedMessage id='add' />
                        </button>
                      </div>
                    </div>
                    {/* tslint:disable-next-line:max-line-length */}
                    {this.state.questionIsDuplicate && <small className='duplicate'><FormattedMessage id='duplicate' /></small>}
                    {this.state.examplesAlert && (
                      <small className='links'>
                        <FormattedMessage id='alert.pleaseAddExamples' />
                      </small>
                    )}
                    </div>
                  <div className='col-6'>
                    {this.state.questionExamples.length > 0 && (
                      <div className='input-group'>
                        <input
                          type='text'
                          className='form-control'
                          id='questionexamples'
                          value={this.state.localSearch}
                          placeholder={this.formatMessage('search')}
                          onChange={this.searchHandler}
                        />
                        <div className='input-group-append'>
                          <button className='btn btn-light' type='button' onClick={this.search}>
                            <i className='fas fa-search' />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  </div>
                </div>
                {this.state.questionExamples.length > 0 && (
                  <div className='story-examples-help scroll-table'>
                    <Table
                      tableId={'Questions'}
                      data={this.state.filteredExamples}
                      columns={columns}
                      page={this.getCurrentPage()}
                      dropDownMenuItems={this.dropDownMenuItems}
                    />
                  </div>
                )}
                <div className='story-answers-help d-flex justify-content-between mt-4'>
                  <h5>
                    <FormattedMessage id='assistant.studio.answer' />
                  </h5>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    data-dismiss='modal'
                    onClick={this.addNewBubble}
                  >
                    <FormattedMessage id='assistant.studio.addanotherbubble' />
                  </button>
                </div>
                <div className='story-answers-help card card-body shadow-sm'>
                  <Editor
                    editorState={this.state.previewBubbles[this.state.editbubbleIndex]}
                    wrapperClassName='demo-wrapper'
                    editorClassName='demo-editor'
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                      options: ['inline', 'fontSize', 'textAlign', 'list', 'link'],
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                    }}
                  />
                </div>
                <h5>
                  <FormattedMessage id='assistant.studio.preview' />
                </h5>
                {this.state.previewBubbles.map((bubble, index) => {
                  return (
                    <div className='story-answers-help d-flex justify-content-center mb-3' key={index}>
                      <div className='speech-bubble-robo speech-bubble-studio'>
                        <span dangerouslySetInnerHTML={createMarkup(bubble)} />
                      </div>
                      {/* tslint:disable-next-line:max-line-length */}
                      <span className='pointer' title={this.formatMessage('assistant.studio.delete.answer.hint')} onClick={() => this.deletePreviewBubble(bubble)}>
                        <i className='far fa-trash-alt x2 mr-3'/>
                      </span>
                      {/* tslint:disable-next-line:max-line-length */}
                      <span className='pointer' title={this.formatMessage('assistant.studio.edit.answer.hint')} onClick={() => this.editPreviewBubble(index)}>
                        <i className='fas fa-edit x2' id={'edit' + index}/>
                      </span>
                    </div>
                  );
                })}
              </Modal.Body>
              <div className='modal-footer'>
                <button
                  id='cancel'
                  type='button'
                  className='btn btn-dark'
                  data-dismiss='modal'
                  onClick={this.clearAndClose}
                >
                  <FormattedMessage id='cancel' />
                </button>
                <button className='story-save-help btn btn-primary' type='submit' id='save'>
                   <FormattedMessage id='save' />
                </button>
              </div>
            </React.Fragment>
          );
        }}
      </Form>
    </Modal>
  );
};
