import React from 'react';
import DatePicker from 'react-datepicker';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { DatePickerRange } from './DatePickerRange.component';
import { FormattedMessage } from 'react-intl';

export const DatePickerRangeView: ViewFunction<DatePickerRange> = function() {
  return (
    <React.Fragment>
      <div className='time-filter btn-group mr-2' role='group'>
        <label htmlFor='staticEmail' className='mr-1 col-form-label text-right'>
          <small>
          <FormattedMessage id='statsAssistantChart.filter.label.from'/>
          </small>
        </label>
        <div className='sample-datepicker1' id='from-datepicker'>
          <div className='react-datepicker-wrapper'>
            <div className='react-datepicker__input-container'>
              <DatePicker
                className={'form-control'}
                selected={this.getFromDate()}
                onChange={this.onFromDateChangeHandler}
                maxDate={new Date()}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='time-filter btn-group mr-2' role='group'>
        <label htmlFor='staticEmail' className='mr-1 col-form-label text-right'>
          <small>
          <FormattedMessage id='statsAssistantChart.filter.label.to'/>
          </small>
        </label>
        <div className='sample-datepicker2' id='to-datepicker'>
          <div className='react-datepicker-wrapper'>
            <div className='react-datepicker__input-container'>
              <DatePicker
                className={'form-control'}
                selected={this.getToDate()}
                onChange={this.onToDateChangeHandler}
                minDate={this.getMinToDate()}
                maxDate={this.getMaxToDate()}
                disabled={this.disableToDate()}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
