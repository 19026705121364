import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { Api, RefreshApi } from '../../../../../../api/Server';
import { HttpRequestType } from '../../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../../model/http/Request';
import { SessionContext } from '../../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../../../base/ApiComponent';
import {
  withPaginationRouting,
  WithPaginationRoutingProps,
} from '../../../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../../base/model/WithAlertProps';
import { withContext } from '../../../../../lib/component/withContext/withContext.hoc';
import { EditAssistantContext } from '../../state/EditAssistantContext';
import { EditAssistantStateModel } from '../../state/EditAssistantStateModel';
import { AssistantDialogueDetails, AssistantDialogueDetailsResponse } from '../LogsModel';
import { GetLogsList, SentimentEnum, ChannelType } from '../../../../../common/model/logs/LogsModel';
import { LogsAssistantDetailView } from './LogsAssistantDetail.view';

interface LogsAssistantDetailProps {
  dialogueUuid: string;
}

interface IntlProps {
  intl: IntlShape;
}

type Props = IntlProps &
  ApiProps<GetLogsList & AssistantDialogueDetailsResponse> &
  SessionStateModel &
  EditAssistantStateModel &
  WithPaginationRoutingProps<Navigation> &
  WithAlertProps &
  LogsAssistantDetailProps;

interface LogsDetailState extends ApiState {
  detailData: AssistantDialogueDetails | null;
  totalPages: number;
  currentPage: number;
  showCopyAlert: boolean;
}

export class LogsAssistantDetailBase extends ApiBase<
  GetLogsList & AssistantDialogueDetailsResponse,
  Props,
  LogsDetailState
> {
  public render = LogsAssistantDetailView.bind(this);

  private readonly GETDETAILS = 'getDetails';

  constructor(props: Props) {
    super(props);

    this.state = {
      detailData: null,
      error: null,
      authError: null,
      totalPages: 0,
      currentPage: 0,
      showCopyAlert: false,
    };
  }

  public componentDidMount() {
    this.callDetail();
  }

  public componentDidUpdate(prevProps: Props) {
    super.componentDidUpdate(prevProps);
    if (prevProps.dialogueUuid !== this.props.dialogueUuid) {
      this.callDetail();
    }
  }

  public getStarted(): string {
    return this.state.detailData !== null ? this.state.detailData.started.toString() : '';
  }

  public getUuid(): string {
    return this.props.dialogueUuid ? this.props.dialogueUuid : '';
  }

  public getEnded(): string {
    return this.state.detailData !== null ? this.state.detailData.ended.toString() : '';
  }

  public getDuration(): number {
    return this.state.detailData !== null ? this.state.detailData.duration : 0;
  }

  public getMessages(): number {
    return this.state.detailData !== null ? this.state.detailData.messages : 0;
  }

  public getConfidence(): number {
    return this.state.detailData !== null ? Math.round(this.state.detailData.confidence * 100.0) : 0.0;
  }

  public getSentiment(): SentimentEnum {
    return this.state.detailData !== null ? this.state.detailData.sentiment : SentimentEnum.NEUTRAL;
  }

  public getChannelType(): ChannelType {
    return this.state.detailData !== null ? this.state.detailData.channelType : ChannelType.NONE;
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public showCopyAlert = () => () => {
    this.setState({ showCopyAlert: true });
    setTimeout(() => this.clearCopyAlert(), 5000);
  }

  public clearCopyAlert() {
    this.setState({  showCopyAlert: false });
  }

  protected setResponseData(): void {
    const response = this.props.response;
    this.setState(() => ({
      detailData: response.content,
    }));
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(
      this.formatMessage('logsAssistantDetailBase.errorFetchingDialogueDetail') +
        (this.props.error ? ': ' + this.props.error.message : ''),
    );
  }

  private callDetail(): void {
    const request: Request<{}> = {
      id: this.GETDETAILS,
      method: HttpRequestType.GET,
      relativePath: `/admin/assistants/detail/${this.props.currentAssistant}/${this.props.dialogueUuid}`,
      payload: null,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };
    this.doCall(request);
  }
}

const LogsAssistantDetailWithIntl = injectIntl(LogsAssistantDetailBase);
const LogsAssistantDetailWithAlert = withAlert<LogsAssistantDetailBase['props']>()(LogsAssistantDetailWithIntl);
const LogsAssistantDetailWithSession = withContext(SessionContext)(LogsAssistantDetailWithAlert);
const LogsAssistantDetailWithAssistant = withContext(EditAssistantContext)(LogsAssistantDetailWithSession);
const LogsAssistantDetailWithApi = RefreshApi(Api(LogsAssistantDetailWithAssistant));
export const LogsAssistantDetail = withPaginationRouting()(LogsAssistantDetailWithApi);
