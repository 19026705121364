import React from 'react';
import {ViewFunction} from '../../../../../../../model/component/ViewFunction';
import {BubbleHTMLBase} from './BubbleHTML.component';

export const BubbleHTMLView: ViewFunction<BubbleHTMLBase> = function() {
  return (
    <p>
      <span dangerouslySetInnerHTML={{ __html: `${this.getText()}`}}/>
    </p>
  );
};

export default BubbleHTMLView;
