import React from 'react';
import {ViewFunction} from '../../../../../../../model/component/ViewFunction';
import {BubbleProcessingBase} from './BubbleProcessing.component';

export const BubbleProcessingView: ViewFunction<BubbleProcessingBase> = function() {
  return (
      <p id='processing-element'>
        <div className='robo-dot-flashing'/>
      </p>
  );
};

export default BubbleProcessingView;
