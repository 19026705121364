import React, { PropsWithChildren } from 'react';
import {BreadCrumbsPayload} from '../../component/Breadcrumbs/model';
import { HeaderBtn } from '../../component/PageHeader/model/HeaderBtn';
import { LayoutContext } from './LayoutContext';
import { LayoutStateModel } from './LayoutStateModel';

export class LayoutStoreProvider extends React.Component<PropsWithChildren<{}>, LayoutStateModel> {

  constructor(props: never) {
    super(props);

    this.state = {
      pageTitle: null,
      sidebarCollapsed: false,
      sidebarLinksVisible: true,
      setPageTitle: this.setPageTitle,
      toggleSidebar: this.toggleSidebar,
      headerButtons: [],
      addHeaderBtn: this.addHeaderBtn,
      removeHeaderBtn: this.removeHeaderBtn,
      updateHeaderBtn: this.updateHeaderBtn,
      showModal: false,
      setModalVisibility: this.setModalVisibility,
      setSidebarLinkVisibility: this.setSidebarLinkVisibility,
      currentPage: null,
      breadCrumbsPayload: {
        currentPage: null,
        pageNumber: null,
      },
      setBreadCrumbsInfo: this.setBreadCrumbsInfo,
    };
  }

  public toggleSidebar = () => {
    this.setState({
      sidebarCollapsed: !this.state.sidebarCollapsed,
    });
  }

  public setPageTitle = (pageTitle: string | null): void => {
    if (pageTitle !== this.state.pageTitle) {
      this.setState({ pageTitle });
    }
  }

  public setBreadCrumbsInfo = (breadCrumbsPayload: BreadCrumbsPayload): void => {
    if (breadCrumbsPayload !== this.state.breadCrumbsPayload) {
      this.setState({
        breadCrumbsPayload,
      });
    }
  }

  public addHeaderBtn = (button: HeaderBtn) => {
    this.setState((previousState) => {
      const found = previousState.headerButtons.find((element) => element.id === button.id);
      if (!found) {
        return { headerButtons: [...previousState.headerButtons, button] };
      } else {
        return { headerButtons: [...previousState.headerButtons] };
      }
    });
  }

  public removeHeaderBtn = (id: string) => {
    this.setState((previousState) => ({
      headerButtons: previousState.headerButtons.filter((btn) => btn.id !== id),
    }));
  }

  public updateHeaderBtn = (id: string, button: HeaderBtn) => {
    this.setState((previousState) => {
      const found = previousState.headerButtons.find((element) => element.id === id);
      if (found) {
        return { headerButtons: [...previousState.headerButtons.filter((btn) => btn.id !== id), button] };
      } else {
        return { headerButtons: [...previousState.headerButtons] };
      }
    });
  }

  public setModalVisibility = (show: boolean) => {
    this.setState({ showModal: show });
  }

  public setSidebarLinkVisibility = (show: boolean) => {
    this.setState({ sidebarLinksVisible: show });
  }

  public render() {
    return (
      <LayoutContext.Provider value={this.state}>
        {this.props.children}
      </LayoutContext.Provider>
    );
  }

}
