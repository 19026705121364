import React, { PropsWithChildren } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { LayoutContext } from '../../../../base/state/Layout/LayoutContext';
import { LayoutStateModel } from '../../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { Status } from '../../../model/Status';
import { EditGroupContext } from './EditGroupContext';
import { GroupModel } from '../../../model/GroupModel';
import { EditGroupStateModel } from './EditGroupStateModel';

type Props = PropsWithChildren<{}> & RouteComponentProps<{groupId: string}> & LayoutStateModel;

export class EditGroupStoreProviderBase
  extends React.Component<Props, EditGroupStateModel> {

  constructor(props: Props) {
    super(props);

    const groupId = this.props.match.params.groupId;
    this.state = {
      isToggleActive: this.isToggleActive,
      setToggleState: this.setToggleState,
      currentGroup: groupId,
      groupData: null,
      setGroupInfo: this.setGroupInfo,
      showConfirmDialog: false,
      currentItem: null,
      statusToChange: '',
      setConfirmVisibility: this.setConfirmVisibility,
      confirm: this.confirm,
    };
  }

  public render() {
    return (
      <EditGroupContext.Provider value={this.state}>
        {this.props.children}
      </EditGroupContext.Provider>
    );
  }

  public setConfirmVisibility = (show: boolean) => {
    this.setState({ showConfirmDialog: show });
  }

  public confirm = () => {
    const statusToChange = this.isToggleActive() ? 'disable' : 'enable';
    this.setState({
      statusToChange,
    });
    this.setConfirmVisibility(true);
  }

  public componentWillUnmount(): void {
    this.props.setPageTitle(null);
  }

  private setToggleState = (isToggleActive: boolean) => {
    if (!this.state.groupData) {
      return;
    }
    this.setState((prevState: Readonly<EditGroupStateModel>) => {
      const groupData: GroupModel = {
        ...prevState.groupData!,
        state: isToggleActive ? Status.ENABLED : Status.DISABLED,
      };
      return {
        groupData,
      };
    });
  }

  private isToggleActive = () => {
    if (!this.state.groupData) {
      return false;
    }
    return this.state.groupData.state === Status.ENABLED;
  }

  private setGroupInfo = (groupData: GroupModel) => {
    if (groupData === undefined || groupData.name === undefined) {
      return; // invalid set, so we will return
    }

    this.setState({
      groupData,
    });

    this.props.setPageTitle(`Edit ${groupData.name}`);
    this.props.setBreadCrumbsInfo({
      currentPage: groupData.name,
      currentId: groupData.uuid,
      pageNumber: null,
    });
  }

}

export const EditGroupStoreProviderWithRouting = withRouter(EditGroupStoreProviderBase);
export const EditGroupStoreProvider = withContext(LayoutContext)(EditGroupStoreProviderWithRouting);
