import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { FreshchatAPIProviderChannelBase } from './FreshchatAPIProviderChannel.component';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export const FreshchatAPIProviderChannelView: ViewFunction<FreshchatAPIProviderChannelBase> = function() {

  function buildSkipWelcomeMessageTooltip() {
    return (
      <Tooltip id={`tooltip-skip-welcome-message`}>
        <FormattedMessage id='channels.tooltip.skipWelcomeMessage' />
      </Tooltip>
    );
  }

  return (
    <React.Fragment>
      <div className='form-group'>
        <div className='input-group'>
          <input
            type='text'
            readOnly
            className='form-control'
            id={this.getUuid()}
            placeholder={this.buildUrl()}
          />
          <div className='input-group-append'>
            <CopyToClipboard text={this.buildUrl()}>
              <button className='btn btn-secondary' type='button' id={'copyFreschatUrl'}>
              {this.formatMessage('channels.copy')}
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
      <form onSubmit={this.submit}>
        <div className='form-group'>
          <label htmlFor='apiTokenFreshchat'>{this.formatMessage('channels.apiToken')}</label>
          <input
            className='form-control'
            id='apiTokenFreshchat'
            name='apiTokenFreshchat'
            type='text'
            placeholder={this.formatMessage('channels.apiTokenEnter')}
            value={this.getApiToken()}
            onChange={this.setApiToken}
            disabled={!this.isAdmin()}
          />
        </div>
        <div className='form-group'>
        <label htmlFor='toggleFreshchat'>Skip welcome message</label>
          <div className='toggle-status float-left' onClick={this.setIgnoreWelcomeMessage}>
            <i
              id='toggleFreshchat'
              data-property-name='statusBt'
              data-property-value={this.dataPropertyValue()}
              className={this.getToggle()}
              title={this.getToggleTitle()}
            />
          </div>
          &nbsp;
          <OverlayTrigger key={'top'} placement={'top'} overlay={buildSkipWelcomeMessageTooltip()}>
            <i className='far fa-question-circle' data-toggle='tooltip' data-placement='top' />
          </OverlayTrigger>
        </div>
        {this.isAdmin() &&
          (
            <div className='text-right'>
            <button
              className='btn btn-secondary'
              type='submit'
              id={'saveFreschatChannel'}
            >
              {this.formatMessage('channels.save')}
            </button>
          </div>
          )
        }
      </form>
    </React.Fragment>
  );
};
