import React, { ReactNode } from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { CmsAnswer } from './AnswerTypeLayout/CmsAnswer/CmsAnswer.component';
import { CommandAnswer } from './AnswerTypeLayout/CommandAnswer/CommandAnswer.component';
import { HtmlAnswer } from './AnswerTypeLayout/HtmlAnswer/HtmlAnswer.component';
import { ImageAnswer } from './AnswerTypeLayout/ImageAnswer/ImageAnswer.component';
import { LinksAnswer } from './AnswerTypeLayout/LinksAnswer/LinksAnswer.component';
import { MultiAnswer } from './AnswerTypeLayout/MultiChoiceAnswer/MultiChoiceAnswer.component';
import { TextAnswer } from './AnswerTypeLayout/TextAnswer/TextAnswer.component';
import { VideoAnswer } from './AnswerTypeLayout/VideoAnswer/VideoAnswer.component';
import { YoutubeAnswer } from './AnswerTypeLayout/YoutubeAnswer/YoutubeAnswer.component';
import { EditAnswerLayoutBase } from './EditAnswerLayout.component';

export const EditAnswerLayoutView: ViewFunction<EditAnswerLayoutBase> = function() {
  const renderFormType: { [key: string]: ReactNode } =  {
    text: <TextAnswer/>,
    html: <HtmlAnswer/>,
    cms: <CmsAnswer/>,
    multichoice: <MultiAnswer/>,
    image: <ImageAnswer/>,
    video: <VideoAnswer/>,
    youtube: <YoutubeAnswer/>,
    links: <LinksAnswer/>,
    command: <CommandAnswer/>,
  };

  const renderActiveComponent = () => {
    const current = this.getAnswerType();
    if (current) {
      return renderFormType[current.toLowerCase()];
    }
  };

  return (
    <div>
      {renderActiveComponent()}
    </div>
  );
};
