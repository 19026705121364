import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { TestAssistantBase } from './TestAssistant.component';
import { TestChatContainerBase } from './TestChatContainer.component';
import { SendEventBase } from './SendEvent.component';
import ReactJson from 'react-json-view';

export const TestAssistantView: ViewFunction<TestAssistantBase> = function() {
  return (
    <React.Fragment>
      <SendEventBase
        botId={this.props.match.params.botId}
        intl={this.props.intl}
        isVisible={this.state.isModalVisible}
        handleClose={this.handleClose}
      />
      <div className='test-buttons'>
        <button type='button' className='btn btn-danger send-event' id={'sendEvent'} onClick={this.handleOpen}>
          {this.formatMessage('test.sendEvent')}
        </button>
        <button type='button' className='btn btn-danger' id={'resetTest'} onClick={this.resetTest}>
          {this.formatMessage('test.resetTest')}
        </button>
      </div>
      <div className='test-container mt-4 mb-4'>
        <div className='robo-container-open'>
          <div className='robo-header'>
            <div>
              <span>
                <img className='robo-logo' src='//cdn.robo-ai.com/widget/assets/icon-white.png' alt='' />
              </span>
            </div>
          </div>
          <TestChatContainerBase
            botId={this.props.match.params.botId}
            apiKey={this.props.organization?.adminApiKey}
            addToUserDebugger={this.addToUserDebugger}
            addToBotDebugger={this.addToBotDebugger}
          />
        </div>

        <div className='debugger'>
          <p>{this.formatMessage('test.userInput')}</p>
          <div className='debugger-code' ref={this.userInputRef}>
            <ReactJson
              theme='monokai'
              name={null}
              displayObjectSize
              style={{ background: 'transparent' }}
              src={this.state.debuggerUser}
            />
          </div>
          <p>{this.formatMessage('test.botResponse')}</p>
          <div className='debugger-code' ref={this.botOutputRef}>
            <ReactJson
              theme='monokai'
              collapseStringsAfterLength={10}
              collapsed={2}
              name={null}
              displayObjectSize
              style={{ background: 'transparent' }}
              src={this.state.debuggerBot}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TestAssistantView;
