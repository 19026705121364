import React from 'react';
import { ViewFunction } from '../../../../../../../model/component/ViewFunction';
import { BubbleCommandBase } from './BubbleCommand.component';

export const BubbleCommandView: ViewFunction<BubbleCommandBase> = function() {
  return (
    <>
      <div className='command p-3'>
        <h6>Command: {this.getCommandName()}</h6>

        {/* tslint:disable-next-line:max-line-length jsx-wrap-multiline */}
        {this.props.params?.length !== 0 && <table className='table table-borderless' style={{ background: 'transparent' }}>
          <thead>
            <tr>
              <th scope='col'>Key</th>
              <th scope='col'>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {this.getParams().map((param, index) => (
                <>
                  <th key={`key_${index}`}>{`${param.key!}`}</th>
                  <th key={`value_${index}`}>{`${param.value!}`}</th>
                </>
              ))}
            </tr>
          </tbody>
        </table>}
      </div>
    </>
  );
};

export default BubbleCommandView;
