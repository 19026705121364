import React from 'react';
import { FormattedMessage } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { Roles } from '../../../base/model/route/Role';
import { Status } from '../../model/AccountModel';
import { Confirm } from '../../../base/component/confirm/Confirm.component';
import { EditUserBase } from './EditUser.component';
import { TwoFactorAuth } from './TwoFactor.component';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';

export const EditUserView: ViewFunction<EditUserBase> = function() {
  return (
    <React.Fragment>
      <Confirm
        show={this.state.showConfirmDialog}
        okLabel={this.formatMessage('ok')}
        cancelLabel={this.formatMessage('cancel')}
        confirmation={this.formatMessage('alert.confirmUserChangeStatus',
          { statusToChange: this.state.statusToChange})}
        proceedFunc={this.confirmCallback}
        title={'Confirm'}
        setConfirmVisibility={this.setConfirmVisibility}
      />
      <div className='card shadow-sm'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
              <form>
                <div className='form-group'>
                  <label htmlFor='name'>Name</label>
                  <input type='text' className='form-control' id='name' value={this.getName()} disabled />
                </div>
                <div className='form-group'>
                  <label htmlFor='email'>Email</label>
                  <input type='text' className='form-control' id='email' value={this.getEmail()} disabled />
                </div>
                <div className='form-group'>
                  <label htmlFor='timezone'>
                    <FormattedMessage id='account.profile.profile.timezone' />
                  </label>
                  <TimezonePicker
                    id='timezone'
                    style={{ display: 'block' }}
                    value={this.state.timezone}
                    onChange={this.handleTimezoneChange}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='mobileNumber'>Mobile number</label>
                  <PhoneInput country={'us'} value={this.state.mobileNumber} onChange={this.handleOnChange} />
                </div>
                <h6><FormattedMessage id='organization.role' /></h6>
                <div className='custom-control custom-radio custom-control-inline'>
                  <input
                    type='radio'
                    id='customRadio1'
                    value='user'
                    name='customRadio'
                    className='custom-control-input'
                    checked={this.state.selectedRole === Roles.user}
                    readOnly
                  />
                  <label id='user' onClick={this.handleOptionChange} className='custom-control-label'>
                  <FormattedMessage id='organization.user' />
                  </label>
                </div>
                <div className='custom-control custom-radio custom-control-inline'>
                  <input
                    type='radio'
                    id='customRadio3'
                    value='admin'
                    name='customRadio'
                    className='custom-control-input'
                    checked={this.state.selectedRole === Roles.admin}
                    readOnly
                  />
                  <label id='admin' onClick={this.handleOptionChange} className='custom-control-label'>
                  <FormattedMessage id='organization.admin' />
                  </label>
                </div>
                {this.isSuper() && (
                  <React.Fragment>
                    <div className='custom-control custom-radio custom-control-inline'>
                      <input
                        type='radio'
                        id='customRadio4'
                        value='super'
                        name='customRadio'
                        className='custom-control-input'
                        checked={this.state.selectedRole === Roles.super}
                        readOnly
                      />
                      <label id='super' onClick={this.handleOptionChange} className='custom-control-label'>
                      <FormattedMessage id='organization.super' />
                      </label>
                    </div>{' '}
                  </React.Fragment>
                )
                }
              </form>
            </div>
            <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
              <form>
                <div className='form-group'>
                  <h6>Status</h6>
                  {this.state.statusToChange !== Status.INVITED && (
                    <React.Fragment>
                      <div className='toggle-status' onClick={this.confirm} style={{ float: 'left' }}>
                        <i
                          id='edit-user-status'
                          data-property-value={this.getToggleTitle()}
                          className={this.getToggleClass()}
                          title={this.getToggleTitle()}
                        />
                      </div>
                      <br />
                    </React.Fragment>
                  )}
                  {this.state.statusToChange === Status.INVITED && (
                    <p className='pt-1'>
                      <FormattedMessage id='organization.invited' /> -
                      <div onClick={this.sendInvitation}>
                      <FormattedMessage id='organization.resend' />
                      </div>
                    </p>
                  )}
                </div>
                <div className='form-group'>
                  <h6><FormattedMessage id='organization.lastLogIn' /></h6>
                  <p>{this.getLastLogin()}</p>
                </div>
                <TwoFactorAuth userData={this.state.data} />
              </form>
            </div>
          </div>
          <div className='text-right'>
            <button type='button' className='btn btn-primary' id='submit' onClick={this.onSubmit}>
            <FormattedMessage id='save' />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
