import React, { ReactNode } from 'react';
import { SessionContext } from '../../../auth/state/Session/SessionContext';
import { Route } from '../../model/route/Route';
import { LayoutContext } from './../../state/Layout/LayoutContext';
import ProtectedRoute from './ProtectedRoute';
import { default as Routes } from './Routes';

interface ComponentProps {
  path?: string;
  location: string | undefined;
  history: any;
  match: string;
  staticContext?: any;
  children?: ReactNode;
  innerProps?: any;
}

const RouteWithSubRoutes = (route: Route): any => {
  if (!route.routes && !route.component) {
    return null;
  }

  const Provider = route.provider || React.Fragment;
  const Layout: React.ComponentType = route.layout || React.Fragment;
  if (route.component) {
    const Component: React.ComponentType<any> = route.component;
    const routeRender = (props: ComponentProps) => {
      props.innerProps = route.innerProps;
      return (
        <LayoutContext.Consumer>
          {(layout) => (
            <SessionContext.Consumer>
              {(session) => (
                <Provider>
                  <Layout>
                    <Component {...props} routes={route.routes} {...session} {...layout} />
                  </Layout>
                </Provider>
              )}
            </SessionContext.Consumer>
          )}
        </LayoutContext.Consumer>
      );
    };
    const routeProps = { ...route };
    delete routeProps.component;
    return (
      <SessionContext.Consumer>
        {(session) => {
          return (
            <ProtectedRoute {...routeProps} principalRoles={session ? session.authorities : []} render={routeRender} />
          );
        }}
      </SessionContext.Consumer>
    );
  } else if (route.routes) {
    return (
      <Provider>
        <Layout>
          <Routes default={route.default} routes={route.routes} />
        </Layout>
      </Provider>
    );
  }
};

export default RouteWithSubRoutes;
