import { withAlert } from 'react-alert';
import {IntlShape, injectIntl} from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { Response } from '../../../../model/http/Response';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import {
  withPaginationRouting,
  WithPaginationRoutingProps,
} from '../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { Status, UserModel } from '../../model/AccountModel';
import { DropDownMenuItem } from './../../../base/component/Table/Table.component';
import { UserListView } from './UserList.view';

enum UserListOperationType {
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  GET = 'GET',
  NONE = 'NONE',
}

interface State extends ApiState {
  data: UserModel[];
  totalPages: number;
  showConfirmDialog: boolean;
  currentItem: UserModel | null;
  confirmAction: string | null;
  confirmMsg: string | null;
}

interface GetUserList extends Response {
  content: UserModel[];
  timestamp: Date;
  size: string;
  page: number;
  pageElements: number;
  totalElements: number;
}

interface IntlProps {
  intl: IntlShape;
}

type Props =
  IntlProps
  & ApiProps<GetUserList>
  & LayoutStateModel
  & SessionStateModel
  & RouteComponentProps<Navigation>
  & WithAlertProps
  & WithPaginationRoutingProps;

export class UserListBase extends ApiBase<GetUserList, Props, State> {

  public render = UserListView.bind(this);

  constructor(props: Props) {
    super(props);

    this.state = {
      data: [],
      totalPages: 0,
      error: null,
      showConfirmDialog: false,
      currentItem: null,
      authError: null,
      confirmAction: null,
      confirmMsg: '',

    };

  }

  public componentDidMount = async () => {
    this.props.setPageTitle('Users');

    await this.requestUsers();
  }

  public componentDidUpdate(prevProps: Props) {
    super.componentDidUpdate(prevProps);
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  public onRouteChanged() {
    this.requestUsers();
  }

  public getPage() {
    return this.props.page;
  }

  public getTotalPages() {
    return this.state.totalPages;
  }

  public getPageSize() {
    return this.props.pageSize;
  }

  public getCurrentCriteria(): string {
    return this.props.getExtraParam('criteria') || '';
  }

  public changePageHandler = (pageNumber: number) => {
    this.props.goToPage(pageNumber);
  }

  public changePageSizeHandler = (pageSize: number) => {
    this.props.changePageSize(pageSize);
  }

  public searchHandler = (criteria: string) => {
    this.props.goToPage(0, {
      criteria,
    });
  }

  public getData() {
    return this.state.data;
  }

  public dropDownMenuItems = (): DropDownMenuItem[] => {
    const dropDownItemList: DropDownMenuItem[] =
      [
        {
          text: 'View',
          callBack: this.editHandler,
        },
        {
          text: this.getStatusDropDownText,
          callBack: this.confirmStatusChange,
        },
      ];
    return dropDownItemList;
  }

  public getStatusDropDownText = (item: UserModel) => {
    switch (item.status) {
      case Status.ENABLED:
        return this.props.intl.formatMessage({id: 'disable'});
      case Status.DISABLED:
        return this.props.intl.formatMessage({id: 'enable'});
      case Status.INVITED:
        return this.props.intl.formatMessage({id: 'account.users.profile.resendInvitation'});
      default:
        return this.props.intl.formatMessage({id: 'account.users.profile.na'});
    }
  }

  public editHandler = (item: UserModel) => {
    this.props.history.push(`/account/users/${item.uuid}`);
  }

  public sendInvitationHandler = async (item: UserModel) => {
    const request: Request<{}> = {
      method: HttpRequestType.POST,
      payload: null,
      relativePath: `/admin/users/${item.uuid}/resendInvitation`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: UserListOperationType.UPDATE,
    };
    await this.doCall(request);
  }

  public  requestUsers = async () => {
    const criteria = encodeURIComponent(this.getCurrentCriteria());
    const page = this.getPage();
    const pageSize = this.getPageSize();
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/users?full=true&search=${criteria}&page=${page}&size=${pageSize}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: UserListOperationType.GET,
    };

    await this.doCall(request);
  }

  public updateStatus = async (item: UserModel, value: string) => {
    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload: null,
      relativePath: `/admin/users/${item.uuid}/status/${value}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: UserListOperationType.UPDATE,
    };
    await this.doCall(request);
  }

  public setConfirmVisibility = (show: boolean) => {
    this.setState({ showConfirmDialog: show });
  }

  public confirmStatusChange = (item: UserModel) => {
    if (item) {
      const {confirmMsg} = this.getStatusInfo(item);
      this.setState({
        currentItem: item,
        confirmAction: 'statusChange',
        confirmMsg,
      },
        () => {
          this.setConfirmVisibility(true);
        },
      );
    }
  }

  public getStatusInfo = (item: UserModel) => {
    let confirmMsg = null;
    if (item) {
      switch (item.status) {
        case Status.ENABLED:
          confirmMsg = this.props.intl.formatMessage({id: 'account.users.profile.confirmDisable'});
          break;
        case Status.DISABLED:
          confirmMsg = this.props.intl.formatMessage({id: 'account.users.profile.confirmEnable'});
          break;
        case Status.INVITED:
          confirmMsg = this.props.intl.formatMessage({id: 'account.users.profile.confirmResendInvitation'});
          break;
        default:
          confirmMsg = this.props.intl.formatMessage({id: 'account.users.profile.notSupportedStatus'});
          break;
      }
    }
    return {
      confirmMsg,
    };
  }

  public confirmCallback = () => {
    if (this.state.currentItem) {
      if (this.state.confirmAction === 'statusChange') {
        if (this.state.currentItem.status === Status.INVITED) {
          this.sendInvitationHandler(this.state.currentItem);
        } else {
          const changeStatus = this.state.currentItem.status === Status.DISABLED ? 'ENABLED' : 'DISABLED';
          this.updateStatus(this.state.currentItem, changeStatus);
        }
      }
    }
  }

  public getCurrentItemName = () => {
    if (this.state.currentItem) {
      return this.state.currentItem.username;
    }
    return '';
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({id});
  }

  protected setResponseData(): void {
    const response = this.props.response;

    if (this.props.callId === UserListOperationType.DELETE || this.props.callId === UserListOperationType.UPDATE) {
      this.requestUsers();

    } else {
      this.setState({
        data: response.content,
        totalPages: Math.ceil(response.totalElements / Number(response.size)),
      });
    }
    if (response.page) {
      this.props.setBreadCrumbsInfo({
        currentPage: null,
        pageNumber: response.page.toString(),
      });
    }

  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }

}
const UserListWithIntl = injectIntl(UserListBase);
const UserListWithAlert = withAlert<UserListBase['props']>()(UserListWithIntl);
const UserListWithApi = RefreshApi(Api(UserListWithAlert));
export const UsersList = withPaginationRouting()(UserListWithApi);
