import { default as AccountRoutes } from '../account/Routes';
import { default as AnswerRoutes } from '../answer/Routes';
import { default as AssistantRoutes } from '../assistant/Routes';
import { default as AuthRoutes } from '../auth/Routes';
import { default as DashboardRoutes } from '../dashboard/Routes';
import { default as PublicRoutes } from '../public/Routes';
import { default as GroupRoutes } from '../group/Routes';
import { default as OrganizationRoutes } from '../organization/Routes';
import { Layout } from './component/Layout/Layout.component';
import PageUnauthorized from './component/Unauthorized/PageUnauthorized.component';
import { Roles } from './model/route/Role';
import { Route as RouteDef } from './model/route/Route';

export const root = '/auth';

const roles = Object.keys(Roles);
const general = [
  {
    path: '/unauthorized',
    component: PageUnauthorized,
    authorize: roles,
    layout: Layout,
    routes: [],
    breadcrumb: 'unauthorized',
  },
];

export const routes: RouteDef[] = [];
routes.push(...AuthRoutes);
routes.push(...AssistantRoutes);
routes.push(...GroupRoutes);
routes.push(...AnswerRoutes);
routes.push(...DashboardRoutes);
routes.push(...AccountRoutes);
routes.push(...OrganizationRoutes);
routes.push(...PublicRoutes);
routes.push(...general);
