import React from 'react';
import {injectIntl, IntlShape} from 'react-intl';
import { PageSizeView } from './PageSize.view';

interface Props {
  changePageSize: (size: number) => void;
  size: number;
  sizes?: number[];
  intl: IntlShape;
}

export const defaultPageSizeList: number[] = [10, 25, 50, 100];

export class PageSizeBase extends React.Component<Props, never> {

  public static defaultProps: Partial<Props> = {
    sizes: defaultPageSizeList,
  };

  public render = PageSizeView.bind(this);

  public changePageSizeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value;

    this.props.changePageSize(Number(value));
  }

  public getPageSizes() {
    const { sizes } = this.props;
    return sizes || defaultPageSizeList;
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({id});
  }
}

export const PageSize = injectIntl(PageSizeBase);
