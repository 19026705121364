import { withAlert } from 'react-alert';
import { IntlShape, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { Response } from '../../../../model/http/Response';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import {
  withPaginationRouting,
  WithPaginationRoutingProps,
} from '../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { MouseEvent } from 'react';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import {OrganizationModel, Status, OrganizationParamType, Parameters} from '../../model/AccountModel';
import { DropDownMenuItem } from './../../../base/component/Table/Table.component';
import { OrganizationListView } from './OrganizationList.view';

interface State extends ApiState {
  data: OrganizationModel[];
  totalPages: number;
  showConfirmDialog: boolean;
  currentItem: OrganizationModel | null;
  confirmAction: string | null;
  confirmMsg: string | null;
}

interface GetOrganizationList extends Response {
  content: OrganizationModel[];
  timestamp: Date;
  size: string;
  page: number;
  pageElements: number;
  totalElements: number;
}

interface IntlProps {
  intl: IntlShape;
}

type Props = IntlProps &
  ApiProps<GetOrganizationList> &
  LayoutStateModel &
  SessionStateModel &
  RouteComponentProps<Navigation> &
  WithAlertProps &
  WithPaginationRoutingProps;

export class OrganizationListBase extends ApiBase<GetOrganizationList, Props, State> {
  public render = OrganizationListView.bind(this);

  constructor(props: Props) {
    super(props);

    this.state = {
      data: [],
      totalPages: 0,
      error: null,
      showConfirmDialog: false,
      currentItem: null,
      authError: null,
      confirmAction: null,
      confirmMsg: '',
    };
  }

  public componentDidMount = async () => {
    this.props.setPageTitle('Organizations');
    this.props.setSidebarLinkVisibility(false);
    await this.requestOrganizations();
  }

  public componentDidUpdate(prevProps: Props) {
    super.componentDidUpdate(prevProps);
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  public onRouteChanged() {
    this.requestOrganizations();
  }

  public getPage() {
    return this.props.page;
  }

  public getTotalPages() {
    return this.state.totalPages;
  }

  public getPageSize() {
    return this.props.pageSize;
  }

  public getCurrentCriteria(): string {
    return this.props.getExtraParam('criteria') || '';
  }

  public changePageHandler = (pageNumber: number) => {
    this.props.goToPage(pageNumber);
  }

  public changePageSizeHandler = (pageSize: number) => {
    this.props.changePageSize(pageSize);
  }

  public searchHandler = (criteria: string) => {
    this.props.goToPage(0, {
      criteria,
    });
  }

  public getData() {
    return this.state.data;
  }

  public confirmTrialChange = (item: OrganizationModel | null) => (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (item) {
      const trialParameter = item.parameters.find((param: Parameters) => param.key === OrganizationParamType.TRIAL);
      const newData = this.state.data.map((organization: OrganizationModel) => {
        if (organization.uuid === item.uuid && trialParameter) {
          trialParameter.value =
          trialParameter.value === Status.ENABLED ? Status.DISABLED : Status.ENABLED;
        }
        return organization;
      });
      this.setState(
        {
          currentItem: item,
          data: newData,
          confirmAction: 'statusChange',
        },
        () => {
          this.changeTrial(item);
        },
      );
    }
  }

  public async changeTrial(item: OrganizationModel) {
    const newTrial =
      item.parameters?.find((element) => element.key === OrganizationParamType.TRIAL)?.value === Status.ENABLED
        ? Status.ENABLED
        : Status.DISABLED;
    const request: Request<{}> = {
      method: HttpRequestType.PATCH,
      payload: null,
      relativePath: `/admin/organization/administration/trial/${item.uuid}/${newTrial}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'update-organization-trial',
    };
    await this.doCall(request);
  }

  public getToggle(item: OrganizationModel): string {
    if (item && item.status === Status.ENABLED) {
      return 'fas fa-toggle-on fa-2x on text-success';
    }
    return 'fas fa-toggle-off fa-2x off text-secondary';
  }

  public getTrialToggle(item: OrganizationModel): string {
    if (
      item &&
      item.parameters?.find((element) => element.key === OrganizationParamType.TRIAL)?.value === Status.ENABLED
    ) {
      return 'fas fa-toggle-on fa-2x on text-success';
    }
    return 'fas fa-toggle-off fa-2x off text-secondary';
  }

  public dropDownMenuItems = (item: OrganizationModel): DropDownMenuItem[] => {
      const dropDownItemList: DropDownMenuItem[] = [];
      if (item.companyInfo.status === 'INVITED') {
      dropDownItemList.push({
          text: 'Edit',
          callBack: this.editHandler,
        },
        {
          text: 'Resend Invite',
          callBack: this.resendInvitation,
        });
      } else {
        dropDownItemList.push({
          text: 'Edit',
          callBack: this.editHandler,
        });
      }
      return dropDownItemList;
  }

  public editHandler = (item: OrganizationModel) => {
    this.props.history.push(`/organizations/${item.uuid}/info`);
  }

  public resendInvitation = async (item: OrganizationModel) => {
    const organizationUUID = item.uuid;
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/users/resendinvitation/organizations/${organizationUUID}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'resend-organizations-invitations',
    };
    await this.doCall(request);
  }

  public requestOrganizations = async () => {
    const criteria = encodeURIComponent(this.getCurrentCriteria());
    const page = this.getPage();
    const pageSize = this.getPageSize();
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/organization/administration/${criteria ? criteria : 'ALL'}/${page}/${pageSize}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'get-organizations',
    };
    await this.doCall(request);
  }

  public setConfirmVisibility = (show: boolean) => {
    this.setState({ showConfirmDialog: show });
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  protected setResponseData(): void {
    const response = this.props.response;
    if (this.props.callId === 'get-organizations') {
      this.setState({
        data: response.content,
        totalPages: Math.ceil(response.totalElements / Number(response.size)),
      });
      if (response.page) {
        this.props.setBreadCrumbsInfo({
          currentPage: null,
          pageNumber: response.page.toString(),
        });
      }
    } else {
      this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
      this.requestOrganizations();
    }
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
    this.requestOrganizations();
  }
}
const OrganizationListWithIntl = injectIntl(OrganizationListBase);
const OrganizationListWithAlert = withAlert<OrganizationListBase['props']>()(OrganizationListWithIntl);
const OrganizationListWithApi = RefreshApi(Api(OrganizationListWithAlert));
export const OrganizationList = withPaginationRouting()(OrganizationListWithApi);
