import React from 'react';
import { Thumbs } from '../../../model/logs/LogsModel';

export const LogsSentiment: any = (props: any) => {
  const getSentiment = () => {
    if (props.item.feedbackDialogue) {
      switch (props.item.feedbackDialogue.sentimentWeight) {
        case 2:
          return <i className='far fs-15 fa-smile green' />;
        case 1:
          return <i className='far fs-15 fa-frown red' />;
        default:
          return <i className='far fs-15 fa-meh yellow' />;
      }
    }
    if (props.item.nluAnalysis && props.item.nluAnalysis.sentiment && props.item.nluAnalysis.sentiment.length > 0) {
      const sentiment = props.item.nluAnalysis.sentiment[0]; // the first row is the global analysis to sentiment
      switch (sentiment.label.toUpperCase()) {
        case props.SentimentEnum.POSITIVE:
          return <i className='far fs-15 fa-smile' />;
        case props.SentimentEnum.NEGATIVE:
          return <i className='far fs-15 fa-frown' />;
        default:
          return <i className='far fs-15 fa-meh' />;
      }
    }
    if (props.item.thumbs) {
      switch (props.item.thumbs.thumbs) {
        case Thumbs.UP:
          return <i className='lni lni-thumbs-up' />;
        case Thumbs.DOWN:
          return <i className='lni lni-thumbs-down' />;
      }
    }
  };

  return <React.Fragment>{getSentiment()}</React.Fragment>;
};
