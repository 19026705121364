// tslint:disable

import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Api, RefreshApi } from '../../../../../api/Server';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../model/http/Request';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { Navigation } from '../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../../base/state/Layout/LayoutStateModel';
import { EditGroupStateModel } from '../state/EditGroupStateModel';
import { GroupBotSelectorView } from './GroupBotSelector.view';
import { AssistantModel } from '../../../../assistant/component/AssistantList/AssistantModel';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';

export interface Bot {
  value: string;
  label?: string;
}

interface GetAssistantList extends Response {
  content: AssistantModel[];
  timestamp: Date;
  size: string;
  page: number;
  pageElements: number;
  totalElements: number;
}

interface IntlProps {
  intl: IntlShape;
  setBot: (uuid: string) => void;
  bot: string;
}

interface State extends ApiState {
  assistantList: AssistantModel[];
  isClearable: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  isRtl: boolean;
  isSearchable: boolean;
  bot: Bot | undefined;
  items: Items;
}

interface Items extends Array<Bot> {}

type Props = IntlProps &
  ApiProps<GetAssistantList> &
  SessionStateModel &
  EditGroupStateModel &
  RouteComponentProps<Navigation> &
  WithAlertProps &
  LayoutStateModel;

export class GroupBotSelectorBase extends ApiBase<GetAssistantList, Props, State> {
  public render = GroupBotSelectorView.bind(this);

  constructor(props: Props) {
    super(props);
    this.state = {
      assistantList: [],
      error: null,
      authError: null,
      isClearable: false,
      isDisabled: false,
      isLoading: false,
      isRtl: false,
      isSearchable: true,
      bot: undefined,
      items: [],
    };
  }

  public componentDidMount(): void {
    super.componentDidMount();
    this.updateList();
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    super.componentDidUpdate(prevProps);
    if (this.props.bot && prevProps.bot !== this.props.bot) {
      this.setSavedBot();
    }
  }

  public updateList = () => {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/assistants/?full=1&page=0&size=100`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'getAssistantList',
    };
    this.doCall(request);
  };

  public setSavedBot() {
    const bot = this.state.items.find((val: Bot) => val.value === this.props.bot);
    this.setState({
      bot: bot,
    });
  }

  public onBotSelected = (optionSelected: Bot | null | undefined) => {
    if (optionSelected !== null && optionSelected !== undefined) {
      this.props.setBot(optionSelected.value);
    }
  };

  public setResponseData() {
    const response = this.props.response;
    const items = response.content.map((assistant) => ({
      label: `${assistant.name} - ${assistant.description}`,
      value: assistant.uuid,
    }));

    this.setState({
      items,
    });
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }
}

const GroupsBotSelectorWithIntl = injectIntl(GroupBotSelectorBase);
const GroupsBotSelectorWithAlert = withAlert<GroupBotSelectorBase['props']>()(GroupsBotSelectorWithIntl);
const GroupsBotSelectorWithSession = withContext(SessionContext)(GroupsBotSelectorWithAlert);
export const GroupBotSelector = RefreshApi(Api(GroupsBotSelectorWithSession));
