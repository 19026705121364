import { Api, RefreshApi } from '../../../../../api/Server';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { AssistantParams, EditAssistantStateModel } from '../state/EditAssistantStateModel';
import { ChangeEvent, FormEvent } from 'react';
import {
  Engines,
  ServiceInfo,
  ServiceParam,
  ServiceType,
  EngineInputsType,
  SentimentInputs,
  SentimentLanguages,
} from '../../../model/engines';
import { IntlShape, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { EditAssistantContext } from '../state/EditAssistantContext';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Navigation } from '../../../../base/model/route/Navigation';
import { ParamValue } from '../../../../group/component/edit/state/EditGroupStateModel';
import { Request } from '../../../../../model/http/Request';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { Status } from '../../../model/Status';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { withAlert } from 'react-alert';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { SentimentView } from './Sentiment.view';

interface SentimentUpdate extends Response {
  content: ServiceInfo;
  timestamp: Date;
}

interface InfoState extends ApiState {
  engine: Engines;
  apiKey?: string;
  url?: string;
  language?: string;
  accessKeyId?: string;
  secretAccessKey?: string;
  awsUrl?: string;
}

interface PayloadAssistantParams {
  params: {};
}

interface PayloadServiceParams {
  type: ServiceType;
  subtype: Engines;
  parameters: ServiceParam[];
}

interface IntlProps {
  intl: IntlShape;
}

interface InnerPropsFunctions {
  reloadAssistant: () => void;
}

interface InnerProps {
  innerProps: InnerPropsFunctions;
}

interface Submit {
  [id: string]: string | number;
}

type Props = IntlProps &
  ApiProps<SentimentUpdate> &
  SessionStateModel &
  EditAssistantStateModel &
  RouteComponentProps<Navigation> &
  WithAlertProps &
  InnerProps;

const updateAssistant = 'updateAssistant';
const updateService = 'update-sentiment';
const load = 'load';

export class SentimentBase extends ApiBase<SentimentUpdate, Props, InfoState> {
  public render = SentimentView.bind(this);

  public constructor(props: Props) {
    super(props);

    const language =
      this.props.assistantData !== null
        ? this.props.assistantData.params.find((x: ParamValue) => x.name === AssistantParams.SENTIMENT_LANGUAGE)
        : undefined;

    this.state = {
      authError: null,
      error: null,
      appError: null,
      engine: Engines.IBM,
      language: language !== undefined ? language.value : SentimentLanguages.EN.toUpperCase(),
    };
    this.loadEngineProps();
  }

  public loadEngineProps = () => {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/assistants/${this.props.currentAssistant}/${ServiceType.NLU}/service`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: load,
    };
    this.doCall(request);
  }

  public getStatus(): Status {
    const status =
      this.props.assistantData !== undefined && this.props.assistantData !== null
        ? this.props.assistantData.params.find((x: ParamValue) => x.name === AssistantParams.SENTIMENT_STATE)
        : undefined;
    return status !== undefined && JSON.parse(status.value) ? Status.ENABLED : Status.DISABLED;
  }

  public setEngine = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as Engines;
    this.setState({
      engine: value,
    });
    // if (value === Engines.NATIVE.toUpperCase()) {
    //   this.setState({
    //     language: SentimentLanguages.EN.toUpperCase(),
    //   });
    // }
  }

  public getEngine(): Engines {
    return this.state.engine;
  }

  public setApiKey = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      apiKey: value,
    });
  }

  public getApiKey(): string {
    return this.state.apiKey === undefined ? '' : this.state.apiKey.valueOf();
  }

  public setUrl = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      url: value,
    });
  }

  public getUrl(): string {
    return this.state.url === undefined ? '' : this.state.url.valueOf();
  }

  public setLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    this.setState({
      language: value,
    });
  }

  public getLanguage(): string {
    return this.state.language === undefined ? '' : this.state.language.valueOf();
  }

  public setAccessKeyId = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      accessKeyId: value,
    });
  }

  public getAccessKeyId(): string {
    return this.state.accessKeyId === undefined ? '' : this.state.accessKeyId.valueOf();
  }

  public setSecretAccessKey = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      secretAccessKey: value,
    });
  }

  public getSecretAccessKey(): string {
    return this.state.secretAccessKey === undefined ? '' : this.state.secretAccessKey.valueOf();
  }

  public setAwsUrl = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      awsUrl: value,
    });
  }

  public getAwsUrl(): string {
    return this.state.awsUrl === undefined ? '' : this.state.awsUrl.valueOf();
  }

  public checkDisplay(input: EngineInputsType): string {
    switch (this.state.engine.toUpperCase()) {
      case Engines.IBM.toUpperCase():
        return SentimentInputs.ibm.values.indexOf(input) > -1 ? '' : this.formatMessage('none').toLowerCase();
      // case Engines.NATIVE.toUpperCase():
      //   return SentimentInputs.native.values.indexOf(input) > -1 ? '' : this.formatMessage('none').toLowerCase();
      case Engines.AWS.toUpperCase():
        return SentimentInputs.aws.values.indexOf(input) > -1 ? '' : this.formatMessage('none').toLowerCase();
      default:
        return this.formatMessage('none').toLowerCase();
    }
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const params: Submit = {};
    if (this.checkDisplay(EngineInputsType.SENTIMENT_LANGUAGE).length === 0) {
      params[`${AssistantParams.SENTIMENT_LANGUAGE}`] = this.getLanguage();
    }

    const payload: PayloadAssistantParams = {
      params,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/assistants/${this.props.currentAssistant}/`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: updateAssistant,
    };

    this.doCall(request);
  }

  public updateService() {
    const params: ServiceParam[] = [];

    let engineInputSelected = SentimentInputs.ibm;

    switch (this.getEngine()) {
      case Engines.IBM.toUpperCase():
        engineInputSelected = SentimentInputs.ibm;
        break;
      // case Engines.NATIVE.toUpperCase():
      //   engineInputSelected = SentimentInputs.native;
      //   break;
      case Engines.AWS.toUpperCase():
        engineInputSelected = SentimentInputs.aws;
        break;
      default:
        engineInputSelected = SentimentInputs.ibm;
        break;
    }

    if (engineInputSelected.values.indexOf(EngineInputsType.APIKEY) > -1) {
      params.push({
        name: EngineInputsType.APIKEY,
        value: this.getApiKey(),
      });
    }

    if (engineInputSelected.values.indexOf(EngineInputsType.API_URL) > -1) {
      params.push({
        name: EngineInputsType.API_URL,
        value: this.getUrl(),
      });
    }

    if (engineInputSelected.values.indexOf(EngineInputsType.ACCESS_KEY_ID) > -1) {
      params.push({
        name: EngineInputsType.ACCESS_KEY_ID,
        value: this.getAccessKeyId(),
      });
    }

    if (engineInputSelected.values.indexOf(EngineInputsType.SECRET_ACCESS_KEY) > -1) {
      params.push({
        name: EngineInputsType.SECRET_ACCESS_KEY,
        value: this.getSecretAccessKey(),
      });
    }

    if (engineInputSelected.values.indexOf(EngineInputsType.AWS_URL) > -1) {
      params.push({
        name: EngineInputsType.AWS_URL,
        value: this.getAwsUrl(),
      });
    }

    const payload: PayloadServiceParams = {
      type: ServiceType.NLU,
      subtype: this.state.engine,
      parameters: params,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/assistants/${this.props.currentAssistant}/service`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: updateService,
    };

    this.doCall(request);
  }

  public setResponseData() {
    if (this.props.callId === updateAssistant) {
      this.updateService();
    } else if (this.props.callId === updateService) {
      this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
      this.props.innerProps.reloadAssistant();
    } else if (this.props.callId === load) {
      const info: ServiceInfo = this.props.response.content as ServiceInfo;
      switch (info.subtype) {
        case Engines.IBM.toUpperCase():
          const apiKey = info.parameters.find((x: ServiceParam) => x.name === EngineInputsType.APIKEY.toLowerCase());
          const url = info.parameters.find((x: ServiceParam) => x.name === EngineInputsType.API_URL);
          this.setState({
            engine: info.subtype,
            apiKey: apiKey !== undefined ? apiKey.value : '',
            url: url !== undefined ? url.value : '',
          });
          break;
        // case Engines.NATIVE.toUpperCase():
        //   const language = info.parameters.find((x: ServiceParam) => x.name === EngineInputsType.SENTIMENT_LANGUAGE);
        //   this.setState({
        //     engine: info.subtype,
        //     language: language !== undefined ? language.value : '',
        //   });
        //   break;
        case Engines.AWS.toUpperCase():
          const accessKeyId = info.parameters.find((x: ServiceParam) => x.name === EngineInputsType.ACCESS_KEY_ID);
          const secretAccessKey = info.parameters.find(
            (x: ServiceParam) => x.name === EngineInputsType.SECRET_ACCESS_KEY,
          );
          const awsUrl = info.parameters.find((x: ServiceParam) => x.name === EngineInputsType.AWS_URL);
          this.setState({
            engine: info.subtype,
            accessKeyId: accessKeyId !== undefined ? accessKeyId.value : '',
            secretAccessKey: secretAccessKey !== undefined ? secretAccessKey.value : '',
            awsUrl: awsUrl !== undefined ? awsUrl.value : '',
          });
          break;
      }
    }
  }

  public setErrorResponseData() {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
  }

  public isAdmin(): boolean {
    return super.isAdmin(this.props.authorities);
  }
}

const SentimentWithIntl = injectIntl(SentimentBase);
const SentimentWithAlert = withAlert<SentimentBase['props']>()(SentimentWithIntl);
const SentimentWithSession = withContext(SessionContext)(SentimentWithAlert);
const SentimentWithContext = withContext(EditAssistantContext)(SentimentWithSession);
const SentimentWithApi = RefreshApi(Api(SentimentWithContext));
export const Sentiment = withRouter(SentimentWithApi);
