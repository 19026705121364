import React, { CSSProperties } from 'react';
import { AlertComponentPropsWithStyle } from 'react-alert';
import { CloseIcon } from './CloseIcon.component';
import { ErrorIcon } from './ErrorIcon.component';
import { InfoIcon } from './InfoIcon.component';
import { SuccessIcon } from './SuccessIcon.component';

const alertStyle: CSSProperties = {
  backgroundColor: '#16181D',
  boxShadow: '0 .125rem .25rem rgba(0,0,0,.5) !important',
  color: 'white',
  padding: '1.5rem',
  borderRadius: '0.5rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: '"Roboto", sans-serif',
  width: '100%',
  boxSizing: 'border-box',
  margin: 10,
};

const buttonStyle: CSSProperties = {
  marginLeft: 20,
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: 'rgb(255, 255, 255)',
};

export const AlertTemplate = ({message, options, style, close}: AlertComponentPropsWithStyle) => {
  return (
    <div style={{...alertStyle, ...style}}>
      {options.type === 'info' && <InfoIcon />}
      {options.type === 'success' && <SuccessIcon />}
      {options.type === 'error' && <ErrorIcon />}
      <span style={{flex: '2 1 0%'}}>{message}</span>
      <button style={buttonStyle} onClick={close}>
        <CloseIcon/>
      </button>
    </div>
  );
};
