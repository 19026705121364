import React from 'react';
import { Link } from 'react-router-dom';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { PageSize } from '../../../base/component/PageSize/PageSize.component';
import { Pagination } from '../../../base/component/Pagination/Pagination.component';
import { Search } from '../../../base/component/Search/Search.component';
import { ColumnObj, Table } from '../../../base/component/Table/Table.component';
import { OrganizationModel } from '../../model/AccountModel';
import { OrganizationListBase } from './OrganizationList.component';
import { CreateOrganization } from '../CreateOrganization/CreateOrganization.component';

export const OrganizationListView: ViewFunction<OrganizationListBase> = function() {
  const columns: ColumnObj[] = [
    {
      title: `${this.formatMessage('name')}`,
      propertyName: 'name',
      render: (item: OrganizationModel) => (
        <Link data-action='edit-User' to={`/organizations/${item.uuid}`}>
          {item.companyInfo.name}
        </Link>
      ),
    },
    {
      title: `${this.formatMessage('organization.primaryUser')}`,
      propertyName: 'primaryUser',
      render: (item: OrganizationModel) => <span>{item.primaryHolder ? item.primaryHolder.email : ''}</span>,
    },
    {
      title: `${this.formatMessage('organization.numberOfBots')}`,
      propertyName: 'numberOfBots',
      className: 'text-right',
      render: (item: OrganizationModel) => <span>{item.botsNumber}</span>,
    },
    {
      title: `${this.formatMessage('organization.status')}`,
      propertyName: 'status',
      className: 'text-right',
      render: (item: OrganizationModel) => <span>{item.companyInfo.status}</span>,
    },
    {
      title: `${this.formatMessage('trial')}`,
      propertyName: 'trial',
      className: 'text-right',
      render: (item: OrganizationModel) => (
        <div className='toggle-status ' onClick={this.confirmTrialChange(item)}>
          <i id={`state-${item}`} className={this.getTrialToggle(item)} />
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <CreateOrganization refresh={this.requestOrganizations} />
      <div className='card single-card shadow-sm'>
        <div className='card-body'>
          <div className='row'>
            <Search searchHandler={this.searchHandler} search={this.getCurrentCriteria()} />
          </div>
          <Table
            tableId={'Users'}
            data={this.getData()}
            columns={columns}
            page={this.getPage()}
            dropDownMenuItems={this.dropDownMenuItems}
          />
          <div className='row mt-3'>
            <div className='col-sm-6'>
              <PageSize changePageSize={this.changePageSizeHandler} size={this.getPageSize()} />
            </div>
            <div className='col-sm-6'>
              <Pagination
                totalPages={this.getTotalPages()}
                changePaginationHandler={this.changePageHandler}
                page={this.getPage()}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
