import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { SideBarBase } from './SideBar.component';

export const SideBarView: ViewFunction<SideBarBase> = function() {
  return (
    <nav id='sidebar' className={this.props.collapsed ? 'active' : ''}>
      {this.props.visible && (
        <ul className='list-unstyled components'>
          <li className={this.getActiveClass(['/dashboard'])}>
            <Link id='view-dashboard' to='/dashboard'>
              {' '}
              <i className='fas fa-chart-line fa-fw' />
              <FormattedMessage id='sidebar.dashboard' />
            </Link>
          </li>
          <li className={this.getActiveClass(['/bots/list'])}>
            <Link id='view-assistants' to={`/bots/list/?page=0`}>
              <i className='fas fa-robot fa-fw' />
              <FormattedMessage id='sidebar.assistants' />
            </Link>
          </li>
          {this.haveAdminFunctions() && (
            <>
            <div className='dropdown-divider'/>
            <div id='admin-options'>
            <li className={this.getActiveClass(['/account/organisation'])}>
                <Link id='view-organisation' to={`/account/organisation`}>
                  <i className='fas fa-building fa-fw' />
                  <FormattedMessage id='topBarMenu.organization' />
                </Link>
              </li>
              <li className={this.getActiveClass(['/account/users'])}>
                <Link id='view-users' to={`/account/users`}>
                  <i className='fas fa-users fa-fw' />
                  <FormattedMessage id='topBarMenu.users' />
                </Link>
              </li>

              <li className={this.getActiveClass(['/account/apikeys'])}>
                <Link id='view-apikeys' to={`/account/apikeys`}>
                  <i className='fas fa-code fa-fw' />
                  <FormattedMessage id='topBarMenu.apiKeys' />
                </Link>
              </li>
              </div>
            </>
          )}          
          {process.env.REACT_APP_ARE_GROUPS_VISIBLE === 'yes' && (
            <li className={this.getActiveClass(['/groups/list'])}>
              <Link id='view-groups' to={`/groups/list/?page=0`}>
                <i className='fas fa-users fa-fw' />
                <FormattedMessage id='sidebar.groups' />
              </Link>
            </li>
          )}

          {process.env.REACT_APP_ARE_CHATS_VISIBLE === 'yes' && (
            <li className={this.getActiveClass(['/chats/list'])}>
              <a href={this.getMessengerURL()}>
                <i className='fas fa-comment-dots  fa-fw' />
                <FormattedMessage id='sidebar.chats' />
              </a>
            </li>
          )}
        </ul>
      )}
      {!this.props.visible && this.haveSuperAdminFunctions() && (
            <ul className='list-unstyled components'>
              <li className={this.getActiveClass(['/organizations/list'])}>
                <Link id='view-organisation-list' to={`/organizations/list`}>
                  <i className='fas fa-building fa-fw' />
                  <FormattedMessage id='topBarMenu.organizationList' />
                </Link>
              </li>
            </ul>
      )}
    </nav>
  );
};

export default SideBarView;
