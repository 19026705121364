import React from 'react';
import {ViewFunction} from '../../../../../../../model/component/ViewFunction';
import {BubbleLinksBase} from './BubbleLinks.component';

export const BubbleLinksView: ViewFunction<BubbleLinksBase> = function() {
  return (
    <p>
      <ul className='list-unstyled'>
        {this.getLinks().map((link, index) => (
            <li key={index}>
              <a href={link.url} className='text-white' target='_blank' rel='noopener noreferrer'>{link.title}</a>
            </li>
          ))
        }
      </ul>
    </p>
  );
};

export default BubbleLinksView;
