import { FormEvent } from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../../api/Server';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../model/http/Request';
import { Response } from '../../../../../model/http/Response';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { Navigation } from '../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { EditAssistantContext } from '../state/EditAssistantContext';
import { Status } from '../../../model/Status';
import { MouseEvent } from 'react';
import { ChannelModel, ChannelStateModel } from './state/ChannelStateModel';
import { LayoutStateModel } from '../../../../base/state/Layout/LayoutStateModel';
import { LayoutContext } from '../../../../base/state/Layout/LayoutContext';
import { ChannelApiView } from './ChannelApi.view';

interface ResponseChannelApi extends Response {
  content: ChannelModel;
  timestamp: Date;
  size: string;
}

interface ChannelApiState extends ApiState {
  data: ChannelModel | null;
  showConfirmDialog: boolean;
  currentItem: ChannelModel | null;
  statusToChange: string;
}

interface IntlProps {
  intl: IntlShape;
  assistantId: string;
  isAdmin: boolean;
}

type Props = IntlProps &
  ApiProps<ResponseChannelApi> &
  SessionStateModel &
  ChannelStateModel &
  LayoutStateModel &
  RouteComponentProps<{ botId: string }> &
  RouteComponentProps<Navigation> &
  WithAlertProps;

export class ChannelApiBase extends ApiBase<ResponseChannelApi, Props, ChannelApiState> {
  public render = ChannelApiView.bind(this);

  constructor(props: Props) {
    super(props);
    this.setToggleState = this.setToggleState.bind(this);

    this.state = {
      data: null,
      error: null,
      authError: null,
      showConfirmDialog: false,
      currentItem: null,
      statusToChange: '',
    };
  }

  public componentDidMount(): void {
    this.getChannelApi();
  }

  public confirm = (item: ChannelModel | null) => (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!this.props.isAdmin) {
      return;
    }

    if (item) {
      const statusToChange = item.status === Status.ENABLED ? Status.DISABLED : Status.ENABLED;

      this.setState({
        currentItem: item,
        statusToChange,
      });
      this.setConfirmVisibility(true);
    }
  }

  public getToggleTitle(item: ChannelModel | null): string {
    if (item && item.status === Status.ENABLED) {
      return 'Enabled';
    }
    return 'Disabled';
  }

  public getAssistantId() {
    return this.props.match.params.botId;
  }

  public updateChannelSubscription = (newStatus: string, channelUuid: string) => {
    const payload = null;
    const botId = this.getAssistantId();
    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/assistants/channel/${channelUuid}/assistant/${botId}/status/${newStatus}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'update-status',
    };
    this.doCall(request);
  }

  public getChannelApi() {
    const payload = null;
    const botId = this.getAssistantId();
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload,
      relativePath: `/admin/assistants/${botId}/channel-api`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };
    this.doCall(request);
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState(
      {
        error: null,
      },
      () => {
        this.getChannelApi();
      },
    );
  }

  public hasError(): boolean {
    return this.state.error !== null;
  }

  public confirmCallback = () => {
    this.setToggleState(this.state.currentItem);
  }

  public setConfirmVisibility = (show: boolean) => {
    this.setState({
      showConfirmDialog: show,
    });
  }

  public setResponseData() {
    const response = this.props.response;
    if (this.props.callId === 'update-status') {
      this.getChannelApi();
    } else {
      this.setState({
        data: response.content as ChannelModel,
      });
    }
  }

  public formatMessage(id: string, values: Record<string, any> = {}) {
    return this.props.intl.formatMessage({ id }, values);
  }

  public setToggleState = (item: ChannelModel | null) => {
    if (item) {
      const statusToChange = item.status === Status.ENABLED ? Status.DISABLED : Status.ENABLED;
      this.setState({
        statusToChange,
      });

      this.updateChannelSubscription(this.state.statusToChange, item.uuid);
    }
  }

  public getToggle(item: ChannelModel | null): string {
    if (item && item.status === Status.ENABLED) {
      return 'fas fa-toggle-on fa-2x on text-success';
    }
    return 'fas fa-toggle-off fa-2x off text-secondary';
  }

  public dataPropertyValue = (item: ChannelModel | null) => {
    if (item && item.status === Status.ENABLED) {
      return true;
    }
    return false;
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
  }
}

const ChannelApiWithIntl = injectIntl(ChannelApiBase);
const ChannelApiWithAlert = withAlert<ChannelApiBase['props']>()(ChannelApiWithIntl);
const ChannelApiWithLayout = withContext(LayoutContext)(ChannelApiWithAlert);
const ChannelApiWithSession = withContext(SessionContext)(ChannelApiWithLayout);
const ChannelApiWithContext = withContext(EditAssistantContext)(ChannelApiWithSession);
const ChannelApiWithApi = RefreshApi(Api(ChannelApiWithContext));
export const ChannelApi = withRouter(ChannelApiWithApi);
