import React from 'react';
import BubbleHTMLView from './BubbleHTMLView';

interface Props {
  text?: string;
}

export class BubbleHTMLBase extends React.Component<Props, {}> {

  public render = BubbleHTMLView.bind(this);

  public getText(): string {
    return this.props.text ? this.props.text : '';
  }

}

export const BubbleHTML = BubbleHTMLBase;
