import React, {FormEvent} from 'react';
import {injectIntl, IntlShape} from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { getEnumType } from '../../../../util/enum';
import { HeaderBtn } from '../../../base/component/PageHeader/model/HeaderBtn';
import { LayoutContext } from '../../../base/state/Layout/LayoutContext';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { AnswerType } from '../../model/AnswerType';
import { Languages } from '../../model/Languages';
import { AnswerContext } from '../../state/AnswerContext';
import { AnswerStateModel } from '../../state/AnswerStateModel';
import { CreateAnswerView } from './CreateAnswer.view';

interface State {
  answerCode: string;
  answerLang: string;
  answerType: AnswerType | null;
  isValid: boolean;
}

interface IntlProps {
  intl: IntlShape;
}

type Props = IntlProps & LayoutStateModel & AnswerStateModel & RouteComponentProps;

export class CreateAnswerBase extends React.Component<Props, State> {
  public state: State = {
    answerCode: '',
    answerLang: '',
    answerType: null,
    isValid: false,
  };

  public render = CreateAnswerView.bind(this);

  public constructor(props: Props) {
    super(props);
  }

  public componentDidMount(): void {
    this.props.setPageTitle(this.formatMessage('answerList.answers'));
    const createAnswerBtn: HeaderBtn = {
      title: `${this.formatMessage('createAnswer.createNewAnswer')}`,
      hint: `${this.formatMessage('createAnswer.createNewAnswer')}`,
      callback: this.handleOpen,
      id: 'create-new-answer',
      enabled: true,
    };

    this.props.addHeaderBtn(createAnswerBtn);
  }

  public getAnswerTypes = (): string[] => {
    return Object.values(AnswerType).filter((item) => item !== null);
  }

  public getAnswerLangs = (): string[] => {
    return Object.values(Languages);
  }

  public changeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      answerCode: value,
    });
  }

  public changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      answerLang: value,
    });
  }

  public isAllValid = () => {
    return ((this.state.answerType !== null) && (this.state.answerLang !== '') && (this.state.answerCode !== ''));
  }

  public changeType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value: string = target.value;

    const enumVal: any = getEnumType(value, AnswerType);
    this.setState({
      answerType: enumVal,
    });
  }

  public setIsFormValid = (isValid: boolean) => {
    if (this.state.isValid !== isValid) {
      this.setState({
        isValid,
      });
    }
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.handleSave();
  }

  public handleSave = () => {

    this.props.setAnswer({
      code: this.state.answerCode,
      language: this.state.answerLang,
      type: this.state.answerType!,
      answerContent: null,
      uuid: null,
    }, () => {
      this.props.history.push(`/answers/create#${this.state.answerType}`);

      this.props.setModalVisibility(false);
    });

  }

  public componentWillUnmount = () => {
    this.props.removeHeaderBtn('create-new-answer');
  }

  public isModalVisible = () => {
    return this.props.showModal;
  }

  public getAnswerType = () => {
    if (this.state.answerType) {
      return this.state.answerType!.valueOf();
    }
    return '';
  }

  public handleClose = () => {
    this.setState({
      answerType: null,
      answerLang: '',
      answerCode: '',
    });
    this.props.setModalVisibility(false);
  }

  public handleOpen = () => {
    this.props.setModalVisibility(true);
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({id});
  }
}

const CreateAnswerWithIntl = injectIntl(CreateAnswerBase);
export const CreateAnswerWithRouter = withRouter(CreateAnswerWithIntl);
export const CreateAnswerLayoutContext = withContext(LayoutContext)(CreateAnswerWithRouter);
export const CreateAnswer = withContext(AnswerContext)(CreateAnswerLayoutContext);
