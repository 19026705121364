import { Api, RefreshApi } from '../../../../../api/Server';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { IntlShape, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { EditAssistantContext } from '../state/EditAssistantContext';
import { WelcomeMessagesView } from './WelcomeMessages.view';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Navigation } from '../../../../base/model/route/Navigation';
import { Request } from '../../../../../model/http/Request';
import { Response } from '../../../../../model/http/Response';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { withAlert } from 'react-alert';
import { LayoutStateModel } from '../../../../base/state/Layout/LayoutStateModel';
import {
  withPaginationRouting,
  WithPaginationRoutingProps,
} from '../../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { DropDownMenuItem } from '../../../../base/component/Table/Table.component';
import { InnerProps } from '../../../model/Status';

interface ResponseUpdate extends Response {
  content: Message[];
  timestamp: Date;
  size: string;
  page: number;
  pageElements: number;
  totalElements: number;
}

export interface Message {
  message: string;
  uuid?: string;
  type?: string;
}

interface InfoState extends ApiState {
  messages: Message[] | null;
  isValid: boolean;
  totalPages: number;
  isModalVisible: boolean;
  messageToEdit: Message | null;
  showConfirmDialogue: boolean;
  confirmAction: string | null;
  messageToDelete: Message | null;
}

interface IntlProps {
  intl: IntlShape;
  innerProps: InnerProps;
}

type Props = IntlProps &
  ApiProps<ResponseUpdate> &
  SessionStateModel &
  LayoutStateModel &
  WithPaginationRoutingProps<Navigation> &
  RouteComponentProps<Navigation> &
  RouteComponentProps<{ botId: string }> &
  WithAlertProps;

export class WelcomeMessagesBase extends ApiBase<ResponseUpdate, Props, InfoState> {
  public render = WelcomeMessagesView.bind(this);

  constructor(props: Props) {
    super(props);
    this.state = {
      messages: null,
      error: null,
      totalPages: 0,
      authError: null,
      isValid: false,
      isModalVisible: false,
      messageToEdit: null,
      showConfirmDialogue: false,
      confirmAction: null,
      messageToDelete: null,
    };
  }

  public componentDidMount() {
    this.onEmiter(this.updateList , 'importSuccess');
  }

  public componentWillMount(): void {
    this.updateList();
  }

  public componentDidUpdate(prevProps: Props) {
    super.componentDidUpdate(prevProps);
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  public onRouteChanged() {
    this.updateList();
  }

  public getAssistantId() {
    return this.props.match.params.botId;
  }

  public updateList = () => {
    const criteria = encodeURIComponent(this.getCurrentCriteria());
    const currentPage = this.getCurrentPage();
    const currentPageSize = this.getCurrentPageSize();
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/studio/assistant/${this.getAssistantId()}/welcomemessages?criteria=${criteria}&page=${currentPage}&size=${currentPageSize}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'reload-welcomemessages',
    };
    this.doCall(request);
  }

  public addNewWelcome = (newMessage: Message) => {
    const payload = newMessage;
    const request: Request<{}> = {
      method: HttpRequestType.POST,
      payload,
      relativePath: `/admin/studio/assistant/welcomemessage/${this.getAssistantId()}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'update-welcomemessages',
    };
    this.doCall(request);
  }

  public updateWelcome = (newMessage: Message) => {
    const payload = { message: newMessage.message };
    const welcomeId = newMessage.uuid;
    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/studio/assistant/welcomemessage/${welcomeId}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'update-welcomemessages',
    };
    this.doCall(request);
  }

  public deleteWelcome = (newMessage: Message) => {
    const welcomeId = newMessage.uuid;
    const request: Request<{}> = {
      method: HttpRequestType.DELETE,
      payload: null,
      relativePath: `/admin/studio/assistant/welcomemessage/${welcomeId}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'update-welcomemessages',
    };
    this.doCall(request);
  }

  public getCurrentPage(): number {
    return this.props.page;
  }

  public getCurrentPageSize(): number {
    return this.props.pageSize;
  }

  public getTotalPages() {
    return this.state.totalPages;
  }

  public changePageSize = (pageSize: number) => {
    this.props.changePageSize(pageSize);
  }

  public getCurrentCriteria(): string {
    return this.props.getExtraParam('criteria') || '';
  }

  public changePaginationHandler = (pageNumber: number) => {
    this.props.goToPage(pageNumber);
  }

  public searchHandler = (criteria: string) => {
    this.props.goToPage(0, {
      criteria,
    });
  }

  public dropDownMenuItems = () => {
    const dropDownItemList: DropDownMenuItem[] = [
      {
        text: 'Edit',
        callBack: this.edit,
      },
      {
        text: 'Delete',
        callBack: this.deleteConfirm,
      },
    ];
    return dropDownItemList;
  }

  public setConfirmVisibility = (show: boolean) => {
    this.setState({ showConfirmDialogue: show});
  }

  public confirmCallback = () => {
    if (this.state.messageToDelete) {
      this.delete(this.state.messageToDelete);
    }
  }

  public deleteConfirm = (item: Message) => {
    this.setState({messageToDelete: item});
    this.setConfirmVisibility(true);
  }

  public delete = (item: Message) => {
    const newObj =
      this.state.messages?.filter((value) => {
        return value !== item;
      }) || null;
    this.setState({
      messages: newObj,
    });
    this.deleteWelcome(item);
  }

  public edit = (item: Message) => {
    this.setState({
      messageToEdit: item,
      isModalVisible: true,
    });
  }

  public saveEdits = (newItem: Message) => {
    const newMessages =
      this.state.messages?.map((message) => {
        if (message === this.state.messageToEdit) {
          return newItem;
        } else {
          return message;
        }
      }) || null;
    this.setState(
      {
        messages: newMessages,
        messageToEdit: null,
      },
      () => {
        this.handleClose();
      },
    );
    this.updateWelcome(newItem);
  }

  public getData() {
    return this.state.messages ? this.state.messages : [];
  }

  public handleClose = () => {
    this.setState({ isModalVisible: false, messageToEdit: null });
  }

  public save = (newMessage: Message) => {
    if (this.state.messages) {
      this.setState({ messages: [newMessage, ...this.state.messages] }, () => {
        this.handleClose();
      });
    } else {
      this.setState({
        messages: [newMessage],
      });
    }
    this.addNewWelcome(newMessage);
  }

  public handleOpen = () => {
    this.setState({
      isModalVisible: true,
    });
  }

  public hasError(): boolean {
    return this.state.error !== null;
  }

  public setResponseData() {
    if (this.props.callId === 'reload-welcomemessages') {
      this.props.innerProps.getBotStatus();
      const response = this.props.response;
      this.setState({
        messages: response.content,
        totalPages: Math.ceil(response.totalElements / Number(response.size)),
      });
      if (response.page) {
        this.props.setBreadCrumbsInfo({
          currentPage: null,
          pageNumber: response.page.toString(),
        });
      }
    } else {
      this.updateList();
      this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
    }
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
  }
}

const WelcomeMessagesWithIntl = injectIntl(WelcomeMessagesBase);
const WelcomeMessagesWithAlert = withAlert<WelcomeMessagesBase['props']>()(WelcomeMessagesWithIntl);
const WelcomeMessagesWithSession = withContext(SessionContext)(WelcomeMessagesWithAlert);
const WelcomeMessagesWithContext = withContext(EditAssistantContext)(WelcomeMessagesWithSession);
const WelcomeMessagesWithApi = RefreshApi(Api(WelcomeMessagesWithContext));
export const WelcomeMessages = withPaginationRouting()(WelcomeMessagesWithApi);
