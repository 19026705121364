import React, { CSSProperties, ReactNode } from 'react';
import TableView from './Table.view';

export interface DropDownMenuItem {
  text: ((item: any) => string) | string;
  callBack: (item: any) => void;
}

interface Props {
  tableId: string;
  columns: any[];
  data: any[];
  page: number;
  rowDetail?: any;
  dropDownMenuItems?: (item: any) => DropDownMenuItem[];
}

export interface ColumnObj {
  propertyName: string;
  title: string;
  style?: CSSProperties;
  className?: string;
  render?: (item: any, index: number) => ReactNode;
  getPropertyValue?: (item: any, propertyName: string) => string;
}

export class Table extends React.Component<Props, never> {

  public render = TableView.bind(this);

  public getData(): any[] {
    return this.props.data;
  }

  public getColumns() {
    return this.props.columns;
  }

  public getPage() {
    return this.props.page;
  }

  public getDetail() {
    return this.props.rowDetail;
  }

  public haveDetail() {
    return this.props.rowDetail !== undefined && this.props.rowDetail !== null;
  }
}
