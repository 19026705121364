import { withAlert } from 'react-alert';
import {injectIntl, IntlShape} from 'react-intl';
import { Api, RefreshApi } from '../../../../../../api/Server';
import { HttpRequestType } from '../../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../../model/http/Request';
import { SessionContext } from '../../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../../../base/ApiComponent';
import { withPaginationRouting, WithPaginationRoutingProps } from '../../../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../../base/model/WithAlertProps';
import { withContext } from '../../../../../lib/component/withContext/withContext.hoc';
import { EditGroupContext } from '../../state/EditGroupContext';
import { EditGroupStateModel } from '../../state/EditGroupStateModel';
import { GetLogsList, LogsModel } from '../../../../../common/model/logs/LogsModel';
import { LogsGroupDialogueLogsView } from './LogsGroupDialogueLogs.view';

interface LogsGroupDetailProps {
    dialogueUuid: string;
}

interface IntlProps {
  intl: IntlShape;
}

type Props =
  IntlProps
  & ApiProps<GetLogsList>
  & SessionStateModel
  & EditGroupStateModel
  & WithPaginationRoutingProps<Navigation>
  & WithAlertProps & LogsGroupDetailProps;

interface LogsDetailState extends ApiState {
    listData: LogsModel[];
    totalPages: number;
    currentPage: number;
}

interface LogsPayload {
    dialogue_uuid: string;
  }

export class LogsGroupDialogueLogsBase extends ApiBase<GetLogsList, Props, LogsDetailState> {

    public render = LogsGroupDialogueLogsView.bind(this);

    private readonly GETLOGS = 'getLogs';

    constructor(props: Props) {
        super(props);

        this.state = {
            listData: [],
            error: null,
            authError: null,
            totalPages: 0,
            currentPage: 0,
        };
    }

    public componentDidMount() {
        this.callLogsForDialogue();
    }

    public componentDidUpdate(prevProps: Props) {
        super.componentDidUpdate(prevProps);
        if (prevProps.dialogueUuid !== this.props.dialogueUuid) {
            this.callLogsForDialogue();
        }
    }

    public callLogsForDialogue() {
        const payload: LogsPayload = {
            dialogue_uuid: this.props.dialogueUuid,
        };

        const { currentGroup } = this.props;
        const page = this.getCurrentPage();
        const pageSize = this.getCurrentPageSize();
        const request: Request<{}> = {
            id: this.GETLOGS,
            method: HttpRequestType.POST,
            payload,
            relativePath: `/admin/groups/${currentGroup}/logs?page=${page}&size=${pageSize}`,
            token: this.props.token,
            refreshToken: this.props.refreshToken,
        };
        this.doCall(request);
    }

    public getCurrentPage() {
        return this.state.currentPage;
    }

    public getCurrentPageSize(): number {
        return this.props.pageSize;
    }

    public changePageSize = (size: number) => {
        this.props.changePageSize(size);
    }

    public changePaginationHandler = (pageNumber: number) => {
        this.setState(() => ({
            currentPage: pageNumber,
        }), () => {
            this.callLogsForDialogue();
        });
    }

    public getDialogueList(): LogsModel[] {
        return this.state.listData !== null ? this.state.listData : [];
    }

    public formatMessage(id: string) {
      return this.props.intl.formatMessage({id});
    }

    protected setResponseData(): void {
        const response = this.props.response;
        this.setState(() => ({
            listData: response.content,
            totalPages: Math.ceil(response.totalElements / Number(response.size)),
        }));
    }

    protected setErrorResponseData(): void {
       this.props.alert.error(this.formatMessage('logsGroupDialogueLogsBase.errorFetchingLogsDetail') +
       (this.props.error ? ': ' + this.props.error!.message : ''));
    }

}

const LogsGroupDialogueLogsWithIntl = injectIntl(LogsGroupDialogueLogsBase);
const LogsGroupDialogueLogsWithAlert =
  withAlert<LogsGroupDialogueLogsBase['props']>()(LogsGroupDialogueLogsWithIntl);
const LogsGroupDialogueLogsWithSession = withContext(SessionContext)(LogsGroupDialogueLogsWithAlert);
const LogsGroupDialogueLogsWithAssistant = withContext(EditGroupContext)(LogsGroupDialogueLogsWithSession);
const LogsGroupDialogueLogsWithApi = RefreshApi(Api(LogsGroupDialogueLogsWithAssistant));
export const LogsGroupDialogueLogsDetail = withPaginationRouting()(LogsGroupDialogueLogsWithApi);
