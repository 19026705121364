import React, { PropsWithChildren } from 'react';
import { Answer } from '../model/Answer';
import { AnswerType } from '../model/AnswerType';
import { AnswerContext } from './AnswerContext';
import { AnswerStateModel } from './AnswerStateModel';

type Props = PropsWithChildren<{}>;

export class AnswerStoreProvider
  extends React.Component<Props, AnswerStateModel> {

  constructor(props: Props) {
    super(props);
    this.state = {
      answer: null,
      setAnswer: this.setAnswer,
      setAnswerType: this.setAnswerType,
    };
  }

  public render() {
    return (
      <AnswerContext.Provider value={this.state}>
        {this.props.children}
      </AnswerContext.Provider>
    );
  }

  private setAnswer = (answer: Answer | null, cb?: () => void) => {
    this.setState({
      answer,
    }, cb);
  }

  private setAnswerType(answerType: AnswerType) {
    if (this.state) {
      const answer: Answer = {
        language: this.state.answer!.language,
        code: this.state.answer!.code,
        type: answerType,
        answerContent: null,
        uuid: null,
      };
      this.setAnswer(answer);
    }
  }
}
