import React from 'react';
import { Dropdown, Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { MenuBase } from './Menu.component';

export const MenuView: ViewFunction<MenuBase> = function() {
  return (
    <Navbar.Collapse id='navbarSupportedContent' data-action='navbarSupportedContent' style={{ background: 'white' }}>
      <Nav className='ml-auto'>
        {this.props.visible && (
          <Nav className='navbar-nav ml-4 mr-auto d-lg-none d-xl-none' style={{ width: '100%' }}>
            <Nav.Link data-action='view-dashboard' as={Link} to='/dashboard'>
              <i className='fas fa-home mr-2' />
              <FormattedMessage id='sidebar.dashboard' />
            </Nav.Link>
            <Nav.Link data-action='view-assistants' href='/bots/list/0'>
              <i className='fas fa-users mr-2' />
              <FormattedMessage id='sidebar.assistants' />
            </Nav.Link>
            {this.haveAdminFunctions() && (
              <>
                <Nav.Link data-action='view-users' as={Link} to='/account/users'>
                  <i className='fas fa-users fa-fw mr-2' />
                  <FormattedMessage id='topBarMenu.users' />
                </Nav.Link>

                <Nav.Link data-action='view-organisation' as={Link} to='/account/organisation'>
                  <i className='fas fa-building fa-fw mr-2' />
                  <FormattedMessage id='topBarMenu.organization' />
                </Nav.Link>

                <Nav.Link data-action='view-apikeys' as={Link} to='/account/apikeys'>
                  <i className='fas fa-code fa-fw mr-2' />
                  <FormattedMessage id='topBarMenu.apiKeys' />
                </Nav.Link>
              </>
            )}
            {process.env.REACT_APP_ARE_GROUPS_VISIBLE === 'yes' && (
              <Nav.Link data-action='view-groups' href='/groups/list'>
                <i className='fas fa-users fa-fw mr-2' />
                <FormattedMessage id='sidebar.groups' />
              </Nav.Link>
            )}
            {process.env.REACT_APP_ARE_CHATS_VISIBLE === 'yes' && (
              <Nav.Link data-action='view-chats' href='/chats/list'>
                <i className='fas fa-comment-dots  fa-fw mr-2' />
                <FormattedMessage id='sidebar.chats' />
              </Nav.Link>
            )}
            <NavDropdown.Divider />
          </Nav>
        )}

        <Dropdown as={NavItem} alignRight>
          <Dropdown.Toggle data-action='view-account' id='view-account' as={Nav.Link}>
            <span className='nav-toggle-space'/>
            <FormattedMessage id='topBarMenu.account' />
          </Dropdown.Toggle>
          {this.props.visible && this.haveAdminFunctions() && (
            <Dropdown.Menu>
              <NavDropdown.Item id='view-myProfile' as={Link} to='/account/profile'>
                <FormattedMessage id='topBarMenu.myProfile' />
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Divider />
              <NavDropdown.Item href='#' id='logout' onClick={this.logout}>
                <FormattedMessage id='topBarMenu.logout' />
              </NavDropdown.Item>
            </Dropdown.Menu>
          )}
          {this.props.visible && !this.haveAdminFunctions() && (
            <Dropdown.Menu>
              <NavDropdown.Item id='view-myProfile' as={Link} to='/profile'>
                <FormattedMessage id='topBarMenu.myProfile' />
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Divider />
              <NavDropdown.Item href='#' id='logout' onClick={this.logout}>
                <FormattedMessage id='topBarMenu.logout' />
              </NavDropdown.Item>
            </Dropdown.Menu>
          )}
          {!this.props.visible && (
            <Dropdown.Menu>
              <NavDropdown.Item href='#' id='logout' onClick={this.logout}>
                <FormattedMessage id='topBarMenu.logout' />
              </NavDropdown.Item>
            </Dropdown.Menu>
          )}
        </Dropdown>
      </Nav>
    </Navbar.Collapse>
  );
};
