import React from 'react';
import {FormattedMessage} from 'react-intl';
import { ViewFunction } from '../../../../../../model/component/ViewFunction';
import { Input } from '../../../../../form/component/Input.component';
import { notEmpty } from '../../../../../form/lib/rules';
import formLayout from '../formLayout';
import { TextAnswerBase } from './TextAnswer.component';

export const TextAnswerView: ViewFunction<TextAnswerBase> = function() {
  const childComponent = () => {
    return (
      <React.Fragment>
        <div className='form-group'>
          <label htmlFor='textInput'><FormattedMessage id='textAnswer'/></label>
          <span className='float-right text-danger'>*</span>
          <Input
            type='text'
            className='form-control'
            id='textInput'
            placeholder={`${this.formatMessage('textAnswer.enterYourTextAnswerHere')}...`}
            aria-describedby='textAnswer'
            value={this.getAnswerText()}
            onChange={this.changeText}
            rules={[notEmpty()]}
          />
        </div>
        <div className='text-right'>
          <button
            id='saveBtn'
            type='submit'
            className='btn btn-primary'
          >
            {`${this.formatMessage('save')}`}
          </button>
        </div>
      </React.Fragment>
    );
  };

  return (
    formLayout(this, childComponent)
  );
};
