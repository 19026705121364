import { ChannelType, SentimentEnum } from '../../../../common/model/logs/LogsModel';

export interface AssistantDialogueDetailsResponse extends Response {
  content: AssistantDialogueDetails;
}

export enum LogsExportFormat {
  XLSX = 'XLSX',
  CSV = 'CSV',
}

export interface AssistantDialogueDetails extends Response {
  started: Date;
  ended: Date;
  duration: number;
  messages: number;
  confidence: number;
  sentiment: SentimentEnum;
  channelType: ChannelType;
}
