import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import {FormattedMessage} from 'react-intl';
import { ViewFunction } from '../../../../../../model/component/ViewFunction';
import { Input } from '../../../../../form/component/Input.component';
import { notEmpty } from '../../../../../form/lib/rules';
import formLayout from '../formLayout';
import { CommandAnswerBase } from './CommandAnswer.component';

export const CommandAnswerView: ViewFunction<CommandAnswerBase> = function() {
  const childComponent = () => {
  return (
    <React.Fragment>
      <div className='form-group'>
        <label htmlFor='commandtitle'><FormattedMessage id='commandAnswerBase.commandName'/></label>
        <span className='float-right text-danger'>*</span>
        <Input
          type='text'
          className='form-control'
          id='commandtitle'
          aria-describedby='commandtitle'
          placeholder={`${this.formatMessage('commandAnswerBase.enterYourCommandNameHere')}...`}
          value={this.getAnswerCommandName()}
          onChange={this.changeCommandName}
          rules={[notEmpty()]}
        />
      </div>
      <div className='form-group'>
        <h6 className='mb-0'><FormattedMessage id='commandAnswerBase.parameters'/></h6>
      </div>
      <div className='form-group'>
        <label htmlFor='parametername'><FormattedMessage id='commandAnswerBase.parameterName'/></label>
        <Input // cant use rules={[notEmpty()]} rule because this field must be
          type='text'// empty when submit, but is must be notEmpty when click on add
          className={'form-control ' + (this.state.parametersKeyClass)}
          id='parametername'
          aria-describedby='parametername'
          placeholder={`${this.formatMessage('commandAnswerBase.enterYourTitleHere')}...`}
          value={this.getAnswerParameterName()}
          onChange={this.changeParameterName}
        />
        {this.state.parametersKeyClass !== '' && (
          <div className='invalid-feedback'>
            {this.formatMessage('form.itCannotBeEmpty')}
          </div>
        )}
      </div>
      <div className='form-group'>
        <label htmlFor='optionValue'><FormattedMessage id='value'/></label>
        <input
          type='text'
          className={'form-control ' + (this.state.parametersValueClass)}
          id='optionValue'
          aria-describedby='optionValue'
          placeholder={`${this.formatMessage('commandAnswerBase.enterYourCommandLinkHere')}...`}
          value={this.getAnswerCommandValue()}
          onChange={this.changeCommandValue}
        />
        {this.state.parametersValueClass !== '' && (
          <div className='invalid-feedback'>
            {this.formatMessage('form.itCannotBeEmpty')}
          </div>
        )}
      </div>
      <div className='form-group'>
        <div className='text-right'>
          <button
            id='addCommand'
            type='button'
            className={'btn btn-primary'}
            onClick={this.addCommand}
          >
            {`${this.getButtonText()} ${this.formatMessage('commandAnswerBase.parameter')}`}
          </button>
        </div>
      </div>
      <div className='height-control'>
        <div className='table-responsive'>

          <table className='table mb-4' id='parameters-list'>
            <thead>
            <tr>
              <th scope='col'><FormattedMessage id='commandAnswerBase.parameter'/></th>
              <th scope='col'><FormattedMessage id='value'/></th>
              <th style={{width: '40px'}} scope='col'/>
            </tr>
            </thead>
            <tbody>
            {this.getCommandList().map((item, i) => {
              return (
                <tr key={i} data-type='item'>
                  <td data-property-value={item.key} data-property-name='name'>{item.key}</td>
                  <td data-property-value={item.value} data-property-name='value'>{item.value}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle data-action='view-options' bsPrefix={'btn-icon'} id={`dropdownMenuButton-${i}`}>
                        <i className='fas fa-ellipsis-v'/>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          bsPrefix={'dropdown-item'}
                          data-action='Edit'
                          onClick={this.handleEditCommandRow(i)}
                        >
                          ${this.formatMessage('edit')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          bsPrefix={'dropdown-item'}
                          data-action='Delete'
                          onClick={this.handleDeleteCommandRow(i)}
                        >
                          ${this.formatMessage('delete')}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </div>
      </div>
      <div className='text-right'>
        <button
          id='saveBtn'
          type='submit'
          className='btn btn-primary'
        >
          ${this.formatMessage('save')}
        </button>
      </div>
    </React.Fragment>
  ); };

  return (
    formLayout(this, childComponent)
  );
};
