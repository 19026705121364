import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { SearchBase} from './Search.component';

export const SearchView: ViewFunction<SearchBase> = function() {

  return (
      <div className='col-lg-4 col-xl-4 mb-3'>
        <div className='input-group'>
          <input
            id='search-text'
            onChange={this.changeSearch}
            value={this.state.search}
            type='text'
            className='form-control'
            placeholder={`${this.formatMessage('search')}...`}
            aria-label='Search...'
            onKeyPress={this.checkIfEnterWasPressed}
          />
          <div className='input-group-append'>
            <button
              className='btn btn-light'
              type='button'
              id='search'
              onClick={this.search}
            >
              <i className='fas fa-search'/>
            </button>
          </div>
        </div>
      </div>
  );
};

export default SearchView;
