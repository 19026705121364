import { FormEvent } from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Api, RefreshApi } from '../../../../../api/Server';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../model/http/Request';
import { Response } from '../../../../../model/http/Response';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { Navigation } from '../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';

import {
  withPaginationRouting,
  WithPaginationRoutingProps,
} from '../../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Status } from '../../../model/Status';

import { LayoutStateModel } from '../../../../base/state/Layout/LayoutStateModel';
import { ChannelView } from './Channel.view';
import { ChannelContext } from './state/ChannelContext';
import { ChannelModel, ChannelStateModel } from './state/ChannelStateModel';

interface ResponseUpdate extends Response {
  content: ChannelModel[];
  timestamp: Date;
  size: string;
  page: number;
  pageElements: number;
  totalElements: number;
}

interface ChannelState extends ApiState {
  data: ChannelModel[];
  totalPages: number;
  showConfirmDialog: boolean;
  currentItem: ChannelModel | null;
  statusToChange: string;
}

interface IntlProps {
  intl: IntlShape;
}

type Props = IntlProps &
  ApiProps<ResponseUpdate> &
  SessionStateModel &
  ChannelStateModel &
  LayoutStateModel &
  RouteComponentProps<{ botId: string }> &
  WithPaginationRoutingProps<Navigation> &
  WithPaginationRoutingProps<{ botId: string }> &
  WithAlertProps;

export class ChannelBase extends ApiBase<ResponseUpdate, Props, ChannelState> {
  public render = ChannelView.bind(this);

  constructor(props: Props) {
    super(props);

    this.state = {
      data: [],
      totalPages: 0,
      error: null,
      authError: null,
      showConfirmDialog: false,
      currentItem: null,
      statusToChange: '',
    };
  }

  public changeApikey = (value: string, item: ChannelModel) => {
    const newData = this.state.data;
    newData.forEach((currItem: ChannelModel) => {
      // tslint:disable-next-line:triple-equals
      if (currItem.uuid === item.uuid) {
        currItem.selectedApiKey = value;
      }
    });
    this.setState({
      data: newData,
    });
  }

  public componentDidMount(): void {
    this.getChannels();
  }

  public componentDidUpdate(prevProps: Props) {
    super.componentDidUpdate(prevProps);
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }
  public getTotalPages() {
    return this.state.totalPages;
  }

  public getPageSize() {
    return this.props.pageSize;
  }

  public changePageHandler = (pageNumber: number) => {
    this.props.goToPage(pageNumber);
  }

  public changePageSizeHandler = (pageSize: number) => {
    this.props.changePageSize(pageSize);
  }

  public confirm = (item: ChannelModel) => {
    if (item) {
      const statusToChange = item.status === Status.ENABLED ? Status.DISABLED : Status.ENABLED;
      this.setState({
        currentItem: item,
        statusToChange,
      });
      this.setConfirmVisibility(true);
    }
  }

  public getCurrentPageSize(): number {
    return this.props.pageSize;
  }

  public getCurrentCriteria(): string {
    return this.props.getExtraParam('criteria') || '';
  }

  public changePaginationHandler = (pageNumber: number) => {
    this.props.goToPage(pageNumber);
  }

  public getPage() {
    return this.props.page;
  }

  public getAssistantId() {
    return this.props.match.params.botId;
  }

  public getChannels() {
    const payload = null;
    const currentPage = this.getCurrentPage();
    const currentPageSize = this.getCurrentPageSize();
    const botId = this.getAssistantId();

    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload,
      relativePath: `/admin/assistants/${botId}/channels?full=1&page=${currentPage}&size=${currentPageSize}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };

    this.doCall(request);
  }

  public updateChannelSubscription = (channel: string, newStatus: string) => {
    const payload = null;
    const botId = this.getAssistantId();

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/assistants/channel/${channel}/assistant/${botId}/status/${newStatus}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'update-status',
    };
    this.doCall(request);
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState(
      {
        error: null,
      },
      () => {
        this.getChannels();
      },
    );
  }

  public hasError(): boolean {
    return this.state.error !== null;
  }

  public confirmCallback = () => {
    this.setToggleState(this.state.currentItem);
  }

  public onRouteChanged() {
    this.getChannels();
  }

  public setConfirmVisibility = (show: boolean) => {
    this.setState({
      showConfirmDialog: show,
    });
  }

  public changePageSize = (pageSize: number) => {
    this.props.changePageSize(pageSize);
  }

  public getApiKeys(): string[] {
    if (this.props.currentItem) {
      return this.props.currentItem.apiKeys;
    }
    return [];
  }

  public setResponseData() {
    const response = this.props.response;
    if (this.props.callId === 'update-status') {
      this.getChannels();
    } else {
      this.setState({
        data: response.content,
        totalPages: Math.ceil(response.totalElements / Number(response.size)),
      });

      if (response.page) {
        this.props.setBreadCrumbsInfo({
          currentPage: null,
          pageNumber: response.page.toString(),
        });
      }
    }
  }

  public formatMessage(id: string, values: Record<string, any> = {}) {
    return this.props.intl.formatMessage({ id }, values);
  }

  public getCurrentPage(): number {
    return this.props.page;
  }

  public setToggleState = (item: ChannelModel | null) => {
    if (item) {
      const statusToChange = item.status === Status.ENABLED ? Status.DISABLED : Status.ENABLED;
      this.setState({
        statusToChange,
      });
      this.updateChannelSubscription(item.uuid, this.state.statusToChange);
    }
  }

  public isAdmin(): boolean {
    return super.isAdmin(this.props.authorities);
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
  }
}

const ChannelWithIntl = injectIntl(ChannelBase);
const ChannelWithAlert = withAlert<ChannelBase['props']>()(ChannelWithIntl);
const ChannelWithSession = withContext(SessionContext)(ChannelWithAlert);
const ChannelWithContext = withContext(ChannelContext)(ChannelWithSession);
const ChannelWithApi = RefreshApi(Api(ChannelWithContext));
export const Channel = withPaginationRouting()(ChannelWithApi);
