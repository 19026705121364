import React, {FormEvent} from 'react';
import { withAlert } from 'react-alert';
import {injectIntl} from 'react-intl';
import { withRouter } from 'react-router';
import validator from 'validator';
import { Api, RefreshApi } from '../../../../../../api/Server';
import { SessionContext } from '../../../../../auth/state/Session/SessionContext';
import { LayoutContext } from '../../../../../base/state/Layout/LayoutContext';
import { withContext } from '../../../../../lib/component/withContext/withContext.hoc';
import { AnswerBody } from '../../../../model/AnswerModel';
import { AnswerType } from '../../../../model/AnswerType';
import { FormTableState } from '../../../../model/FormTableState';
import { AnswerContext } from '../../../../state/AnswerContext';
import { EditAnswerBase } from '../../EditAnswer.component';
import { LinksAnswerView } from './LinksAnswer.view';

interface AnswerLinksJson extends AnswerBody {
  links: AnswerLinksOption[];
  title?: string;
  url?: string;
}

interface AnswerLinksOption {
  title: string;
  url: string;
}

export class LinksAnswerBase extends EditAnswerBase<AnswerLinksJson> {

  public render = LinksAnswerView.bind(this);

  public answer: AnswerLinksJson = {
    links: [],
    title: '',
    url: '',
    type: AnswerType.LINKS,
  };

  private btnTitle: string = this.formatMessage('add');
  private editAnswerRow: number = -1;
  private formTableState: FormTableState = FormTableState.CREATEROW;

  public componentWillMount(): void {
    if (this.props.answer!.uuid) {

      const answer: AnswerLinksJson = {
        links: this.props.answer!.answerContent.links,
        title: '',
        url: '',
      };

      this.answer = answer;
    }
    this.setState({
      answer: this.answer,
    });

  }

  public changeLinkTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.title = value;

    this.setState({
      answer: this.answer,
      parametersKeyClass: (this.state.answer.title === '') ? this.formatMessage('isInvalid') : '',
    });
  }

  public changeLinkUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.url = value;

    this.setState({
      answer: this.answer,
      parametersValueClass: (!validator.isURL(this.state.answer!.url,
        { require_tld: false, protocols: ['http', 'https'], require_protocol: true }))
        ? this.formatMessage('isInvalid') : '',
    });
  }

  public getContent(): AnswerLinksJson {
    const json: AnswerLinksJson = {
      links: this.answer.links,
    };
    return json;
  }

  public getAnswerLinkTitle(): string {
    return this.state.answer.title;
  }

  public getAnswerLinkUrl(): string {
    return this.state.answer.url;
  }

  public getLinksList(): AnswerLinksOption[] {
    return this.state.answer.links;
  }

  public getButtoLinkText(): string {
    return this.btnTitle;
  }
  public isLinkInputValid() {
    if (!validator.isURL(this.state.answer!.url,
      { require_tld: false, protocols: ['http', 'https'], require_protocol: true })) {
      return false;
    }
    return ((this.state.answer!.url !== '') && (this.state.answer!.title !== ''));
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.answer.links.length === 0) {
      this.setState({
        parametersKeyClass: this.formatMessage('isInvalid'),
        parametersValueClass: this.formatMessage('isInvalid'),
      });
      return false;
    }
    this.handleSave();
  }

  public addLink = () => {
    const areInputFieldsNotValid = ((this.state.answer.title === '') || (!validator.isURL(this.state.answer!.url,
      { require_tld: false, protocols: ['http', 'https'], require_protocol: true })));
    if (areInputFieldsNotValid) {
      this.setState({
        parametersKeyClass: (this.state.answer.title === '') ? this.formatMessage('isInvalid') : '',
        parametersValueClass: (!validator.isURL(this.state.answer!.url,
          { require_tld: false, protocols: ['http', 'https'], require_protocol: true })) ? this.formatMessage('isInvalid') : '',
      });
      return false;
    }
    const choice: AnswerLinksOption = {
      title: this.state.answer.title,
      url: this.state.answer.url,
    };
    // EDIT
    this.btnTitle = this.formatMessage('add');
    if (this.formTableState === FormTableState.EDITROW) {
      this.answer.links[this.editAnswerRow!] = choice;
      this.formTableState = FormTableState.CREATEROW;
      this.editAnswerRow = -1;
    } else { // Add
      const newList: AnswerLinksOption[] = [...this.answer.links, choice];
      this.answer.links = newList;
    }

    this.answer.url = '';
    this.answer.title = '';

    this.setState({
      answer: this.answer,
    });
  }

  public handleDeleteLinkRow = (rowNumber: number) => () => {
    this.answer.links.splice(rowNumber, 1);
    this.setState({
      answer: this.answer,
    });
  }

  public handleEditLinkRow = (rowNumber: number) => () => {
    const row = this.answer.links[rowNumber];
    this.answer.url = row.url;
    this.answer.title = row.title;
    this.btnTitle = this.formatMessage('update');
    this.editAnswerRow = rowNumber;
    this.formTableState = FormTableState.EDITROW;

    this.setState({
      answer: this.answer,
      parametersKeyClass: (this.state.answer.title === '') ? this.formatMessage('isInvalid') : '',
      parametersValueClass: (!validator.isURL(this.state.answer!.url,
        { require_tld: false, protocols: ['http', 'https'], require_protocol: true }))
        ? this.formatMessage('isInvalid') : '',
    });
  }
}

const LinksAnswerWithIntl = injectIntl(LinksAnswerBase);
const LinksAnswerWithAlert = withAlert<LinksAnswerBase['props']>()(LinksAnswerWithIntl);
const LinkAnswerWithSession = withContext(SessionContext)(LinksAnswerWithAlert);
const LinkWithContext = withContext(AnswerContext)(LinkAnswerWithSession);
const LinkWithLayout = withContext(LayoutContext)(LinkWithContext);
const LinksAnswerWithApi = RefreshApi(Api(LinkWithLayout));
export const LinksAnswer = withRouter(LinksAnswerWithApi);
