import { Api, RefreshApi } from '../../../../../api/Server';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { AppRouteComponentProps } from '../../../../base/model/route/AppRouteComponentProps';
import { EditAssistantStateModel } from '../state/EditAssistantStateModel';
import { IntlShape, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { EditAssistantContext } from '../state/EditAssistantContext';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { PublishView } from './Publish.view';
import { Navigation } from '../../../../base/model/route/Navigation';
import { Request } from '../../../../../model/http/Request';
import { Response } from '../../../../../model/http/Response';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { withAlert } from 'react-alert';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';

interface ResponseUpdateTraining extends Response {
  content: TrainingModel;
  timestamp: Date;
}

interface TrainingModel {
  status: TrainingStatus;
  modified: boolean;
  message: string | null;
}

interface InfoState extends ApiState {
  isValid: boolean;
}

export enum TrainingStatus {
  ONLINE = 'ONLINE',
  DRAFT = 'DRAFT',
  TRAINING = 'TRAINING',
  ERROR = 'ERROR',
  CREATING = 'CREATING',
  PROCESSING = 'PROCESSING',
  WAITING = 'WAITING',
  INVALID = 'INVALID_TRAINING_CONTENT',
}

interface IntlProps {
  intl: IntlShape;
  getBotStatus: () => void;
  modified: boolean;
  trainingStatus: TrainingStatus;
  setTrainingStatusDraft: () => void;
}

type Props = IntlProps &
  ApiProps<ResponseUpdateTraining> &
  SessionStateModel &
  EditAssistantStateModel &
  RouteComponentProps<Navigation> &
  RouteComponentProps<{ botId: string; settingId: string }> &
  WithAlertProps &
  AppRouteComponentProps;

export class PublishBase extends ApiBase<ResponseUpdateTraining, Props, InfoState> {
  public render = PublishView.bind(this);

  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      authError: null,
      isValid: false,
    };
  }

  public publishBot = () => {
    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload: null,
      relativePath: `/admin/studio/assistant/${this.getAssistantId()}/train`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'publish-bot',
    };
    this.doCall(request);
  }

  public getBadgeStatus = () => {
    if (this.props.trainingStatus === TrainingStatus.INVALID) {
      this.props.setTrainingStatusDraft();
    }
    return this.props.trainingStatus;
  }

  public getBadgeColour = () => {
    switch (this.props.trainingStatus) {
      case TrainingStatus.DRAFT:
        return 'badge-secondary';
      case TrainingStatus.ONLINE:
        return 'badge-primary';
      case TrainingStatus.ERROR:
        return 'badge-danger';
      default:
        return 'badge-warning';
    }
  }

  public isPublishDisabled = () => {
    if (this.props.trainingStatus === TrainingStatus.ONLINE || !this.props.modified) {
      return true;
    }
  }

  public isError = () => {
    if (this.props.trainingStatus === TrainingStatus.ERROR) {
      return true;
    }
  }

  public hasError(): boolean {
    return this.state.error !== null;
  }

  public isATrainingStatus = (status: string) => {
    if (
      status === TrainingStatus.TRAINING ||
      status === TrainingStatus.PROCESSING ||
      status === TrainingStatus.WAITING ||
      status === TrainingStatus.CREATING
    ) {
      return true;
    } else {
      return false;
    }
  }

  public setResponseData() {
    if (this.props.callId === 'publish-bot') {
      if (this.props.response.content.message) {
        this.props.alert.error(this.formatMessage(this.props.response.content.message));
      }
      this.props.getBotStatus();
    } else {
      return;
    }
  }

  public getAssistantId() {
    return this.props.match.params.botId;
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
  }

  protected isAdmin(): boolean {
    return super.isAdmin(this.props.authorities);
  }
}

const PublishWithIntl = injectIntl(PublishBase);
const PublishWithAlert = withAlert<PublishBase['props']>()(PublishWithIntl);
const PublishWithSession = withContext(SessionContext)(PublishWithAlert);
const PublishWithContext = withContext(EditAssistantContext)(PublishWithSession);
const PublishWithApi = RefreshApi(Api(PublishWithContext));
export const Publish = withRouter(PublishWithApi);
