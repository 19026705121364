import React from 'react';
import { DropDownMenuItem } from './../Table.component';
import LineView from './Line.view';

interface Props {
  position: number;
  columns: any[];
  item: any;
  dropDownMenuItems?: (item: any) => DropDownMenuItem[];
}

export class Line extends React.Component<Props, never> {

  public render = LineView.bind(this);

  public constructor(props: Props) {
    super(props);
  }

  public getColumns() {
    return this.props.columns;
  }

  public getItem() {
    return this.props.item;
  }

  public getText = (optionItem: DropDownMenuItem) => {
    if (optionItem.text instanceof Function) {
        return optionItem.text(this.props.item);
    }
    return optionItem.text;
  }
  public onClickHandler = (optionItem: DropDownMenuItem) => (event: React.MouseEvent<any>) => {
    event.preventDefault();
    optionItem.callBack(this.props.item);
  }

}
