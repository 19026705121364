import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs.component';
import { PageHeaderBase } from './PageHeader.component';

export const PageHeaderView: ViewFunction<PageHeaderBase> = function() {
  const { headerButtons } = this.props;
  return (
    <div className='row'>
      <div className='col-sm-8'>
        {this.getHeaderTitle() && (
          <h6 id='page-title' className='page-title'>
            {this.getHeaderTitle()}
          </h6>
        )}
        {this.userIsSuper() ? (
          <Breadcrumbs breadCrumbsInfo={this.getBreadCrumbsInfo()} />
        ) : (
          <div className='mt-3' />
        )}
      </div>

      <div className='col-sm-4 text-right-mobile-center'>
        {headerButtons.map((item, idx) => {
          return (
            <button
              key={idx}
              id={item.id}
              className='btn btn-secondary mt-1'
              data-toggle='modal'
              disabled={!item.enabled}
              onClick={item.callback}
              title={item.hint}
            >
              {item.title}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default PageHeaderView;
