import React, {FormEvent} from 'react';
import {IntlShape} from 'react-intl';
import {RouteComponentProps} from 'react-router';
import {HttpRequestType} from '../../../../model/http/HttpRequestType';
import {Request} from '../../../../model/http/Request';
import {SessionStateModel} from '../../../auth/state/Session/SessionStateModel';
import {ApiBase, ApiProps, ApiState} from '../../../base/ApiComponent';
import {WithAlertProps} from '../../../base/model/WithAlertProps';
import {ValidationRule} from '../../../form/model/ValidationRule';
import {AnswerBody} from '../../model/AnswerModel';
import {AnswerType, answerTypeCode} from '../../model/AnswerType';
import {Languages, languagesCode} from '../../model/Languages';
import {AnswerStateModel} from '../../state/AnswerStateModel';
import {AnswerPayload} from './model/AnswerPayload';
import {AnswerResponse} from './model/AnswerResponse';

enum Actions {
  CREATE,
  UPDATE,
}

interface State extends ApiState {
  answerCode: string;
  answerType: string;
  answerLang: string;
  answer: any;
  editorState: any;
  isValid: boolean;
  parametersKeyClass: string;
  parametersValueClass: string;
  rules: ValidationRule[];
}

interface IntlProps {
  intl: IntlShape;
}

type Props =
  IntlProps &
  ApiProps<AnswerResponse<any>> &
  SessionStateModel &
  AnswerStateModel &
  WithAlertProps &
  RouteComponentProps<{answerId: string}>;

export abstract class EditAnswerBase<T extends AnswerBody> extends ApiBase<AnswerResponse<T>, Props, State> {
  public title: string = this.formatMessage('editAnswer');

  public state: State = {
    answerCode: '',
    answerType: '',
    answerLang: '',
    answer: null,
    error: null,
    editorState: null,
    authError: null,
    isValid: false,
    parametersKeyClass: '',
    parametersValueClass: '',
    rules: [],
  };

  public componentDidMount(): void {
    this.handleSave = this.handleSave.bind(this);
  }

  public changeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;
    if (this.props.answer) {
      const answer = this.props.answer;
      answer.code = value;
      this.props.setAnswer(answer);
    }
  }

  public changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value;

    if (this.props.answer) {
      const answer = this.props.answer;
      answer.language = value;
      this.props.setAnswer(answer);
    }
  }

  public setIsFormValid(isValid: boolean) {
    if (this.state.isValid !== isValid) {
      this.setState({
        isValid,
      });
    }
  }

  public getTitle() {
    return this.title;
  }

  public getAnswerId() {
    return this.props.match.params.answerId;
  }

  public getAnswerCode() {
    if (this.props.answer) {
      return this.props.answer.code;
    }
  }
  public getAnswerType() {
    if (this.props.answer) {
      return this.props.answer.type;
    }
  }

  public getAnswerLang() {
    if (this.props.answer) {
      return this.props.answer.language;
    }
  }

  public getAnswerTypes(): string[] {
    return Object.values(AnswerType).filter((item) => item !== null);
  }

  public getAnswerLangs(): string[] {
    return Object.values(Languages);
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.handleSave();
  }

  public handleSave = () => {
    let request: Request<AnswerPayload>;
    if (this.props.answer) {
      let payload: AnswerPayload = {
        type: answerTypeCode[this.props.answer!.type],
        language: languagesCode[this.props.answer!.language],
        code: this.props.answer!.code,
        uuid: null,
      };

      payload = { ...payload, ...this.getContent() };
      if (this.props.answer.uuid) {
        request = {
          id: Actions.UPDATE,
          method: HttpRequestType.PUT,
          payload,
          relativePath: `/admin/answers/${this.props.answer.uuid}`,
          token: this.props.token,
          refreshToken: this.props.refreshToken,
        };
      } else {
        request = {
          id: Actions.CREATE,
          method: HttpRequestType.POST,
          payload,
          relativePath: '/admin/answers',
          token: this.props.token,
          refreshToken: this.props.refreshToken,
        };
      }
      this.doCall(request);
    }
  }

  public setResponseData() {
    const message = this.props.callId === Actions.CREATE ?
      this.formatMessage('alert.successfullyCreated') : this.formatMessage('alert.successfullyUpdated');
    this.props.alert.success(message);
    this.props.history.push('/answers/list/0');
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({id});
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }

  protected abstract getContent(): T;

}
