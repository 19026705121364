import React from 'react';
import { Modal } from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { Form } from '../../../form/component/Form.component';
import { Input } from '../../../form/component/Input.component';
import { Select } from '../../../form/component/Select.component';
import { notEmpty } from '../../../form/lib/rules';
import { languagesCode } from '../../model/Languages';
import { CreateAnswerBase } from './CreateAnswer.component';

export const CreateAnswerView: ViewFunction<CreateAnswerBase> = function() {
  return (
    <Modal show={this.isModalVisible()} onHide={this.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title as='h5'><FormattedMessage id='createAnswer.createNewAnswer'/></Modal.Title>
      </Modal.Header>
      <Form onSubmit={this.onSubmit}>{(isValid: boolean) => {
        this.setIsFormValid(isValid);
        return (
          <React.Fragment>
            <Modal.Body>
              <div className='form-group'>
                <label htmlFor='answerCode'><FormattedMessage id='createAnswer.code'/></label>
                <span className='float-right text-danger'>*</span>
                <Input
                  type='text'
                  className='form-control'
                  id='answerCode'
                  placeholder={this.formatMessage('createAnswer.enterCode')}
                  value={this.state.answerCode}
                  onChange={this.changeCode}
                  rules={[notEmpty()]}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='answerLang'><FormattedMessage id='createAnswer.language'/></label>
                <span className='float-right text-danger'>*</span>
                <Select
                  className='form-control'
                  id='answerLang'
                  value={this.state.answerLang}
                  onChange={this.changeLanguage}
                  rules={[notEmpty()]}
                >
                  <option hidden>{`${this.formatMessage('choose')}...`}</option>
                  {this.getAnswerLangs().map((item, idx) => {
                    return (
                      <option data-property-value={languagesCode[item]} key={idx}>{item}</option>
                    );
                  })}
                </Select>
              </div>
              <div className='form-group'>
                <label htmlFor='answerType'><FormattedMessage id='createAnswer.type'/></label>
                <span className='float-right text-danger'>*</span>
                <Select
                  id='answerType'
                  className='form-control'
                  value={this.getAnswerType()}
                  onChange={this.changeType}
                  rules={[notEmpty()]}
                >
                  <option hidden>{`${this.formatMessage('choose')}...`}</option>
                  {this.getAnswerTypes().map((item, idx) => {
                    return (
                      <option data-property-value={item} key={idx}>{item}</option>
                    );
                  })}
                </Select>
              </div>
            </Modal.Body>
            <div className='modal-footer'>
              <button
                id='cancel'
                type='button'
                className='btn btn-light'
                data-dismiss='modal'
                onClick={this.handleClose}
              >
                {`${this.formatMessage('cancel')}`}
              </button>
              <button
                id='proceed'
                type='submit'
                className='btn btn-primary'
              >
                {`${this.formatMessage('proceed')}`}
              </button>
            </div>
          </React.Fragment>
        );
      }}
      </Form>

    </Modal>
  );
};
