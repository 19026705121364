import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { PageSize } from '../../../base/component/PageSize/PageSize.component';
import { Pagination } from '../../../base/component/Pagination/Pagination.component';
import { Search } from '../../../base/component/Search/Search.component';
import { ColumnObj, Table } from '../../../base/component/Table/Table.component';
import { OrganizationAssistantListBase } from './OrganizationAssistantList.component';
import { AssistantModel, Params } from '../../../assistant/component/AssistantList/AssistantModel';

export const OrganizationAssistantListView: ViewFunction<OrganizationAssistantListBase> = function() {
  const columns: ColumnObj[] = [
    {
      title: `${this.formatMessage('name')}`,
      propertyName: 'name',
      render: (item: AssistantModel) => {
        return <span>{item.name}</span>;
      }
    },
    {
      title: `${this.formatMessage('type')}`,
      propertyName: 'type',
      className: 'd-none d-lg-table-cell',
      render: (item: AssistantModel) => {
        let paramType = '';
        item.params.forEach((param: Params) => {
          if (param.name === 'type') {
            paramType = param.value;
          }
        });

        return <span>{paramType}</span>;
      },
    },
    {
      title: `${this.formatMessage('topStats.totalConversations')}`,
      propertyName: 'activeConversations',
      className: 'text-right',
      render: (item: AssistantModel) => {
        if (item.stats) {
          return <span>{item.stats.totalChats}</span>;
        }
        return <span>0</span>;
      },
    },
    {
        title: `${this.formatMessage('topStats.avgConversations.month')}`,
        propertyName: 'activeConversations',
        className: 'text-right',
        render: (item: AssistantModel) => {
          if (item.stats) {
            return <span>{item.stats.avgChatsPerMonth}</span>;
          }
          return <span>0</span>;
        },
      },
  ];

  return (
    <React.Fragment>
      <div className='card single-card shadow-sm'>
        <div className='card-body'>
          <div className='row'>
            <Search searchHandler={this.searchHandler} search={this.getCurrentCriteria()} />
          </div>
          <Table
            tableId={'assistants'}
            data={this.state.data}
            columns={columns}
            page={this.getCurrentPage()}
          />
          {/*<!-- end table responsive  -->*/}
          <div className='row mt-3'>
            <div className='col-sm-6 mb-1 display-view'>
              <PageSize changePageSize={this.changePageSize} size={this.getCurrentPageSize()} />
            </div>
            <div className='col-sm-6'>
              <Pagination
                totalPages={this.state.totalPages}
                changePaginationHandler={this.changePaginationHandler}
                page={this.getCurrentPage()}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};