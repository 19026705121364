import React from 'react';
import 'react-phone-input-2/lib/style.css';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { OrganisationBase } from './Organisation.component';
import { FormattedMessage } from 'react-intl';
import { CountrySelect } from './../../../organization/component/CountriesSelect/CountrySelect.component';
import { Input } from '../../../form/component/Input.component';
import { Form } from '../../../form/component/Form.component';
import { notEmpty } from '../../../form/lib/rules';
import PhoneInput from 'react-phone-input-2';

export const OrganisationView: ViewFunction<OrganisationBase> = function() {
  return (
    <React.Fragment>
      <div className='card shadow-sm'>
        <div className='card-body'>
          <Form onSubmit={this.onSubmit}>
            {(isValid: boolean) => {
              this.setIsFormValid(isValid);
              return (
                <React.Fragment>
                  <div className='row'>
                    <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                      <h6>
                        <FormattedMessage id='organization.companyinformation' />
                      </h6>
                      <div className='form-group'>
                        <label htmlFor='companyname'>
                          <FormattedMessage id='organization.companyName' />
                        </label>
                        <Input
                          type='text'
                          className={'form-control'}
                          id='companyname'
                          name='companyName'
                          value={this.state.companyName}
                          onChange={this.handleFormChange}
                          rules={[notEmpty()]}
                        />
                      </div>
                      <div className='form-group'>
                        <label htmlFor='streetaddress1'>
                          <FormattedMessage id='organization.streetAddress1' />
                        </label>
                        <Input
                          type='text'
                          className={'form-control'}
                          id='streetaddress1'
                          name='streetAddress1'
                          value={this.state.streetAddress1}
                          onChange={this.handleFormChange}
                          rules={[notEmpty()]}
                        />
                      </div>
                      <div className='form-group'>
                        <label htmlFor='streetaddress2'>
                          <FormattedMessage id='organization.streetAddress2' />
                        </label>
                        <Input
                          type='text'
                          className={'form-control'}
                          id='streetaddress2'
                          name='streetAddress2'
                          value={this.state.streetAddress2}
                          onChange={this.handleFormChange}
                        />
                      </div>
                      <div className='row'>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                          <div className='form-group'>
                            <label htmlFor='country'>
                              <FormattedMessage id='organization.country' />
                            </label>
                            <CountrySelect
                              handleCountryChange={this.handleCountryChange}
                              nicename={this.state.countryNiceName}
                              numcode={this.state.country}
                              countrySubmitError={this.state.countrySubmitError}
                              isUserView
                            />
                          </div>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                          <div className='form-group'>
                            <label htmlFor='zipcode'>
                              <FormattedMessage id='organization.postcode' />
                            </label>
                            <Input
                              type='text'
                              className={'form-control'}
                              id='zipcode'
                              name='zipcode'
                              value={this.state.zipcode}
                              onChange={this.handleFormChange}
                              rules={[notEmpty()]}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='vat'>
                          <FormattedMessage id='organization.vatId' />
                        </label>
                        <Input
                          type='text'
                          className={'form-control'}
                          id='vat'
                          name='vat'
                          value={this.state.vat}
                          onChange={this.handleFormChange}
                        />
                      </div>
                    </div>
                    <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                      <h6>
                        <FormattedMessage id='organization.primaryUser' />
                      </h6>
                      <div className='form-group'>
                          <label htmlFor='name'>
                            <FormattedMessage id='organization.firstName' />
                          </label>
                          <Input
                            type='text'
                            className={'form-control'}
                            id='firstName'
                            name='firstName'
                            value={this.state.firstName}
                            onChange={this.handleFormChange}
                            rules={[notEmpty()]}
                          />
                        </div>
                        <div className='form-group'>
                          <label htmlFor='name'>
                            <FormattedMessage id='organization.lastName' />
                          </label>
                          <Input
                            type='text'
                            className={'form-control'}
                            id='lastName'
                            name='lastName'
                            value={this.state.lastName}
                            onChange={this.handleFormChange}
                            rules={[notEmpty()]}
                          />
                        </div>
                      <div className='form-group'>
                        <label htmlFor='email'>
                          <FormattedMessage id='organization.email' />
                        </label>
                        <Input
                          type='text'
                          className={'form-control'}
                          id='email'
                          name='email'
                          value={this.state.email}
                          onChange={this.handleFormChange}
                          rules={[notEmpty()]}
                        />
                      </div>
                      <div className='form-group'>
                        <label htmlFor='phone'>
                          <FormattedMessage id='organization.phone' />
                        </label>
                        <PhoneInput country={'us'} value={this.state.phone} onChange={this.handlePhoneChange} />
                      </div>
                    </div>
                  </div>
                  <div className='text-right'>
                    <button id='save' type='submit' className='btn btn-primary'  onClick={this.checkCountryError}>
                      <FormattedMessage id='save' />
                    </button>
                  </div>
                </React.Fragment>
              );
            }}
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};
