import React, { Component } from 'react';
import { SessionStoreProvider } from '../../../auth/state/Session/SessionStoreProvider';
import AppRoutes from '../../AppRoutes';
import { LayoutStoreProvider } from '../../state/Layout/LayoutStoreProvider';
import { AlertProvider } from '../AlertProvider/AlertProvider.component';

class AppComponent extends Component {
  public render() {
    return (
      <SessionStoreProvider>
        <LayoutStoreProvider>
          <AlertProvider>
            <AppRoutes />
          </AlertProvider>
        </LayoutStoreProvider>
      </SessionStoreProvider>
    );
  }
}

export default AppComponent;
