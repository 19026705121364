import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Routes from '../../../../base/component/Routes/Routes';
import { AssistantInfoBase } from './InfoAssistant.component';
import { FormattedMessage } from 'react-intl';
import { Input } from '../../../../form/component/Input.component';
import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { notEmpty } from '../../../../form/lib/rules';
import { Type } from '../../EditAssistant/state/EditAssistantStateModel';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';

const ONE_DAY_IN_MINUTES = 1440;
const FOUR_YEARS_IN_DAYS = 1480;

export const InfoAssistantView: ViewFunction<AssistantInfoBase> = function() {
  function buildIdleTooltip() {
    return (
      <Tooltip id={`tooltip-top-maximum-idle-conversation-time`}>
        <FormattedMessage id='createAssistant.tooltip.maximumIdleConversationTime' />
      </Tooltip>
    );
  }
  function buildDataTooltip() {
    return (
      <Tooltip id={`assistant.info.generalsettings.maximumdatahold`}>
        <FormattedMessage id='createAssistant.tooltip.maximumData' />
      </Tooltip>
    );
  }
  function buildNameTooltip() {
    return (
      <Tooltip id={`tooltip-display-name`}>
        <FormattedMessage id='createAssistant.tooltip.displayname' />
      </Tooltip>
    );
  }

  return (
    <>
      <div className='card card-body shadow-sm'>
        <form onSubmit={this.onSubmit}>
          <React.Fragment>
            {this.hasError() && <div className='alert alert-danger'>Error</div>}
            <div className='form-group'>
              <label htmlFor='type'>
                <FormattedMessage id='type' />
              </label>
              <Input type='text' className='form-control-plaintext' id='type' value={this.state.type} readOnly />
            </div>
            <div className='form-group'>
              <label htmlFor='name'>
                <FormattedMessage id='name' />
              </label>
              <Input
                type='text'
                className='form-control'
                id='name'
                placeholder={this.formatMessage('createAssistant.enterName')}
                value={this.getName()}
                onChange={this.changeNameHandler}
                rules={[notEmpty()]}
                disabled={!this.isAdmin()}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='displayName'>
                <FormattedMessage id='displayName' />
                &nbsp;
                <OverlayTrigger key={'top'} placement={'top'} overlay={buildNameTooltip()}>
                  <i className='far fa-question-circle' data-toggle='tooltip' data-placement='top' />
                </OverlayTrigger>
              </label>
              <Input
                type='text'
                className='form-control'
                id='displayName'
                placeholder={this.formatMessage('createAssistant.enterName')}
                value={this.getDisplayName()}
                onChange={this.changeDisplayNameHandler}
                rules={[notEmpty()]}
                disabled={!this.isAdmin()}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='description'>
                <FormattedMessage id='description' />
              </label>
              <textarea
                id='description'
                className='form-control'
                placeholder={this.formatMessage('createAssistant.enterDescription')}
                value={this.getDescription()}
                style={{ marginTop: '0px', marginBottom: '0px', height: '57px' }}
                onChange={this.changeDescriptionHandler}
                disabled={!this.isAdmin()}
              />
            </div>
            {this.state.type === Type.STUDIO && (
              <div className='form-group'>
                <label>
                  <FormattedMessage id='createAssistant.language' />
                </label>
                <Input
                  type='text'
                  className='form-control-plaintext'
                  id='language'
                  value={this.state.language}
                  readOnly
                />
              </div>
            )}
            <div className='form-group'>
              <label htmlFor='customRange2' className='form-label'>
                <FormattedMessage id='assistant.info.generalsettings.maximumidletime' />
                &nbsp;
                <OverlayTrigger key={'top'} placement={'top'} overlay={buildIdleTooltip()}>
                  <i className='far fa-question-circle' data-toggle='tooltip' data-placement='top' />
                </OverlayTrigger>
              </label>
              <div>
                <RangeSlider
                  value={this.getMaxIdleTime()}
                  max={ONE_DAY_IN_MINUTES}
                  min={5}
                  step={1}
                  id='maximum-idle-conversation-time'
                  disabled={!this.isAdmin()}
                  onChange={this.changeMaxIdleConversationTime}
                  variant='secondary'
                  tooltip='off'
                />
                <div className='text-center'>
                  {this.getMaxIdleTime() + ' ' + this.formatMessage('assistant.info.minutes')}
                </div>
              </div>
            </div>
            <div className='form-group'>
              <label htmlFor='customRange2' className='form-label'>
                <FormattedMessage id='assistant.info.generalsettings.maximumdatahold' />
                &nbsp;
                <OverlayTrigger key={'top'} placement={'top'} overlay={buildDataTooltip()}>
                  <i className='far fa-question-circle' data-toggle='tooltip' data-placement='top' />
                </OverlayTrigger>
              </label>
              <div>
                <RangeSlider
                  value={this.getMaxData()}
                  max={FOUR_YEARS_IN_DAYS}
                  min={7}
                  step={1}
                  id='maximum-idle-conversation-time-data'
                  disabled={!this.isAdmin()}
                  onChange={this.changeMaxData}
                  variant='secondary'
                  tooltip='off'
                />
                <div className='text-center'>
                  {this.getMaxData() + ' ' + this.formatMessage('assistant.info.days')}
                </div>
              </div>
            </div>
            {this.isAdmin() && (
              <div className='text-right'>
                <button id='save' type='submit' className='btn btn-secondary'>
                  <FormattedMessage id='save' />
                </button>
              </div>
            )}
          </React.Fragment>
        </form>
      </div>
      {this.state.type !== Type.STUDIO && (
        <div className='row single-card'>
          <div className='col-3'>
            <ul className='nav nav-pills flex-column nav-tabs mb-1' role='tablist'>
              <li className='nav-item'>
                <Link
                  className={`nav-link shadow-sm mr-1 mb-1 ${this.getFormActiveClass(
                    this.getFormOptionType().TEXTTOSPEECH,
                  )}`}
                  id='bot-text-to-speech-link'
                  to={`texttospeech`}
                >
                  {' '}
                  {this.formatMessage('assistant.info.tts')}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={`nav-link shadow-sm mr-1 mb-1 ${this.getFormActiveClass(
                    this.getFormOptionType().SPEECHTOTEXT,
                  )}`}
                  id='bot-speech-to-text-link'
                  to={`speechtotext`}
                >
                  {' '}
                  {this.formatMessage('assistant.info.stt')}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={`nav-link shadow-sm mr-1 mb-1 ${this.getFormActiveClass(
                    this.getFormOptionType().SENTIMENT,
                  )}`}
                  id='bot-sentiment-link'
                  to={`sentiment`}
                >
                  {' '}
                  {this.formatMessage('assistant.info.sentiment')}
                </Link>
              </li>
            </ul>
          </div>
          <div className='col-9'>
            <Routes routes={this.props.routes} innerProps={{ reloadAssistant: this.reloadAssistant }} />
          </div>
        </div>
      )}
    </>
  );
};
