import { Layout } from '../base/component/Layout/Layout.component';
import { Roles } from '../base/model/route/Role';
import { Route } from '../base/model/route/Route';
import { OrganizationList } from './component/OrganizationList/OrganizationList.component';
import { OrganizationEditAdmin } from './component/OrganizationEditAdmin/OrganizationEditAdmin.component';
import { OrganizationUsersList } from './component/OrganizationEditAdmin/OrganizationUserList.component';
import { EditOrganization } from './component/OrganizationEditAdmin/EditOrganization.component';
import { OrganizationAssistantList } from './component/OrganizationEditAdmin/OrganizationAssistantList.component'

const routes: Route[] = [
  {
    path: '/organizations',
    default: '/organizations',
    routes: [
      {
        path: '/organizations/list',
        component: OrganizationList,
        authorize: [Roles.super],
        redirectNonAuthorized: '/unauthorized',
        layout: Layout,
      },
      {
        path: '/organizations/:organization',
        component: EditOrganization,
        authorize: [Roles.super],
        redirectNonAuthorized: '/unauthorized',
        layout: Layout,
        default: '/organizations/:organization/info',
        routes: [
          {
            path: '/organizations/:organization/info',
            component: OrganizationEditAdmin,
          },
          {
            path: '/organizations/:organization/users',
            component: OrganizationUsersList,
          },
          {
            path: '/organizations/:organization/bot-list',
            component: OrganizationAssistantList
          },
        ],
      },
    ],
  },
];

export default routes;
