import React from 'react';
import { Link } from 'react-router-dom';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import Confirm from '../../../base/component/confirm/Confirm.component';
import { PageSize } from '../../../base/component/PageSize/PageSize.component';
import { Pagination } from '../../../base/component/Pagination/Pagination.component';
import { ColumnObj, Table } from '../../../base/component/Table/Table.component';
import { GroupModel } from '../../model/GroupModel';
import { CreateGroup } from '../create/CreateGroup.component';
import { GroupListBase } from './GroupList.component';

export const GroupListView: ViewFunction<GroupListBase> = function() {

  const columns: ColumnObj[] = [
    {
      title: `${this.formatMessage('name')}`,
      propertyName: 'name',
      render: (item: GroupModel) => (
        <Link data-action='edit-answer' to={`/groups/edit/${item.uuid}`}>
          {item.name}
        </Link>
      ),
    },
    {
      title: `${this.formatMessage('description')}`,
      propertyName: 'description',
      render: (item: GroupModel) => (<span>{item.description}</span>),
    },
    {
      title: `${this.formatMessage('groupList.status')}`,
      propertyName: 'status',
      style: { width: '80px' },
      render: (item: GroupModel) => (
        <div className='toggle-status statustable' onClick={this.confirm(item)}>
          <i
            id={`state-${item.uuid}`}
            data-property-name='statusBt'
            data-property-value={this.dataPropertyValue(item)}
            className={this.getLineToggleClass(item)}
            title={this.getLineToggleTitle(item)}
          />
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Confirm
        show={this.state.showConfirmDialog}
        okLabel={this.formatMessage('ok')}
        cancelLabel={this.formatMessage('cancel')}
        confirmation={this.formatMessage('createGroup.confirm.changeStatus',
          { statusToChange: this.state.statusToChange})}
        proceedFunc={this.confirmCallback}
        title={this.formatMessage('confirm')}
        setConfirmVisibility={this.setConfirmVisibility}
      />
      <CreateGroup {...this.props}/>
      <div className='card shadow-sm'>
        <div className='card-body'>
          <div className='row'/>
          <Table
            tableId={'groups'}
            data={this.state.data}
            columns={columns}
            page={this.getCurrentPage()}
            dropDownMenuItems={this.dropDownMenuItems}
          />
          {/*<!-- end table responsive  -->*/}
          <div className='row mt-3'>
            <div className='col-sm-6 mb-1 display-view'>
              <PageSize changePageSize={this.changePageSize} size={this.getCurrentPageSize()} />
            </div>
            <div className='col-sm-6'>
              <Pagination
                totalPages={this.state.totalPages}
                changePaginationHandler={this.changePaginationHandler}
                page={this.getCurrentPage()}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
