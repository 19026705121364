import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { LayoutContext } from '../../../base/state/Layout/LayoutContext';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { ImportBotView } from './ImportBot.view';
import { ChangeEvent } from 'react';

interface State {
  file: File | null;
  fileError: boolean;
}

interface IntlProps {
  intl: IntlShape;
}

interface OtherProps {
  onClose: () => void;
  modalVisibility: boolean;
  onImport: (file: File | null) => void;
}

type Props = OtherProps & IntlProps & LayoutStateModel & RouteComponentProps;

export class ImportBotBase extends React.Component<Props, State> {
  public state: State = {
    file: null,
    fileError: false,
  };

  public render = ImportBotView.bind(this);

  public isModalVisible = () => {
    return this.props.modalVisibility;
  }

  public handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      this.setState(
        {
          file,
          fileError: false,
        },
        () => {
          if (file.type !== 'application/json') {
            this.setState({
              fileError: true,
              file: null,
            });
          }
        },
      );
    }
  }

  public handleClose = () => {
    this.props.onClose();
  }

  public onClickImport = () => {
    this.props.onImport(this.state.file);
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }
}

const ImportBotWithIntl = injectIntl(ImportBotBase);
export const ImportBotWithRouter = withRouter(ImportBotWithIntl);
export const ImportBotLayoutContext = withContext(LayoutContext)(ImportBotWithRouter);
export const ImportBot = ImportBotLayoutContext;
