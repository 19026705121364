import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/img/logo-vivid-red_png@1x.png';
import { ViewFunction } from '../../../model/component/ViewFunction';
import LoadingBar from './../../base/component/loadingBar/loadingBar';
import { VerifyLinkBase } from './VerifyLink.component';

export const VerifyLinkView: ViewFunction<VerifyLinkBase> = function() {
    return (
      <React.Fragment>
      <LoadingBar
        height={4}
        color={'#ff3366'}
        onRef={this.setRef}
      />
      <div className='container container-public'>
      <div className='text-center'>
        <Link to='/auth'><img alt={''} className='logo-login' src={logo}/></Link>
      </div>

      <div className='card shadow-sm'>
        <div className='card-body'>
          <h3>{this.state.header}</h3>
          <p>{this.state.message} {(this.state.error) ? this.state.error : ''}</p>
        </div>
      </div>
    </div>
    <div className='text-center'>
      <small>
        {this.formatMessage('registration.already.have')}<Link to='/auth/login'>{this.formatMessage('login')}</Link>
      </small>
    </div>
    </React.Fragment>
   );
  };

export default VerifyLinkView;
