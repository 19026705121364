import React from 'react';
import { Input } from '../../../form/component/Input.component';
import { notEmpty, isEmail} from '../../../form/lib/rules';
import { useIntl } from 'react-intl';

export const PasswordFormSendEmail: any = (props: any) => {
  const intl = useIntl();
  return (
    <React.Fragment>
      <div className='form-group'>
        <Input
          id='username'
          type='text'
          className='form-control'
          placeholder={intl.formatMessage({ id: 'login.enterEmail' })}
          value={props.state.email}
          onChange={props.changeEmail}
          rules={[notEmpty(), isEmail()]}
        />
      </div>
      <div className='row'>
        <div className='col-12 text-center'>
          <button type='submit' id='passwordResetSubmit' className='btn btn-primary' disabled={!props.isValid}>
            {intl.formatMessage({ id: 'password.reset' })}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PasswordFormSendEmail;
