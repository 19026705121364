import {FormEvent} from 'react';
import { MouseEvent } from 'react';
import {withAlert} from 'react-alert';
import {injectIntl, IntlShape} from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import {Api, RefreshApi} from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { Response } from '../../../../model/http/Response';
import {SessionContext} from '../../../auth/state/Session/SessionContext';
import {SessionStateModel} from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { Navigation } from '../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import {LayoutStateModel} from '../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import {TwoFactorContext} from './twofactor.state/TwoFactorContext';
import {TwoFactorStateModel, UserModel} from './twofactor.state/TwoFactorStateModel';
import {TwofactorView} from './Twofactor.view';
interface ResponseTwoFactor extends Response {
    content: UserModel;
    timestamp: Date;
    size: string;
}

interface TwoFactorState extends ApiState {
    data: UserModel | null;
    showConfirmDialog: boolean;
    currentItem: UserModel | null;
    twoFactorAuth: boolean;
    status: string;
}

interface IntlProps {
    intl: IntlShape;
}

type Props = IntlProps &
    ApiProps<ResponseTwoFactor> &
    SessionStateModel &
    TwoFactorStateModel &
    LayoutStateModel &
  RouteComponentProps<{ userId: string }>&
    RouteComponentProps<Navigation> &
    WithAlertProps;

export class TwoFactorBase extends ApiBase<ResponseTwoFactor, Props, TwoFactorState> {
    public render = TwofactorView.bind(this);

    constructor(props: Props) {
        super(props);
        this.setToggleState = this.setToggleState.bind(this);

        this.state = {
            data: null,
            error: null,
            authError: null,
            showConfirmDialog: false,
            currentItem: null,
            twoFactorAuth: false,
            status: '',
        };
    }

    public confirm = (item: UserModel | null) => (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();

        if (item) {
            this.setConfirmVisibility(true);
        }
    }

    public getToggleTitle(item: UserModel | null): string {
        if (item &&  item.twoFactorAuth ) {
            return 'Enabled';
        }
        return 'Disabled';
    }

    public updateUser2FA = ( newStatus: boolean, userUuid: string, mobileNumber: string) => {
        const payload = null;

        const request: Request<{}> = {
            method: HttpRequestType.PUT,
            payload,
            relativePath: `/admin/users/update/${userUuid}/2fa/status/${newStatus}/mobile/${mobileNumber}`,
            token: this.props.token,
            refreshToken: this.props.refreshToken,
            id: 'update-status',
        };
        this.doCall(request);
    }

    public getTwoFactor() {
        const payload = null;
        const userId = this.props.match.params.userId;
        const request: Request<{}> = {
            method: HttpRequestType.GET,
            payload,
            relativePath: `/admin/users/2fa/uuid/${userId}`,
            token: this.props.token,
            refreshToken: this.props.refreshToken,
        };
        this.doCall(request);
    }

    public onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.setState(
            {
                error: null,
            },
            () => {
                this.getTwoFactor();
            },
        );
    }

    public hasError(): boolean {
        return this.state.error !== null;
    }

    public confirmCallback = () => {
        this.setToggleState(this.props.userData);
    }

    public setConfirmVisibility = (show: boolean) => {
        this.setState({
            showConfirmDialog: show,
        });
    }

    public setResponseData() {
        const response = this.props.response;

        this.setState({
                data: response.content,
                twoFactorAuth: response.content.twoFactorAuth,
                currentItem: response.content,
            });
    }

  public formatMessage(id: string, values: Record<string, any> = {}) {
    return this.props.intl.formatMessage({ id }, values);
  }

    public setToggleState = (item: UserModel | null) => {
        if (item) {
           this.updateUser2FA( !this.state.twoFactorAuth, item.uuid, item.mobileNumber);
        }
    }
    public getToggle(item: UserModel | null): string {
        if (item && item.twoFactorAuth) {
            return 'fas fa-toggle-on fa-2x on text-success';
        }
        return 'fas fa-toggle-off fa-2x off text-secondary';
    }

    public dataPropertyValue = (item: UserModel | null) => {
        if (item && item.twoFactorAuth) {
            return true;
        }
        return false;
    }

    public componentDidMount(): void {
        this.getTwoFactor();
    }

  protected setErrorResponseData(): void {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
    this.setState({
        twoFactorAuth: this.state.twoFactorAuth,
        currentItem: this.state.currentItem,
    });

  }

}

const TwoFactorAuthWithIntl = injectIntl(TwoFactorBase);
const TwoFactorAuthWithAlert = withAlert<TwoFactorBase['props']>()(TwoFactorAuthWithIntl);
const TwoFactorAuthWithSession = withContext(SessionContext)(TwoFactorAuthWithAlert);
const TwoFactorAuthWithContext = withContext(TwoFactorContext)(TwoFactorAuthWithSession);
const TwoFactorAuthWithApi = RefreshApi(Api(TwoFactorAuthWithContext));
export const TwoFactorAuth = withRouter(TwoFactorAuthWithApi);
