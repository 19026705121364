import { PublishBase } from './Publish.component';
import { FormattedMessage } from 'react-intl';

import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';

export const PublishView: ViewFunction<PublishBase> = function() {
  return (
    <>
      <div className='card card-body shadow-sm text-center'>
        <h5>
          <span
            className={`badge ${this.getBadgeColour()}`}
            style={{color: '#fff'}}
            id={'botStatus'}
          >
            {this.getBadgeStatus()}
          </span>
        </h5>
        {!this.isATrainingStatus(this.props.trainingStatus) ? (
          <>
            {/* tslint:disable-next-line:max-line-length */}
            <button
              className='publish btn btn-primary mb-2'
              type='button'
              id={'publishBot'}
              onClick={this.publishBot}
              disabled={!this.props.modified}
            >
              <FormattedMessage id='assistant.studio.publishbot' />
            </button>
            {this.props.modified && (
              <small>
                <FormattedMessage id='assistant.studio.changestopublish' />
              </small>
            )}
            {this.isError() && (
              <small className='links'>
                <FormattedMessage id='assistant.studio.publishbotError' />
              </small>
            )}
          </>
        ) : (
          <>
            <div className='d-flex flex-column align-items-center justify-content-center'>
              <div className='lds-ellipsis'>
                <div/>
                <div/>
                <div/>
                <div/>
              </div>
              <small className='links'>training...</small>
            </div>
          </>
        )}
      </div>
    </>
  );
};
