import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import {FormattedMessage} from 'react-intl';
import { ViewFunction } from '../../../../../../model/component/ViewFunction';
import formLayout from '../formLayout';
import { MultiChoiceAnswerBase } from './MultiChoiceAnswer.component';

export const MultiChoiceAnswerView: ViewFunction<MultiChoiceAnswerBase> = function() {
  const childComponent = () => {
    return (
      <React.Fragment>
        <div className='form-group'>
          <h6 className='mb-0'><FormattedMessage id='multiChoiceAnswerBase.options'/></h6>
        </div>
        <div className='form-group'>
          <label htmlFor='optionLabel'><FormattedMessage id='label'/></label>
          <input
            type='text'
            className={'form-control ' + this.state.parametersKeyClass}
            id='optionLabel'
            aria-describedby='optionLabel'
            placeholder={`${this.formatMessage('multiChoiceAnswerBase.enterTheLabelOfYourOptionHere')}...`}
            value={this.getAnswerLabel()}
            onChange={this.changeLabel}
          />
          {this.state.parametersKeyClass !== '' && <div className='invalid-feedback'>
            {`${this.formatMessage('form.itCannotBeEmpty')}`}</div>}
        </div>
        <div className='form-group'>
          <label htmlFor='optionValue'><FormattedMessage id='value'/></label>
          <input
            type='text'
            className={'form-control ' + this.state.parametersValueClass}
            id='optionValue'
            aria-describedby='optionValue'
            placeholder={`${this.formatMessage('multiChoiceAnswerBase.enterTheValueOfYourOptionHere')}...`}
            value={this.getAnswerValue()}
            onChange={this.changeValue}
          />
          {this.state.parametersValueClass !== '' && <div className='invalid-feedback'>{`${this.formatMessage('form.itCannotBeEmpty')}`}</div>}
        </div>
        <div className='form-group'>
          <div className='text-right'>
            <button id='addOption' type='button' className='btn btn-primary' onClick={this.addOption}>
              {`${this.getButtonMultiText()} Option`}
            </button>
          </div>
        </div>
        <div className='height-control'>
          <div className='table-responsive'>
            <table className='table mb-4' id='options-list'>
              <thead>
                <tr>
                  <th scope='col'><FormattedMessage id='label'/></th>
                  <th scope='col'><FormattedMessage id='value'/></th>
                  <th style={{ width: '40px' }} scope='col' />
                </tr>
              </thead>
              <tbody>
                {this.getMultiChoiceList().map((item, i) => {
                  return (
                    <tr key={i} data-type='item'>
                      <td data-property-value={item.label} data-property-name='label'>
                        {item.label}
                      </td>
                      <td data-property-value={item.value} data-property-name='value'>
                        {item.value}
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            data-action='view-options'
                            bsPrefix={'btn-icon'}
                            id={`dropdownMenuButton-${i}`}
                          >
                            <i className='fas fa-ellipsis-v' />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              bsPrefix={'dropdown-item'}
                              data-action='Edit'
                              onClick={this.handleEditMultiRow(i)}
                            >
                              {`${this.formatMessage('edit')}`}
                            </Dropdown.Item>
                            <Dropdown.Item
                              bsPrefix={'dropdown-item'}
                              data-action='Delete'
                              onClick={this.handleDeleteMultiRow(i)}
                            >
                              {`${this.formatMessage('delete')}`}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className='text-right'>
          <button id='saveBtn' type='submit' className='btn btn-primary'>
            {`${this.formatMessage('save')}`}
          </button>
        </div>
      </React.Fragment>
    );
  };

  return formLayout(this, childComponent);
};
