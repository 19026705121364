import { withAlert } from 'react-alert';
import { ChangeEvent } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { Response } from '../../../../model/http/Response';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { AppRouteComponentProps } from '../../../base/model/route/AppRouteComponentProps';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { Status, UserModel } from '../../model/AccountModel';
import { UserProfileView } from './UserProfile.view';

interface ResponseUpdate extends Response {
  content: UserModel;
  timestamp: Date;
}

interface Payload {
  firstname: string;
  lastname: string;
  email: string;
  mobileNumber: string;
  timezone: string;
}

interface IntlProps {
  intl: IntlShape;
}

interface State extends ApiState {
  data: UserModel | null;
  name: string;
  email: string;
  mobileNumber: string;
  firstName: string;
  lastName: string;
  timezone: string;
  isValid: boolean;
  statusToChange: string;
  selectedRole: string;
  showConfirmDialog: boolean;
  dialCode: string;
}
type Props = IntlProps &
  ApiProps<ResponseUpdate> &
  LayoutStateModel &
  RouteComponentProps<{ userId: string }> &
  SessionStateModel &
  WithAlertProps &
  AppRouteComponentProps;

export class UserProfileBase extends ApiBase<ResponseUpdate, Props, State> {
  public render = UserProfileView.bind(this);

  public state: State = {
    error: null,
    authError: null,
    data: null,
    name: '',
    email: '',
    mobileNumber: '',
    firstName: '',
    lastName: '',
    timezone: '',
    isValid: false,
    statusToChange: Status.DISABLED,
    selectedRole: 'user',
    showConfirmDialog: false,
    dialCode: '',
  };

  public componentDidMount = async () => {
    this.props.setPageTitle(this.formatMessage('account.profile.profile'));
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/users/profile/${this.props.currentUsername}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };
    await this.doCall(request);
  }

  public setResponseData() {
    const { content } = this.props.response;
    this.setState({
      data: content,
      statusToChange: content.status,
      selectedRole: content.roles[0],
      mobileNumber: content.mobileNumber,
      firstName: content.firstName,
      lastName: content.lastName,
      email: content.email,
      timezone: content.timezone,
    });
    this.props.setBreadCrumbsInfo({
      currentPage: `${content.firstName} ${content.lastName}`,
      currentId: content.uuid,
      pageNumber: null,
    });

    if (this.props.callId === 'update-user') {
      this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
    }
  }

  public componentWillUnmount(): void {
    this.props.setBreadCrumbsInfo({
      currentPage: null,
    });
  }

  public getName = (): string => {
    if (this.state.data) {
      return this.state.data.username;
    }
    return '';
  }

  public getFirstName = (): string => {
    if (this.state.data) {
      return this.state.firstName;
    }
    return '';
  }

  public getLastName = (): string => {
    if (this.state.data) {
      return this.state.lastName;
    }
    return '';
  }

  public getEmail = (): string => {
    if (this.state.data) {
      return this.state.data!.email;
    }
    return '';
  }

  public getMobile = (): string => {
    if (this.state.data) {
      return this.state.data!.mobileNumber;
    }
    return '';
  }

  public handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name: string = event.target.value;
    this.setState({
      name,
    });
  }

  public handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const email: string = event.target.value;
    this.setState({
      email,
    });
  }

  public handleTimezoneChange = (value: string) => {
    this.setState({ timezone: value });
  }

  public handleMobileChange = (value: string, data: any) => {
    this.setState({
      mobileNumber: value,
      dialCode: data.dialCode,
    });
  }

  public handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const firstName = event.target.value;
    this.setState({ firstName });
  }

  public handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const lastName = event.target.value;
    this.setState({ lastName });
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public isUserActive = () => {
    if (this.state.data) {
      return this.state.data!.status === Status.ENABLED;
    } else {
      return false;
    }
  }

  public updateUser = async () => {
    const payload: Payload = {
      firstname: this.state.firstName,
      lastname: this.state.lastName,
      email: this.state.email,
      mobileNumber: this.state.mobileNumber,
      timezone: this.state.timezone,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/users/update/${this.props.currentUsername}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'update-user',
    };
    this.doCall(request);
  }

  public onSubmit = () => {
    this.checkPhoneNumber();

    this.setState(
      {
        error: null,
      },
      () => {
        this.updateUser();
      },
    );
  }

  public checkPhoneNumber = () => {
    // tslint:disable-next-line:max-line-length
    const phoneNumberWithoutDialCode = this.state.mobileNumber.replace(/[^0-9]+/g, '').slice(this.state.dialCode.length);
    if (phoneNumberWithoutDialCode.length === 0) {
      // set empty phone number without '+' if the phone number is not provided
      this.setState({
        mobileNumber: phoneNumberWithoutDialCode,
      });
    }
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
  }
}

const UserProfileWithIntl = injectIntl(UserProfileBase);
const UserProfileWithAlert = withAlert<UserProfileBase['props']>()(UserProfileWithIntl);
const UserProfileWithApi = RefreshApi(Api(UserProfileWithAlert));
export const UserProfile = withRouter(UserProfileWithApi);
