import { StudioAssistantBase } from './StudioAssistant.component';
import Routes from '../../../../base/component/Routes/Routes';
import { Link } from 'react-router-dom';
import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { Publish } from './Publish.component';

export const StudioAssistantView: ViewFunction<StudioAssistantBase> = function() {
  return (
    <>
      <div className='row'>
        <div className='col-3'>
          <ul className='nav nav-pills flex-column nav-tabs mb-1' role='tablist'>
            <li className='nav-item'>
              <Link
                className={`studio-welcome-messages nav-link shadow-sm mr-1 mb-1 ${this.getFormActiveClass(
                  this.getFormOptionType().WELCOMEMESSAGES,
                )}`}
                id='bot-general-settings-link-welcome-messages'
                to={`welcomemessages`}
              >
                {' '}
                {this.formatMessage('assistant.studio.welcomemessages')}
              </Link>
              <Link
                className={`studio-faqs nav-link shadow-sm mr-1 mb-1 ${this.getFormActiveClass(this.getFormOptionType().FAQ)}`}
                id='bot-general-settings-link-faq'
                to={`faq`}
              >
                {' '}
                {this.formatMessage('assistant.studio.faq')}
              </Link>
              <Link
                className={`studio-fallback nav-link shadow-sm mr-1 mb-1 ${this.getFormActiveClass(this.getFormOptionType().fALLBACK)}`}
                id='bot-general-settings-link-fallback'
                to={`fallback`}
              >
                {' '}
                {this.formatMessage('assistant.studio.fallback')}
              </Link>
            </li>
          </ul>
          <Publish
            {...this.props}
            getBotStatus={this.getBotStatus}
            trainingStatus={this.state.trainingStatus}
            modified={this.state.modified}
            setTrainingStatusDraft={this.setTrainingStatusDraft}
          />
        </div>
        <div className='col-9'>
          <Routes
            routes={this.props.routes}
            innerProps={{
              reloadAssistant: this.reloadAssistant,
              getBotStatus: this.getBotStatus,
              trainingStatus: this.state.trainingStatus,
              modified: this.state.modified,
            }}
          />
        </div>
      </div>
    </>
  );
};
