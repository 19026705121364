import React from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { Api, RefreshApi } from '../../../../../api/Server';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Request, RequestActions } from '../../../../../model/http/Request';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import {
  withPaginationRouting,
  WithPaginationRoutingProps,
} from '../../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { EditAssistantContext } from '../state/EditAssistantContext';
import { EditAssistantStateModel } from '../state/EditAssistantStateModel';
import LogsAssistantView from './LogsAssistant.view';
import { GetLogsList, LogsModel, StatsTimeFrame } from '../../../../common/model/logs/LogsModel';
import download from 'downloadjs';
import { LogsExportFormat } from './LogsModel';

interface LogsState extends ApiState {
  data: LogsModel[];
  search: string;
  totalPages: number;
  fromDate: Date | null;
  toDate: Date | null;
  currentFilterConversationId: string;
  selectedItem: string;
  dateSelectedItem: string;
  searchTimeFrame: StatsTimeFrame;
  gettingExport: boolean;
}

interface LogsPayload {
  endDate?: string;
  startDate?: string;
  dialogue_uuid?: string;
}

interface IntlProps {
  intl: IntlShape;
}

type Props = IntlProps &
  ApiProps<GetLogsList & string> &
  LayoutStateModel &
  EditAssistantStateModel &
  SessionStateModel &
  WithPaginationRoutingProps<Navigation> &
  WithAlertProps;

export class LogsAssistantBase extends ApiBase<GetLogsList & string, Props, LogsState> {
  public render = LogsAssistantView.bind(this);
  private readonly GETLOGS = 'getLogs';
  private readonly GETLOGSEXPORTCSV = 'getLogsExportCsv';
  private readonly GETLOGSEXPORTXLSX = 'getLogsExportXlsx';

  constructor(props: Props) {
    super(props);

    this.state = {
      data: [],
      search: '',
      totalPages: 0,
      error: null,
      authError: null,
      fromDate: this.getCurrentStartDate() ? new Date(this.getCurrentStartDate()) : null,
      toDate: this.getCurrentEndDate() ? new Date(this.getCurrentEndDate()) : null,
      currentFilterConversationId: this.getCurrentConversationId(),
      selectedItem: '',
      dateSelectedItem: '',
      searchTimeFrame: StatsTimeFrame.ALL,
      gettingExport: false,
    };
  }

  public componentDidMount(): void {
    this.getLogs();
  }

  public componentDidUpdate(prevProps: Props) {
    super.componentDidUpdate(prevProps);
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  public onRouteChanged() {
    this.getLogs();
  }

  public getCurrentPage() {
    return this.props.page;
  }

  public getCurrentPageSize(): number {
    return this.props.pageSize;
  }

  public getCurrentStartDate(): string {
    return this.props.getExtraParam('startDate') || '';
  }

  public getCurrentEndDate(): string {
    return this.props.getExtraParam('endDate') || '';
  }

  public getCurrentConversationId(): string {
    return this.props.getExtraParam('dialogue_uuid') || '';
  }

  public downloadLogs = async (format: LogsExportFormat) => {
    this.setState({
      gettingExport: true,
    });

    const payload: LogsPayload = {};

    if (this.getCurrentStartDate()) {
      payload.startDate = new Date(this.getCurrentStartDate()).toISOString();
    }
    if (this.getCurrentEndDate()) {
      payload.endDate = new Date(this.getCurrentEndDate()).toISOString();
    }
    if (this.getConversationId() !== '') {
      payload.dialogue_uuid = this.state.currentFilterConversationId;
    }

    const { currentAssistant } = this.props;
    const request: Request<{}> = {
      id: format === LogsExportFormat.XLSX ? this.GETLOGSEXPORTXLSX : this.GETLOGSEXPORTCSV,
      method: HttpRequestType.POST,
      payload,
      relativePath: `/admin/assistants/${currentAssistant}/logs/${format}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      action: RequestActions.DOWNLOAD,
    };
    await this.doCall(request);
  }

  public getLogs() {
    const payload: LogsPayload = {};

    if (this.getCurrentStartDate()) {
      payload.startDate = new Date(this.getCurrentStartDate()).toISOString();
    }
    if (this.getCurrentEndDate()) {
      payload.endDate = new Date(this.getCurrentEndDate()).toISOString();
    }
    if (this.getConversationId() !== '') {
      payload.dialogue_uuid = this.state.currentFilterConversationId;
    }

    const { currentAssistant } = this.props;
    const page = this.getCurrentPage();
    const pageSize = this.getCurrentPageSize();
    const request: Request<{}> = {
      id: this.GETLOGS,
      method: HttpRequestType.POST,
      payload,
      relativePath: `/admin/assistants/${currentAssistant}/logs?page=${page}&size=${pageSize}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };
    this.doCall(request);
  }

  public getData() {
    return this.state.data;
  }

  public changePaginationHandler = (pageNumber: number) => {
    this.props.goToPage(pageNumber);
  }

  public changePageSize = (size: number) => {
    this.props.changePageSize(size);
  }

  public onSubmitFilter = () => {
    this.props.goToPage(0, {
      startDate: this.state.fromDate ? this.state.fromDate.toISOString() : null,
      endDate: this.state.toDate ? this.state.toDate.toISOString() : null,
      dialogue_uuid: this.state.currentFilterConversationId,
    });
    this.getLogs();
  }

  public onFromDateChangeHandler = (date: Date | null) => {
    this.setState(
      {
        fromDate: date,
        searchTimeFrame: StatsTimeFrame.CUSTOM,
      },
      () => {
        setTimeout(() => {
          this.onSubmitFilter();
        }, 1000);
      },
    );
  }

  public onToDateChangeHandler = (date: Date | null) => {
    this.setState(
      {
        toDate: date,
        searchTimeFrame: StatsTimeFrame.CUSTOM,
      },
      () => {
        if (this.state.fromDate) {
          setTimeout(() => {
            this.onSubmitFilter();
          }, 1000);
        }
      },
    );
  }

  public clearFilters = () => {
    this.setState(
      {
        fromDate: null,
        toDate: null,
        currentFilterConversationId: '',
        searchTimeFrame: StatsTimeFrame.ALL,
      },
      () => {
        setTimeout(() => {
          this.onSubmitFilter();
        }, 1000);
      },
    );
  }

  public getConversationId = (): string => {
    return this.state.currentFilterConversationId;
  }

  public onChangeConversationID = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    this.setState({
      currentFilterConversationId: value,
    });
  }

  public handleConversationDetail = (item: LogsModel) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (this.state.selectedItem === item.dialogue_uuid && this.state.dateSelectedItem === item.timestamp) {
      this.setState({
        selectedItem: '',
        dateSelectedItem: '',
      });
    } else {
      this.setState({
        selectedItem: item.dialogue_uuid,
        dateSelectedItem: item.timestamp,
      });
    }
  }

  public getChevron = (item: LogsModel) => {
    return this.state.selectedItem === item.dialogue_uuid && this.state.dateSelectedItem === item.timestamp
      ? 'up'
      : 'down';
  }

  public show(item: LogsModel): string {
    return this.state.selectedItem === item.dialogue_uuid && this.state.dateSelectedItem === item.timestamp
      ? 'show'
      : '';
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public selectTimeFrame = (term: StatsTimeFrame) => {
    this.setState({
      searchTimeFrame: term,
    });
  }

  public submitButtonFilters = (fromDate: Date | null, toDate: Date | null) => {
    this.setState(
      {
        fromDate,
        toDate,
      },
      () => {
        this.onSubmitFilter();
      },
    );
  }

  public getAllData = () => {
    this.selectTimeFrame(StatsTimeFrame.ALL);
    this.clearFilters();
  }

  public getTimeFrameData = (timeFrame: StatsTimeFrame) => () => {
    const startDate = new Date();
    const endDate = new Date();
    switch (timeFrame) {
      case StatsTimeFrame.YEAR:
        startDate.setFullYear(startDate.getFullYear() - 1);
        break;
      case StatsTimeFrame.TRIMESTER:
        startDate.setMonth(startDate.getMonth() - 3);
        break;
      case StatsTimeFrame.MONTH:
        startDate.setMonth(startDate.getMonth() - 1);
        break;
      case StatsTimeFrame.WEEK:
        startDate.setDate(startDate.getDate() - 7);
        break;
      case StatsTimeFrame.DAY:
        startDate.setDate(startDate.getDate() - 1);
        break;
    }
    this.selectTimeFrame(timeFrame);
    this.submitButtonFilters(startDate, endDate);
  }

  protected setResponseData(): void {
    const response = this.props.response;
    const callId = this.props.callId;
    if (callId === this.GETLOGSEXPORTXLSX) {
      this.setState({
        gettingExport: false,
      });
      if (response) {
        download(response, 'logs.xlsx', 'application/vnd.ms-excel');
      }
    }
    if (callId === this.GETLOGSEXPORTCSV) {
      this.setState({
        gettingExport: false,
      });
      if (response) {
        download(response, 'logs.csv', 'application/text');
      }
    } else if (callId === this.GETLOGS) {
      if (response.content) {
        this.setState(() => ({
          data: response.content,
          totalPages: Math.ceil(response.totalElements / Number(response.size)),
        }));
      }
    }
  }

  protected setErrorResponseData(): void {
    this.setState({
      gettingExport: false,
    });
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error.message : ''),
    );
  }
}

const LogsAssistantWithIntl = injectIntl(LogsAssistantBase);
const LogsAssistantWithAlert = withAlert<LogsAssistantBase['props']>()(LogsAssistantWithIntl);
const LogsAssistantWithSession = withContext(SessionContext)(LogsAssistantWithAlert);
const LogsAssistantSession = withContext(EditAssistantContext)(LogsAssistantWithSession);
const LogsAssistantWithApi = RefreshApi(Api(LogsAssistantSession));
export const LogsAssistant = withPaginationRouting()(LogsAssistantWithApi);
