import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { utcDateFormat } from '../../../../../util/dateFormat';
import { PageSize } from '../../../../base/component/PageSize/PageSize.component';
import { Pagination } from '../../../../base/component/Pagination/Pagination.component';
import { ColumnObj, Table } from '../../../../base/component/Table/Table.component';
import { LogsAssistantDetail } from './detail/LogsAssistantDetail.component';
import { LogsAssistantDialogueLogsDetail } from './detail/LogsAssistantDialogueLogs.component';
import { LogsAssistantBase } from './LogsAssistant.component';
import { LogsModel, SentimentEnum, StatsTimeFrame } from '../../../../common/model/logs/LogsModel';
import { LogsBubble } from '../../../../common/component/logs/bubble/LogsBubble.component';
import { LogsBubbleInput } from '../../../../common/component/logs/inputbubble/LogsBubbleInput';
import { LogsBubbleFeedback } from '../../../../common/component/logs/inputbubble/LogsBubbleFeedback';
import { LogsBubbleNumber } from '../../../../common/component/logs/bubblenumber/LogsBubbleNumber';
import { LogsBubbleNumberThumbs } from '../../../../common/component/logs/bubblenumberthumbs/LogsBubbleNumberThumbs';
import { LogsDatePicker } from '../../../../common/component/logs/datepicker/LogsDatePicker';
import { LogsConfidence } from '../../../../common/component/logs/confidence/LogsConfidence';
import { LogsSentiment } from '../../../../common/component/logs/sentiment/LogsSentiment';
import { OrganizationTrial, OrganizationParamType } from '../../../../auth/state/Session/SessionStateModel';

export const LogsAssistantView: ViewFunction<LogsAssistantBase> = function() {
  const rowDetail = {
    render: (item: LogsModel) => (
      <td colSpan={7} className='detail-row'>
        <div
          className={`detail-row-content collapse ${this.show(item)}`}
          id={`collapseConversationDetail-${item.dialogue_uuid}`}
        >
          <div className='conversation-summary card card-body shadow'>
            {item.dialogue_uuid === this.state.selectedItem && this.state.dateSelectedItem === item.timestamp && (
              <div className='row'>
                <LogsAssistantDetail dialogueUuid={item.dialogue_uuid} />
                <LogsAssistantDialogueLogsDetail
                  dialogueUuid={item.dialogue_uuid}
                />
              </div>
            )}
          </div>
        </div>
      </td>
    ),
  };

  const columns: ColumnObj[] = [
    {
      title: `${this.formatMessage('date')}/${this.formatMessage('time')}`,
      propertyName: 'timestamp',
      className: 'text-nowrap',
      render: (item: LogsModel) => (
        <div>
          <div>{utcDateFormat(item.timestamp)}</div>
          <div>
            <i
              className={`fas fa-chevron-${this.getChevron(item)} mt-2 links`}
              onClick={this.handleConversationDetail(item)}
            />
          </div>
        </div>
      ),
    },
    {
      title: this.formatMessage('input'),
      propertyName: 'message',
      render: (item: LogsModel) => {
        if (item.feedback) {
          return <LogsBubbleFeedback item={item.feedback} />;
        } else if (item.message || item.feedbackDialogue || item.thumbs?.message) {
          return <LogsBubbleInput item={item} />;
        }
      },
    },
    {
      title: this.formatMessage('logsAssistantDetailBase.sentiment'),
      propertyName: 'nluAnalysis',
      className: 'text-center',
      render: (item: LogsModel) => {
        return <LogsSentiment item={item} SentimentEnum={SentimentEnum} />;
      },
    },
    {
      title: this.formatMessage('logsAssistantDialogueLogsBase.topIntent'),
      propertyName: 'intents',
      render: (item: LogsModel) => {
        const intents = item.intents ? [...item.intents] : [];
        intents.sort((a, b) => b.confidence - a.confidence);
        if (intents.length > 0 && !item.feedbackDialogue) {
          return <span>{intents[0].identifier}</span>;
        }
        return <span />;
      },
    },
    {
      title: this.formatMessage('logsAssistantDetailBase.confidence'),
      propertyName: 'confidence',
      render: (item: LogsModel) => {
        if (item.intents && item.intents.length > 0 && !item.feedbackDialogue && item.intents[0].identifier !== 'start-dialogue') {
          return <LogsConfidence item={item} />;
        }
        return <span />;
      },
    },
    {
      title: this.formatMessage('logsAssistantDialogueLogsBase.answer'),
      propertyName: 'answer',
      render: (item: LogsModel) => {
        if (item.feedback) {
          return;
        } else if (item.thumbs) {
          const answers: any = item.thumbs.answers?.map((value: string) => JSON.parse(value));
          if (answers) {
            const firstTextAnswer: any = answers.find((value: any) => value.type !== 'ssml');
            return (
              <>
                <div style={{ minWidth: '300px' }} data-property-name='author' data-property-value='robo'>
                  <LogsBubble answer={firstTextAnswer} />
                </div>
                {/* tslint:disable-next-line:max-line-length */}
                <LogsBubbleNumberThumbs item={item} answers={answers} handleConversationDetail={this.handleConversationDetail} />
              </>
            );
          }
        } else if (item.answers && !item.feedbackDialogue) {
          const firstTextAnswer: any = item.answers.find((value) => value.type !== 'ssml');
          return (
            <>
              <div style={{ minWidth: '300px' }} data-property-name='author' data-property-value='robo'>
                <LogsBubble answer={firstTextAnswer} />
              </div>
              <LogsBubbleNumber item={item} handleConversationDetail={this.handleConversationDetail} />
            </>
          );
        }
        return <span />;
      },
    },
  ];

  const getActiveClassForTimeFrame = (statsTimeFrame: string) => {
    return this.state.searchTimeFrame === statsTimeFrame ? 'active' : '';
  };
  const trialProp = this.props.organization?.parameters.find((p) => p.key === OrganizationParamType.TRIAL)?.value;
  return (
    <React.Fragment>
      <div className='card single-card card-body shadow-sm'>
        <LogsDatePicker
          getActiveClassForTimeFrame={getActiveClassForTimeFrame}
          getAllData={this.getAllData}
          getTimeFrameData={this.getTimeFrameData}
          StatsTimeFrame={StatsTimeFrame}
          onFromDateChangeHandler={this.onFromDateChangeHandler}
          onToDateChangeHandler={this.onToDateChangeHandler}
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
          onChangeConversationID={this.onChangeConversationID}
          onSubmitFilter={this.onSubmitFilter}
          export={this.downloadLogs}
          isTrial={trialProp === OrganizationTrial.ENABLED}
          clearFilters={this.clearFilters}
          value={this.getConversationId()}
          gettingExport={this.state.gettingExport}
          getExtraParam={this.props.getExtraParam}
        />
        <Table tableId={'Logs'} columns={columns} rowDetail={rowDetail} data={this.getData()} page={0} />
        <div className='row mt-3'>
          <div className='col-sm-6'>
            <PageSize changePageSize={this.changePageSize} size={this.getCurrentPageSize()} />
          </div>
          <div className='col-sm-6'>
            <Pagination
              totalPages={this.state.totalPages}
              changePaginationHandler={this.changePaginationHandler}
              page={this.getCurrentPage()}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LogsAssistantView;
