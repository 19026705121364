import React from 'react';
import { ViewFunction } from '../../../model/component/ViewFunction';
import { Form } from '../../form/component/Form.component';
import LoadingBar from '../../base/component/loadingBar/loadingBar';
import { InvitationSentBase } from './InvitationSent.component';
import logo from '../../../assets/img/logo-vivid-red_png@1x.png';

export const InvitationSentView: ViewFunction<InvitationSentBase> = function() {
  return (
    <React.Fragment>
      <LoadingBar height={4} className='loading loading-login' color={'#ff3366'} onRef={this.setRef} />
      <Form style={{ marginTop: '100px' }}>
      {() => (
          <React.Fragment>
            <div className='card shadow-sm'>
              <div className='card-body'>
                <div className='text-center mb-3'>
                  <img className='logo-login' src={logo} alt='Two Impulse' />
                </div>
                <div className='row'>
                    <div className='col-12 text-center'>
                        {this.formatMessage('registration.invitationSent')}
                    </div>
                </div>
                <div className='row'>
                  <div className='col-12 text-center mt-3'>
                  <button
                        type='submit'
                        id='login'
                        className='btn btn-primary'
                        onClick={this.redirectLogin}
                  >
                        {this.formatMessage('login')}
                  </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-center'>
              <small>{this.formatMessage('roboai.date')}</small>
            </div>
            </React.Fragment>
        )}
            </Form>
    </React.Fragment>
  );
};

export default InvitationSentView;
