import React from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import Routes from './component/Routes/Routes';
import { root as defaultRoute, routes } from './Routes';

const AppRoutes = () => (
  <Router>
    <Switch>
      <Redirect exact from='/' to={'/dashboard'} />
      <Routes routes={routes} default={defaultRoute} />
    </Switch>
  </Router>
);

export default AppRoutes;
