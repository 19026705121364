import { GroupModel } from '../../model/GroupModel';
import { IntlShape, injectIntl } from 'react-intl';
import { AppRouteComponentProps } from '../../../base/model/route/AppRouteComponentProps';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Navigation } from '../../../base/model/route/Navigation';

import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { ApiProps, ApiBase } from '../../../base/ApiComponent';
import { withAlert } from 'react-alert';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { Api, RefreshApi } from '../../../../api/Server';
import { Request } from '../../../../model/http/Request';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { EditGroupStateModel } from './state/EditGroupStateModel';
import { EditGroupContext } from './state/EditGroupContext';
import { EditGroupView } from './EditGroup.view';

interface ResponseUpdate extends Response {
  content: GroupModel;
  timestamp: Date;
}
interface IntlProps {
  intl: IntlShape;
}
interface GroupSection {
  name: string;
  identifier: string;
}

type Props = IntlProps &
  ApiProps<ResponseUpdate> &
  SessionStateModel &
  LayoutStateModel &
  EditGroupStateModel &
  RouteComponentProps<Navigation> &
  RouteComponentProps<{ groupId: string }> &
  SessionStateModel &
  WithAlertProps &
  AppRouteComponentProps;

export class EditGroupBase extends ApiBase<ResponseUpdate, Props, never> {
  public render = EditGroupView.bind(this);

  public sections: GroupSection[] = [
    { identifier: 'info', name: 'Info' },
    { identifier: 'agents', name: 'Agents' },
    { identifier: 'channels', name: 'Channels' },
    { identifier: 'logs', name: 'Logs' },
    { identifier: 'bot', name: 'Bot' },
    /* { identifier: 'analytics', name: 'Analytics' },
      { identifier: 'logs', name: 'Logs' },
      ,
      */
    // TODO: please uncomment the lines according to the impementation of the tabs.
  ];

  public componentDidMount = async () => {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/group/${this.getGroupId()}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };
    await this.doCall(request);
  }

  public getGroupId() {
    return this.props.match.params.groupId;
  }

  public setResponseData() {
    const { uuid, name, description, state } = this.props.response.content;
    const groupData: GroupModel = {
      uuid,
      name,
      description,
      state,
    };
    this.props.setGroupInfo(groupData);
  }

  public componentWillUnmount(): void {
    this.props.setBreadCrumbsInfo({
      currentPage: null,
    });
  }

  public getSectionRoute = (section: string) => {
    return `/groups/edit/${this.getGroupId()}/${section}`;
  }

  public isActive = (section: string) => this.props.location.pathname === this.getSectionRoute(section);

  public getAriaSelected = (section: string) => {
    return this.isActive(section) ? 'true' : 'false';
  }

  public getActiveClass = (section: string) => {
    return this.isActive(section) ? 'active' : '';
  }

  protected setErrorResponseData(): void {
    this.props.alert.error('error');
  }
}

const EditGroupWithIntl = injectIntl(EditGroupBase);
const EditGroupWithAlert = withAlert<EditGroupBase['props']>()(EditGroupWithIntl);
const EditGroupStatusWithContext = withContext(EditGroupContext)(EditGroupWithAlert);
const EditGroupWithApi = RefreshApi(Api(EditGroupStatusWithContext));
export const EditGroup = withRouter(EditGroupWithApi);
