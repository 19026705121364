import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { CmWhatsappProviderBase } from './CmWhatsappProviderChannel.component';

export const CmWhatsappProviderView: ViewFunction<CmWhatsappProviderBase> = function() {
  return (
    <React.Fragment>
      <div className='form-group'>
        <div className='input-group'>
          <input type='text' readOnly className='form-control' id={this.getUuid()} placeholder={this.buildUrl()} />
          <div className='input-group-append'>
            <CopyToClipboard text={this.buildUrl()}>
              <button className='btn btn-secondary' type='button' id={'copyWhatsappUrl'}>
                {this.formatMessage('channels.copy')}
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
      <form onSubmit={this.submit}>
        <div className='form-group'>
          <label htmlFor='productTokenCM'>{this.formatMessage('channels.producttoken')}</label>
          <input
            className='form-control'
            id='productTokenCM'
            name='productTokenCM'
            aria-describedby='webhookURLFacebook'
            type='text'
            placeholder='Add...'
            defaultValue={this.getXApiKey()}
            onChange={this.setXApiKey}
            disabled={!this.isAdmin()}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='whatsAppProfileNumberCM'>{this.formatMessage('channels.whatsappbusinessnumber')}</label>
          <input
            className='form-control'
            id='whatsAppProfileNumberCM'
            name='whatsAppProfileNumberCM'
            aria-describedby='whatsAppProfileNumberCM'
            type='text'
            placeholder='+49 436 432 5434'
            defaultValue={this.getWhatsappPhoneNumber()}
            onChange={this.setWhatsappPhoneNumber}
            disabled={!this.isAdmin()}
          />
        </div>
        {this.isAdmin() && (
          <div className='text-right'>
            <button className='btn btn-secondary' type='submit' id={'saveWhatsappChannel'}>
              {this.formatMessage('channels.save')}
            </button>
          </div>
        )}
      </form>
    </React.Fragment>
  );
};
