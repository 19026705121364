import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import LoadingBar from '../../../base/component/loadingBar/loadingBar';
import { Form } from '../../../form/component/Form.component';
import PasswordFormSendEmail from './PasswordFormSendEmail';
import logo from '../../../../assets/img/logo-vivid-red_png@1x.png';
import { ResetPasswordFormBase } from './ResetPasswordForm.component';
import {Link} from 'react-router-dom';

export const ResetPasswordFormView: ViewFunction<ResetPasswordFormBase> = function() {
  return (
    <React.Fragment>
      <LoadingBar height={4} className='loading loading-login' color={'#ff3366'} onRef={this.setRef} />
      <Form onSubmit={this.chooseSubmit} style={{ marginTop: '100px' }}>
        {(isValid: boolean) => (
          <React.Fragment>
            <div className='card shadow-sm'>
              <div className='card-body'>
                <div className='text-center mb-3'>
                  <img className='logo-login' src={logo} alt='Two Impulse' />
                  <h5>{this.formatMessage('conversationalai.message')}</h5>
                </div>
                <PasswordFormSendEmail state={this.state} changeEmail={this.changeEmail} isValid={isValid} />
              </div>
            </div>
            <div className='text-center'>
              <small>
                 <Link className='links' to='/auth/login'>Login</Link>
              </small>
            </div>
            <div className='text-center'>
              <small>{this.formatMessage('roboai.date')}</small>
            </div>
          </React.Fragment>
        )}
      </Form>
    </React.Fragment>
  );
};

export default ResetPasswordFormView;
