import { FAQBase, FAQObject } from './FAQ.component';
import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { ColumnObj, Table } from '../../../../base/component/Table/Table.component';
import { PageSize } from '../../../../base/component/PageSize/PageSize.component';
import { Pagination } from '../../../../base/component/Pagination/Pagination.component';
import { Search } from '../../../../base/component/Search/Search.component';
import { AddFAQ } from './AddFAQ.component';
import Confirm from '../../../../base/component/confirm/Confirm.component';

export const FAQView: ViewFunction<FAQBase> = function() {
  const columns: ColumnObj[] = [
    {
      title: this.formatMessage('assistant.studio.story'),
      propertyName: 'label',
      render: (item: FAQObject) => <p>{item.title}</p>,
    },
    {
      title: this.formatMessage('assistant.studio.examples'),
      propertyName: 'label',
      render: (item: FAQObject) => <p>{item.examples.length}</p>,
    },
    {
      title: this.formatMessage('assistant.studio.answer'),
      propertyName: 'label',
      render: (item: FAQObject) =>
        item.answers.map((bubble, index) => {
          return (
            <div key={index} className='speech-bubble-robo speech-bubble-studio mb-2'>
              <p>
              <span dangerouslySetInnerHTML={{ __html: bubble }} />
              </p>
            </div>
          );
        }),
    },
  ];

  return (
    <>
     <Confirm
        show={this.state.showConfirmDialogue}
        okLabel={this.formatMessage('ok')}
        cancelLabel={this.formatMessage('cancel')}
        confirmation={this.formatMessage('are.you.sure')}
        proceedFunc={this.confirmCallback}
        title={this.formatMessage('confirm')}
        setConfirmVisibility={this.setConfirmVisibility}
     />
      <AddFAQ
        isVisible={this.state.isModalVisible}
        handleClose={this.handleClose}
        save={this.save}
        FAQToEdit={this.state.FAQToEdit}
        saveEdits={this.saveEdits}
      />
      <div className='card card-body shadow-sm'>
        <div className='robo-welcome-message-search'>
          <Search searchHandler={this.searchHandler} search={this.getCurrentCriteria()} />
          <button type='button' className='create-new-faq btn btn-primary mb-3' onClick={this.handleOpen}>
            {this.formatMessage('assistant.studio.createnew')}
          </button>
        </div>
        <Table
          tableId={'Events'}
          data={this.getData()}
          columns={columns}
          page={this.getCurrentPage()}
          dropDownMenuItems={this.dropDownMenuItems}
        />
        <div className='row mt-3'>
          <div className='col-sm-6 mb-1 display-view'>
            <PageSize changePageSize={this.changePageSize} size={this.getCurrentPageSize()} />
          </div>
          <div className='col-sm-6'>
            <Pagination
              totalPages={this.state.totalPages}
              changePaginationHandler={this.changePaginationHandler}
              page={this.getCurrentPage()}
            />
          </div>
        </div>
      </div>
    </>
  );
};
