import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import Confirm from '../../../../base/component/confirm/Confirm.component';
import { GroupStatusBase } from './Status.component';

export const StatusView: ViewFunction<GroupStatusBase> = function() {
  return (
    <div className='card'>
      <Confirm
        show={this.props.showConfirmDialog}
        okLabel={'Ok'}
        cancelLabel={'Cancel'}
        confirmation={'Are you sure you would like to ' + this.props.statusToChange + ' this group?'}
        proceedFunc={this.confirmCallback}
        title={'Confirm'}
        setConfirmVisibility={this.props.setConfirmVisibility}
      />
      <div className='card-body' style={{paddingTop: '1rem', paddingBottom: '1rem'}}>
        <div className='row'>
          <div className='col-sm-8'>
            <h4 className='mb-0'>Status
              <span style={{fontSize: '0.9rem', color: '#999', fontWeight: 400}}/>
            </h4>
          </div>
          <div className='col-sm-4'>
            <div className='text-right'>
              <div className='toggle-status' onClick={this.props.confirm}>
                <i
                  id='status'
                  data-property-value={this.getToggleTitle()}
                  className={this.getToggle(this.props.groupData)}
                  title={this.getToggleTitle()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusView;
