import { WelcomeMessagesBase, Message } from './WelcomeMessages.component';
import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { ColumnObj, Table } from '../../../../base/component/Table/Table.component';
import { PageSize } from '../../../../base/component/PageSize/PageSize.component';
import { Pagination } from '../../../../base/component/Pagination/Pagination.component';
import { Search } from '../../../../base/component/Search/Search.component';
import { AddWelcome } from './AddWelcome.component';
import Confirm from '../../../../base/component/confirm/Confirm.component';

export const WelcomeMessagesView: ViewFunction<WelcomeMessagesBase> = function() {
  const columns: ColumnObj[] = [
    {
      title: this.formatMessage('assistant.studio.message'),
      propertyName: 'label',
      render: (item: Message) => (
        <div className='speech-bubble-robo speech-bubble-studio'>
          <p>
            <span dangerouslySetInnerHTML={{ __html: item.message }} />
          </p>
        </div>
      ),
    },
  ];
  return (
    <>
      <Confirm
        show={this.state.showConfirmDialogue}
        okLabel={this.formatMessage('ok')}
        cancelLabel={this.formatMessage('cancel')}
        confirmation={this.formatMessage('are.you.sure')}
        proceedFunc={this.confirmCallback}
        title={this.formatMessage('confirm')}
        setConfirmVisibility={this.setConfirmVisibility}
      />
      <AddWelcome
        isVisible={this.state.isModalVisible}
        handleClose={this.handleClose}
        save={this.save}
        messageToEdit={this.state.messageToEdit}
        saveEdits={this.saveEdits}
        title={this.formatMessage('assistant.studio.welcomemessage')}
      />
      <div className='card card-body shadow-sm'>
        <div className='robo-welcome-message-search'>
          <Search searchHandler={this.searchHandler} search={this.getCurrentCriteria()} />
          <button
            type='button'
            className='create-welcome-message btn btn-primary mb-3'
            id={'createNewMessage'}
            onClick={this.handleOpen}
          >
            {this.formatMessage('assistant.studio.createnew')}
          </button>
        </div>
        <Table
          tableId={'Events'}
          data={this.getData()}
          columns={columns}
          page={this.getCurrentPage()}
          dropDownMenuItems={this.dropDownMenuItems}
        />
        <div className='row mt-3'>
          <div className='col-sm-6'>
            <PageSize changePageSize={this.changePageSize} size={this.getCurrentPageSize()} />
          </div>
          <div className='col-sm-6'>
            <Pagination
              totalPages={this.state.totalPages}
              changePaginationHandler={this.changePaginationHandler}
              page={this.getCurrentPage()}
            />
          </div>
        </div>
      </div>
    </>
  );
};
