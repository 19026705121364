// tslint: disable

import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Api, RefreshApi } from '../../../../../api/Server';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../model/http/Request';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import {
  withPaginationRouting,
  WithPaginationRoutingProps,
} from '../../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../../base/state/Layout/LayoutStateModel';
import { EditGroupStateModel } from '../state/EditGroupStateModel';
import { GroupBotView } from './GroupBot.view';
import { MouseEvent } from 'react';
import { GroupAssistantModel, GroupAssistantStatus, GroupAssistantMode } from './GroupAssistantModel';

interface GetGroupAssistant extends Response {
  content: GroupAssistantModel;
  timestamp: Date;
}

interface IntlProps {
  intl: IntlShape;
}

interface Event {
  target: Value;
}

interface Value {
  value: string;
}

interface State extends ApiState {
  status: GroupAssistantStatus;
  bot: string;
  mode: GroupAssistantMode;
  confidenceThreshold: number;
  isloaded: boolean;
}

type Props = IntlProps &
  ApiProps<GetGroupAssistant> &
  SessionStateModel &
  EditGroupStateModel &
  RouteComponentProps<Navigation> &
  WithPaginationRoutingProps<Navigation> &
  WithAlertProps &
  LayoutStateModel;

const GET_GROUP_ASSISTANT_DETAILS_CALL = 'getGroupAssistantDetails';
const UPDATE_GROUP_ASSISTANT_DETAILS_CALL = 'updateGroupAssistantDetails';
const UPDATE_GROUP_ASSISTANT_STATUS_CALL = 'updateGroupBotStatus';

export class GroupBotBase extends ApiBase<GetGroupAssistant, Props, State> {
  public render = GroupBotView.bind(this);

  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      authError: null,
      status: GroupAssistantStatus.DISABLED,
      bot: '',
      mode: GroupAssistantMode.FOREGROUND,
      confidenceThreshold: 0,
      isloaded: false,
    };
  }

  public componentDidMount(): void {
    this.getBotGroupDetails();
  }

  public getToggleClass(enabled: boolean): string {
    if (enabled === true) {
      return 'fas fa-toggle-on fa-2x on text-success';
    }
    return 'fas fa-toggle-off fa-2x off text-secondary';
  }

  public getToggleTitle(enabled: boolean): string {
    if (enabled === true) {
      return this.formatMessage('GroupBotView.Enabled');
    }
    return this.formatMessage('GroupBotView.Disabled');
  }

  public onStatusToggle = () => (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.setGroupBotStatus();
  }

  public setGroupBotStatus = () => {
    const status =
      this.state.status === GroupAssistantStatus.ENABLED ? GroupAssistantStatus.DISABLED : GroupAssistantStatus.ENABLED;
    this.setState({
      status,
    });
    const { currentGroup } = this.props;
    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload: null,
      relativePath: `/admin/group/${currentGroup}/assistant/status/${status}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: UPDATE_GROUP_ASSISTANT_STATUS_CALL,
    };
    this.doCall(request);
  }

  public setBot = (uuid: string) => {
    this.setState({
      bot: uuid,
    });
  }

  public getBot() {
    return this.state.bot;
  }

  public onModeChanged = (event: Event) => {
    const value = event.target.value === 'FOREGROUND' ? GroupAssistantMode.FOREGROUND : GroupAssistantMode.BACKGROUND;
    this.setState({
      mode: value,
    });
  }

  public setConfidenceThreshold = (event: Event) => {
    const value = parseInt(event.target.value);
    this.setState({
      confidenceThreshold: value,
    });
  }

  public getConfidenceThreshold() {
    return this.state.confidenceThreshold;
  }

  public submitFields = () => {
    this.updateBotGroupDetails();
  }

  public getCurrentCriteria(): string {
    return this.props.getExtraParam('criteria') || '';
  }

  public getBotGroupDetails = () => {
    const { currentGroup } = this.props;
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/group/${currentGroup}/assistant`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: GET_GROUP_ASSISTANT_DETAILS_CALL,
    };
    this.doCall(request);
  }

  public updateBotGroupDetails = () => {
    const { currentGroup } = this.props;
    const payload = {
      botUuid: this.state.bot,
      mode: this.state.mode,
      confidenceThreshold: this.state.mode === GroupAssistantMode.FOREGROUND ? this.state.confidenceThreshold : 0,
    };
    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/group/${currentGroup}/assistant`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: UPDATE_GROUP_ASSISTANT_DETAILS_CALL,
    };

    this.doCall(request);
  }

  public setResponseData() {
    const response = this.props.response;

    if (this.props.callId === GET_GROUP_ASSISTANT_DETAILS_CALL) {
      this.setState({
        bot: response.content.botUuid,
        status: response.content.status,
        mode: response.content.mode,
        confidenceThreshold: response.content.confidenceThreshold,
        isloaded: true,
      });
    } else if (this.props.callId === UPDATE_GROUP_ASSISTANT_DETAILS_CALL) {
      this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
    }
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public isEnabled() {
    return this.state.status === GroupAssistantStatus.ENABLED;
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }
}

const GroupsBotWithIntl = injectIntl(GroupBotBase);
const GroupsBotWithAlert = withAlert<GroupBotBase['props']>()(GroupsBotWithIntl);
const GroupsBotWithApi = RefreshApi(Api(GroupsBotWithAlert));
export const GroupBot = withPaginationRouting()(GroupsBotWithApi);
