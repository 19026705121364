import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DatePickerRange } from '../../../../base/component/DatePickerRange/DatePickerRange.component';
import { useIntl } from 'react-intl';
import { LogsExportFormat } from '../../../../assistant/component/EditAssistant/logs/LogsModel';
import moment from 'moment';

export const LogsDatePicker: any = (props: any) => {
  const intl = useIntl();

  const getCurrentConversationId = () => {
    return props.getExtraParam('dialogue_uuid') || '';
  };

  const exportEnabled = () => {
      return !props.isTrial && isLessThan1Week() && !props.gettingExport;
  };

  const exportTitle  = () => {
    let title = intl.formatMessage({ id: 'export' });
    if (props.isTrial) {
        title = intl.formatMessage({ id: 'dashboard.export.disabled' });
    } else if (!isLessThan1Week()) {
      title = intl.formatMessage({ id: 'disabledExports' });
    }
    return title;
  };

  const isLessThan1Week = () => {
    if (getCurrentConversationId()) {
      return true;
    }
    const start = moment(props.fromDate, 'DD-MM-YYYY');
    const end = moment(props.toDate, 'DD-MM-YYYY');
    if (end.diff(start, 'days')) {
      return end.diff(start, 'days') > 8 ? false : true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className='text-center mb-3'>
        <div className='btn-group text-center' role='group'>
          <button
            id={'allFilter'}
            type='button'
            className={`btn btn btn-light ${props.getActiveClassForTimeFrame(props.StatsTimeFrame.ALL)}`}
            onClick={props.getAllData}
          >
            <FormattedMessage id={'statsAssistantChart.filter.button.all'} />
          </button>
          <button
            id={'yearFilter'}
            type='button'
            className={`btn btn btn-light ${props.getActiveClassForTimeFrame(props.StatsTimeFrame.YEAR)}`}
            onClick={props.getTimeFrameData(props.StatsTimeFrame.YEAR)}
          >
            <FormattedMessage id={'statsAssistantChart.filter.button.year'} />
          </button>
          <button
            id={'trimesterFilter'}
            type='button'
            className={`btn btn btn-light ${props.getActiveClassForTimeFrame(props.StatsTimeFrame.TRIMESTER)}`}
            onClick={props.getTimeFrameData(props.StatsTimeFrame.TRIMESTER)}
          >
            <FormattedMessage id={'statsAssistantChart.filter.button.trimester'} />
          </button>
          <button
            id={'monthFilter'}
            type='button'
            className={`btn btn btn-light ${props.getActiveClassForTimeFrame(props.StatsTimeFrame.MONTH)}`}
            onClick={props.getTimeFrameData(props.StatsTimeFrame.MONTH)}
          >
            <FormattedMessage id={'statsAssistantChart.filter.button.month'} />
          </button>
          <button
            id={'weekFilter'}
            type='button'
            className={`btn btn btn-light ${props.getActiveClassForTimeFrame(props.StatsTimeFrame.WEEK)}`}
            onClick={props.getTimeFrameData(props.StatsTimeFrame.WEEK)}
          >
            <FormattedMessage id={'statsAssistantChart.filter.button.week'} />
          </button>
          <button
            id={'dayFilter'}
            type='button'
            className={`btn btn btn-light ${props.getActiveClassForTimeFrame(props.StatsTimeFrame.DAY)}`}
            onClick={props.getTimeFrameData(props.StatsTimeFrame.DAY)}
          >
            <FormattedMessage id={'statsAssistantChart.filter.button.day'} />
          </button>
        </div>
      </div>
      <div
        className='btn-toolbar mb-3 d-flex justify-content-center'
        role='toolbar'
      >
        <DatePickerRange
          onFromDateChangeHandler={props.onFromDateChangeHandler}
          onToDateChangeHandler={props.onToDateChangeHandler}
          fromDate={props.fromDate}
          toDate={props.toDate}
        />
        <div className='btn-group mr-2 input-group' role='group'>
          <input
            id={'conversationId'}
            type='text'
            className='form-control'
            placeholder={intl.formatMessage({ id: 'logsAssistantView.conversationId' })}
            value={props.value}
            style={{ border: '#6904b7 2px solid' }}
            onChange={props.onChangeConversationID}
          />
          <span className='input-group-append'>
            <button
              id={'filterLogs'}
              className='btn btn-secondary'
              type='button'
              title={intl.formatMessage({ id: 'filter' })}
              onClick={props.onSubmitFilter}
            >
              <i className='fas fa-filter' />
            </button>
          </span>
        </div>

        <React.Fragment>
          <div className='btn-group mr-1' role='group'>
            <button
              id='exportXlsxLogs'
              type='button'
              title={exportTitle()}
              className='btn btn-sm btn-secondary'
              onClick={() => props.export(LogsExportFormat.XLSX)}
              disabled={!exportEnabled()}
            >
              <FormattedMessage id={'export.xlsx'} />
            </button>
          </div>
          <div className='btn-group mr-1' role='group'>
            <button
              id='exportCsvLogs'
              type='button'
              title={exportTitle()}
              className='btn btn-sm btn-secondary'
              onClick={() => props.export(LogsExportFormat.CSV)}
              disabled={!exportEnabled()}
            >
              <FormattedMessage id={'export.csv'} />
            </button>
          </div>
        </React.Fragment>

        <div className='btn-group mr-1' style={{ float: 'right' }} role='group'>
          <button
            id='clean-filter'
            type='button'
            title={intl.formatMessage({ id: 'cleanFilters' })}
            className='btn btn-sm btn-dark'
            onClick={props.clearFilters}
          >
            <FormattedMessage id={'cleanFilters'} />
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};
