import { Layout } from '../base/component/Layout/Layout.component';
import { Roles } from '../base/model/route/Role';
import { Route } from '../base/model/route/Route';
import { ApiKeyList } from './component/ApiKey/ApiKeyList.component';
import { EditUser } from './component/EditUser/EditUser.component';
import { UsersList } from './component/UsersList/UserList.component';
import { UserProfile } from './component/UserProfile/UserProfile.component';
import { Organisation } from './component/Organisation/Organisation.component';

const routes: Route[] = [
  {
    path: '/account',
    default: '/account/users',
    routes: [
      {
        path: '/account/users/:userId',
        component: EditUser,
        authorize: [Roles.admin],
        redirectNonAuthorized: '/unauthorized',
        layout: Layout,
      },
      {
        path: '/account/users',
        component: UsersList,
        authorize: [Roles.admin],
        redirectNonAuthorized: '/unauthorized',
        layout: Layout,
      },
      {
        path: '/account/organisation',
        component: Organisation,
        authorize: [Roles.admin],
        redirectNonAuthorized: '/unauthorized',
        layout: Layout,
      },
      {
        path: '/account/apikeys',
        component: ApiKeyList,
        authorize: [Roles.admin],
        redirectNonAuthorized: '/unauthorized',
        layout: Layout,
      },
      {
        path: '/account/profile',
        component: UserProfile,
        authorize: Object.values(Roles),
        redirectNonAuthorized: '/unauthorized',
        layout: Layout,
      },
    ],
  },
];

export default routes;
