import React from 'react';
import { Link } from 'react-router-dom';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import Confirm from '../../../base/component/confirm/Confirm.component';
import { PageSize } from '../../../base/component/PageSize/PageSize.component';
import { Pagination } from '../../../base/component/Pagination/Pagination.component';
import { Search } from '../../../base/component/Search/Search.component';
import { ColumnObj, Table } from '../../../base/component/Table/Table.component';
import { UserModel } from '../../model/AccountModel';
/* import { InviteUser } from '../inviteUser/InviteUser.component'; add when luis has added route */
import { utcDateFormat } from '../../../../util/dateFormat';
import { OrganizationUserListBase } from './OrganizationUserList.component';
export const OrganizationUserListView: ViewFunction<OrganizationUserListBase> = function() {
  const columns: ColumnObj[] = [
    {
      title: this.formatMessage('organization.name'),
      propertyName: 'name',
      render: (item: UserModel) => (
        <Link data-action='edit-User' to={`#`}>
          {item.username}
        </Link>
      ),
    },
    {
      title: this.formatMessage('organization.email'),
      propertyName: 'email',
      render: (item: UserModel) => <span>{item.email}</span>,
    },
    {
      title: this.formatMessage('organization.role'),
      propertyName: 'role',
      render: (item: UserModel) => <span>{item.roles[0]}</span>,
    },
    {
      title: this.formatMessage('organization.lastLogIn'),
      propertyName: 'lastLogin',
      render: (item: UserModel) => <span>{utcDateFormat(item.lastLogin)}</span>,
    },
    {
      title: this.formatMessage('status'),
      style: { textTransform: 'capitalize' },
      propertyName: 'status',
      render: (item: UserModel) => <span>{item.status.toLocaleLowerCase()}</span>,
    },
  ];

  return (
    <React.Fragment>
      <Confirm
        show={this.state.showConfirmDialog}
        okLabel={this.formatMessage('ok')}
        cancelLabel={this.formatMessage('cancel')}
        confirmation={this.state.confirmMsg!}
        proceedFunc={this.confirmCallback}
        title={this.formatMessage('confirm')}
        setConfirmVisibility={this.setConfirmVisibility}
      />
     {/*  <InviteUser />  add when luis has added the route*/}
      <div className='card shadow-sm'>
        <div className='card-body'>
          <div className='row'>
            <Search searchHandler={this.searchHandler} search={this.getCurrentCriteria()} />
          </div>
          <Table
            tableId={'Users'}
            data={this.getData()}
            columns={columns}
            page={this.getPage()}
        /*     dropDownMenuItems={this.dropDownMenuItems} add when Luis has finished routes*/
          />
          <div className='row mt-3'>
            <div className='col-sm-6'>
              <PageSize changePageSize={this.changePageSizeHandler} size={this.getPageSize()} />
            </div>
            <div className='col-sm-6'>
              <Pagination
                totalPages={this.getTotalPages()}
                changePaginationHandler={this.changePageHandler}
                page={this.getPage()}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
