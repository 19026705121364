import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/img/icon-white_png@1x.png';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { Menu } from './Menu.component';
import { TopBarBase } from './TopBar.component';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-bootstrap/Modal';

export const TopBarView: ViewFunction<TopBarBase> = function() {

  return (
    <Navbar bg='light' expand='lg' fixed='top' className='shadow-sm'>
      <Navbar.Brand id='view-dashboard' as={Link} to='/dashboard'>
        <img src={logo} className='logo-robo' alt='React Bootstrap logo' />
      </Navbar.Brand>
      <button
        data-action='view-sidebar'
        id='sidebarCollapse'
        className='btn btn-transparent ml-1'
        onClick={this.toggleSidebar}
        type='button'
      >
        <i className='fas fa-bars' />
      </button>

      <Navbar.Toggle data-action='view-navbar' aria-controls='navbarSupportedContent' />
      <div className='app-title'>
        <h5>
          <FormattedMessage id='conversationalai.message' />
        </h5>
      </div>

      <div className='app-upgrade d-flex justify-content-center'>
      {this.isTrialAndAdmin() && (
        <button
          id='btn-upgrade'
          className='btn btn-upgrade'
          title={this.formatMessage('upgrade.title')}
          onClick={this.openUpgrade}
        >
          <FormattedMessage id='upgrade' />
        </button>
      )}
    </div>
      <Menu visible={this.props.sidebarLinksVisible}/>
      <Modal data-type='modal' dialogClassName='modal-xl wide-modal' show={this.state.showUpgrade}
             onHide={this.closeUpgrade}>
        <Modal.Header closeButton>
          <Modal.Title as='h5'> <FormattedMessage id='upgrade.modal.title' /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div id='plans' className='btn-group btn-group-toggle btn-group-plan' data-toggle='buttons'>
                <div className='card-deck mb-3 text-center'>
              <div className='card mb-4 shadow-sm'>
                <div className='card-header upgrade-card-header'>
                  <h4 className='my-0 font-weight-normal'><FormattedMessage id='upgrade.plan.free' /></h4>
                  <h6 className='my-0 font-weight-normal'><FormattedMessage id='upgrade.plan.current' /></h6>
                </div>
                <div className='card-body'>
                  <h1 className='card-title pricing-card-title'><FormattedMessage id='upgrade.plan.free.price' /></h1>
                  <ul className='list-unstyled mt-3 mb-4'>
                    <li><FormattedMessage id='upgrade.plan.free.bots' /></li>
                    <li><FormattedMessage id='upgrade.plan.free.chats' /></li>
                  </ul>
                </div>
              </div>
              <div className='card mb-4 shadow-sm'>
                <div className='card-header upgrade-card-header'>
                  <h4 className='my-0 font-weight-normal'><FormattedMessage id='upgrade.plan.startup'/></h4>
                </div>
                <div className='card-body'>
                  <h1 className='card-title pricing-card-title'><FormattedMessage id='upgrade.plan.startup.price'/></h1>
                  <ul className='list-unstyled mt-3 mb-4'>
                    <li><FormattedMessage id='upgrade.plan.startup.bots' /></li>
                    <li><FormattedMessage id='upgrade.plan.startup.chats' /></li>
                  </ul>
                  <label className={`btn btn-primary btn-toggle btn-plan ${this.state.startup ? 'active' : ''}`}>
                              <input type='radio' name='options' value='Startup' defaultChecked={this.state.startup}
                                     onClick={this.selectStartupPlan}/> Select
                            </label>
                </div>
              </div>
              <div className='card mb-4 shadow-sm'>
                <div className='card-header upgrade-card-header'>
                  <h4 className='my-0 font-weight-normal'><FormattedMessage id='upgrade.plan.pro' /></h4>
                </div>
                <div className='card-body'>
                  <h1 className='card-title pricing-card-title'><FormattedMessage id='upgrade.plan.pro.price' /></h1>
                  <ul className='list-unstyled mt-3 mb-4'>
                    <li><FormattedMessage id='upgrade.plan.pro.bots' /></li>
                    <li><FormattedMessage id='upgrade.plan.pro.chats' /></li>
                  </ul>
                  <label className={`btn btn-primary btn-toggle btn-plan ${this.state.pro ? 'active' : ''}`}>
                              <input type='radio' name='options' value='Pro' defaultChecked={this.state.pro}
                                     onClick={this.selectProPlan}/> Select
                            </label>

                </div>
              </div>

              <div className='card mb-4 shadow-sm'>
                <div className='card-header upgrade-card-header'>
                  <h4 className='my-0 font-weight-normal'><FormattedMessage id='upgrade.plan.enterprise' /></h4>
                </div>
                <div className='card-body'>
                  <h1 className='card-title pricing-card-title'>
                    <FormattedMessage id='upgrade.plan.enterprise.price'/></h1>
                  <ul className='list-unstyled mt-3 mb-4'>
                    <li><FormattedMessage id='upgrade.plan.enterprise.bots' /></li>
                    <li><FormattedMessage id='upgrade.plan.enterprise.chats' /></li>
                  </ul>
                  <label className={`btn btn-primary btn-toggle btn-plan ${this.state.enterprise ? 'active' : ''}`}>
                              <input type='radio' name='options' value='Enterprise'
                                     defaultChecked={this.state.enterprise}
                                     onClick={this.selectEnterprisePlan}/>
                    <FormattedMessage id='upgrade.plan.select' />
                  </label>

                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='hubspot'/>
          </div>
        </Modal.Body>
      </Modal>
    </Navbar>
  );
};
