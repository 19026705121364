import React from 'react';
import { ViewFunction } from '../../../../../../../model/component/ViewFunction';
import { BubbleCarouselBase } from './BubbleCarousel.component';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

export const BubbleCarouselView: ViewFunction<BubbleCarouselBase> = function() {
  const settings = {
    infinite: true,
    dots: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
  };

  const handleImageClick = (url: string | undefined) => {
    window.open(url);
  };

  return (
    <>
      <div className='robo-carousel d-flex justify-content-center'>
        <Slider {...settings} className={`slider`}>
          {this.props.options?.map((option, index) => {
            return (
              <div key={index} className={`slick-content`}>
                <button className='slick-button' onClick={() => handleImageClick(option.url)}>
                  <img src={option.thumbnailURL} className='slick-image' alt={option.title} />
                </button>
                <p className='slick-title'>{option.title}</p>
                {option.options &&
                  option.options.length &&
                  option.options.map((obj, id) => {
                    return (
                      <button type='button' style={{ margin: '2px' }} className='robo-button' key={id}>
                        {obj.label}
                      </button>
                    );
                  })}
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default BubbleCarouselView;
