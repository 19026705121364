import { Layout } from '../base/component/Layout/Layout.component';
import { Roles } from '../base/model/route/Role';
import { Route } from '../base/model/route/Route';
import { GroupsList } from './component/list/GroupList.component';
import { EditGroup } from './component/edit/EditGroup.component';
import { GroupInfo } from './component/edit/info/InfoGroup.component';
import { EditGroupStoreProvider } from './component/edit/state/EditGroupStoreProvider';
import { GroupsAgentsList } from './component/edit/operators/GroupAgents.component';
import { GroupChannel } from './component/edit/channels/GroupChannel.component';
import { LogsGroup } from './component/edit/logs/LogsGroup.component';
import { GroupBot } from './component/edit/bot/GroupBot.component';

const roles = Object.keys(Roles);

const routes: Route[] = [
  {
    path: '/groups',
    default: '/groups/list/?page=0',
    routes: [
      {
        path: '/groups/list/',
        component: GroupsList,
        authorize: roles,
        redirectNonAuthorized: '/unauthorized',
        layout: Layout,
      },
      {
        path: '/groups/edit/:groupId',
        component: EditGroup,
        authorize: roles,
        redirectNonAuthorized: '/unauthorized',
        layout: Layout,
        provider: EditGroupStoreProvider,
        default: '/groups/edit/:groupId/info',
        routes: [
          {
            path: '/groups/edit/:groupId/info',
            component: GroupInfo,
          },
          {
            path: '/groups/edit/:groupId/agents',
            component: GroupsAgentsList,
          },
          {
            path: '/groups/edit/:groupId/channels',
            component: GroupChannel,
          },
          {
            path: '/groups/edit/:groupId/logs',
            component: LogsGroup,
          },
          {
            path: '/groups/edit/:groupId/bot',
            component: GroupBot,
          },
        ],
      },
    ],
  },
];

export default routes;
