import React from 'react';
import { withAlert } from 'react-alert';
import {injectIntl} from 'react-intl';
import { withRouter } from 'react-router';
import validator from 'validator';
import { Api, RefreshApi } from '../../../../../../api/Server';
import { SessionContext } from '../../../../../auth/state/Session/SessionContext';
import { LayoutContext } from '../../../../../base/state/Layout/LayoutContext';
import { withContext } from '../../../../../lib/component/withContext/withContext.hoc';
import { AnswerBody } from '../../../../model/AnswerModel';
import { AnswerType } from '../../../../model/AnswerType';
import { AnswerContext } from '../../../../state/AnswerContext';
import { EditAnswerBase } from '../../EditAnswer.component';
import { ImageAnswerView } from './ImageAnswer.view';

interface AnswerImageJson extends AnswerBody {
  title: string;
  url: string;
  thumbnailURL: string;
}

export class ImageAnswerBase extends EditAnswerBase<AnswerImageJson> {

  public render = ImageAnswerView.bind(this);

  public answer: AnswerImageJson = {
    title: '',
    url: '',
    thumbnailURL: '',
    type: AnswerType.IMAGE,
  };

  public componentWillMount(): void {
    if (this.props.answer!.uuid) {

      const answer: AnswerImageJson = {
        title: this.props.answer!.answerContent.title,
        url: this.props.answer!.answerContent.url,
        thumbnailURL: this.props.answer!.answerContent.thumbnailURL,
      };

      this.answer = answer;
    }
    this.setState({
      answer: this.answer,
    });
  }
  public changeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.title = value;

    this.setState({
      answer: this.answer,
    });
  }

  public changeImageUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.url = value;

    this.setState({
      answer: this.answer,
    });
  }

  public changeMediaUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.thumbnailURL = value;

    this.setState({
      answer: this.answer,
    });
  }
  public isAllValid() {
    if (!validator.isURL(this.state.answer!.url,
      { require_tld: false, protocols: ['http', 'https'], require_protocol: true })) {
      return false;
    }
    if (!validator.isURL(this.state.answer!.thumbnailURL,
      { require_tld: false, protocols: ['http', 'https'], require_protocol: true })) {
      return false;
    }
    const saveButtonEnabled = ((this.state.answer!.title !== '') &&
      (this.state.answer!.thumbnailURL !== '') &&
      (this.state.answer!.url !== '') &&
      (this.props.answer!.language !== '') && (this.props.answer!.code !== ''));
    return saveButtonEnabled;
  }
  public getContent(): AnswerImageJson {
    const json: AnswerImageJson = {
      thumbnailURL: this.answer.thumbnailURL,
      url: this.answer.url,
      title: this.answer.title,
    };
    return json;
  }

  public getAnswerTitle(): string {
    return this.state.answer.title;
  }

  public getAnswerImageUrl(): string {
    return this.state.answer.url;
  }

  public getAnswerMediaUrl(): string {
    return this.state.answer.thumbnailURL;
  }

}

const ImageAnswerWithIntl = injectIntl(ImageAnswerBase);
const ImageAnswerWithAlert = withAlert<ImageAnswerBase['props']>()(ImageAnswerWithIntl);
const ImageAnswerWithSession = withContext(SessionContext)(ImageAnswerWithAlert);
const ImageWithContext = withContext(AnswerContext)(ImageAnswerWithSession);
const ImageWithLayout = withContext(LayoutContext)(ImageWithContext);
const ImageAnswerWithApi = RefreshApi(Api(ImageWithLayout));
export const ImageAnswer = withRouter(ImageAnswerWithApi);
