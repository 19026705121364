import React from 'react';
import {AnswerCarouselOption} from '../../../../../../answer/model/AnswerModel';
import BubbleCarouselView from './BubbleCarouselView';

interface Props {
  options?: AnswerCarouselOption[];
}

export class BubbleCarouselBase extends React.Component<Props, {}> {

  public render = BubbleCarouselView.bind(this);

}

export const BubbleCarousel = BubbleCarouselBase;
