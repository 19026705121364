import React from 'react';
import BubbleVideoView from './BubbleVideoView';

interface Props {
  title?: string;
  src?: string;
}

export class BubbleVideoBase extends React.Component<Props, {}> {

  public render = BubbleVideoView.bind(this);

  public getTitle() {
    return this.props.title;
  }

  public getSrc() {
    return this.props.src;
  }

}

export const BubbleVideo = BubbleVideoBase;
