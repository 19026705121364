export enum AnswerType {
  CAROUSEL = 'Carousel',
  CMS = 'CMS',
  COMMAND = 'Command',
  HINTS = 'hints',
  HTML = 'HTML',
  IMAGE = 'Image',
  LINKS = 'Links',
  MULTICHOICE = 'Multichoice',
  SSML = 'ssml',
  TEXT = 'Text',
  VIDEO = 'Video',
  YOUTUBE = 'Youtube',
  AUDIO = 'Audio',
  PROCESSING = 'processing',
}

export enum BotMessageTypes {
  CAROUSEL = 'carousel',
  CMS = 'cms',
  COMMAND = 'command',
  HINTS = 'hints',
  HTML = 'html',
  IMAGE = 'image',
  LINKS = 'links',
  MULTICHOICE = 'multichoice',
  SSML = 'ssml',
  TEXT = 'text',
  VIDEO = 'video',
  YOUTUBE = 'youtube',
  MEDIA = 'media',
  AUDIO = 'audio',
  PROCESSING = 'processing',
}

export const answerTypeCode: { [key: string]: string } = {
  Carousel: 'carousel',
  CMS: 'cms',
  Command: 'command',
  hints: 'hints',
  HTML: 'html',
  Image: 'image',
  Links: 'links',
  Multichoice: 'multichoice',
  ssml: 'ssml',
  Text: 'text',
  Video: 'video',
  Youtube: 'youtube',
  Audio: 'audio',
  processing: 'processing',
  undefined: 'undefined',
};

export const answerTypeFormatToDropdown: { [key: string]: AnswerType } = {
  Carousel: AnswerType.CAROUSEL,
  Cms: AnswerType.CMS,
  Command: AnswerType.COMMAND,
  Hints: AnswerType.HINTS,
  Html: AnswerType.HTML,
  Image: AnswerType.IMAGE,
  Links: AnswerType.LINKS,
  Multichoice: AnswerType.MULTICHOICE,
  Ssml: AnswerType.SSML,
  Text: AnswerType.TEXT,
  Video: AnswerType.VIDEO,
  Youtube: AnswerType.YOUTUBE,
  Audio: AnswerType.AUDIO,
  processing: AnswerType.PROCESSING,
};
