import React, {FormEvent} from 'react';
import { withAlert } from 'react-alert';
import {injectIntl} from 'react-intl';
import { withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../../../api/Server';
import { SessionContext } from '../../../../../auth/state/Session/SessionContext';
import { LayoutContext } from '../../../../../base/state/Layout/LayoutContext';
import { withContext } from '../../../../../lib/component/withContext/withContext.hoc';
import { AnswerBody } from '../../../../model/AnswerModel';
import { AnswerType } from '../../../../model/AnswerType';
import { FormTableState } from '../../../../model/FormTableState';
import { AnswerContext } from '../../../../state/AnswerContext';
import { EditAnswerBase } from '../../EditAnswer.component';
import { MultiChoiceAnswerView } from './MultiChoiceAnswer.view';

interface AnswerMultiChoiceJson extends AnswerBody {
  options: AnswerOption[];
  label?: string;
  value?: string;
}

export interface AnswerOption {
  label: string;
  value: string;
}

export class MultiChoiceAnswerBase extends EditAnswerBase<AnswerMultiChoiceJson> {

  public render = MultiChoiceAnswerView.bind(this);

  public answer: AnswerMultiChoiceJson = {
    options: [],
    label: '',
    value: '',
    type: AnswerType.MULTICHOICE,
  };

  private btnTitle: string = this.formatMessage('add');
  private editAnswerRow: number =  -1;
  private formTableState: FormTableState = FormTableState.CREATEROW;

  public componentWillMount(): void {

    if (this.props.answer!.uuid) {
      const answer: AnswerMultiChoiceJson = {
        options: this.props.answer!.answerContent.options,
        label: '',
        value: '',
      };

      this.answer = answer;

    }
    this.setState({
      answer: this.answer,
    });
  }

  public changeLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.label = value;

    this.setState({
      answer: this.answer,
      parametersKeyClass: (this.state.answer.label === '') ? this.formatMessage('isInvalid') : '',
    });
  }

  public changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.value = value;

    this.setState({
      answer: this.answer,
      parametersValueClass: (this.state.answer.value === '') ? this.formatMessage('isInvalid') : '',
    });
  }

  public isInputValid() {
    return ((this.state.answer!.label !== '') && (this.state.answer!.value !== ''));
  }

  public getContent(): AnswerMultiChoiceJson {
    const json: AnswerMultiChoiceJson = {
      options: this.answer.options,
    };
    return json;
  }

  public getAnswerLabel(): string {
    return this.state.answer.label;
  }

  public getAnswerValue(): string {
    return this.state.answer.value;
  }

  public getMultiChoiceList(): [AnswerOption] {
    return this.state.answer.options;
  }

  public getButtonMultiText(): string {
    return this.btnTitle;
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.answer.options.length === 0) {
      this.setState({
        parametersKeyClass: this.formatMessage('isInvalid'),
        parametersValueClass: this.formatMessage('isInvalid'),
      });
      return false;
    }
    this.handleSave();
  }

  public addOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const areInputFieldsNotValid = ((this.state.answer.label === '') || (this.state.answer.value === ''));
    if (areInputFieldsNotValid) {
      this.setState({
        parametersKeyClass: (this.state.answer.label === '') ? this.formatMessage('isInvalid') : '',
        parametersValueClass:  (this.state.answer.value === '') ? this.formatMessage('isInvalid') : '',
      });
      return;
    }
    const choice: AnswerOption = {
      value: this.state.answer.value,
      label: this.state.answer.label,
    };

    this.btnTitle =  this.formatMessage('add');
    // EDIT
    if (this.formTableState === FormTableState.EDITROW) {
      this.answer.options[this.editAnswerRow!] = choice;
      this.formTableState = FormTableState.CREATEROW;
      this.editAnswerRow = -1;
    } else { // Add
      const newList: AnswerOption[] = [...this.answer.options, choice];
      this.answer.options = newList;
    }

    this.answer.value = '';
    this.answer.label = '';

    this.setState({
      answer: this.answer,
    });
  }

  public handleDeleteMultiRow = (rowNumber: number) => () => {
    this.answer.options.splice(rowNumber, 1 );
    this.setState({
      answer: this.answer,
    });
  }

  public handleEditMultiRow = (rowNumber: number) => () => {
    const row = this.answer.options[rowNumber];
    this.answer.value = row.value;
    this.answer.label = row.label;
    this.btnTitle = this.formatMessage('update');
    this.editAnswerRow = rowNumber;
    this.formTableState = FormTableState.EDITROW;
    this.setState({
      parametersKeyClass: (this.state.answer.label === '') ? this.formatMessage('isInvalid') : '',
      parametersValueClass:  (this.state.answer.value === '') ? this.formatMessage('isInvalid') : '',
      answer: this.answer,
    });
  }

}
const MultiAnswerWithIntl = injectIntl(MultiChoiceAnswerBase);
const MultiAnswerWithAlert = withAlert<MultiChoiceAnswerBase['props']>()(MultiAnswerWithIntl);
const MultiAnswerWithSession = withContext(SessionContext)(MultiAnswerWithAlert);
const MultiWithContext = withContext(AnswerContext)(MultiAnswerWithSession);
const MultiWithLayout = withContext(LayoutContext)(MultiWithContext);
const MultiAnswerWithApi = RefreshApi(Api(MultiWithLayout));
export const MultiAnswer = withRouter(MultiAnswerWithApi);
