import React from 'react';

export const ErrorIcon = () => (
  <svg
    width={24}
    height={24}
    fill='none'
    stroke='#dc3545'
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    style={{ marginRight: 20, minWidth: 24 }}
  >
    <circle cx={12} cy={12} r={10} />
    <line x1={12} y1={8} x2={12} y2={12} />
    <line x1={12} y1={16} x2={12} y2={16} />
  </svg>
);
