export interface LogsModel {
  message: string;
  timestamp: string;
  intents: Intent[];
  entities?: Entity[];
  dialogue_uuid: string;
  nluAnalysis: NluAnalysis;
  detailVisible: boolean;
  channelType: ChannelType;
  answers: Answers[];
  feedbackDialogue: Rating;
  thumbs: ThumbsFeedback;
  feedback: Feedback;
}

interface Feedback {
  expectedAnswer: string;
  questions: string[];
  trustedSource: string;
}

interface ThumbsFeedback {
  thumbs: Thumbs;
  answers: string[];
  message: string;
}

interface Rating {
  comments: string;
  sentimentWeight: number;
}

export interface Answers {
  type: string;
  text: string;
}

export interface Intent {
  identifier: string;
  confidence: number;
}

export interface Entity {
  entity?: string;
  value?: string;
  location?: EntityLocation[];
}

export interface EntityLocation {
  start: number;
  end: number;
}

export interface Sentiment {
  label: string;
  score: number;
  text: string;
}

export interface NluAnalysis {
  sentiment: Sentiment[];
  language: string;
  timestamp: string;
  type: string;
}

export enum SentimentEnum {
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE',
  NEUTRAL = 'NEUTRAL',
}

export enum Thumbs {
  UP = 'THUMBS_UP',
  DOWN = 'THUMBS_DOWN',
}

export interface GetLogsList extends Response {
  content: LogsModel[];
  timestamp: Date;
  size: number;
  page: number;
  pageElements: number;
  totalElements: number;
}

export enum ChannelType {
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
  MESSENGER = 'MESSENGER',
  FACEBOOK = 'FACEBOOK',
  SKYPE = 'SKYPE',
  TELEGRAM = 'TELEGRAM',
  RCS = 'RCS',
  VIBER = 'VIBER',
  TWITTER = 'TWITTER',
  WE_CHAT = 'WE_CHAT',
  PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS',
  INSTAGRAM = 'INSTAGRAM',
  PHONE = 'PHONE',
  API = 'API',
  TEST = 'TEST',
  NONE = 'NONE',
}

export enum StatsFilterUnit {
  YEARS = 'years',
  MONTHS = 'months',
  WEEKS = 'weeks',
  DAYS = 'days',
}

export enum StatsTimeFrame {
  ALL = 'ALL',
  YEAR = 'YEAR',
  TRIMESTER = 'TRIMESTER',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
  CUSTOM = 'CUSTOM',
}
