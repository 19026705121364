import React from 'react';
import BubbleTextView from './BubbleTextView';

interface Props {
  text?: string;
}

export class BubbleTextBase extends React.Component<Props, {}> {

  public render = BubbleTextView.bind(this);

  public getTextMessage() {
    return this.props.text;
  }

}

export const BubbleText = BubbleTextBase;
