import { AssistantParams } from '../state/EditAssistantStateModel';

import { Engines, EngineInputsType, SentimentLanguages } from '../../../model/engines';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Select } from '../../../../form/component/Select.component';
import { StatusService } from './StatusService.component';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { SentimentBase } from './Sentiment.component';

export const SentimentView: ViewFunction<SentimentBase> = function() {
  return (
    <div className='card card-body shadow-sm mobile-margin'>
      <h6>
        <FormattedMessage id='assistant.info.sentiment' />
      </h6>
      <StatusService
        reload={this.props.innerProps.reloadAssistant}
        type={this.getEngine()}
        status={this.getStatus()}
        authorities={this.props.authorities}
        property={AssistantParams.SENTIMENT_STATE}
        idAppend='Sentiment'
      />
      <form onSubmit={this.submit}>
        <div className='form-group'>
          <Select
            className='custom-select engine-select'
            id='engine'
            value={this.getEngine()}
            onChange={this.setEngine}
            disabled={!this.isAdmin()}
          >
            <option value={Engines.IBM.toUpperCase()}>{Engines.IBM}</option>
            {/*<option value={Engines.NATIVE.toUpperCase()}>{Engines.NATIVE}</option>*/}
            <option value={Engines.AWS.toUpperCase()}>{Engines.AWS}</option>
          </Select>
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.APIKEY) }}>
          <label htmlFor='api-key'>
            <FormattedMessage id='assistant.info.engine.apiKey' />
          </label>
          <input
            className='form-control'
            id='api-key'
            placeholder={this.formatMessage('assistant.info.engine.enterapiKey')}
            defaultValue={this.getApiKey()}
            onChange={this.setApiKey}
            disabled={!this.isAdmin()}
          />
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.API_URL) }}>
          <label htmlFor='url'>
            <FormattedMessage id='assistant.info.engine.url' />
          </label>
          <input
            className='form-control'
            id='url'
            placeholder={this.formatMessage('assistant.info.engine.enterurl')}
            defaultValue={this.getUrl()}
            onChange={this.setUrl}
            type='url'
            disabled={!this.isAdmin()}
          />
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.AWS_URL) }}>
          <label htmlFor='aws-url'>
            <FormattedMessage id='assistant.info.engine.url' />
          </label>
          <input
            className='form-control'
            id='url-awl'
            placeholder={this.formatMessage('assistant.info.engine.enterurl')}
            defaultValue={this.getAwsUrl()}
            onChange={this.setAwsUrl}
            type='url'
            disabled={!this.isAdmin()}
          />
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.ACCESS_KEY_ID) }}>
          <label htmlFor='accessKeyId'>
            <FormattedMessage id='assistant.info.engine.accesskeyid' />
          </label>
          <input
            className='form-control'
            id='access-key-id'
            placeholder={this.formatMessage('assistant.info.engine.enteraccesskeyid')}
            defaultValue={this.getAccessKeyId()}
            onChange={this.setAccessKeyId}
            disabled={!this.isAdmin()}
          />
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.SECRET_ACCESS_KEY) }}>
          <label htmlFor='secretAccessKey'>
            <FormattedMessage id='assistant.info.engine.secretaaccesskey' />
          </label>
          <input
            className='form-control'
            id='secret-access-key'
            placeholder={this.formatMessage('assistant.info.engine.entersecretaaccesskey')}
            defaultValue={this.getSecretAccessKey()}
            onChange={this.setSecretAccessKey}
            disabled={!this.isAdmin()}
          />
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.SENTIMENT_LANGUAGE) }}>
          <label htmlFor='api-key'>
            <FormattedMessage id='assistant.info.sentimentlanguage' />
          </label>
          <Select
            className='custom-select engine-select'
            id='engine'
            value={this.getLanguage()}
            onChange={this.setLanguage}
            disabled={!this.isAdmin()}
          >
            <option value={SentimentLanguages.EN}>{this.formatMessage('assistant.info.sentimentlanguage.en')}</option>
            <option value={SentimentLanguages.DE}>{this.formatMessage('assistant.info.sentimentlanguage.de')}</option>
          </Select>
        </div>
        {this.isAdmin() &&
          <div className='text-right'>
            <button id='saveSentiment' type='submit' className='btn btn-secondary'>
              <FormattedMessage id='save' />
            </button>
          </div>
        }
      </form>
    </div>
  );
};
