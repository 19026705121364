import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { Input } from '../../../../form/component/Input.component';
import { notEmpty } from '../../../../form/lib/rules';
import { SendEventBase, KeyValue, Events } from './SendEvent.component';
import { ColumnObj, Table } from '../../../../base/component/Table/Table.component';

export const SendEventView: ViewFunction<SendEventBase> = function() {
  const columns: ColumnObj[] = [
    {
      title: 'name',
      propertyName: 'label',
      render: (item: Events) => <span>{item.name}</span>,
    },
    {
      title: 'params',
      propertyName: 'params',
      style: { width: '200px' },
      render: (item: Events) => <span>{JSON.stringify(item.params)}</span>,
    },
  ];

  return (
    <Modal show={this.isModalVisible()} onHide={() => this.props.handleClose(false)} className='shadow-sm'>
      <Modal.Header className='' closeButton>
        <Modal.Title as='h5' id={'sendEventModalWindow'}>
          <FormattedMessage id='sendEvent.SendEvent' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!this.state.isAddEventOpen && (
          <Table
            tableId={'Events'}
            data={this.getData()}
            columns={columns}
            page={this.getPage()}
            dropDownMenuItems={this.dropDownMenuItems}
          />
        )}
        {this.state.isAddEventOpen && (
          <React.Fragment>
            <div className='form-group'>
              <label htmlFor='name'>
                <FormattedMessage id='sendEvent.EventName' />
              </label>
              <Input
                type='text'
                className={'form-control'}
                id='name'
                placeholder='name'
                value={this.state.name}
                onChange={(e) => this.handleNameChange(e)}
                rules={[notEmpty()]}
              />
            </div>
            <label htmlFor='apiKey'>
              <FormattedMessage id='sendEvent.Parameters' />
            </label>
            <div className='container'>
              <div className='row'>
                <div className='col form-group pl-0'>
                  <div className='form-group'>
                    <label htmlFor='key'>
                      <FormattedMessage id='sendEvent.Key' />
                    </label>
                    <Input
                      type='text'
                      className={'form-control'}
                      id='key'
                      placeholder='key'
                      value={this.state.key}
                      onChange={(e) => this.handleKeyChange(e)}
                    />
                  </div>
                </div>
                <div className='col form-group'>
                  <div className='form-group'>
                    <label htmlFor='value'>
                      <FormattedMessage id='sendEvent.Value' />
                    </label>
                    <Input
                      type='text'
                      className={'form-control'}
                      id='value'
                      placeholder='value'
                      value={this.state.value}
                      onChange={(e) => this.handleValueChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button type='button' className='btn btn-danger send-event' id={'addEvent'} onClick={this.handleAdd}>
              Add
            </button>
            <table className='table mt-2 event-table'>
              <thead>
                <tr className=''>
                  <th scope='col'>
                    <FormattedMessage id='sendEvent.Key' />
                  </th>
                  <th scope='col'>
                    <FormattedMessage id='sendEvent.Value' />
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.params.length > 0 &&
                  (this.state.params as KeyValue[]).map((item: KeyValue, index: number) => {
                    return (
                      <tr key={index}>
                        <th scope='row'> {item.key} </th>
                        <td>{item.value}</td>
                        <td onClick={() => this.delete(item)}>
                          <button className='delete-param' id={'deleteEvent'}>x</button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>{' '}
          </React.Fragment>
        )}
      </Modal.Body>
      <div className='modal-footer'>
        <button
          id='cancel'
          type='button'
          className='btn btn-dark'
          data-dismiss='modal'
          onClick={() => this.props.handleClose(false)}
        >
          {this.formatMessage('cancel')}
        </button>
        {this.state.isAddEventOpen ? (
          <button id='saveEvent' onClick={this.save} className='btn btn-primary'>
            {this.formatMessage('save')}
          </button>
        ) : (
          <button id='add' onClick={this.openaddnewEvent} className='btn btn-primary'>
            {this.formatMessage('add')}
          </button>
        )}
      </div>
    </Modal>
  );
};
