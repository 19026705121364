import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { Select } from '../../../../form/component/Select.component';
import { CharacterVoice } from '../state/EditAssistantStateModel';
import { PhoneProviderChannelBase } from './PhoneProviderChannel.component';

export const PhoneProviderChannelView: ViewFunction<PhoneProviderChannelBase> = function() {
  return (
    <React.Fragment>
      <div className='form-group'>
        <div className='input-group'>
          <input type='text' readOnly className='form-control' id={this.getUuid()} placeholder={this.buildUrl()} />
          <div className='input-group-append'>
            <CopyToClipboard text={this.buildUrl()}>
              <button className='btn btn-secondary' type='button' id={'copyPhoneUrl'}>
                {this.formatMessage('channels.copy')}
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
      <form onSubmit={this.submit}>
        <div className='form-group'>
          <label htmlFor='accountSID'>{this.formatMessage('channel.voice')}</label>
          <Select
            className='custom-select engine-select'
            id='phone-voice-character'
            value={this.getCharacter()}
            onChange={this.setCharacter}
            disabled={!this.isAdmin()}
          >
              {this.getCharacters().map((option: CharacterVoice) => (
              <option key={option.value} id={option.value} value={option.value}>
                {option.friendlyName}
              </option>
            ))}
          </Select>
        </div>
        <div className='form-group'>
          <label>{this.formatMessage('channel.voice.language')}</label>
          <input
              type='text'
              readOnly
              className='form-control'
              id='selected-voice-language'
              value={this.getLanguage()}
          />
        </div>
        {this.isAdmin() &&
          (
            <div className='text-right'>
            <button className='btn btn-secondary' type='submit' id={'savePhoneChannel'}>
              {this.formatMessage('channels.save')}
            </button>
          </div>
          )
        }
      </form>
    </React.Fragment>
  );
};
