import React from 'react';
import { Link } from 'react-router-dom';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { PageSize } from '../../../../base/component/PageSize/PageSize.component';
import { Pagination } from '../../../../base/component/Pagination/Pagination.component';
import { ColumnObj, Table } from '../../../../base/component/Table/Table.component';
import { GroupAgent } from '../../../model/GroupModel';
import { GroupAgentsBase } from './GroupAgents.component';

export const GroupAgentsView: ViewFunction<GroupAgentsBase> = function() {

  const columns: ColumnObj[] = [
    {
      title: `${this.formatMessage('name')}`,
      propertyName: 'name',
      render: (item: GroupAgent) => (
        <Link data-action='edit-answer' to={`/groups/edit/${item.uuid}`}>
          {item.name}
        </Link>
      ),
    },
    {
      title: `${this.formatMessage('email')}`,
      propertyName: 'email',
      render: (item: GroupAgent) => (<span>{item.email}</span>),
    },
  ];

  return (
    <React.Fragment>
      <div className='card shadow-sm'>
        <div className='card-body'>
          <div className='row'/>
          <Table
            tableId={'groups'}
            data={this.state.data}
            columns={columns}
            page={this.getCurrentPage()}
          />
          {/*<!-- end table responsive  -->*/}
          <div className='row mt-3'>
            <div className='col-sm-6 mb-1 display-view'>
              <PageSize changePageSize={this.changePageSize} size={this.getCurrentPageSize()} />
            </div>
            <div className='col-sm-6'>
              <Pagination
                totalPages={this.state.totalPages}
                changePaginationHandler={this.changePaginationHandler}
                page={this.getCurrentPage()}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
