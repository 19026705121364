import { FormEvent } from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Api, RefreshApi } from '../../../../../api/Server';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../model/http/Request';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { Navigation } from '../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { EditAssistantContext } from '../state/EditAssistantContext';
import { ChannelParameter, ParamType, AccountSIDName, AuthTokeName, PhoneNumberName } from './model/UpdateParams';
import { ChannelModel, ChannelStateModel } from './state/ChannelStateModel';
import { SmsProviderChannelView } from './SmsAPIProviderChannel.view';

interface IntlProps {
  intl: IntlShape;
}

interface FacebookAPIResponse extends Response {
  timestamp: Date;
}

interface FacebookAPIState extends ApiState {
  accountSID: string;
  authToken: string;
  phoneNumber: string;
  item: ChannelModel | null;
}

interface Payload {
  parameters: ChannelParameter[];
}

type Props = IntlProps &
  ApiProps<any> &
  SessionStateModel &
  ChannelStateModel &
  LayoutStateModel &
  RouteComponentProps<{ botId: string }> &
  RouteComponentProps<Navigation> &
  WithAlertProps;

const requestId = 'update-cm-whatsap-channel';
export class SmsProviderChannelBase extends ApiBase<FacebookAPIResponse, Props & ChannelModel, FacebookAPIState> {
  public render = SmsProviderChannelView.bind(this);

  constructor(props: Props & ChannelModel) {
    super(props);

    this.state = {
      item: null,
      accountSID: props.accountSID !== null && props.accountSID !== undefined ? props.accountSID.toString() : '',
      authToken: props.authToken !== null && props.authToken !== undefined ? props.authToken.toString() : '',
      phoneNumber: props.phoneNumber !== null && props.phoneNumber !== undefined ? props.phoneNumber.toString() : '',
      appError: null,
      error: null,
      authError: null,
    };
  }

  public getAccountSID(): string {
    return this.state.accountSID !== undefined ? this.state.accountSID : '';
  }

  public getAuthToken(): string {
    return this.state.authToken !== undefined ? this.state.authToken : '';
  }

  public getPhoneNumber(): string {
    return this.state.phoneNumber !== undefined ? this.state.phoneNumber : '';
  }

  public setAccountSID = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const value = target.value;

    this.setState({
      accountSID: value,
    });
  }

  public setAuthToken = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const value = target.value;

    this.setState({
      authToken: value,
    });
  }

  public setPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const value = target.value;

    this.setState({
      phoneNumber: value,
    });
  }

  public submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const pageAccountSIDParam: ChannelParameter = {
      name: AccountSIDName,
      type: ParamType.TEXT,
      value: this.getAccountSID(),
    };

    const authTokenParam: ChannelParameter = {
      name: AuthTokeName,
      type: ParamType.TEXT,
      value: this.getAuthToken(),
    };

    const phoneNumberParam: ChannelParameter = {
      name: PhoneNumberName,
      type: ParamType.TEXT,
      value: this.getPhoneNumber(),
    };

    const parameters: ChannelParameter[] = [pageAccountSIDParam, authTokenParam, phoneNumberParam];

    const payload: Payload = {
      parameters,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/assistants/channel/${this.props.uuid}/assistant/${this.props.currentAssistant}/update`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: requestId,
    };
    this.doCall(request);
  }

  public formatMessage(id: string): string {
    return this.props.intl.formatMessage({ id });
  }

  public buildUrl(): string {
    return `${process.env.REACT_APP_STUDIO_URL}/external/channels/${this.getUuid()}?X-API-Key=${
      this.props.selectedApiKey
    }`;
  }

  public getUuid(): string {
    return this.props.uuid;
  }

  public setErrorResponseData() {
    const callId = this.props.callId;
    if (callId === requestId) {
      this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
    }
  }

  public setResponseData() {
    const callId = this.props.callId;
    if (callId === requestId) {
      this.props.alert.success(this.formatMessage('alert.successfullyCreated'));
    }
  }

  public isAdmin(): boolean {
    return this.props.isAdmin;
  }
}

const SmsProviderChannelIntl = injectIntl(SmsProviderChannelBase);
const SmsProviderChannelWithAlert = withAlert<SmsProviderChannelBase['props']>()(SmsProviderChannelIntl);
const SmsProviderChannelWithSession = withContext(SessionContext)(SmsProviderChannelWithAlert);
const SmsProviderChannelWithContext = withContext(EditAssistantContext)(SmsProviderChannelWithSession);
const SmsProviderChannelWithApi = RefreshApi(Api(SmsProviderChannelWithContext));
export const SmsProviderChannel = withRouter(SmsProviderChannelWithApi);
