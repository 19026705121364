import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Api, RefreshApi } from '../../../../../api/Server';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../model/http/Request';
import { Response } from '../../../../../model/http/Response';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps } from '../../../../base/ApiComponent';
import { Navigation } from '../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { Status } from '../../../model/Status';
import { AssistantModel, EditAssistantStateModel } from '../state/EditAssistantStateModel';
import { StatusView } from './Status.view';

interface ResponseUpdate extends Response {
  content: AssistantModel;
  timestamp: Date;
}

interface IntlProps {
  intl: IntlShape;
}

type Props = IntlProps &
  ApiProps<ResponseUpdate> &
  SessionStateModel &
  EditAssistantStateModel &
  RouteComponentProps<Navigation> &
  WithAlertProps;

export class AssistantStatusBase extends ApiBase<ResponseUpdate, Props, never> {
  public render = StatusView.bind(this);

  public setToggleClass() {
    const bot = this.props.currentAssistant;
    const statusToChange = !this.props.isToggleActive() ? Status.ENABLED : Status.DISABLED;

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload: null,
      relativePath: `/admin/assistants/${bot}/status/${statusToChange}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'status',
    };

    this.doCall(request);
  }

  public confirmCallback = () => {
    this.setToggleClass();
  }
  public getToggleClass(): string {
    if (this.props.isToggleActive()) {
      return 'fas fa-toggle-on fa-2x on text-success';
    }
    return 'fas fa-toggle-off fa-2x off text-secondary';
  }

  public getToggle(item: AssistantModel | null): string {
    if (item && item.status === Status.ENABLED) {
      return 'fas fa-toggle-on fa-2x on text-success';
    }
    return 'fas fa-toggle-off fa-2x off text-secondary';
  }

  public getToggleTitle(): string {
    if (this.props.isToggleActive()) {
      return this.formatMessage('enabled');
    }
    return this.formatMessage('disabled');
  }

  public setResponseData() {
    if (this.props.callId === 'status') {
      // tslint:disable-next-line:max-line-length
      const { uuid, name, description, status, maxIdleTime, studioConfidence, maxData, params, displayName, type, language, trainingStatus } = this.props.response.content;
      if (uuid === undefined) {
        return; // another call, nothing to do. Good calls allways have uuid for assistant
      }
      const assistantData: AssistantModel = {
        uuid,
        name,
        displayName,
        description,
        status,
        maxIdleTime,
        studioConfidence,
        maxData,
        params,
        type,
        language,
        trainingStatus,
      };
      this.props.setAssistantInfo(assistantData);
      this.props.setToggleState(status === Status.ENABLED);
    }
  }

  public formatMessage(id: string, values: Record<string, any> = {}) {
    return this.props.intl.formatMessage({ id }, values);
  }

  public isAdmin(): boolean {
    return super.isAdmin(this.props.authorities);
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }
}

export const AssistantStatusWithIntl = injectIntl(AssistantStatusBase);
export const AssistantStatus = RefreshApi(Api(AssistantStatusWithIntl));
