export enum Languages {
  ENGLISH = 'English',
  GERMAN = 'German',
  FRENCH = 'French',
  ITALIAN = 'Italian',
  SPANISH = 'Spanish',
  PORTUGUESE = 'Portuguese',
}

export const languagesCode: { [key: string]: string } = {
  English: 'en',
  German: 'de',
  French: 'fr',
  Italian: 'it',
  Spanish: 'es',
  Portuguese: 'pt',
};

export const answerLangFormatToDropdown: { [key: string]: Languages } = {
  en: Languages.ENGLISH,
  de: Languages.GERMAN,
  fr: Languages.FRENCH,
  it: Languages.ITALIAN,
  es: Languages.SPANISH,
  pt: Languages.PORTUGUESE,
};
