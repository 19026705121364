import React from 'react';
import { useIntl } from 'react-intl';
import logo from '../../../../assets/img/logo-vivid-red_png@1x.png';

export const ResetPasswordTokenExpired = () => {
  const intl = useIntl();

  return (
    <React.Fragment>

      <div className='card shadow-sm' style={{ marginTop: '100px' }}>
        <div className='card-body'>
          <div className='text-center mb-3'>
            <img className='logo-login' src={logo} alt='Two Impulse' />
            <h5>{intl.formatMessage({ id: 'conversationalai.message' })}</h5>
          </div>
          <div className='text-center'>
            <p>{intl.formatMessage({id: 'login.tokenInvalid'})}</p>
          </div>
          <div className='row'>
            <div className='col-12 text-center'>
              <a id='login' className='btn btn-primary' href='/auth/login'>
                {intl.formatMessage({ id: 'login' })}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='text-center'>
        <small> {intl.formatMessage({ id: 'roboai.date' })}</small>
      </div>
    </React.Fragment>
  );
};

export default ResetPasswordTokenExpired;
