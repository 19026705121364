import React from 'react';
import { Link } from 'react-router-dom';
import { ViewFunction } from '../../../model/component/ViewFunction';
import { Form } from '../../form/component/Form.component';
import { Input } from '../../form/component/Input.component';
import LoadingBar from './../../base/component/loadingBar/loadingBar';
import { ConfirmPassword, lengthBetween, passwordValidity } from './../../form/lib/rules';
import { RegistrationBase } from './Registration.component';
import logo from '../../../assets/img/logo-vivid-red_png@1x.png';

export const RegistrationView: ViewFunction<RegistrationBase> = function() {
  return (
    <React.Fragment>
      <LoadingBar
        height={4}
        color={'#ff3366'}
        onRef={this.setRef}
      />
      <Form onSubmit={this.onSubmit}>{() => (
        <React.Fragment>
          <div className='text-center'>
            <img className='logo-login' src={logo}  alt='Two Impulse'/>
            <h5>{this.formatMessage('conversationalai.message')}</h5>
            <p>{this.formatMessage('registration.confirm')}</p>
          </div>
          <div
            className={'alert ' + (this.state.error ? 'alert-danger' : 'alert-success')}
            style={{display:  (this.state.showMessage ? '' : 'none')}}
          >
              {this.state.message}
          </div>

          <div className='card shadow-sm'>
            <div className='card-body'>
              <div className='form-group'>
                <label>{this.formatMessage('registration.username')}</label>
                <Input
                  id='registrationName'
                  type='text'
                  className='form-control'
                  placeholder={this.formatMessage('registration.enterUsername')}
                  value={this.state.username}
                  onChange={this.changeUsername}
                  rules={[lengthBetween(5, 100, `Name must have between ${5} and ${100} characters`)]}
                />
              </div>
              <div className='form-group'>
                <label>{this.formatMessage('registration.password')}</label>
                <Input
                  id='registrationPassword'
                  type='password'
                  className='form-control'
                  placeholder={this.formatMessage('registration.enterPassword')}
                  value={this.state.password}
                  onChange={this.changePassword}
                  rules={[passwordValidity()]}
                />
              </div>
              <div className='form-group'>
                <label>{this.formatMessage('registration.confirmPassword')}</label>
                <Input
                  id='ConfirmPassword'
                  type='password'
                  className='form-control'
                  placeholder={this.formatMessage('registration.confirmPassword')}
                  value={this.state.confirmPassword}
                  onChange={this.changeConfirmPassword}
                  rules={[ConfirmPassword(this.state.password)]}
                />
              </div>
              <div className='row'>
                <div className='col-12 text-center mt-2'>
                  <button
                    type='submit'
                    id='login'
                    className='btn btn-primary'
                    disabled={!this.state.showRegistration}
                  >
                    {this.formatMessage('register')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='text-center'>
            {/* tslint:disable-next-line:max-line-length */}
            <small>{this.formatMessage('registration.already.have')} <Link className='links' to='/auth/login'>{this.formatMessage('login')}</Link></small>
          </div>
        </React.Fragment>
      )}
      </Form>
    </React.Fragment>
  );
};

export default RegistrationView;
