import { FormEvent } from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteChildrenProps } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Api, AuthApi } from '../../../../api/Server';
import { AppError, AppErrorType } from '../../../../model/AppError';
import { AsyncState } from '../../../../model/AsyncState';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { WithPaginationRoutingProps } from '../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { SessionContext } from '../../state/Session/SessionContext';
import { Build, SessionStateModel, Organization } from '../../state/Session/SessionStateModel';
import { Request } from './../../../../model/http/Request';
import { ResetPasswordFormView } from './ResetPasswordForm.view';

interface ComponentProps {
  authenticate: (username: string, password: string) => void;
  call: (Request: Request<{}>, newToken?: string, acceptAudio?: boolean) => void;
  authError: AppError;
  authState: AsyncState;
  authToken: string;
  authRefreshToken: string;
  authExpiresIn: Date;
  authAuthorities: string[];
  response: { build: Build };
  statusCode: number;
  callId: string;
  state: AsyncState;
  intl: IntlShape;
}

interface State extends ApiState {
  email: string;
  error: AppErrorType | null;
  build: Build | null;
}

interface LoginResponse extends Response {
  timestamp: Date;
}

interface OrganizationResponse extends Response {
  content: Organization;
  timestamp: Date;
}

interface ResetResponse extends Response {
  content: string;
  timestamp: Date;
}

interface IntlProps {
  intl: IntlShape;
}

type Props = IntlProps &
  ComponentProps &
  SessionStateModel &
  RouteChildrenProps &
  ApiProps<LoginResponse & OrganizationResponse & ResetResponse> &
  LayoutStateModel &
  RouteComponentProps<Navigation> &
  WithAlertProps &
  WithPaginationRoutingProps;

interface EmailPayload {
  email: string;
}

export class ResetPasswordFormBase extends ApiBase<LoginResponse & OrganizationResponse & ResetResponse, Props, State> {
  public state: State = {
    email: '',
    error: null,
    build: null,
    authError: null,
  };

  public render = ResetPasswordFormView.bind(this);

  private _loadingBar: any = null;

  constructor(props: Props) {
    super(props);
    this._loadingBar = null;
  }

public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.state !== this.props.state) {
      this.setResponseData();
      this._loadingBar.complete();
    }
  }

  public changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      email: value,
    });
  }

  public chooseSubmit = (e: FormEvent<HTMLFormElement>) => {
    this.onSubmitEmail(e);
  }

  public onSubmitEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.resetPassword();
  }

  public resetPassword = async () => {
    const payload: EmailPayload = { email: this.state.email };
    const request: Request<{}> = {
      method: HttpRequestType.POST,
      payload,
      relativePath: `/password/reset`,
      token: this.props.authToken,
      refreshToken: this.props.authRefreshToken,
    };
    this._loadingBar.continuousStart();
    await this.props.call(request);
  }

  public hasError(): boolean {
    return this.state.error !== null;
  }

  public setRef = (ref: any) => {
    this._loadingBar = ref;
  }

  public setResponseData() {
    this._loadingBar.complete();
    this.props.history.push('/auth/resetPasswordSuccess/password.sendSuccess');
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  protected setErrorResponseData() {
    this._loadingBar.complete();
  }

}
const ResetPasswordWithIntl = injectIntl(ResetPasswordFormBase);
const ResetPasswordWithIntlWithAlert = withAlert<ResetPasswordFormBase['props']>()(ResetPasswordWithIntl);
const ResetPasswordFormWithContext = withContext(SessionContext)(ResetPasswordWithIntlWithAlert);
const ResetPasswordFormCall = Api(ResetPasswordFormWithContext);
export const ResetPasswordForm = AuthApi(ResetPasswordFormCall);
