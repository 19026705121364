import React from 'react';
import {FormattedMessage} from 'react-intl';
import { ViewFunction } from '../../../../../../model/component/ViewFunction';
import { utcDateFormat } from '../../../../../../util/dateFormat';
import { SentimentEnum } from '../../../../../common/model/logs/LogsModel';
import { LogsGroupDetailBase } from './LogsGroupDetail.component';
import { LogsGroupChannel } from '../channel/LogsGroupChannel.component';

export const LogsGroupDetailView: ViewFunction<LogsGroupDetailBase> = function() {
    let sentimentClass = 'far fs-15 ';

    switch (this.getSentiment()) {
        case SentimentEnum.POSITIVE: sentimentClass += 'fa-smile';
                                     break;
        case SentimentEnum.NEGATIVE: sentimentClass += 'fa-frown';
                                     break;
        default: sentimentClass += 'fa-meh';
                 break;
    }

    return (
        <div className='col-lg-12 col-sm-12'>
            <div className='row'>

                <div className='col-4 text-center card conversation-summary-kpi'>
                    <div><LogsGroupChannel channelType={this.getChannelType()} /></div>
                    <div>{this.getChannelType()}</div>
                </div>
                <div className='col-4 text-center card conversation-summary-kpi'/>
                <div className='col-4 text-center card conversation-summary-kpi'/>
                <div className='col-4 text-center card conversation-summary-kpi'>
                    <div><i className='far fs-15 fa-clock text-center'/></div>
                    <div><b><FormattedMessage id={'logsGroupDetailBase.started'}/></b></div>
                    <div> {utcDateFormat(this.getStarted())}</div>

                </div>

                <div className='col-4 text-center card conversation-summary-kpi'>
                    <div><i className='far fs-15 fa-clock text-center'/></div>
                    <div><b><FormattedMessage id={'logsGroupDetailBase.ended'}/></b></div>
                    <div>{utcDateFormat(this.getEnded())}</div>

                </div>

                <div className='col-4 text-center card conversation-summary-kpi'>
                    <div><i className='far fs-15 fa-clock text-center'/></div>
                    <div><b><FormattedMessage id={'logsGroupDetailBase.duration'}/></b></div>
                    <div>{this.getDuration()} seconds</div>

                </div>

                <div className='col-4 text-center card conversation-summary-kpi'>
                    <div><i className='far fs-15 fa-comments text-center'/></div>
                    <div><b><FormattedMessage id={'logsGroupDetailBase.messages'}/></b></div>
                    <div>{this.getMessages()}</div>

                </div>

                <div className='col-4 text-center card conversation-summary-kpi'>
                <div><i className='far fs-15 fa-thumbs-up text-center'/></div>
                <div><b><FormattedMessage id={'logsGroupDetailBase.confidence'}/></b></div>
                <div>{this.getConfidence()}%</div>

                </div>

                <div className='col-4 text-center card conversation-summary-kpi'>
                    <div><b><FormattedMessage id={'logsGroupDetailBase.sentiment'}/></b></div>
                    <div><i className={sentimentClass}/></div>
                </div>

            </div>

        </div>

    );
};
