import { Bubble, Message } from '../test/model/Message';
import {Status, TrainingStatus} from '../../../model/Status';

export enum AssistantParams {
  MAX_IDLE_TIME = 'max_idle_time',
  MAX_DATA_TIME = 'max_data_time',
  CHARACTER = 'text_to_speech_voice',
  TTS_LANGUAGE = 'text_to_speech_language',
  MODEL = 'speech_to_text_model',
  STT_LANGUAGE = 'speech_to_text_language',
  ENABLE_DISABLE_TTS = 'text_to_speech_enabled',
  ENABLE_DISABLE_STT = 'speech_to_text_enabled',
  SENTIMENT_STATE = 'nlu_enabled',
  SENTIMENT_LANGUAGE = 'nlu_language',
  TYPE = 'type',
  STUDIO_LANGUAGE = 'studio_language',
  DATA_EXPIRATION_DAYS = 'data_expiration_days',
  STUDIO_CONFIDENCE = 'studio_confidence_threshold',
}

export interface CharacterVoice {
  value: string;
  friendlyName: string;
}

export interface Language {
  value: string;
  friendlyName: string;
}

export interface SttSelectable {
  value: string;
  friendlyName: string;
}

export interface ParamValue {
  name: string;
  value: string;
  mandatory: boolean;
  defaultValue: string;
  valueClass: string;
  created: Date;
  updated: Date;
}

export enum Type {
  SDK = 'SDK',
  STUDIO = 'STUDIO',
}

export enum Languages {
  en = 'en',
  de = 'de',
  // es = 'es',
  pt = 'pt',
}

export interface AssistantModel {
  uuid: string;
  name: string;
  description: string;
  displayName: string;
  status: Status;
  maxIdleTime: string;
  studioConfidence: string;
  maxData: string;
  params: ParamValue[];
  language: Languages;
  type: Type;
  trainingStatus: TrainingStatus;
}

export interface FallbackModel {
  delayedFallbackType: string;
  delayedFallbackCount: number;
  handoffGroup: string | null;
  handoffMessage: string;
  simpleMessage: string;
  standardMessage: string;
}

export interface EditAssistantStateModel {
  isToggleActive: () => boolean;
  setToggleState: (state: boolean) => void;
  setAssistantInfo: (assistantData: AssistantModel) => void;
  setConfirmVisibility: (show: boolean) => void;
  confirm: () => void;
  setBubbles: (bubbles: Bubble[]) => void;
  setRecordingTime: (time: string) => void;
  toggleRecordState: () => void;
  closeKeyboardSection: () => void;
  openKeyboardSection: () => void;
  setUserAudio: (userAudio: MediaStream) => void;
  setIsForCancelAudio: (isForCancelAudio: boolean) => void;
  audioPlayerPlay: (audioPlayer: HTMLAudioElement) => void;
  toggleAutoPlayState: () => void;
  playAudio: (message: Message, index?: number) => void;
  changeAudioState: (message: Message, isForPlaying: boolean) => void;
  setAllMessagesToPlayed: () => void;
  hasProcessingMessage: () => boolean;
  currentAssistant: string | null;
  assistantData: AssistantModel | null;
  showConfirmDialog: boolean;
  currentItem: AssistantModel | null;
  statusToChange: string;
  bubbles: Bubble[];
  isKeyboardOpen: boolean;
  isRecording: boolean;
  isForCancelAudio: boolean;
  isAutoPlayOn: boolean;
  time: string;
  userAudio: MediaStream | undefined;
}
