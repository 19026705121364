import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { SessionContext } from '../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { Roles } from '../../model/route/Role';
import { LayoutContext } from '../../state/Layout/LayoutContext';
import { LayoutStateModel } from './../../state/Layout/LayoutStateModel';
import { MenuView } from './Menu.view';

interface State {
  isAccountMenuOpened: boolean;
}

interface ComponentProps {
  visible: boolean;
}

type Props = LayoutStateModel & SessionStateModel & RouteComponentProps<{}> & ComponentProps;

export class MenuBase extends React.Component<Props, State> {
  public state: State = {
    isAccountMenuOpened: false,
  };

  public render = MenuView.bind(this);

  constructor(props: Props) {
    super(props);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleBodyClick = this.handleBodyClick.bind(this);
  }

  public componentDidMount() {
    document.body.addEventListener('click', this.handleBodyClick);
    this.setState({
      isAccountMenuOpened: false,
    });
  }
  public componentWillUnmount() {
    document.body.removeEventListener('click', this.handleBodyClick);
  }

  public handleBodyClick = (e: MouseEvent) => {
    const domNode = this.context.node;

    if (!domNode || !domNode.contains(e.target)) {
      this.setState({
        isAccountMenuOpened: false,
      });
    }
  }

  public haveAdminFunctions = () => {
    return this.props.authorities.includes(Roles.admin);
  }

  public handleMenuClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.setState((previousState) => ({
      isAccountMenuOpened: !previousState.isAccountMenuOpened,
    }));
  }

  public classAccountMenu = () => {
    return this.state.isAccountMenuOpened ? 'show' : '';
  }

  public logout = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    this.props.clearSession();
    this.props.setSidebarLinkVisibility(true);
    this.props.history.push('/auth/login');
  }
}

export const MenuWithRouter = withRouter(MenuBase);
export const MenuSession = withContext(SessionContext)(MenuWithRouter);
export const Menu = withContext(LayoutContext)<ComponentProps>(MenuSession);
