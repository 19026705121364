import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import LoadingBar from '../../../base/component/loadingBar/loadingBar';
import { ResetPasswordBase } from './ResetPassword.component';
import {ConfirmPassword, passwordValidity} from '../../../form/lib/rules';
import {Input} from '../../../form/component/Input.component';
import {Form} from '../../../form/component/Form.component';
import logo from '../../../../assets/img/logo-vivid-red_png@1x.png';

export const ResetPasswordView: ViewFunction<ResetPasswordBase> = function() {
  return (
    <React.Fragment>

      <LoadingBar height={4} className='loading loading-login' color={'#ff3366'} onRef={this.setRef} />
      <div className='card shadow-sm' style={{ marginTop: '100px' }}>
        <div className='card-body'>
          <div className='text-center mb-3'>
            <img className='logo-login' src={logo} alt='Two Impulse' />
            <h5>{this.formatMessage('conversationalai.message')}</h5>
          </div>
          <div className='text-center'>
            <Form onSubmit={this.onSubmit}>
              {(isValid: boolean) => (
                <React.Fragment>
                  <div className='form-group'>
                    <Input
                      id='password'
                      type='password'
                      className='form-control'
                      placeholder={this.formatMessage( 'login.enterPassword' )}
                      value={this.state.password}
                      onChange={this.changePassword}
                      rules={[passwordValidity()]}
                    />
                  </div>
                  <div className='form-group'>
                    <Input
                      id='confirmPassword'
                      type='password'
                      className='form-control'
                      placeholder={this.formatMessage('login.confirmPassword')}
                      value={this.state.confirmPassword}
                      onChange={this.changeConfirmPassword}
                      rules={[ConfirmPassword(this.state.password)]}
                    />
                  </div>
                  <div className='row'>
                    <div className='col-12 text-center'>
                      <button
                        type='submit'
                        id='login'
                        className='btn btn-primary'
                        disabled={!isValid}
                      >
                        {this.formatMessage( 'password.reset')}
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </Form>
          </div>
        </div>
      </div>
      <div className='text-center'>
        <small> {this.formatMessage( 'roboai.date' )}</small>
      </div>
    </React.Fragment>
  );
};

export default ResetPasswordView;
