import { Component } from 'react';
import { EditorState, ContentState } from 'draft-js';
import { AddWelcomeView } from './AddWelcome.view';
import { Message } from './WelcomeMessages.component';
import htmlToDraft from 'html-to-draftjs';

interface State {
  editorState: EditorState;
}
interface Props {
  isVisible: boolean;
  handleClose: () => void;
  save: (newMessage: Message) => void;
  saveEdits: (newMessage: Message) => void;
  messageToEdit: Message | null;
  title: string;
}
export class AddWelcomeBase extends Component<Props, State> {
  public render = AddWelcomeView.bind(this);

  constructor(props: Props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props !== prevProps && this.props.messageToEdit) {
      const contentBlock = htmlToDraft(this.props.messageToEdit.message);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState,
      });
    }
  }

  public onEditorStateChange = (editorState: EditorState) => {
    this.setState({
      editorState,
    });
  }

  public clear = () => {
    this.setState({
      editorState: EditorState.createEmpty(),
    });
  }

  public clearAndClose = () => {
    this.clear();
    this.props.handleClose();
  }

  public clearAndSave = (html: string) => {
    const trimmedHtmlStrimg = html.split('').slice(3, -5).join('');
    this.props.messageToEdit
      ? this.props.saveEdits({ message: trimmedHtmlStrimg, uuid: this.props.messageToEdit.uuid })
      : this.props.save({ message: trimmedHtmlStrimg });
    this.clear();
  }

  public isModalVisible = () => {
    return this.props.isVisible;
  }

}

export const AddWelcome = AddWelcomeBase;
