import React from 'react';
import {Modal} from 'react-bootstrap';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { Form } from '../../../form/component/Form.component';
import { Input } from '../../../form/component/Input.component';
import { isCsEmail, notEmpty } from '../../../form/lib/rules';
import { InviteUserBase } from './InviteUser.component';
import { FormattedMessage } from 'react-intl';

export const InviteUserView: ViewFunction<InviteUserBase> = function() {
  return (

    <Modal show={this.isModalVisible()} onHide={this.handleClose} className='shadow-sm'>
      <Modal.Header className='' closeButton>
        <Modal.Title as='h5' id='title-invite'>
          <FormattedMessage id='inviteUserBase.invite' />
        </Modal.Title>
      </Modal.Header>

      <Form onSubmit={this.onSubmit}>{(isValid: boolean) => {
        this.setIsFormValid(isValid);
        return (
          <React.Fragment>
            <Modal.Body>
              {this.hasError() && (
                <div className='alert alert-danger'>
                  {this.state.appError!.message}
                </div>
              )}
              <div className='form-group'>
                <label htmlFor='emails'>
                  <FormattedMessage id='registration.invitationEmailAddresses' />
                </label>
                <Input
                  type='text'
                  className={'form-control'}
                  id='emails'
                  placeholder=''
                  value={this.state.emails}
                  onChange={this.changeEmails}
                  rules={[notEmpty(), isCsEmail()]}
                />
              </div>
            </Modal.Body>
            <div className='modal-footer'>
              <button
                id='cancel'
                type='button'
                className='btn btn-dark'
                data-dismiss='modal'
                onClick={this.handleClose}
              >
                Cancel
              </button>
              <button
                id='save'
                type='submit'
                className='btn btn-primary'
              >
                Invite
              </button>
            </div>
          </React.Fragment>
        );
      }}
      </Form>
    </Modal>
  );
};
