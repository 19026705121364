import { FormEvent } from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../api/Server';
import { AppError } from '../../../../model/AppError';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { Response } from '../../../../model/http/Response';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { AppRouteComponentProps } from '../../../base/model/route/AppRouteComponentProps';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { OrganizationModel, Status, Payload, OrganizationParamType } from '../../model/AccountModel';
import { OrganizationEditAdminView } from './OrganizationEditAdmin.view';

interface ResponseUpdate extends Response {
  content: OrganizationModel;
  timestamp: Date;
}

interface IntlProps {
  intl: IntlShape;
  payload: OrganizationModel;
}

interface State extends ApiState {
  data: OrganizationModel | null;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  streetAddress1: string;
  streetAddress2: string;
  country: string;
  countryNumcode: number;
  zipcode: string;
  vat: string;
  trial: string | undefined;
  maximumBots: string;
  isLoadingData: boolean;
  isValid: boolean;
  selectedRole: string;
  showConfirmDialog: boolean;
  appError?: AppError | null;
  countrySubmitError: boolean;
  status: string;
}

type Props = IntlProps &
  ApiProps<ResponseUpdate> &
  LayoutStateModel &
  RouteComponentProps<{ userId: string }> &
  SessionStateModel &
  WithAlertProps &
  AppRouteComponentProps;

export class OrganizationEditAdminBase extends ApiBase<ResponseUpdate, Props, State> {
  public render = OrganizationEditAdminView.bind(this);

  public state: State = {
    error: null,
    authError: null,
    data: null,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    streetAddress1: '',
    streetAddress2: '',
    country: '',
    countryNumcode: 0,
    zipcode: '',
    vat: '',
    trial: Status.DISABLED,
    maximumBots: '',
    isValid: false,
    isLoadingData: true,
    selectedRole: 'user',
    showConfirmDialog: false,
    countrySubmitError: false,
    status: '',
  };

  public componentDidMount = () => {
    this.props.setPageTitle(this.props.match.params.organization);
    this.props.setSidebarLinkVisibility(false);
    this.requestOrganizationData();
  }

  public async requestOrganizationData() {
    const uuid = this.props.match.params.organization;
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/organization/administration/${uuid}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'get-organisation-details',
    };
    await this.doCall(request);
  }

  public setResponseData() {
    if (this.props.callId === 'get-organisation-details') {
      const { content } = this.props.response;
      const trial = content.parameters.find((object) => object.key === OrganizationParamType.TRIAL)?.value;
      let trialBots: string | undefined = '';
      if (trial && trial === Status.ENABLED) {
        trialBots = content.parameters.find((object) => object.key === OrganizationParamType.TRIAL_BOT_ALLOWED)?.value;
      }
      this.setState({
        data: content,
        firstName: content.primaryHolder.firstname,
        lastName: content.primaryHolder.lastname,
        email: content.primaryHolder.email,
        phone: content.primaryHolder.phone ? content.primaryHolder.phone : '',
        companyName: content.companyInfo.name,
        streetAddress1: content.companyInfo.streetAddress1 ? content.companyInfo.streetAddress1 : '',
        streetAddress2: content.companyInfo.streetAddress2 ? content.companyInfo.streetAddress2 : '',
        country: content.companyInfo.country ? content.companyInfo.country.nicename : '',
        countryNumcode: content.companyInfo.country ? content.companyInfo.country.numcode : 0,
        zipcode: content.companyInfo.zip,
        vat: content.companyInfo.vatId,
        status: content.companyInfo.status,
        trial,
        maximumBots: trialBots ? trialBots : '',
        isLoadingData: false,
      });
    } else if (this.props.callId === 'update-organization-trial') {
      this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
    } else {
      this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
    }
  }

  public handleFormChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const key = e.currentTarget.name as keyof State;
    const value = e.currentTarget.value as string;

    if (Object.keys(this.state).includes(key)) {
      this.setState({
        ...this.state,
        [key]: value,
      });
    }
  }

  public handleCountryChange = (event: any): void => {
    const value: number = event.target.value;
    this.setState({
      countryNumcode: value,
    });
  }

  public handlePhoneChange = (value: any) => {
    this.setState({
      phone: value,
    });
  }

  public getToggleClass = () => {
    if (this.state.trial === Status.ENABLED) {
      return 'fas fa-toggle-on fa-2x on text-success';
    } else {
      return 'fas fa-toggle-off fa-2x off text-secondary';
    }
  }

  public handleToggleTrial = () => {
    this.setState(
      {
        trial: this.state.trial === Status.ENABLED ? Status.DISABLED : Status.ENABLED,
        maximumBots: this.state.trial === Status.ENABLED ? '' : '3',
      },
      async () => {
        const uuid = this.props.match.params.organization;
        const request: Request<{}> = {
          method: HttpRequestType.PATCH,
          payload: null,
          relativePath: `/admin/organization/administration/trial/${uuid}/${this.state.trial}`,
          token: this.props.token,
          refreshToken: this.props.refreshToken,
          id: 'update-organization-trial',
        };
        await this.doCall(request);
      },
    );
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public updateUser = async () => {
    const payload: Payload = {
      company: {
        countryCode: this.state.countryNumcode,
        name: this.state.companyName,
        streetAddress1: this.state.streetAddress1,
        streetAddress2: this.state.streetAddress2,
        vatId: this.state.vat,
        zip: this.state.zipcode,
      },
      primaryAccountHolder: {
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phone: this.state.phone,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      settings: {
        allowedBots: this.state.maximumBots,
        trialStatus: this.state.trial,
      },
    };

    const uuid = this.props.match.params.organization;
    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/organization/administration/${uuid}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'update-organisatioin',
    };
    this.doCall(request);
  }

  public hasError = () => {
    return this.state.appError !== null;
  }

  public setIsFormValid = (isValid: boolean) => {
    if (this.state.isValid !== isValid) {
      this.setState({
        isValid,
      });
    }
  }

  public checkCountryError = () => {
    if (!this.state.countryNumcode) {
      this.setState({
        countrySubmitError: true,
      });
    } else {
      this.setState({
        countrySubmitError: false,
      });
    }
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!this.state.countrySubmitError) {
      this.setState(
        {
          error: null,
        },
        () => {
          this.updateUser();
        },
      );
    }
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
    this.setState({
      isLoadingData: true,
    });
  }
}

const OrganizationEditAdminWithIntl = injectIntl(OrganizationEditAdminBase);
const OrganizationEditAdminWithAlert = withAlert<OrganizationEditAdminBase['props']>()(OrganizationEditAdminWithIntl);
const OrganizationEditAdminWithApi = RefreshApi(Api(OrganizationEditAdminWithAlert));
export const OrganizationEditAdmin = withRouter(OrganizationEditAdminWithApi);
