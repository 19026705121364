import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { TopStatsBase } from './TopStats.component';

export const TopStatsView: ViewFunction<TopStatsBase> = function() {

  return (
    <>
      <div className='stat-box'>
        {this.isInSelectedStats('numberOfClicks') && (
          <div className='card shadow-sm'>
            <div className='card-body'>
              <i className='fas fa-mouse-pointer float-left  fs-2 mt-1' />
              <h4 className='text-right mb-0 lh-1' id={'numberOfClicks'}>{this.getNumberOfClicks()}</h4>
              <p className='text-right mb-0'>
                <small>
                  <FormattedMessage id='topStats.numberOfClicks' />
                </small>
              </p>
            </div>
          </div>
        )}

        {this.isInSelectedStats('activeConversations') && (
          <div className='card shadow-sm'>
            <div className='card-body'>
              <i className='far fa-comments float-left  fs-2 mt-1' />
              <h4 className='text-right mb-0 lh-1' id={'activeConversations'}>{this.getActiveConversations()}</h4>
              <p className='text-right mb-0'>
                <small>
                  <FormattedMessage id='topStats.activeConversations' />
                </small>
              </p>
            </div>
          </div>
        )}

        {this.isInSelectedStats('numberOfInputs') && (
          <div className='card shadow-sm'>
            <div className='card-body'>
              <i className='far fa-comment float-left  fs-2 mt-1' />
              <h4 className='text-right mb-0 lh-1' id={'numberOfInputs'}>{this.getInputs()}</h4>
              <p className='text-right mb-0'>
                <small>
                  <FormattedMessage id='topStats.numberOfInputs' />
                </small>
              </p>
            </div>
          </div>
        )}

        {this.isInSelectedStats('avgConfidence') && (
          <div className='card shadow-sm'>
            <div className='card-body'>
              <i className='fas fa-check float-left  fs-2 mt-1' />
              <h4 className='text-right mb-0 lh-1' id={'avgConfidence'}>{this.getAvgConfidence()}%</h4>
              <p className='text-right mb-0'>
                <small>
                  <FormattedMessage id='topStats.avgConfidence' />
                </small>
              </p>
            </div>
          </div>
        )}

        {this.isInSelectedStats('numberOfThumbsUp') && (
          <div className='card shadow-sm'>
            <div className='card-body'>
              <i className='fas fa-thumbs-up float-left  fs-2 mt-1' />
              <h4 className='text-right mb-0 lh-1' id={'numberOfThumbsUp'}>{this.getNumberOfThumbsUp()}</h4>
              <p className='text-right mb-0'>
                <small>
                  <FormattedMessage id='topStats.numberOfThumbsUp' />
                </small>
              </p>
            </div>
          </div>
        )}

        {this.isInSelectedStats('numberOfThumbsDown') && (
        <div className='card shadow-sm'>
          <div className='card-body'>
            <i className='fas fa-thumbs-down float-left  fs-2 mt-1' />
            <h4 className='text-right mb-0 lh-1' id={'numberOfThumbsDown'}>{this.getNumberOfThumbsDown()}</h4>
            <p className='text-right mb-0'>
              <small>
                <FormattedMessage id='topStats.numberOfThumbsDown' />
              </small>
            </p>
          </div>
        </div>
      )}

        {this.isInSelectedStats('numberOfNegative') && (
        <div className='card shadow-sm'>
          <div className='card-body'>
            <i className='fas fa-frown float-left  fs-2 mt-1' />
            <h4 className='text-right mb-0 lh-1' id={'numberOfNegative'}>{this.getNumberOfNegative()}</h4>
            <p className='text-right mb-0'>
              <small>
                <FormattedMessage id='topStats.numberOfNegative' />
              </small>
            </p>
          </div>
        </div>
      )}

        {this.isInSelectedStats('numberOfNeutral') && (
        <div className='card shadow-sm'>
          <div className='card-body'>
            <i className='fas fa-meh float-left  fs-2 mt-1' />
            <h4 className='text-right mb-0 lh-1' id={'numberOfNeutral'}>{this.getNumberOfNeutral()}</h4>
            <p className='text-right mb-0'>
              <small>
                <FormattedMessage id='topStats.numberOfNeutral' />
              </small>
            </p>
          </div>
        </div>
      )}

        {this.isInSelectedStats('numberOfPositive') && (
        <div className='card shadow-sm'>
          <div className='card-body'>
            <i className='fas fa-smile float-left  fs-2 mt-1' />
            <h4 className='text-right mb-0 lh-1' id={'numberOfPositive'}>{this.getNumberOfPositive()}</h4>
            <p className='text-right mb-0'>
              <small>
                <FormattedMessage id='topStats.numberOfPositive' />
              </small>
            </p>
          </div>
        </div>
      )}

        {this.isInSelectedStats('avgNumberOfInputs') && (
        <div className='card shadow-sm'>
          <div className='card-body'>
            <i className='far fa-comment float-left  fs-2 mt-1' />
            <h4 className='text-right mb-0 lh-1' id={'avgNumberOfInputs'}>{this.getAvgNumberOfInputs()}</h4>
            <p className='text-right mb-0'>
              <small>
                <FormattedMessage id='topStats.avgNumberOfInputs' />
              </small>
            </p>
          </div>
        </div>
      )}

      </div>
    </>
  );
};

export default TopStatsView;
