import { Component } from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import { SendEventView } from './SendEvent.view';
import { DropDownMenuItem } from '../../../../base/component/Table/Table.component';

interface State {
  name: string;
  key: string;
  value: string;
  params: KeyValue[];
  savedEvents: Events[];
  isAddEventOpen: boolean;
  localStorageObject: SavedEvents[];
}

export interface SavedEvents {
  bot: string;
  events: Events[];
}

export interface Events {
  name: string;
  params: KeyValue[];
}

interface Event {
  target: Value;
}

interface Value {
  value: string;
}

export interface KeyValue {
  key: string;
  value: string;
}

interface Props {
  intl: IntlShape;
  isVisible: boolean;
  botId: string;
  handleClose: (eventSent: boolean) => void;
}

export class SendEventBase extends Component<Props, State> {
  public render = SendEventView.bind(this);

  constructor(props: Props) {
    super(props);
    this.state = {
      name: '',
      key: '',
      value: '',
      params: [],
      isAddEventOpen: false,
      savedEvents: [],
      localStorageObject: [],
    };
  }

  public componentDidMount = () => {
    this.getLocalEvents();
  }

  public getLocalEvents = () => {
    const localEvents = localStorage.getItem('robo_ai_admin_bot_events');
    let localEventsConverted = [];
    let thisBotEvents = [];
    if (localEvents) {
      localEventsConverted = JSON.parse(localEvents);
      thisBotEvents = localEventsConverted.find((item: SavedEvents) => item.bot === this.props.botId)?.events;
    }
    this.setState({
      savedEvents: thisBotEvents && [...thisBotEvents],
      localStorageObject: localEventsConverted && [...localEventsConverted],
    });
  }

  public isModalVisible = () => {
    return this.props.isVisible;
  }

  public openaddnewEvent = () => {
    this.setState({
      isAddEventOpen: true,
    });
  }

  public dropDownMenuItems = () => {
    const dropDownItemList: DropDownMenuItem[] = [
      {
        text: 'Send',
        callBack: this.send,
      },
      {
        text: 'Delete',
        callBack: this.deleteEvent,
      },
    ];
    return dropDownItemList;
  }

  public getPage() {
    return 0;
  }

  public getData() {
    return this.state.savedEvents;
  }

  public handleNameChange = (event: Event) => {
    this.setState({
      name: event.target.value,
    });
  }

  public handleKeyChange = (event: Event) => {
    this.setState({
      key: event.target.value,
    });
  }

  public handleValueChange = (event: Event) => {
    this.setState({
      value: event.target.value,
    });
  }

  public clear = () => {
    this.setState({
      isAddEventOpen: false,
      value: '',
      key: '',
      name: '',
      params: [],
    });
  }

  public handleAdd = () => {
    const newParameter = {
      key: this.state.key,
      value: this.state.value,
    };
    this.setState(
      (prevState) => ({
        params: [...prevState.params, newParameter],
      }),
      () => {
        this.setState({
          value: '',
          key: '',
        });
      },
    );
  }

  public delete = (item: KeyValue) => {
    const newObj = this.state.params.filter((value) => {
      return value !== item;
    });
    this.setState({
      params: newObj,
    });
  }

  public saveToLocalStorage = () => {
    const toSave = [...this.state.localStorageObject];
    const botSavedEventsIndex = toSave.findIndex((item: SavedEvents) => item.bot === this.props.botId);
    if (botSavedEventsIndex >= 0) {
      toSave[botSavedEventsIndex].events.push({ name: this.state.name, params: this.state.params });
    } else {
      toSave.push({
        bot: this.props.botId,
        events: [
          {
            name: this.state.name,
            params: this.state.params,
          },
        ],
      });
    }
    localStorage.setItem('robo_ai_admin_bot_events', JSON.stringify(toSave));
  }

  public save = () => {
    this.saveToLocalStorage();
    const newEvents = this.state.savedEvents.length
      ? [...this.state.savedEvents, { name: this.state.name, params: this.state.params }]
      : [{ name: this.state.name, params: this.state.params }];
    this.setState({
      savedEvents: newEvents,
      isAddEventOpen: false,
    });
    this.clear();
  }

  public deleteEvent = (item: Events) => {
    const newObj = this.state.savedEvents.filter((value) => {
      return value !== item;
    });
    this.setState(
      {
        savedEvents: newObj,
      },
      () => {
        this.deleteFromLocalStorage();
      },
    );
  }

  public deleteFromLocalStorage = () => {
    const toSaveDeleted = [...this.state.localStorageObject];
    const botSavedEventsIndex = toSaveDeleted.findIndex((item: SavedEvents) => item.bot === this.props.botId);
    toSaveDeleted[botSavedEventsIndex].events = [...this.state.savedEvents];
    localStorage.setItem('robo_ai_admin_bot_events', JSON.stringify(toSaveDeleted));
  }

  public send = (item: Events) => {
    window.RoboAi.sendEvent(item.name, item.params);
    this.props.handleClose(true);
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }
}

export const SendEvent = injectIntl(SendEventBase);
