import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { SessionContext } from '../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { Navigation } from '../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { StatsResponse } from './model/StatsResponse';
import { TopStatsView } from './TopStats.view';
import { StatsTimeFrame } from '../../../common/model/logs/LogsModel';

interface State extends ApiState {
  numberOfChats: number;
  numberOfBotClicks: number;
  searchTimeFrame: StatsTimeFrame;
  numberOfInputs: number;
  avgConfidence: number;
  numberOfThumbsUp: number;
  numberOfThumbsDown: number;
  numberOfNegative: number;
  numberOfNeutral: number;
  numberOfPositive: number;
  avgNumberOfInputs: number;
}

interface IntlProps {
  intl: IntlShape;
  searchTimeFrame: StatsTimeFrame;
  botView: string;
  fromDate: Date;
  toDate: Date;
  statsSelected: string[];
}

type Props = IntlProps & ApiProps<StatsResponse> & SessionStateModel & RouteComponentProps<Navigation> & WithAlertProps;

export class TopStatsBase extends ApiBase<StatsResponse, Props, State> {
  public state: State = {
    numberOfChats: 0,
    numberOfBotClicks: 0,
    numberOfInputs: 0,
    avgConfidence: 0,
    numberOfThumbsUp: 0,
    numberOfThumbsDown: 0,
    numberOfNegative: 0,
    numberOfNeutral: 0,
    numberOfPositive: 0,
    avgNumberOfInputs: 0,
    error: null,
    authError: null,
    searchTimeFrame: StatsTimeFrame.WEEK,
  };

  public render = TopStatsView.bind(this);

  private readonly FIRST_ORDER = 'first_order';

  public async componentDidMount(): Promise<void> {
    this.setState({ searchTimeFrame: this.props.searchTimeFrame }, async () => {
      await this.requestFirstOrderData();
    });
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (nextProps.searchTimeFrame !== this.props.searchTimeFrame) {
      this.setState({ searchTimeFrame: nextProps.searchTimeFrame }, () => {
        this.requestFirstOrderData();
      });
    }
  }

  public componentDidUpdate(prevProps: Props) {
    super.componentDidUpdate(prevProps);
    if (this.props.location !== prevProps.location) {
      this.setState(
        {
          searchTimeFrame: this.props.searchTimeFrame,
        },
        () => {
          this.requestFirstOrderData();
        },
      );
    } else if (this.props.botView !== prevProps.botView) {
      this.requestFirstOrderData();
    }
  }

  public getNumberOfClicks(): number {
    return this.state.numberOfBotClicks;
  }

  public getActiveConversations(): number {
    return this.state.numberOfChats;
  }

  public getInputs() {
    return this.state.numberOfInputs;
  }

  public isInSelectedStats(name: string) {
    const stat = this.props.statsSelected.find((ele) => ele === name);
    return stat ? true : false;
  }

  public getAvgConfidence() {
    return Math.floor(this.state.avgConfidence);
  }

  public getNumberOfThumbsUp() {
    return this.state.numberOfThumbsUp;
  }
  public getNumberOfThumbsDown() {
    return this.state.numberOfThumbsDown;
  }
  public getNumberOfNegative() {
    return this.state.numberOfNegative;
  }
  public getNumberOfNeutral() {
    return this.state.numberOfNeutral;
  }
  public getNumberOfPositive() {
    return this.state.numberOfPositive;
  }
  public getAvgNumberOfInputs() {
    return Math.floor(this.state.avgNumberOfInputs);
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public generateRating = (rating: any) => {
    const calculation =
      ((rating.positiveFeedback * 1 + rating.neutralFeedback * 0.5 + rating.negativeFeedback * 0) /
        (rating.positiveFeedback + rating.neutralFeedback + rating.negativeFeedback)) *
      100;
    return Number.isNaN(calculation) ? '0' : calculation.toFixed(2);
  }

  public getBotDetails = () => {
    return this.props.botView !== 'ALL' ? '/assistants/' + this.props.botView : '';
  }

  public getRouteEnding = () => {
    if (this.props.searchTimeFrame === StatsTimeFrame.CUSTOM) {
      const startDateString = this.props.fromDate?.toISOString().substring(0, 10) || '';
      const endDateString = this.props.toDate?.toISOString().substring(0, 10) || '';
      return `${this.getBotDetails()}?startDate=${startDateString}&endDate=${endDateString}`;
    } else {
      return `${this.getBotDetails()}/${this.state.searchTimeFrame}`;
    }
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }

  protected setResponseData() {
    const callId = this.props.callId;
    if (callId === this.FIRST_ORDER) {
      const { content } = this.props.response;
      if (content) {
        this.setState(() => ({
          numberOfChats: content.startedConversationsWithReply,
          numberOfBotClicks: content.startedConversations,
          avgConfidence: content.avgConfidence,
          numberOfInputs: content.numberOfInputs,
          numberOfThumbsUp: content.ratingStats.totalThumbsUp,
          numberOfThumbsDown: content.ratingStats.totalThumbsDown,
          numberOfNegative: content.ratingStats.negativeFeedback,
          numberOfNeutral: content.ratingStats.neutralFeedback,
          numberOfPositive: content.ratingStats.positiveFeedback,
          avgNumberOfInputs: content.averageNumberOfInputs,
        }));
      }
    }
  }

  private async requestFirstOrderData() {
    const route = this.getRouteEnding();
    const request: Request<{}> = {
      id: this.FIRST_ORDER,
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/stats/firstorderstatistics${route}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };
    await this.doCall(request);
  }
}

const TopStatsWithIntl = injectIntl(TopStatsBase);
const TopStatsWithAlert = withAlert<TopStatsBase['props']>()(TopStatsWithIntl);
const TopStatsWithSession = withContext(SessionContext)(TopStatsWithAlert);
const TopStatsWithApi = RefreshApi(Api(TopStatsWithSession));
export const TopStats = withRouter(TopStatsWithApi);
