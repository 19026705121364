import React from 'react';
import { BubbleText } from '../../../../assistant/component/EditAssistant/test/bubbleComponents/bubbleText/BubbleText.component';
import { FormattedMessage } from 'react-intl';

export const LogsBubbleInput: any = (props: any) => {
  let answer;
  try {
    answer = JSON.parse(props.item.message);
  } catch (e) {
    if (props.item.feedbackDialogue) {
      answer = props.item.feedbackDialogue.comments;
    } else if (props.item.thumbs) {
      answer = props.item.thumbs.message;
    } else {
      answer = props.item.message;
    }
  }

  interface Param {
    key: 'string';
    value: 'string';
  }

  return (
    <React.Fragment>
      {answer.type ? (
        <div className='speech-bubble-robo-grey' data-property-name='author' data-property-value='user'>
          <div className='command p-3'>
            <h6>
              <FormattedMessage id={'logsAssistantView.event'} /> {answer.name}
            </h6>
            <table className='table table-borderless transparent'>
              <thead>
                <tr>
                  <th scope='col'>
                    <FormattedMessage id={'logsAssistantView.key'} />
                  </th>
                  <th scope='col'>
                    <FormattedMessage id={'logsAssistantView.value'} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {answer.params.map((param: Param) => {
                  return (
                    <tr>
                      <th>{param.key}</th>
                      <td>{param.value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div>
          <div className='speech-bubble-robo-grey' data-property-name='author' data-property-value='user'>
            <BubbleText text={answer} />
          </div>
          {props.item.feedbackDialogue && <small>Rating</small>}
        </div>
      )}
    </React.Fragment>
  );
};
