import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { Status } from '../../../model/Status';
import { WidgetSnippetBase } from './WidgetSnippet.component';
import CopyToClipboard from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { CreateApiKeyModal } from '../../../../account/component/CreateApiKey/CreateApiKeyModal.component';

export const WidgetSnippetView: ViewFunction<WidgetSnippetBase> = function() {
  return (
    <div>
      <CreateApiKeyModal isVisible={this.state.isModalVisible} onSuccess={this.onSucess} onClose={this.handleClose} />
      <p>Add this code snippet to your website to include the chat widget</p>
      <div className='mt-2 mb-4 d-flex flex-row'>
        <div className='mr-5'>
          <label>API Key</label>
          <select
            className='form-control'
            onChange={( e) => this.setApiKey(e)}
            value={this.state.apiKey}
            id={this.state.apiKey}
          >
            <option>Choose...</option>
            {this.isAdmin() &&
              (
                <option value='newKey' id={'newKey'} style={{ fontWeight: 'bolder', color: '#ff3366' }}>
                Generate new key
              </option>
              )
            }
            {this.state.apiList &&
              this.state.apiList.map((api) => {
                if (api.status === Status.ENABLED) {
                  return (
                     <option key={api.uuid} value={api.uuid} id={api.label.replace(/ /g, '')}>
                      {api.label}
                    </option>
                  );
                }
                return null;
              })}
          </select>
        </div>
        <div>
          <label>Language</label>
          <select className='form-control' onChange={(e) => this.setLanguage(e)}>
            <option>{this.formatMessage('channels.choose')}</option>
            <option value='pt' id={'pt'}>pt</option>
            <option value='en' id={'en'}>en</option>
            <option value='de' id={'de'}>de</option>
            <option value='es' id={'es'}>es</option>
          </select>
        </div>
      </div>
      <div>
        <SyntaxHighlighter language='html'>{this.state.codeSnippet}</SyntaxHighlighter>
      </div>
      <div className='d-flex justify-content-between'>
        <div>
      <CopyToClipboard text={this.state.codeSnippet}>
        <button className='btn btn-secondary' type='button' id={'copy'}>
          {this.formatMessage('channels.copy')}
        </button>
      </CopyToClipboard>
        </div>
        <div>
        <a href='https://developer.robo-ai.com/#chat-widget' rel='noopener noreferrer' target='_blank'>
        <button className='btn btn-primary' type='button' id={'seeDocumentation'}>
          {this.formatMessage('channels.seeDocumentation')}
        </button>
        </a>
        </div>
      </div>
    </div>
  );
};
