import React from 'react';
import moment from 'moment';
import { injectIntl, IntlShape } from 'react-intl';
import { LayoutContext } from '../../../base/state/Layout/LayoutContext';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { DashboardView } from './Dashboard.view';
import { StatsTimeFrame, StatsFilterUnit } from '../../../common/model/logs/LogsModel';
import { withPaginationRouting } from '../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { WithPaginationRoutingProps } from '../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../base/model/route/Navigation';

interface IntlProps {
  intl: IntlShape;
}
interface State {
  searchTimeFrame: StatsTimeFrame;
  fromDate: Date | null;
  toDate: Date | null;
  botView: string;
  isModalVisible: boolean;
  statsSelected: string[];
}

type Props = IntlProps & LayoutStateModel & WithPaginationRoutingProps<Navigation>;
export class DashboardBase extends React.Component<Props, State> {
  public render = DashboardView.bind(this);
  constructor(props: Props) {
    super(props);
    this.state = {
      searchTimeFrame: StatsTimeFrame.WEEK,
      fromDate: null,
      toDate: null,
      botView: 'ALL',
      isModalVisible: false,
      statsSelected: [
        'numberOfClicks',
        'activeConversations',
        'numberOfInputs',
        'avgConvTime',
        'avgRating',
        'thumbsRatio',
        'avgConfidence',
        'numberOfThumbsUp',
        'numberOfThumbsDown',
        'numberOfNegative',
        'numberOfNeutral',
        'numberOfPositive',
        'avgNumberOfInputs',
      ],
    };
  }

  public componentDidMount(): void {
    this.props.setPageTitle(this.formatMessage('sidebar.dashboard'));
    this.setCurrentDatesAndTimeFrame();
    this.getLocalStats();
  }

  public getLocalStats = () => {
    const stats = localStorage.getItem('robo_ai_admin_dashboard_stats');
    if (stats) {
      this.setState({
        statsSelected: JSON.parse(stats),
      });
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  public handleClose = () => {
    this.setState({
      isModalVisible: false,
    });
  }

  public onRouteChanged() {
    this.setCurrentDatesAndTimeFrame();
  }

  public setCurrentDatesAndTimeFrame() {
    const { startDate, endDate, timeFrame } = this.getCurrentTimeFrameDates();
    this.setState({
      fromDate: startDate,
      toDate: endDate,
      searchTimeFrame: timeFrame,
    });
  }

  public calculateTimeFrameDates(window: StatsTimeFrame) {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    date.toISOString();
    const endDate = window === StatsTimeFrame.ALL ? null : date;

    let startDate = null;
    if (window === StatsTimeFrame.YEAR) {
      startDate = moment().subtract(1, StatsFilterUnit.YEARS).startOf('day').toDate();
    } else if (window === StatsTimeFrame.TRIMESTER) {
      startDate = moment().subtract(3, StatsFilterUnit.MONTHS).startOf('day').toDate();
    } else if (window === StatsTimeFrame.MONTH) {
      startDate = moment().subtract(1, StatsFilterUnit.MONTHS).startOf('day').toDate();
    } else if (window === StatsTimeFrame.WEEK) {
      startDate = moment().subtract(1, StatsFilterUnit.WEEKS).startOf('day').toDate();
    } else if (window === StatsTimeFrame.DAY) {
      startDate = moment().subtract(1, StatsFilterUnit.DAYS).startOf('day').toDate();
    }

    return {
      startDate,
      endDate,
    };
  }

  public getCurrentWindow(): StatsTimeFrame {
    return (this.props.getExtraParam('window') as StatsTimeFrame) || null;
  }

  public getCurrentStartDate(): Date | null {
    const param = this.props.getExtraParam('startDate');
    return param ? new Date(param) : null;
  }

  public getCurrentEndDate(): Date | null {
    const param = this.props.getExtraParam('endDate');
    return param ? new Date(param) : null;
  }

  public getCurrentTimeFrameDates() {
    const timeFrame = this.getCurrentWindow() || StatsTimeFrame.WEEK;
    let startDate: Date | null;
    let endDate: Date | null;

    if (timeFrame === StatsTimeFrame.CUSTOM) {
      startDate = this.getCurrentStartDate();
      endDate = this.getCurrentEndDate();
    } else {
      const calculatedDates = this.calculateTimeFrameDates(timeFrame);
      startDate = calculatedDates.startDate;
      endDate = calculatedDates.endDate;
    }

    startDate?.setSeconds(0);
    startDate?.setMilliseconds(0);

    return {
      startDate,
      endDate,
      timeFrame,
    };
  }

  public selectTimeFrame = (timeFrame: StatsTimeFrame) => () => {
    this.setState({
      searchTimeFrame: timeFrame,
    });
    this.props.goToPage(0, {
      window: timeFrame,
    });
  }

  public updateStatsSelected = (newStatsArray: string[]) => {
    this.setState({
      statsSelected: newStatsArray,
    });
  }

  public onSubmitFilter = () => {
    this.props.goToPage(0, {
      startDate: this.state.fromDate ? this.state.fromDate.toISOString() : null,
      endDate: this.state.toDate ? this.state.toDate.toISOString() : null,
      window: StatsTimeFrame.CUSTOM,
    });
  }

  public clearFilters = () => {
    this.setState(
      {
        searchTimeFrame: StatsTimeFrame.WEEK,
        toDate: null,
        fromDate: null,
      },
      () => {
        this.props.goToPage(0, {
          window: StatsTimeFrame.WEEK,
          startDate: null,
          endDate: null,
        });
      },
    );
  }

  public openStatsSettings = () => {
    this.setState({
      isModalVisible: true,
    });
  }

  public onFromDateChangeHandler = (date: Date | null) => {
    this.setState({
      fromDate: date,
    });
  }

  public onToDateChangeHandler = (date: Date | null) => {
    this.setState({
      toDate: date,
    });
  }

  public updateBotView = (botView: string) => {
    this.setState({
      botView,
    });
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }
}

const DashboardWithIntl = injectIntl(DashboardBase);
const DashboardWithPagination = withPaginationRouting()(DashboardWithIntl);
export const Dashboard = withContext(LayoutContext)(DashboardWithPagination);
