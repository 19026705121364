import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import LoadingBar from '../../../base/component/loadingBar/loadingBar';
import { Form } from '../../../form/component/Form.component';
import LoginFormOpener from './LoginFormOpener';
import { LoginFormBase } from './LoginForm.component';
import logo from '../../../../assets/img/logo-vivid-red_png@1x.png';

export const LoginFormView: ViewFunction<LoginFormBase> = function() {
  return (
    <React.Fragment>
      <LoadingBar height={4} className='loading loading-login' color={'#ff3366'} onRef={this.setRef} />
      <Form onSubmit={this.chooseSubmit} style={{ marginTop: '100px' }}>
        {(isValid: boolean) => (
          <React.Fragment>
            {this.hasError() && (
              <div className='alert alert-danger' id='loginError'>
                {this.props.authError ? this.props.authError.message : 'Bad credentials'}
              </div>
            )}

            <div className='card shadow-sm'>
              <div className='card-body'>
                <div className='text-center mb-3'>
                  <img className='logo-login' src={logo} alt='Two Impulse' />
                  <h5>{this.formatMessage('conversationalai.message')}</h5>
                </div>
                  <LoginFormOpener
                    changeUsername={this.changeUsername}
                    changePassword={this.changePassword}
                    state={this.state}
                    isValid={isValid}
                  />
              </div>
            </div>
              <div className='text-center'>
                <small>
                <a className='links' id='resetPassword' href='/auth/resetPasswordForm'>
                  {this.formatMessage('password.forgot')}
                </a>
                </small>
              </div>
              <div className='text-center mt-2 mb-2'>
              {this.state.showRegistration &&
                // tslint:disable-next-line:jsx-wrap-multiline
                <small>
                  {this.formatMessage('noaccount')}&nbsp;
                  <a className='links' id='trialRegistration' href='/public/trialRegistration'>
                     {this.formatMessage('register')}
                  </a>
                </small>
                  }
              </div>
            <div className='text-center'>
              <small>{this.formatMessage('roboai.date')}</small>
            </div>
          </React.Fragment>
        )}
      </Form>
    </React.Fragment>
  );
};

export default LoginFormView;
