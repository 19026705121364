import { withAlert } from 'react-alert';
import {injectIntl, IntlShape} from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { Response } from '../../../../model/http/Response';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import {
  withPaginationRouting,
  WithPaginationRoutingProps,
} from '../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { AnswerModel } from '../../model/AnswerModel';
import { AnswerContext } from '../../state/AnswerContext';
import { AnswerStateModel } from '../../state/AnswerStateModel';
import { DropDownMenuItem } from './../../../base/component/Table/Table.component';
import { withContext } from './../../../lib/component/withContext/withContext.hoc';
import { AnswerListView } from './AnswerList.view';

enum AnswerListOperationType {
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  GET = 'GET',
  NONE = 'NONE',
}

interface State extends ApiState {
  data: AnswerModel[];
  totalPages: number;
  showConfirmDialog: boolean;
  currentItem: AnswerModel | null;
}

interface GetAnswerList extends Response {
  content: AnswerModel[];
  timestamp: Date;
  size: string;
  page: number;
  pageElements: number;
  totalElements: number;
}

interface AnswersPayload {
  criteria?: string;
  language?: string;
  type?: string;
}

interface IntlProps {
  intl: IntlShape;
}

type Props =
  IntlProps
  & ApiProps<GetAnswerList>
  & LayoutStateModel
  & SessionStateModel
  & AnswerStateModel
  & RouteComponentProps<Navigation>
  & WithAlertProps
  & WithPaginationRoutingProps;

export class AnswerListBase extends ApiBase<GetAnswerList, Props, State> {

  public render = AnswerListView.bind(this);

  constructor(props: Props) {
    super(props);

    this.state = {
      data: [],
      totalPages: 0,
      error: null,
      showConfirmDialog: false,
      currentItem: null,
      authError: null,
    };

  }

  public componentDidMount(): void {
    this.props.setPageTitle(this.formatMessage('answerList.answers'));

    this.requestAnswers();
  }

  public componentDidUpdate(prevProps: Props) {
    super.componentDidUpdate(prevProps);
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  public onRouteChanged() {
    this.requestAnswers();
  }

  public getPage() {
    return this.props.page;
  }

  public getTotalPages() {
    return this.state.totalPages;
  }

  public getPageSize() {
    return this.props.pageSize;
  }

  public getCriteriaFromFilter() {
    return this.props.getExtraParam('criteria') || '';
  }

  public getLanguageFromFilter() {
    return this.props.getExtraParam('language') || '';
  }

  public getTypeFromFilter() {
    return this.props.getExtraParam('type') || '';
  }

  public changePageHandler = (pageNumber: number) => {
    this.props.goToPage(pageNumber);
  }

  public changePageSizeHandler = (pageSize: number) => {
    this.props.changePageSize(pageSize);
  }

  public searchHandler = (criteria: string) => {
    this.props.goToPage(0, {
      criteria,
    });
  }

  public onFiltersChangeHandler = (language: string | null, type: string | null) => {
    this.props.goToPage(0, {
      language,
      type,
    });
  }

  public getData() {
    return this.state.data;
  }

  public requestAnswers() {
    const page = this.getPage();
    const pageSize = this.getPageSize();
    const criteria = this.getCriteriaFromFilter();
    const language = this.getLanguageFromFilter();
    const type = this.getTypeFromFilter();

    const payload: AnswersPayload = {};
    if (criteria) {
      payload.criteria = criteria;
    }

    if (language) {
      payload.language = language;
    }

    if (type) {
      payload.type = type;
    }

    const request: Request<{}> = {
      method: HttpRequestType.POST,
      payload,
      relativePath: `/admin/answers/search?page=${page}&size=${pageSize}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: AnswerListOperationType.GET,
    };

    this.doCall(request);
  }

  public dropDownMenuItems = (): DropDownMenuItem[] => {
    const dropDownItemList: DropDownMenuItem[] = [{
      text: `${this.formatMessage('edit')}`,
      callBack: this.editHandler,
    },
      {
        text: `${this.formatMessage('delete')}`,
        callBack: this.confirm,
      }];
    return dropDownItemList;
  }

  public editHandler = (item: AnswerModel) => {
    this.props.history.push(`/answers/edit/${item.uuid}`);
  }

  public deleteHandler = (item: AnswerModel) => {
    const request: Request<{}> = {
      method: HttpRequestType.DELETE,
      payload: null,
      relativePath: `/admin/answers/${item.uuid}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: AnswerListOperationType.DELETE,
    };
    this.doCall(request);
  }

  public setConfirmVisibility = (show: boolean) => {
    this.setState({ showConfirmDialog: show });
  }

  public confirm = (item: AnswerModel) => {
    if (item) {
      this.setState({
        currentItem: item,
      });
      this.setConfirmVisibility(true);
    }
  }

  public confirmCallback = () => {
    if (this.state.currentItem) {
      this.deleteHandler(this.state.currentItem);
    }
  }

  public getCurrentItemName = () => {
    if (this.state.currentItem) {
      return this.state.currentItem.code;
    }
    return '';
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({id});
  }

  protected setResponseData(): void {
    const response = this.props.response;

    if ( this.props.callId === AnswerListOperationType.DELETE) {
      this.requestAnswers();

    } else {
      this.setState({
        data: response.content,
        totalPages: Math.ceil(response.totalElements / Number(response.size)),
      });
    }
    if (response.page) {
      this.props.setBreadCrumbsInfo({
        currentPage: null,
        pageNumber: response.page.toString(),
      });
    }
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }

}

const AnswerListWithIntl = injectIntl(AnswerListBase);
const AnswerListWithAlert = withAlert<AnswerListBase['props']>()(AnswerListWithIntl);
const AnswerListContext = withContext(AnswerContext)(AnswerListWithAlert);
const AnswerListWithApi = RefreshApi(Api(AnswerListContext));
export const AnswerList = withPaginationRouting()(AnswerListWithApi);
