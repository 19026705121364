import React from 'react';
import { Link } from 'react-router-dom';
import { ViewFunction } from '../../../model/component/ViewFunction';
import { Form } from '../../form/component/Form.component';
import { Input } from '../../form/component/Input.component';
import LoadingBar from '../../base/component/loadingBar/loadingBar';
import { notEmpty } from '../../form/lib/rules';
import { TrialRegistrationBase } from './TrialRegistration.component';
import logo from '../../../assets/img/logo-vivid-red_png@1x.png';

export const RegistrationView: ViewFunction<TrialRegistrationBase> = function() {
  return (
    <React.Fragment>
      <LoadingBar height={4} color={'#ff3366'} onRef={this.setRef} />
      <Form onSubmit={this.onSubmit}>
        {() => (
          <React.Fragment>
            <div className='text-center'>
              <img className='logo-login' src={logo} alt='Two Impulse' />
              <h5 id={'trialRegistration'}>{this.formatMessage('registration.trialAccount')}</h5>
            </div>
            <div
              id={'alertDanger'}
              className={'alert ' + (this.state.error ? 'alert-danger' : 'alert-success')}
              style={{ display: this.state.showMessage ? '' : 'none' }}
            >
              {this.state.message}
            </div>
            <div className='card shadow-sm'>
              <div className='card-body'>
                <div className='form-group'>
                  <label>{this.formatMessage('registration.company')}</label>
                  <Input
                    id='registrationCompany'
                    type='text'
                    className='form-control'
                    placeholder={this.formatMessage('registration.company')}
                    value={this.state.company}
                    onChange={this.changeCompany}
                    disabled={!this.state.showRegistration}
                    rules={[notEmpty()]}
                  />
                </div>
                <div className='form-group'>
                  <label>{this.formatMessage('registration.email')}</label>
                  <Input
                    id='registrationEmail'
                    type='text'
                    className='form-control'
                    placeholder={this.formatMessage('registration.email')}
                    value={this.state.email}
                    onChange={this.changeEmail}
                    disabled={!this.state.showRegistration}
                    rules={[notEmpty()]}
                  />
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-6'>
                    <label>{this.formatMessage('registration.firstName')}</label>
                    <Input
                      id='registrationFirstName'
                      type='text'
                      className='form-control'
                      placeholder={this.formatMessage('registration.firstName')}
                      value={this.state.firstName}
                      onChange={this.changeFirstName}
                      disabled={!this.state.showRegistration}
                      rules={[notEmpty()]}
                    />
                  </div>
                  <div className='form-group col-md-6'>
                    <label>{this.formatMessage('registration.lastName')}</label>
                    <Input
                      id='registrationLastName'
                      type='text'
                      className='form-control'
                      placeholder={this.formatMessage('registration.lastName')}
                      value={this.state.lastName}
                      onChange={this.changeLastName}
                      disabled={!this.state.showRegistration}
                      rules={[notEmpty()]}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 text-center'>
                  {!this.state.showRegistration &&
                    (
                    <div className='card-body'>
                      {this.formatMessage('registration.disabled')}
                    </div>
                    )}
                    {/* tslint:disable-next-line:max-line-length */}
                    <button type='submit' id='createAccount' className='btn btn-primary' disabled={!this.state.showRegistration} >
                    {this.formatMessage('registration.trialAccount.button')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-center'>
              <small>
              {this.formatMessage('registration.already.have')}<Link className='links' to='/auth/login'>
                {this.formatMessage('login')}</Link>
              </small>
            </div>
          </React.Fragment>
        )}
      </Form>
    </React.Fragment>
  );
};

export default RegistrationView;
