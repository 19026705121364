import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { Response } from '../../../../model/http/Response';
import { utcDateFormat } from '../../../../util/dateFormat';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { AppRouteComponentProps } from '../../../base/model/route/AppRouteComponentProps';
import { Roles } from '../../../base/model/route/Role';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { Status, UserModel } from '../../model/AccountModel';
import { EditUserView } from './EditUser.view';

interface ResponseUpdate extends Response {
  content: UserModel;
  timestamp: Date;
}

interface Payload {
  roles: string[];
  mobileNumber: string;
  timezone: string;
}

interface IntlProps {
  intl: IntlShape;
}

interface State extends ApiState {
  data: UserModel | null;
  name: string;
  email: string;
  mobileNumber: string;
  timezone: string;
  isValid: boolean;
  statusToChange: string;
  selectedRole: string;
  showConfirmDialog: boolean;
  dialCode: string;
}
type Props = IntlProps &
  ApiProps<ResponseUpdate> &
  LayoutStateModel &
  RouteComponentProps<{ userId: string }> &
  SessionStateModel &
  WithAlertProps &
  AppRouteComponentProps;

export class EditUserBase extends ApiBase<ResponseUpdate, Props, State> {
  public render = EditUserView.bind(this);

  public state: State = {
    error: null,
    authError: null,
    data: null,
    name: '',
    email: '',
    mobileNumber: '',
    timezone: '',
    isValid: false,
    statusToChange: Status.DISABLED,
    selectedRole: process.env.REACT_APP_ARE_ROLES_VISIBLE === 'yes' ? 'user' : 'admin',
    showConfirmDialog: false,
    dialCode: '',
  };

  public componentDidMount = async () => {
    this.props.setPageTitle(this.formatMessage('editUserBase.editUser'));
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/users/uuid/${this.getUserId()}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };
    await this.doCall(request);
  }

  public sendInvitationHandler = async (uuid: string) => {
    const request: Request<{}> = {
      method: HttpRequestType.POST,
      payload: null,
      relativePath: `/admin/users/${uuid}/resendInvitation`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'resendInvitation',
    };
    await this.doCall(request);
  }

  public setToggleClass() {
    const statusToChange = !this.isToggleActive() ? Status.ENABLED : Status.DISABLED;

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload: null,
      relativePath: `/admin/users/${this.getUserId()}/status/${statusToChange}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };

    this.doCall(request);
  }

  public updateUser = async () => {
    const payload: Payload = {
      roles: [this.state.selectedRole],
      mobileNumber: this.state.mobileNumber,
      timezone: this.state.timezone,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/users/${this.getUserId()}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'update-user',
    };
    this.doCall(request);
  }

  public getUserId() {
    return this.props.match.params.userId;
  }

  public setResponseData() {
    const { content } = this.props.response;
    if ('resendInvitation' !== this.props.callId) {
      this.setState({
        data: content,
        statusToChange: content.status,
        selectedRole: content.roles[0],
        mobileNumber: content.mobileNumber,
        timezone: content.timezone,
      });

      this.props.setBreadCrumbsInfo({
        currentPage: content.firstName + ' ' + content.lastName,
        currentId: content.uuid,
        pageNumber: null,
      });
    }
    if ('update-user' === this.props.callId) {
      this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
    }
  }

  public componentWillUnmount(): void {
    this.props.setBreadCrumbsInfo({
      currentPage: null,
    });
  }

  public getName = (): string => {
    if (this.state.data) {
      const name = this.state.data!.firstName + ' ' + this.state.data!.lastName;
      return name.length > 1 ? this.state.data!.firstName + ' ' + this.state.data!.lastName : this.state.data.username;
    }
    return '';
  }

  public getEmail = (): string => {
    if (this.state.data) {
      return this.state.data!.email;
    }
    return '';
  }

  public sendInvitation = () => {
    if (this.state.data) {
      this.sendInvitationHandler(this.state.data.uuid);
    }
    return;
  }

  public onSubmit = () => {
    if (this.isCurrentUser() && !this.isEditable()) {
      this.props.alert.error(this.formatMessage('alert.currentUserStatusError'));

      return false;
    }
    if (this.state.mobileNumber && this.checkPhoneNumberIf2FAEnabled()) {
      return false;
    }
    this.setState(
      {
        error: null,
      },
      () => {
        this.updateUser();
      },
    );
  }

  public setConfirmVisibility = (show: boolean) => {
    this.setState({ showConfirmDialog: show });
  }

  public confirm = () => {
    if (this.isCurrentUser()) {
      this.props.alert.error(this.formatMessage('alert.currentUserStatusError'));

      return false;
    }

    const statusToChange = this.isToggleActive() ? 'disable' : 'enable';
    this.setState({
      statusToChange,
    });
    this.setConfirmVisibility(true);
  }

  public confirmCallback = () => {
    this.setToggleClass();
  }

  public getToggleClass(): string {
    if (this.isToggleActive()) {
      return 'fas fa-toggle-on fa-2x on text-success';
    }
    return 'fas fa-toggle-off fa-2x off text-secondary';
  }

  public setToggleState = () => {
    const statusToChange = this.isToggleActive() ? Status.DISABLED : Status.ENABLED;
    this.setState({
      statusToChange,
    });
  }
  public getToggleTitle(): string {
    if (this.isToggleActive()) {
      return 'Enabled';
    }
    return 'Disabled';
  }

  public getLastLogin = () => {
    if (this.state.data) {
      return utcDateFormat(this.state.data!.lastLogin);
    }
  }

  public handleOnChange = (value: any, data: any) => {
    this.setState({
      mobileNumber: value,
      dialCode: data.dialCode,
    });
  }

  public handleOptionChange = (event: React.MouseEvent<HTMLLabelElement>) => {
    if (this.isCurrentUser()) {
      this.props.alert.error(this.formatMessage('alert.currentUserRoleError'));

      return false;
    }
    const label: HTMLLabelElement = event.target as HTMLLabelElement;

    this.setState({
      selectedRole: label.id,
    });
  }

  public handleTimezoneChange = (value: string) => {
    this.setState({ timezone: value });
  }

  public isEditable = () => {
    if (this.state.data) {
      const { content } = this.props.response;

      if (
        content.email === this.state.data.email &&
        content.username === this.state.data.username &&
        content.status === this.state.data.status &&
        content.roles[0] === this.state.data.roles[0] &&
        content.timezone === this.state.data.timezone
      ) {
        return true;
      }

      return false;
    }
    return true;
  }

  public isCurrentUser = () => {
    if (this.state.data) {
      if (this.props.currentUsername !== this.state.data.username) {
        return false;
      }
    }
    return true;
  }

  public formatMessage(id: string, values: Record<string, any> = {}) {
    return this.props.intl.formatMessage({ id }, values);
  }

  public isSuper = () => {
    return this.props.authorities.includes(Roles.super);
  }

  public checkPhoneNumberIf2FAEnabled = () => {
    const phoneNumberWithoutDialCode = this.state.mobileNumber.replace(/[^0-9]+/g, '')
      .slice(this.state.dialCode.length);
    if (this.state.data && this.state.data.twoFactorAuth && phoneNumberWithoutDialCode.length === 0) {
        // raise an alert if 2FA enabled and the phone number is not provided
        this.props.alert.error(this.formatMessage('alert.alert.2FAEnableValidPhoneError'));
        return true;
    }
    if (this.state.data && !this.state.data.twoFactorAuth && phoneNumberWithoutDialCode.length === 0) {
      // set empty phone number without '+' if 2FA not enabled and the phone number is not provided
      this.setState({
        mobileNumber: phoneNumberWithoutDialCode,
      });
      return false;
    }
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
  }

  private isToggleActive = () => {
    if (this.state.data) {
      return this.state.data!.status === Status.ENABLED;
    } else {
      return false;
    }
  }
}

const EditUserWithIntl = injectIntl(EditUserBase);
const EditUserWithAlert = withAlert<EditUserBase['props']>()(EditUserWithIntl);
const EditUserWithApi = RefreshApi(Api(EditUserWithAlert));
export const EditUser = withRouter(EditUserWithApi);
