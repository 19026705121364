import React, { PropsWithChildren } from 'react';
import { positions, Provider as ReactAlertProvider, transitions } from 'react-alert';
import { AlertTemplate } from './AlertTemplate.component';

const providerOptions = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
};

export const AlertProvider = (props: PropsWithChildren<{}>) => {
  return (
    <ReactAlertProvider template={AlertTemplate} {...providerOptions}>
      {props.children}
    </ReactAlertProvider>
  );
};
