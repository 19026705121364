import preval from 'preval.macro';
import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { FooterBase } from './Footer.component';

const buildDate = preval`module.exports = new Date()`;
const version = preval`
const appPackage = require('../../../../../package.json');
module.exports = appPackage.version`;

export const FooterView: ViewFunction<FooterBase> = function() {
  return (
    <footer className='footer'>
      <div className='container-fluid container-fluid d-none d-md-block small'>
        <div className='row'>
          <div className='col'>
            Backend API: {(this.props.backendBuild) ? this.props.backendBuild!.version + ' | '
              + this.props.backendBuild!.time : 'error getting backend build' }
          </div>
          <div className='col'>
            Frontend:
            {version} | {buildDate}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterView;
