import React from 'react';
import {FormattedMessage} from 'react-intl';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { Form } from '../../../../form/component/Form.component';
import { Input } from '../../../../form/component/Input.component';
import { InfoGroupBase } from './InfoGroup.component';

export const InfoGroupView: ViewFunction<InfoGroupBase> = function() {
  return (
    <div className='card card-body shadow-sm'>
      <Form onSubmit={this.onSubmit}>{(isValid: boolean) => {
      this.setIsFormValid(isValid);
      return (
        <React.Fragment>
          {this.hasError() && (
            <div className='alert alert-danger'>
              Error
            </div>
          )}
          <div className='form-group'>
            <label htmlFor='name'><FormattedMessage id='name'/></label>
            <Input
              readOnly
              type='text'
              className='form-control'
              id='name'
              placeholder={this.formatMessage('createAssistant.enterName')}
              value={this.getName()}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='description'><FormattedMessage id='description'/></label>
            <textarea
              id='description'
              className='form-control'
              placeholder={this.formatMessage('createAssistant.enterDescription')}
              value={this.getDescription()}
              style={{ marginTop: '0px', marginBottom: '0px', height: '57px' }}
              onChange={this.changeDescriptionHandler}
            />
          </div>
          <div className='text-right'>
            <button id='save' type='submit' className='btn btn-primary'>Save changes</button>
          </div>

        </React.Fragment>
      );
    }}
      </Form>
    </div>
  );
};
