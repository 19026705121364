import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Route as RouteDef } from '../../model/route/Route';
import PageNotFound from '../PageNotFound/PageNotFound.component';
import RouteWithSubRoutes from './RouteWithSubRoutes';

interface Props {
  route?: any;
  default?: string;
  routes: RouteDef[];
  innerProps?: any;
}

class Routes extends React.Component<Props, {}> {
  public render() {
    const props = this.props;
    const routesConfig = props.routes;

    if (!routesConfig) {
      throw new Error('The routes prop value must be an array');
    }

    return (
      <Switch>
        {routesConfig.map((route, i) => {
          route.innerProps = props.innerProps;
          return [
            route.default && <Redirect key={`redirect_${i}`} exact from={route.path} to={route.default} />,
            route.path && <RouteWithSubRoutes key={`route_${i}`} {...route} />,
          ];
        })}
        <Route path='/404' component={PageNotFound} />
        <Redirect from='*' to='/' />
      </Switch>
    );
  }
}

export default Routes;
