import {injectIntl, IntlShape} from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Api, RefreshApi } from '../../../../../api/Server';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../model/http/Request';
import { Response } from '../../../../../model/http/Response';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps } from '../../../../base/ApiComponent';
import { Navigation } from '../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { GroupModel } from '../../../model/GroupModel';
import { Status } from '../../../model/Status';
import { EditGroupStateModel } from '../state/EditGroupStateModel';
import { StatusView } from './Status.view';

interface ResponseUpdate extends  Response {
  content: GroupModel;
  timestamp: Date;
}

interface PayloadState {
  state: Status;
}

interface IntlProps {
  intl: IntlShape;
}

type Props =
  IntlProps
  & ApiProps<ResponseUpdate>
  & SessionStateModel
  & EditGroupStateModel
  & RouteComponentProps<Navigation>
  & WithAlertProps;

export class GroupStatusBase extends ApiBase<ResponseUpdate, Props, never> {
  public render = StatusView.bind(this);

  constructor(props: Props) {
    super(props);
    this.setToggleClass = this.setToggleClass.bind(this);
  }

  public setToggleClass() {
    const group = this.props.currentGroup;
    const statusToChange = !this.props.isToggleActive() ? Status.ENABLED : Status.DISABLED;

    const payload: PayloadState = {
      state: statusToChange,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/group/${group}/state`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };

    this.doCall(request);
  }

  public confirmCallback = () => {
    this.setToggleClass();
  }

  public getToggleClass(): string  {
    if (this.props.isToggleActive()) {
      return 'fas fa-toggle-on fa-2x on text-success';
    }
    return 'fas fa-toggle-off fa-2x off text-secondary';
  }

  public getToggle(item: GroupModel | null): string {
      if (item && item.state === Status.ENABLED) {
          return 'fas fa-toggle-on fa-2x on text-success';
      }
      return 'fas fa-toggle-off fa-2x off text-secondary';
  }

  public getToggleTitle(): string  {
    if (this.props && this.props.isToggleActive()) {
      return this.formatMessage('enable');
    }
    return this.formatMessage('disable');
  }

  public setResponseData() {
    const {uuid, name, description, state } = this.props.response.content;

    const groupData: GroupModel = {
      uuid,
      name,
      description,
      state,
    };
    this.props.setGroupInfo(groupData);
    this.props.setToggleState(state === Status.ENABLED);
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({id});
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }
}

export const GroupStatusWithIntl = injectIntl(GroupStatusBase);
export const GroupStatus = RefreshApi(Api(GroupStatusWithIntl));
