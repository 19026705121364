import React, { FocusEvent, SelectHTMLAttributes } from 'react';
import { ValidateableInputProps } from '../model/ValidateableInputProps';

interface ComponentProps {
  value: string;
}

type Props = ValidateableInputProps & SelectHTMLAttributes<HTMLSelectElement> & ComponentProps;

interface State {
  isTouched: boolean;
  isValid: boolean;
}

export const INVALID_INPUT_CLASS = 'is-invalid';

export class Select extends React.Component<Props, State> {

  public static defaultProps: Partial<Props> = {
    rules: [],
    displayValidationFeedback: true,
  };

  public state: State = {
    isTouched: false,
    isValid: false,
  };

  private inputRef = React.createRef<HTMLSelectElement>();

  public render() {
    const { displayValidationFeedback, ...props } = this.props;
    const { isTouched, isValid } = this.state;
    const inputRef = this.inputRef;

    const validationMessage = inputRef.current && inputRef.current.validationMessage;

    let className = props.className || '';
    if (!isValid && isTouched) {
      className += ' ' + INVALID_INPUT_CLASS;
    }

    return (
      <React.Fragment>
        <select
          {...props}
          className={className}
          ref={this.inputRef}
          onChange={this.setTouched('onChange')}
          onBlur={this.setTouched('onBlur')}
        />
        {displayValidationFeedback && !isValid && isTouched && (
          <div className='invalid-feedback'>
            {validationMessage}
          </div>
        )}
      </React.Fragment>
    );
  }

  public componentDidUpdate(): void {
    this.validate();
  }

  public componentDidMount(): void {
    this.validate();
  }

  private setTouched = (eventName: keyof SelectHTMLAttributes<HTMLSelectElement>) =>
    (event: FocusEvent<HTMLSelectElement>) => {
      this.setState({
        isTouched: true,
      });

      if (this.props[eventName]) {
        this.props[eventName](event);
      }
    }

  private validate() {
    const isValid = this.isValid();
    if (this.state.isValid !== isValid) {
      this.setState({
        isValid,
      });
    }
  }

  private isValid() {
    const { rules = [], value } = this.props;
    const inputRef = this.inputRef;

    if (!inputRef.current) {
      return false;
    }

    for (const rule of rules) {
      if (!rule.test(value)) {
        inputRef.current.setCustomValidity(rule.message);
        return false;
      }
    }

    inputRef.current.setCustomValidity('');
    return true;
  }

}
