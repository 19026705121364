import React, { Component } from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Api, RefreshApi } from '../../../../../api/Server';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { Navigation } from '../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { EditAssistantContext } from '../state/EditAssistantContext';
import { TestAssistantView } from './TestAssistant.view';
import { DebugBot, WidgetResponse } from './model/WidgetResponse';

interface Message {
  type: string;
  text: string;
}

interface State {
  isModalVisible: boolean;
  debuggerUser: Message[] | [];
  debuggerBot: DebugBot[] | [];
}

interface IntlProps {
  intl: IntlShape;
}

type Props = IntlProps &
  SessionStateModel &
  RouteComponentProps<{ botId: string }> &
  RouteComponentProps<Navigation> &
  WithAlertProps;

export class TestAssistantBase extends Component<Props, State> {
  public render = TestAssistantView.bind(this);

  public userInputRef = React.createRef<HTMLDivElement>();
  public botOutputRef = React.createRef<HTMLDivElement>();

  public state: State = {
    isModalVisible: false,
    debuggerUser: [],
    debuggerBot: [],
  };

  public handleOpen = () => {
    this.setState({
      isModalVisible: true,
    });
  }

  public handleClose = (eventSent: boolean) => {
    this.setState({ isModalVisible: false });
    if (eventSent) {
      this.props.alert.success(this.formatMessage('alert.eventWasSent'));
    }
  }

  public resetTest = () => {
    RoboAi.endConversation();
    RoboAi.clearMessageHistory();
    RoboAi.startConversation();
    this.setState({
      debuggerBot: [],
      debuggerUser: [],
    });
  }

  public addToUserDebugger = (message: Message) => {
    this.setState(
      (prevState) => ({
        debuggerUser: [...prevState.debuggerUser, message],
      }),
      () => {
        if (this.userInputRef.current) {
          this.userInputRef.current.scrollTop = this.userInputRef.current.scrollHeight;
        }
      },
    );
  }

  public addToBotDebugger = (response: WidgetResponse) => {
    const newObj = {
      answers: (response.answers ? response.answers : []),
      intents: (response.debugMetadata.intents ? response.debugMetadata.intents : []),
      entities: (response.debugMetadata.entities ? response.debugMetadata.entities : []),
      metadata: JSON.parse(response.debugMetadata.metadata ? response.debugMetadata.metadata : '[{}]'),
    };
    this.setState(
      (prevState) => ({
        debuggerBot: [...prevState.debuggerBot, newObj],
      }),
      () => {
        if (this.botOutputRef.current) {
          this.botOutputRef.current.scrollTop = this.botOutputRef.current.scrollHeight;
        }
      },
    );
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }

}

const TestAssistantWithIntl = injectIntl(TestAssistantBase);
const TestAssistantWithAlert = withAlert<TestAssistantBase['props']>()(TestAssistantWithIntl);
const TestAssistantWithSession = withContext(SessionContext)(TestAssistantWithAlert);
const TestAssistantWithContext = withContext(EditAssistantContext)(TestAssistantWithSession);
const TestAssistantWithApi = RefreshApi(Api(TestAssistantWithContext));
export const TestAssistant = withRouter(TestAssistantWithApi);
