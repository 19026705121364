import { Route } from '../base/model/route/Route';
import { PublicLayout } from './../base/component/PublicLayout/PublicLayout.component';
import { VerifyLink } from './LinkExpired/VerifyLink.component';
import { Registration } from './Registration/Registration.component';
import { TrialRegistration } from './TrialRegistration/TrialRegistration.component';
import { InvitationSent } from './InvitationSent/InvitationSent.component';

const routes: Route[] = [
  {
    path: '/public',
    default: '/public/verifyRegistrationEmail',
    routes: [
      {
        path: '/public/verifyRegistrationEmail/:emailToken',
        component: VerifyLink,
      },
      {
        path: '/public/register/:emailToken',
        component: Registration,
        layout: PublicLayout,
      },
      {
        path: '/public/trialRegistration',
        component: TrialRegistration,
        layout: PublicLayout,
      },
      {
        path: '/public/thankYou',
        component: InvitationSent,
        layout: PublicLayout,
      },
    ],
  },
];

export default routes;
