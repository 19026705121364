import React from 'react';

export const LogsBubbleNumberThumbs: any = (props: any) => {
  const getAnswerLength = () => {
    if (props.answers) {
      const ans: number = props.answers.reduce((acc: number, value: any) => {
        if (value.type !== 'ssml' && value.type !== 'hints') {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);
      return ans;
    }
  };
  return (
    <small className='float-right'>
      <div
        data-toggle='collapse'
        role='button'
        onClick={props.handleConversationDetail(props.item)}
        aria-expanded='false'
        className='ml-1 links'
      >
        1/{getAnswerLength()}
      </div>
    </small>
  );
};
