import React from 'react';
import { Link } from 'react-router-dom';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import Routes from '../../../base/component/Routes/Routes';
import { EditOrganizationBase } from './EditOrganization.component';

interface OrganizationSection {
  name: string;
  identifier: string;
}

export const EditOrganizationView: ViewFunction<EditOrganizationBase> = function() {

  return (
    <>
      <ul className='nav nav-pills mb-3' id='myTabContent' role='tablist'>
        {this.sections.map((section: OrganizationSection, idx) => (
          <li className='nav-item' key={idx}>
            <Link
              id={`view-${section.identifier}-section`}
              className={`nav-link shadow-sm mr-1  ${this.getActiveClass(section.identifier)}`}
              role='tab'
              aria-controls={section.identifier}
              aria-selected={this.getAriaSelected(section.identifier)}
              to={this.getSectionRoute(section.identifier)}
            >
              {section.name}
            </Link>
          </li>
        ))}
      </ul>
      <Routes routes={this.props.routes} />
    </>
  );
};
