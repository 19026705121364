import React from 'react';
import {FormattedMessage} from 'react-intl';
import { Form } from '../../../../form/component/Form.component';
import { Input } from '../../../../form/component/Input.component';
import { Select } from '../../../../form/component/Select.component';
import { notEmpty } from '../../../../form/lib/rules';
import { CreateAnswer } from '../../CreateAnswer/CreateAnswer.component';
import { EditAnswerBase } from '../EditAnswer.component';

const formLayout = (self: EditAnswerBase<any>, childComponent: any) => {
  return (
    <Form onSubmit={self.onSubmit}>{(isValid: boolean) => {
      self.setIsFormValid(isValid);
      return (
        <div className='card shadow-sm'>
          <CreateAnswer />
          <div className='card-header'>
            <h6 className='mb-0'>{self.getTitle()}</h6>
          </div>
          <div className='card-body'>
            <div className='form-group'>
              <label htmlFor='exampleInputEmail1'><FormattedMessage id='createAnswer.code'/></label>
              <span className='float-right text-danger'>*</span>
              <Input
                type='text'
                className='form-control'
                id='answerCode'
                aria-describedby='answerCode'
                placeholder={`${self.formatMessage('createAnswer.enterCode')}`}
                onChange={self.changeCode}
                value={self.getAnswerCode() || ''}
                rules={[notEmpty()]}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='answerLang'><FormattedMessage id='createAnswer.language'/></label>
              <span className='float-right text-danger'>*</span>
              <Select
                id='language'
                className='form-control'
                value={self.getAnswerLang()}
                onChange={self.changeLanguage}
                rules={[notEmpty()]}
              >
                <option hidden>{`${self.formatMessage('choose')}...`}</option>
                {self.getAnswerLangs().map((item: any, idx: number) => {
                  return (
                    <option data-property-value={item} key={idx}>{item}</option>
                  );
                })}
              </Select>
            </div>
            <div className='form-group'>
              <label htmlFor='exampleInputEmail1'><FormattedMessage id='createAnswer.type'/></label>
              <span className='float-right text-danger'>*</span>
              <Select
                id='type'
                className='form-control'
                disabled
                value={self.getAnswerType()}
                rules={[notEmpty()]}
              >
                {self.getAnswerTypes().map((item: any, idx: number) => {
                  return (
                    <option key={idx}>{item}</option>
                  );
                })}
              </Select>
            </div>
            {childComponent()}
          </div>
        </div>
      );
    }}
    </Form>
  );
};
export default formLayout;
