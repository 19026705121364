import { Component } from 'react';
import { ChannelType } from '../../../../../common/model/logs/LogsModel';
import { LogsGroupChannelView } from './LogsGroupChannel.view';

interface LogsGroupChannelProps {
    channelType: ChannelType;
}

export class LogsGroupChannel extends Component<LogsGroupChannelProps> {

    public render = LogsGroupChannelView.bind(this);

    public getChannelType(): ChannelType {
        return this.props.channelType;
    }
}
