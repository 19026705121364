export enum Engines {
  // NATIVE = 'Native',
  RASA = 'Rasa',
  WATSON = 'Watson',
  STUDIO = 'Studio',
  MOCK = 'Mock',
  ELASTICSEARCH = 'Elasticsearch',
  IBM = 'IBM',
  MICROSOFT = 'Microsoft',
  GOOGLE = 'Google',
  READSPEAKER = 'ReadSpeaker',
  ENDERTURING = 'EnderTuring',
  AWS = 'AWS',
}

export enum SentimentLanguages {
  EN = 'EN',
  DE = 'DE',
}

export enum EngineInputsType {
  APIKEY = 'apiKey',
  API_URL = 'apiUrl',
  URL = 'url',
  WORKSPACEID = 'workspaceId',
  APIENDPOINT = 'apiEndpoint',
  SERVICE_URL = 'serviceUrl',
  TOKEN_URL = 'tokenUrl',
  PITCH = 'pitch',
  SPEAKING_RATE = 'speakingRate',
  SENTIMENT_LANGUAGE = 'sentimentLanguage',
  STT_MODEL = 'speech_to_text_model',
  ASSISTANT_VOLUME = 'text_to_speech_volume',
  ASSISTANT_SPEED = 'text_to_speech_speed',
  ASSISTANT_PITCH = 'text_to_speech_pitch',
  ASSISTANT_SPEAKINGRATE = 'text_to_speech_speaking_rate',
  RASA_RUNTIME_STATE = 'rasa_runtime_state',
  ASR_TOKEN = 'asrToken',
  ACCESS_KEY_ID = 'accessKeyId',
  SECRET_ACCESS_KEY = 'secretAccessKey',
  AWS_URL = 'url',
}

export const EngineInputs = {
  watson: {
    type: Engines.WATSON,
    values: [EngineInputsType.APIKEY, EngineInputsType.API_URL, EngineInputsType.WORKSPACEID],
  },
  rasa: {
    type: Engines.RASA,
    values: [EngineInputsType.RASA_RUNTIME_STATE],
  },
};

export const SpeechToTextInputs = {
  ibm: {
    type: Engines.IBM,
    values: [EngineInputsType.STT_MODEL, EngineInputsType.URL, EngineInputsType.APIKEY],
  },
  microsoft: {
    type: Engines.MICROSOFT,
    values: [EngineInputsType.APIKEY, EngineInputsType.SERVICE_URL, EngineInputsType.TOKEN_URL],
  },
  google: {
    type: Engines.GOOGLE,
    values: [EngineInputsType.STT_MODEL],
  },
  enderturing: {
    type: Engines.ENDERTURING,
    values: [EngineInputsType.ASR_TOKEN],
  },
};

export const TextToSpeechInputs = {
  ibm: {
    type: Engines.IBM,
    values: [EngineInputsType.URL, EngineInputsType.APIKEY],
  },
  microsoft: {
    type: Engines.MICROSOFT,
    values: [EngineInputsType.APIKEY, EngineInputsType.SERVICE_URL, EngineInputsType.TOKEN_URL],
  },
  google: {
    type: Engines.GOOGLE,
    values: [EngineInputsType.PITCH, EngineInputsType.SPEAKING_RATE],
  },
  readspeaker: {
    type: Engines.READSPEAKER,
    values: [
      EngineInputsType.APIKEY,
      EngineInputsType.ASSISTANT_VOLUME,
      EngineInputsType.ASSISTANT_SPEED,
      EngineInputsType.ASSISTANT_PITCH,
    ],
  },
};

export const SentimentInputs = {
  ibm: {
    type: Engines.IBM,
    values: [EngineInputsType.API_URL, EngineInputsType.APIKEY],
  },
  microsoft: {
    type: Engines.MICROSOFT,
    values: [],
  },
  // native: {
  //   type: Engines.NATIVE,
  //   values: [EngineInputsType.SENTIMENT_LANGUAGE],
  // },
  aws: {
    type: Engines.AWS,
    values: [
      EngineInputsType.AWS_URL,
      EngineInputsType.ACCESS_KEY_ID,
      EngineInputsType.SECRET_ACCESS_KEY,
    ],
  },
};

export const enginesCode: { [key: string]: string } = {
  // Native: 'NATIVE',
  Rasa: 'RASA',
  Watson: 'WATSON',
  Mock: 'MOCK',
  ELASTICSEARCH: 'ELASTICSEARCH',
};

export const engineFormatToDropdown: { [key: string]: Engines } = {
  // NATIVE: Engines.NATIVE,
  RASA: Engines.RASA,
  WATSON: Engines.WATSON,
  MOCK: Engines.MOCK,
  ELASTICSEARCH: Engines.ELASTICSEARCH,
  IBM: Engines.IBM,
};

export enum ServiceType {
  ASSISTANT = 'ASSISTANT',
  INDEXING = 'INDEXING',
  NLU = 'NLU',
  SPEECH_TO_TEXT = 'SPEECH_TO_TEXT',
  TEXT_TO_SPEECH = 'TEXT_TO_SPEECH',
}

export interface ServiceParam {
  name: string;
  value: string;
}

export interface ServiceInfo {
  type: ServiceType;
  subtype: Engines;
  parameters: ServiceParam[];
}
export interface RuntimeInfo {
  assistantId: string;
  status: string;
}
