import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { Footer } from '../footer/Footer.component';
import { PageHeader } from '../PageHeader/PageHeader.component';
import { SideBar } from '../SideBar/SideBar.component';
import { TopBar } from '../TopBar/TopBar.component';
import LoadingBar from './../loadingBar/loadingBar';
import { LayoutBase } from './Layout.component';

export const LayoutView: ViewFunction<LayoutBase> = function() {
  const {children, sidebarCollapsed, sidebarLinksVisible} = this.props;
  return (
    <div className='wrapper'>
      <SideBar collapsed={sidebarCollapsed} visible={sidebarLinksVisible} {...this.props}/>

      <div id='content' className={sidebarCollapsed ? 'active' : ''}>
        <TopBar {...this.props} />
          <LoadingBar
            height={4}
            className='loading'
            color={'#ff3366'}
            onRef={this.setRef}
          />
        <div className='container-fluid'>
          <PageHeader/>
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default LayoutView;
