import React from 'react';
import BubbleImageView from './BubbleImageView';

interface Props {
  title?: string;
  url?: string;
}

export class BubbleImageBase extends React.Component<Props, {}> {

  public render = BubbleImageView.bind(this);

  public getTitle() {
    return this.props.title;
  }

  public getSrc() {
    return this.props.url;
  }

}

export const BubbleImage = BubbleImageBase;
