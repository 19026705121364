import { MouseEvent } from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { withPaginationRouting, WithPaginationRoutingProps } from '../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { GroupModel } from '../../model/GroupModel';
import { Status } from '../../model/Status';
import { DropDownMenuItem } from '../../../base/component/Table/Table.component';
import { GroupListView } from './GroupListView.view';

interface GetGroupsList extends Response {
  content: GroupModel[];
  timestamp: Date;
  size: string;
  page: number;
  pageElements: number;
  totalElements: number;
}

interface PayloadState {
  state: Status;
}

interface IntlProps {
  intl: IntlShape;
}

interface ListState extends ApiState {
  data: GroupModel[];
  totalPages: number;
  currentItem: GroupModel | null;
  statusToChange: string;
  showConfirmDialog: boolean;
}

export enum OperationType {
  UPDATE = 'UPDATE',
  GET = 'GET',
}

type Props =
  IntlProps
    & ApiProps<GetGroupsList>
    & SessionStateModel
    & LayoutStateModel
    & WithPaginationRoutingProps<Navigation>
    & WithAlertProps;

export class GroupListBase extends ApiBase<GetGroupsList, Props, ListState> {

  public render = GroupListView.bind(this);

  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      totalPages: 0,
      error: null,
      authError: null,
      currentItem: null,
      statusToChange: '',
      showConfirmDialog: false,
    };
  }

  public updateStatus(item: GroupModel, value: string): void {
    const payload: PayloadState = {
      state: value === Status.ENABLED ? Status.ENABLED : Status.DISABLED,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/group/${item.uuid}/state`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: OperationType.UPDATE,
    };
    this.doCall(request);
  }

  public setConfirmVisibility = (show: boolean) => {
    this.setState({ showConfirmDialog: show });
  }

  public confirm = (item: GroupModel) => (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (item) {
      const statusToChange = item.state !== Status.ENABLED ?
        this.props.intl.formatMessage({ id: 'enable' }).toLowerCase() :
        this.props.intl.formatMessage({ id: 'disable' }).toLowerCase();

      this.setState({
        currentItem: item,
        statusToChange,
      });
      this.setConfirmVisibility(true);
    }
  }

  public confirmCallback = () => {
    this.setToggleClass(this.state.currentItem);
  }

  public componentDidMount(): void {
    this.props.setPageTitle(this.formatMessage('groupList.title'));
    this.updateList();
  }

  public componentDidUpdate(prevProps: Props) {
    super.componentDidUpdate(prevProps);
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  public onRouteChanged() {
    this.updateList();
  }

  public setToggleClass = (item: GroupModel | null) => {
    if (item) {
      const statusToChange = item.state === Status.ENABLED ? Status.DISABLED : Status.ENABLED;
      this.updateStatus(item, statusToChange);
    }
  }

  public getLineToggleClass(item: GroupModel): string {
    if (item.state === Status.ENABLED) {
      return 'fas fa-toggle-on fa-2x on text-success';
    }
    return 'fas fa-toggle-off fa-2x off text-secondary';
  }

  public dataPropertyValue = (item: GroupModel) => {
    if (item.state === Status.ENABLED) {
      return true;
    }
    return false;
  }

  public getLineToggleTitle(item: GroupModel): string {
    if (item.state === Status.ENABLED) {
      return this.formatMessage('enabled');
    }
    return this.formatMessage('disabled');
  }

  public getCurrentPage(): number {
    return this.props.page;
  }

  public getCurrentPageSize(): number {
    return this.props.pageSize;
  }

  public dropDownMenuItems = (): DropDownMenuItem[] => {
    const dropDownItemList: DropDownMenuItem[] = [{
      text: 'Edit',
      callBack: this.editHandler,
    }];
    return dropDownItemList;
  }

  public editHandler = (item: GroupModel) => {
    this.props.history.push(`/groups/edit/${item.uuid}`);
  }

  public changePageSize = (pageSize: number) => {
    this.props.changePageSize(pageSize);
  }

  public changePaginationHandler = (pageNumber: number) => {
    this.props.goToPage(pageNumber);
  }

  public updateList = () => {
    const currentPage = this.getCurrentPage();
    const currentPageSize = this.getCurrentPageSize();
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/groups/?full=1&page=${currentPage}&size=${currentPageSize}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: OperationType.GET,
    };
    this.doCall(request);
  }

  public setResponseData() {
    const response = this.props.response;
    switch (this.props.callId) {
      case OperationType.GET:
        const size = Number(response.size);
        const totalPages = size > 0 ? Math.ceil(response.totalElements / size) : 0;
        this.setState({
          data: response.content,
          totalPages,
        });

        if (response.page) {
          this.props.setBreadCrumbsInfo({
            currentPage: null,
            pageNumber: response.page.toString(),
          });
        }
        break;
      case OperationType.UPDATE:
        this.updateList();
        break;
    }
  }

  public formatMessage(id: string, values: Record<string, any> = {}) {
    return this.props.intl.formatMessage({ id }, values);
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }
}

const GroupsWithIntl = injectIntl(GroupListBase);
const GroupsListWithAlert = withAlert<GroupListBase['props']>()(GroupsWithIntl);
const GroupsListWithApi = RefreshApi(Api(GroupsListWithAlert));
export const GroupsList = withPaginationRouting()(GroupsListWithApi);
