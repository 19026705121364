import React from 'react';
import { Link } from 'react-router-dom';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import Confirm from '../../../base/component/confirm/Confirm.component';
import { PageSize } from '../../../base/component/PageSize/PageSize.component';
import { Pagination } from '../../../base/component/Pagination/Pagination.component';
import { Search } from '../../../base/component/Search/Search.component';
import { ColumnObj, Table } from '../../../base/component/Table/Table.component';
import { CreateAssistant } from '../CreateAssistant/CreateAssistant.component';
import { AssistantListDataBase } from './AssistantListData.component';
import {AssistantModel, Params} from './AssistantModel';

export const AssistantDataListView: ViewFunction<AssistantListDataBase> = function() {
  const columns: ColumnObj[] = [
    {
      title: `${this.formatMessage('name')}`,
      propertyName: 'name',
      render: (item: AssistantModel) => (
        <Link className='links' data-action='edit-answer' to={`/bots/edit/${item.uuid}`}>
          {item.name}
        </Link>
      ),
    },
    {
      title: `${this.formatMessage('displayName')}`,
      propertyName: 'displayName',
      className: 'd-none d-lg-table-cell',
      style: { minWidth: '150px' },
      render: (item: AssistantModel) => (
          item.displayName
      ),
    },
    {
      title: `${this.formatMessage('type')}`,
      propertyName: 'type',
      className: 'd-none d-lg-table-cell',
      render: (item: AssistantModel) => {
        let paramType = '';
        item.params.forEach((param: Params) => {
          if (param.name === 'type') {
            paramType = param.value;
          }
        });

        return <span>{paramType}</span>;
      },
    },
    {
      title: `${this.formatMessage('description')}`,
      propertyName: 'description',
      className: 'd-none d-lg-table-cell',
      render: (item: AssistantModel) => <span>{item.description}</span>,
    },
    {
      title: `${this.formatMessage('topStats.activeConversations')}`,
      propertyName: 'activeConversations',
      className: 'text-right',
      render: (item: AssistantModel) => {
        if (item.stats) {
          return <span>{item.stats.totalChats}</span>;
        }
        return <span>0</span>;
      },
    },
    {
      title: `${this.formatMessage('assistantsList.confidence')}`,
      propertyName: 'confidence',
      className: 'text-right d-none d-lg-table-cell',
      render: (item: AssistantModel) => {
        if (item.stats) {
          return <span>{Math.floor(item.stats.avgConfidence * 100)}%</span>;
        }
        return <span>0%</span>;
      },
    },
    {
      title: `${this.formatMessage('assistantsList.status')}`,
      propertyName: 'status',
      style: { width: '80px' },
      render: (item: AssistantModel) => (
        <div className='toggle-status statustable' onClick={this.confirm(item)}>
          <i
            id={`state-${item.uuid}`}
            data-property-name='statusBt'
            data-property-value={this.dataPropertyValue(item)}
            className={this.getLineToggleClass(item)}
            title={this.getLineToggleTitle(item)}
          />
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Confirm
        show={this.state.showConfirmDialog}
        okLabel={this.formatMessage('ok')}
        cancelLabel={this.formatMessage('cancel')}
        confirmation={this.formatMessage('assistant.info.confirm.ChangeStatus',
          {statusToChange: this.state.statusToChange})}
        proceedFunc={this.confirmCallback}
        title={this.formatMessage('confirm')}
        setConfirmVisibility={this.setConfirmVisibility}
      />
      <Confirm
        show={this.state.showConfirmArchiveDialog}
        okLabel={this.formatMessage('ok')}
        cancelLabel={this.formatMessage('cancel')}
        confirmation={this.formatMessage('assistantsList.archive.confirmation')}
        proceedFunc={this.confirmArchiveCallback}
        title={this.formatMessage('confirm')}
        setConfirmVisibility={this.setConfirmArchiveVisibility}
      />
      {window.innerWidth > 700 && (
        <CreateAssistant
          removeHeaderBtn={this.props.removeHeaderBtn}
          token={this.props.token}
          refreshToken={this.props.refreshToken}
          isRefreshingToken={this.props.isRefreshingToken}
          expiresIn={this.props.expiresIn}
          setRequiredRefres={this.props.setRequiredRefresh}
          refresh={this.props.refresh}
          loadBarRef={this.props.loadBarRef}
          addHeaderBtn={this.props.addHeaderBtn}
          setModalVisibility={this.props.setModalVisibility}
          organization={this.props.organization}
          showModal={this.props.showModal}
          alert={this.props.alert}
          history={this.props.history}
        />
      )}
      <div className='card single-card shadow-sm'>
        <div className='card-body'>
          <div className='row'>
            <Search searchHandler={this.searchHandler} search={this.getCurrentCriteria()} />
          </div>
          <Table
            tableId={'assistants'}
            data={this.state.data}
            columns={columns}
            page={this.getCurrentPage()}
            dropDownMenuItems={this.dropDownMenuItems}
          />
          {/*<!-- end table responsive  -->*/}
          <div className='row mt-3'>
            <div className='col-sm-6 mb-1 display-view'>
              <PageSize changePageSize={this.changePageSize} size={this.getCurrentPageSize()} />
            </div>
            <div className='col-sm-6'>
              <Pagination
                totalPages={this.state.totalPages}
                changePaginationHandler={this.changePaginationHandler}
                page={this.getCurrentPage()}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
