import { Component } from 'react';
import { ChooseStatsView } from './ChooseStats.view';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  updateStatsSelected: (newStatsArray: string[]) => void;
  statsSelected: string[];
}

export class ChooseStatsBase extends Component<Props, never> {
  public render = ChooseStatsView.bind(this);

  public handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  public onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    if (this.isInSelectedStats(value)) {
      this.removeStat(value);
    } else {
      this.addStat(value);
    }
  }

  public saveToLocalStorage = () => {
    const toSave = [...this.props.statsSelected];
    localStorage.setItem('robo_ai_admin_dashboard_stats', JSON.stringify(toSave));
  }

  public removeStat = async (value: string) => {
    const newArr = this.props.statsSelected.filter((ele) => ele !== value);
    await this.props.updateStatsSelected(newArr);
    this.saveToLocalStorage();
  }

  public addStat = async (value: string) => {
    const newArr = [...this.props.statsSelected, value];
    await this.props.updateStatsSelected(newArr);
    this.saveToLocalStorage();
  }

  public isInSelectedStats = (name: string) => {
    const stat = this.props.statsSelected.find((ele) => ele === name);
    return stat ? true : false;
  }

  public isModalVisible = () => {
    return this.props.isVisible;
  }
}

export const ChooseStats = ChooseStatsBase;
