import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { BotSelectorBase } from './BotSelector.component';
import { Select } from '../../../form/component/Select.component';
import { AssistantModel } from '../../../assistant/component/AssistantList/AssistantModel';

export const BotSelectorView: ViewFunction<BotSelectorBase> = function() {
  return (
    <>
      <Select
        className='form-control mb-3'
        id='answerLang'
        value={this.state.botView}
        onChange={this.selectBotView}
        style={{ maxWidth: '150px' }}
      >
        <option value='ALL'>{`${this.formatMessage('all')}`}</option>
        {this.getBots()?.map((bot: AssistantModel, idx: number) => {
          return (
            <option data-property-value={bot.uuid} value={bot.uuid} key={idx}>
              {bot.name}
            </option>
          );
        })}
      </Select>
    </>
  );
};
