import React from 'react';
import {ViewFunction} from '../../../../../../../model/component/ViewFunction';
import {BubbleImageBase} from './BubbleImage.component';

export const BubbleImageView: ViewFunction<BubbleImageBase> = function() {
  return (
    <p>
      <p>{this.getTitle()}</p>
      <img className='img-fluid' alt='' src={this.getSrc()}/>
    </p>
  );
};

export default BubbleImageView;
