import { Api, RefreshApi } from '../../../../../api/Server';
import { AddFAQView } from './AddFAQ.view';
import { FAQObject } from './FAQ.component';
import { ChangeEvent, FormEvent } from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import { DropDownMenuItem } from '../../../../base/component/Table/Table.component';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { RouteComponentProps } from 'react-router';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { withAlert } from 'react-alert';
import {
  withPaginationRouting,
  WithPaginationRoutingProps,
} from '../../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Response } from '../../../../../model/http/Response';
import { Navigation } from '../../../../base/model/route/Navigation';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { EditAssistantContext } from '../state/EditAssistantContext';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState } from 'draft-js';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

interface State extends ApiState {
  editorState: EditorState;
  previewBubbles: EditorState[];
  title: string;
  description: string;
  question: string;
  questionIsDuplicate: boolean;
  editbubbleIndex: number;
  questionExamples: string[];
  filteredExamples: string[];
  totalPages: number;
  isValid: boolean;
  localSearch: string;
  examplesAlert: boolean;
  showConfirmDialogue: boolean;
  confirmAction: string | null;
  exampleToDelete: string | null;
}

interface ResponseUpdate extends Response {
  content: FAQObject;
  timestamp: Date;
}

interface ExtraProps {
  isVisible: boolean;
  handleClose: () => void;
  save: (newFAQ: FAQObject) => void;
  saveEdits: (newFAQ: FAQObject) => void;
  FAQToEdit: FAQObject | null;
}

interface IntlProps {
  intl: IntlShape;
}

type Props = IntlProps &
  ApiProps<ResponseUpdate> &
  ExtraProps &
  SessionStateModel &
  WithPaginationRoutingProps<Navigation> &
  RouteComponentProps<Navigation> &
  WithAlertProps;

export class AddFAQBase extends ApiBase<ResponseUpdate, Props, State> {
  public render = AddFAQView.bind(this);

  constructor(props: Props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      previewBubbles: [EditorState.createEmpty()],
      editbubbleIndex: 0,
      title: '',
      description: '',
      question: '',
      questionIsDuplicate: false,
      questionExamples: [],
      filteredExamples: [],
      totalPages: 0,
      error: null,
      authError: null,
      isValid: false,
      localSearch: '',
      examplesAlert: false,
      showConfirmDialogue: false,
      confirmAction: null,
      exampleToDelete: null,
    };
  }

  public componentDidMount = () => {};

  public componentDidUpdate(prevProps: Props) {
    if (this.props !== prevProps && this.props.FAQToEdit) {
      this.setState({
        editorState: this.getEditorFromHTML(this.props.FAQToEdit.answers[0]),
        previewBubbles: this.getEditorFromHTMLMultiple(this.props.FAQToEdit.answers),
        editbubbleIndex: 0,
        localSearch: '',
        title: this.props.FAQToEdit.title,
        description: this.props.FAQToEdit.description,
        questionExamples: this.props.FAQToEdit.examples,
        filteredExamples: this.filterQuestions(this.props.FAQToEdit.examples),
      });
    }
  }

  public onEditorStateChange = (editorState: EditorState) => {
    const newArr = [...this.state.previewBubbles].map((value, index) => {
      if (index === this.state.editbubbleIndex) {
        return editorState;
      } else {
        return value;
      }
    });
    this.setState({
      editorState,
      previewBubbles: newArr,
    });
  }

  public changeTitleHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;
    this.setState({
      title: value,
    });
  }

  public changeDescriptionHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;
    this.setState({
      description: value,
    });
  }

  public changeQuestionHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;
    this.setState(
      {
        question: value,
      },
      () => this.isQuestionDuplicate(),
    );
  }

  public isQuestionDuplicate = () => {
    const duplicate = this.state.questionExamples.find((element) => element === this.state.question);
    if (duplicate) {
      this.setState({
        questionIsDuplicate: true,
      });
    } else {
      this.setState({
        questionIsDuplicate: false,
      });
    }
  }

  public addQuestion = () => {
    if (!this.state.questionIsDuplicate) {
      this.setState(
        {
          questionExamples: [this.state.question, ...this.state.questionExamples],
          examplesAlert: false,
        },
        () => this.setState({ question: '', filteredExamples: this.filterQuestions(this.state.questionExamples) }),
      );
    }
  }

  public _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.addQuestion();
    }
  }

  public filterQuestions = (examples: string[]) => {
    // eslint-disable-next-line array-callback-return
    const filteredList = examples.filter((value) => {
      if (value.includes(this.state.localSearch)) {
        return value;
      }
    });
    return filteredList;
  }

  public dropDownMenuItems = () => {
    const dropDownItemList: DropDownMenuItem[] = [
      {
        text: 'Delete',
        callBack: this.deleteConfirm,
      },
    ];
    return dropDownItemList;
  }

  public setConfirmVisibility = (show: boolean) => {
    this.setState({ showConfirmDialogue: show});
  }

  public confirmCallback = () => {
    if (this.state.exampleToDelete) {
      this.delete(this.state.exampleToDelete);
    }
  }

  public deleteConfirm = (item: string) => {
    this.setState({exampleToDelete: item});
    this.setConfirmVisibility(true);
  }

  public getCurrentPage(): number {
    return this.props.page;
  }

  public delete = (item: string) => {
    const newArr = this.state.questionExamples.filter((value) => {
      return value !== item;
    });
    this.setState({
      questionExamples: newArr,
      filteredExamples: this.filterQuestions(newArr),
    });
  }

  public searchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;
    this.setState({
      localSearch: value,
    });
  }

  public search = () => {
    this.setState({ filteredExamples: this.filterQuestions(this.state.questionExamples) });
  }

  public addNewBubble = () => {
    this.setState({
      previewBubbles: [...this.state.previewBubbles, EditorState.createEmpty()],
      editbubbleIndex: this.state.editbubbleIndex + 1,
    });
  }

  public deletePreviewBubble = (bubble: EditorState) => {
    if (this.state.previewBubbles.length === 1) {
      this.setState({
        editorState: EditorState.createEmpty(),
        previewBubbles: [EditorState.createEmpty()],
        editbubbleIndex: 0,
      });
    } else {
      const newObj = this.state.previewBubbles.filter((item) => {
        return item !== bubble;
      });
      this.setState({
        previewBubbles: newObj,
        editorState: newObj[0],
        editbubbleIndex: 0,
      });
    }
  }

  public editPreviewBubble = (index: number) => {
    this.setState({
      editorState: this.state.previewBubbles[index],
      editbubbleIndex: index,
    });
  }

  public clear = () => {
    this.setState({
      editorState: EditorState.createEmpty(),
      previewBubbles: [EditorState.createEmpty()],
      title: '',
      description: '',
      question: '',
      questionExamples: [],
      filteredExamples: [],
      localSearch: '',
      totalPages: 0,
    });
  }

  public clearAndClose = () => {
    this.clear();
    this.props.handleClose();
  }

  public setIsFormValid = (isValid: boolean) => {
    if (this.state.isValid !== isValid) {
      this.setState({
        isValid,
      });
    }
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.state.questionExamples.length) {
      this.setState(
        {
          error: null,
        },
        () => {
          this.clearAndSave();
        },
      );
    } else {
      this.setState({
        examplesAlert: true,
      });
    }
  }

  public getAllHtmlStrings = () => {
    const newArr = this.state.previewBubbles.map((value) => {
      const trimmedHtmlStrimg = this.getHtmlString(value).split('').slice(3, -5).join('');
      return trimmedHtmlStrimg;
    });
    return newArr;
  }

  public getHtmlString = (value: EditorState) => {
    return draftToHtml(convertToRaw(value.getCurrentContent()));
  }

  public getEditorFromHTML = (value: string) => {
    const contentBlock = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }

  public getEditorFromHTMLMultiple = (values: string[]) => {
    const newArr = values.map((value) => {
      return this.getEditorFromHTML(value);
    });
    return newArr;
  }

  public clearAndSave = () => {
    const newFAQ = {
      title: this.state.title,
      examples: this.state.questionExamples,
      answers: this.getAllHtmlStrings(),
      description: this.state.description,
      storyType: 'FAQ',
      uuid: this.props.FAQToEdit?.uuid,
    };
    this.props.FAQToEdit ? this.props.saveEdits(newFAQ) : this.props.save(newFAQ);
    this.clear();
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public setResponseData() {
    this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
  }

  public isModalVisible = () => {
    return this.props.isVisible;
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
  }
}

const AddFAQWithIntl = injectIntl(AddFAQBase);
const AddFAQWithAlert = withAlert<AddFAQBase['props']>()(AddFAQWithIntl);
const AddFAQWithSession = withContext(SessionContext)(AddFAQWithAlert);
const AddFAQWithContext = withContext(EditAssistantContext)(AddFAQWithSession);
const AddFAQWithApi = RefreshApi(Api(AddFAQWithContext));
export const AddFAQ = withPaginationRouting()(AddFAQWithApi);
