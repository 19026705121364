import React from 'react';
import {FormattedMessage} from 'react-intl';
import { ViewFunction } from '../../../../../../model/component/ViewFunction';
import { Input } from '../../../../../form/component/Input.component';
import { notEmpty, url } from '../../../../../form/lib/rules';
import formLayout from '../formLayout';
import { YoutubeAnswerBase } from './YoutubeAnswer.component';

export const YoutubeAnswerView: ViewFunction<YoutubeAnswerBase> = function() {
  const childComponent = () => {
    return (
      <React.Fragment>
        <div className='form-group'>
          <label htmlFor='youtubeTitle'><FormattedMessage id='title'/></label>
          <span className='float-right text-danger'>*</span>
          <Input
            type='text'
            className='form-control'
            id='youtubeTitle'
            aria-describedby='youtubeTitle'
            placeholder={`${this.formatMessage('youtubeAnswerBase.enterYourTitleForTheYoutubeVideohere')}...`}
            value={this.getAnswerYoutubeTitle()}
            onChange={this.changeVideoTitle}
            rules={[notEmpty()]}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='youtubeImageUrl'><FormattedMessage id='youtubeAnswerBase.youtubeVideoImageUrl'/></label>
          <span className='float-right text-danger'>*</span>
          <Input
            type='text'
            className='form-control'
            id='youtubeImageUrl'
            aria-describedby='youtubeImageUrl'
            placeholder={`${this.formatMessage('youtubeAnswerBase.enterYourThumbnailYoutubeVideoImageUrlHere')}...`}
            value={this.getAnswerYoutubeImageUrl()}
            onChange={this.changeVideoImageUrl}
            rules={[url()]}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='youtubeUrl'><FormattedMessage id='youtubeAnswerBase.youtubeVideoId'/></label>
          <span className='float-right text-danger'>*</span>
          <Input
            type='text'
            className='form-control'
            id='youtubeUrl'
            aria-describedby='youtubeUrl'
            placeholder={`${this.formatMessage('youtubeAnswerBase.enterYourYoutubeVideoIdHere')}...`}
            value={this.getAnswerYoutubeMediaUrl()}
            onChange={this.changeYoutubeMediaUrl}
            rules={[notEmpty()]}
          />
        </div>
        <div className='text-right'>
          <button
            id='saveBtn'
            type='submit'
            className='btn btn-primary'
          >
            {`${this.formatMessage('save')}`}
          </button>
        </div>
      </React.Fragment>
    );
  };

  return (
    formLayout(this, childComponent)
  );
};
