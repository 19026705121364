import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { FacebookAPIProviderChannelBase } from './FacebookAPIProviderChannel.component';

export const FacebookAPIProviderChannelView: ViewFunction<FacebookAPIProviderChannelBase> = function() {
  return (
    <React.Fragment>
      <div className='form-group'>
        <div className='input-group'>
          <input type='text' readOnly className='form-control' id={this.getUuid()} placeholder={this.buildUrl()} />
          <div className='input-group-append'>
            <CopyToClipboard text={this.buildUrl()}>
              <button className='btn btn-secondary' type='button'>
                {this.formatMessage('channels.copy')}
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
      <div className='form-group'>
        <label>{this.formatMessage('channel.facebookverifytoken')}</label>
        <div className='input-group'>
          <input
            type='text'
            readOnly
            className='form-control'
            id={this.getUuid()}
            placeholder={this.getVerifyToken()}
          />
          <div className='input-group-append'>
            <CopyToClipboard text={this.getVerifyToken()}>
              <button className='btn btn-secondary' type='button'>
                {this.formatMessage('channels.copy')}
              </button>
            </CopyToClipboard>
          </div>
        </div>{' '}
      </div>
      <form onSubmit={this.submit}>
        <div className='form-group'>
          <label htmlFor='pageAccessToken'>{this.formatMessage('channel.facebookpageaccesstoken')}</label>
          <input
            className='form-control'
            id='pageAccessToken'
            name='pageAccessToken'
            aria-describedby='webhookURLFacebook'
            type='text'
            placeholder='Add...'
            defaultValue={this.getPageAccessToken()}
            onChange={this.setPageAccessToken}
          />
        </div>
        <div className='text-right'>
          <button className='btn btn-secondary' type='submit'>
            {this.formatMessage('channels.save')}
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};
