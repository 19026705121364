import { Api, RefreshApi } from '../../../../../api/Server';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { AssistantParams, CharacterVoice, Language, EditAssistantStateModel } from '../state/EditAssistantStateModel';
import { ChangeEvent, FormEvent } from 'react';
import {
  Engines,
  ServiceInfo,
  ServiceParam,
  ServiceType,
  EngineInputsType,
  TextToSpeechInputs,
} from '../../../model/engines';
import { IntlShape, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { EditAssistantContext } from '../state/EditAssistantContext';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Navigation } from '../../../../base/model/route/Navigation';
import { ParamValue } from '../../../../group/component/edit/state/EditGroupStateModel';
import { Request, RequestActions } from '../../../../../model/http/Request';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { Status } from '../../../model/Status';
import { TextToSpeechView } from './TextToSpeech.view';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { withAlert } from 'react-alert';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';

interface ResponseTTSUpdate extends Response {
  content: ServiceInfo;
  timestamp: Date;
}

interface ResponseTTSVoices extends Response {
  content: CharacterVoice[];
  timestamp: Date;
}

interface InfoState extends ApiState {
  engine: Engines;
  character: string;
  language: string;
  characterOptions: CharacterVoice[];
  languages: Language[];
  apiKey?: string;
  url?: string;
  serviceUrl?: string;
  tokenUrl?: string;
  pitch?: string;
  speakingRate?: string;
  speed?: string;
  volume?: string;
}

interface PayloadAssistantParams {
  params: {};
}

interface PayloadServiceParams {
  type: ServiceType;
  subtype: Engines;
  parameters: ServiceParam[];
}

interface IntlProps {
  intl: IntlShape;
}

interface InnerPropsFunctions {
  reloadAssistant: () => void;
}

interface InnerProps {
  innerProps: InnerPropsFunctions;
}

interface Submit {
  [id: string]: string | number;
}

type Props = IntlProps &
  ApiProps<ResponseTTSUpdate | ResponseTTSVoices> &
  SessionStateModel &
  EditAssistantStateModel &
  RouteComponentProps<Navigation> &
  WithAlertProps &
  InnerProps;

const updateAssistant = 'updateAssistant';
const updateService = 'update-tts';
const load = 'load';
const loadTts = 'loadTts';
const loadLanguage = 'loadLanguage';

export class TextToSpeechBase extends ApiBase<ResponseTTSUpdate | ResponseTTSVoices, Props, InfoState> {
  public render = TextToSpeechView.bind(this);

  public constructor(props: Props) {
    super(props);

    const character = this.getValue(AssistantParams.CHARACTER);
    const language = this.getValue(AssistantParams.TTS_LANGUAGE);

    const pitch = this.getValue(EngineInputsType.ASSISTANT_PITCH);
    const volume = this.getValue(EngineInputsType.ASSISTANT_VOLUME);
    const speed = this.getValue(EngineInputsType.ASSISTANT_SPEED);

    this.state = {
      authError: null,
      error: null,
      appError: null,
      engine: Engines.IBM,
      character: character !== undefined ? character.value : '',
      characterOptions: [],
      languages: [],
      language: language !== undefined ? language.value : '',
      pitch: pitch !== undefined ? pitch.value : '',
      volume: volume !== undefined ? volume.value : '',
      speed: speed !== undefined ? speed.value : '',
    };
    this.loadEngineProps();
  }

  public loadTextToSpeechCharacters(value: Engines, action: RequestActions) {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/assistants/${value}/text-to-speech/characters`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: loadTts,
      action,
    };
    this.doCall(request);
  }

  public loadEngineProps = () => {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/assistants/${this.props.currentAssistant}/${ServiceType.TEXT_TO_SPEECH}/service`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: load,
    };
    this.doCall(request);
  }

  public loadLanguage = (character: string, action?: RequestActions) => {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/assistants/text-to-speech/language/${character}/${this.getEngine()}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: loadLanguage,
      action,
    };
    this.doCall(request);
  }

  public getStatus(): Status {
    const status =
      this.props.assistantData !== undefined && this.props.assistantData !== null
        ? this.props.assistantData.params.find((x: ParamValue) => x.name === AssistantParams.ENABLE_DISABLE_TTS)
        : undefined;
    return status !== undefined && JSON.parse(status.value) ? Status.ENABLED : Status.DISABLED;
  }

  public getOptionsCharacters(): CharacterVoice[] {
    return this.state.characterOptions;
  }

  public getOptionsLanguages(): Language[] {
    return this.state.languages;
  }

  public setEngine = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    this.setState({
      engine: value as Engines,
    });
    this.loadTextToSpeechCharacters(value as Engines, RequestActions.CHANGE);
  }

  public getEngine(): Engines {
    return this.state.engine;
  }

  public setCharacter = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    this.setState({
      character: value,
    });
    this.loadLanguage(value, RequestActions.CHANGE);
  }

  public getCharacter(): string {
    return this.state.character;
  }

  public setLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    this.setState({
      language: value,
    });
  }

  public getLanguage(): string {
    return this.state.language;
  }

  public setApiKey = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      apiKey: value,
    });
  }

  public getApiKey(): string {
    return this.state.apiKey === undefined ? '' : this.state.apiKey.valueOf();
  }

  public setUrl = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      url: value,
    });
  }

  public getUrl(): string {
    return this.state.url === undefined ? '' : this.state.url.valueOf();
  }

  public setServiceUrl = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      serviceUrl: value,
    });
  }

  public getServiceUrl(): string {
    return this.state.serviceUrl === undefined || this.state.serviceUrl === null ? '' : this.state.serviceUrl.valueOf();
  }

  public setTokenUrl = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      tokenUrl: value,
    });
  }

  public getPitch(): string {
    return this.state.pitch === undefined || this.state.pitch === null ? '' : this.state.pitch.valueOf();
  }

  public setPitch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      pitch: value,
    });
  }

  public getSpeakingRate(): string {
    return this.state.speakingRate === undefined || this.state.speakingRate === null
      ? ''
      : this.state.speakingRate.valueOf();
  }

  public setSpeakingRate = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      speakingRate: value,
    });
  }

  public getTokenUrl(): string {
    return this.state.tokenUrl === undefined || this.state.tokenUrl === null ? '' : this.state.tokenUrl.valueOf();
  }

  public getVolume(): string {
    return this.state.volume === undefined || this.state.volume === null ? '' : this.state.volume.valueOf();
  }

  public setVolume = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      volume: value,
    });
  }

  public getSpeed(): string {
    return this.state.speed === undefined || this.state.speed === null ? '' : this.state.speed.valueOf();
  }

  public setSpeed = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      speed: value,
    });
  }

  public checkDisplayMultiple(inputs: EngineInputsType[], start: number): string {
    if (start >= inputs.length) {
      return 'none';
    }
    const check = this.checkDisplay(inputs[start]);
    if (check.length === 0) {
      return check;
    } else {
      return this.checkDisplayMultiple(inputs, start + 1);
    }
  }

  public checkDisplay(input: EngineInputsType): string {
    switch (this.state.engine.toUpperCase()) {
      case Engines.IBM.toUpperCase():
        return TextToSpeechInputs.ibm.values.indexOf(input) > -1 ? '' : 'none';
      case Engines.MICROSOFT.toUpperCase():
        return TextToSpeechInputs.microsoft.values.indexOf(input) > -1 ? '' : 'none';
      case Engines.GOOGLE.toUpperCase():
        return TextToSpeechInputs.google.values.indexOf(input) > -1 ? '' : 'none';
      case Engines.READSPEAKER.toUpperCase():
        return TextToSpeechInputs.readspeaker.values.indexOf(input) > -1 ? '' : 'none';
      default:
        return 'none';
    }
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const params: Submit = {};
    params[`${AssistantParams.CHARACTER}`] = this.state.character;
    params[`${AssistantParams.TTS_LANGUAGE}`] = this.state.language;

    if (this.checkDisplay(EngineInputsType.ASSISTANT_SPEED).length === 0) {
      params[`${EngineInputsType.ASSISTANT_SPEED}`] = Number.parseFloat(this.getSpeed());
    }
    if (this.checkDisplayMultiple([EngineInputsType.PITCH, EngineInputsType.ASSISTANT_PITCH], 0).length === 0) {
      params[`${EngineInputsType.ASSISTANT_PITCH}`] = Number.parseFloat(this.getPitch());
    }
    if (this.checkDisplay(EngineInputsType.ASSISTANT_VOLUME).length === 0) {
      params[`${EngineInputsType.ASSISTANT_VOLUME}`] = Number.parseFloat(this.getVolume());
    }
    if (this.checkDisplay(EngineInputsType.SPEAKING_RATE).length === 0) {
      params[`${EngineInputsType.ASSISTANT_SPEAKINGRATE}`] = Number.parseFloat(this.getSpeakingRate());
    }

    const payload: PayloadAssistantParams = {
      params,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/assistants/${this.props.currentAssistant}/`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: updateAssistant,
    };

    this.doCall(request);
  }

  public updateService() {
    const params: ServiceParam[] = [];

    let engineInputSelected = TextToSpeechInputs.ibm;
    switch (this.getEngine().toUpperCase()) {
      case Engines.IBM.toUpperCase():
        engineInputSelected = TextToSpeechInputs.ibm;
        break;
      case Engines.MICROSOFT.toUpperCase():
        engineInputSelected = TextToSpeechInputs.microsoft;
        break;
      case Engines.GOOGLE.toUpperCase():
        engineInputSelected = TextToSpeechInputs.google;
        break;
      case Engines.READSPEAKER.toUpperCase():
        engineInputSelected = TextToSpeechInputs.readspeaker;
        break;
    }

    if (engineInputSelected.values.indexOf(EngineInputsType.APIKEY) > -1) {
      params.push({
        name: EngineInputsType.APIKEY,
        value: this.getApiKey(),
      });
    }

    if (engineInputSelected.values.indexOf(EngineInputsType.URL) > -1) {
      params.push({
        name: EngineInputsType.URL,
        value: this.getUrl(),
      });
    }

    if (engineInputSelected.values.indexOf(EngineInputsType.SERVICE_URL) > -1) {
      params.push({
        name: EngineInputsType.SERVICE_URL,
        value: this.getServiceUrl(),
      });
    }

    if (engineInputSelected.values.indexOf(EngineInputsType.TOKEN_URL) > -1) {
      params.push({
        name: EngineInputsType.TOKEN_URL,
        value: this.getTokenUrl(),
      });
    }

    if (engineInputSelected.values.indexOf(EngineInputsType.PITCH) > -1) {
      params.push({
        name: EngineInputsType.PITCH,
        value: this.getPitch(),
      });
    }

    if (engineInputSelected.values.indexOf(EngineInputsType.SPEAKING_RATE) > -1) {
      params.push({
        name: EngineInputsType.SPEAKING_RATE,
        value: this.getSpeakingRate(),
      });
    }

    const payload: PayloadServiceParams = {
      type: ServiceType.TEXT_TO_SPEECH,
      subtype: this.state.engine,
      parameters: params,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/assistants/${this.props.currentAssistant}/service`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: updateService,
    };

    this.doCall(request);
  }

  public setResponseData() {
    if (this.props.callId === updateAssistant) {
      this.updateService();
    } else if (this.props.callId === updateService) {
      this.props.innerProps.reloadAssistant();
      this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
    } else if (this.props.callId === load) {
      const info: ServiceInfo = this.props.response.content as ServiceInfo;
      const apiKey = info.parameters.find((x: ServiceParam) => x.name === EngineInputsType.APIKEY);
      switch (info.subtype) {
        case Engines.IBM.toUpperCase():
          const url = info.parameters.find((x: ServiceParam) => x.name === EngineInputsType.URL);
          this.setState({
            engine: info.subtype,
            apiKey: apiKey !== undefined ? apiKey.value : '',
            url: url !== undefined ? url.value : '',
          });
          break;
        case Engines.MICROSOFT.toUpperCase():
          const serviceUrl = info.parameters.find((x: ServiceParam) => x.name === EngineInputsType.SERVICE_URL);
          const tokenUrl = info.parameters.find((x: ServiceParam) => x.name === EngineInputsType.TOKEN_URL);
          this.setState({
            engine: info.subtype,
            apiKey: apiKey !== undefined ? apiKey.value : '',
            serviceUrl: serviceUrl !== undefined ? serviceUrl.value : '',
            tokenUrl: tokenUrl !== undefined ? tokenUrl.value : '',
          });
          break;
        case Engines.GOOGLE.toUpperCase():
          const pitch = info.parameters.find((x: ServiceParam) => x.name === EngineInputsType.PITCH);
          const speakingRate = info.parameters.find((x: ServiceParam) => x.name === EngineInputsType.SPEAKING_RATE);
          this.setState({
            engine: info.subtype,
            pitch: pitch !== undefined ? pitch.value : '',
            speakingRate: speakingRate !== undefined ? speakingRate.value : '',
          });
          break;
        case Engines.READSPEAKER.toUpperCase():
          const assistantPitch = this.getValue(EngineInputsType.ASSISTANT_PITCH);
          const volume = this.getValue(EngineInputsType.ASSISTANT_VOLUME);
          const speed = this.getValue(EngineInputsType.ASSISTANT_SPEED);
          this.setState({
            engine: info.subtype,
            apiKey: apiKey !== undefined ? apiKey.value : '',
            pitch: assistantPitch !== undefined ? assistantPitch.value : '',
            volume: volume !== undefined ? volume.value : '',
            speed: speed !== undefined ? speed.value : '',
          });
          break;
      }
      this.loadTextToSpeechCharacters(info.subtype, RequestActions.BOOT);
    } else if (this.props.callId === loadTts) {
      const options: CharacterVoice[] = this.props.response.content as CharacterVoice[];
      const curCharacter = this.props.action === RequestActions.BOOT ? this.getCharacter() : options[0].value;

      this.setState(
        {
          characterOptions: options,
          character: curCharacter,
        },
        () => this.loadLanguage(curCharacter, this.props.action),
      );
    } else if (this.props.callId === loadLanguage) {
      const options: Language[] = this.props.response.content as Language[];
      const curLanguage = this.props.action === RequestActions.BOOT ? this.getLanguage() : options[0].value;
      this.setState({
        languages: options,
        language: curLanguage,
      });
    }
  }

  public setErrorResponseData() {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
  }

  public isAdmin(): boolean {
    return super.isAdmin(this.props.authorities);
  }

  private getValue(param: AssistantParams | EngineInputsType): ParamValue | undefined {
    return this.props.assistantData !== undefined && this.props.assistantData !== null
      ? this.props.assistantData.params.find((x: ParamValue) => x.name === param)
      : undefined;
  }
}

const TextToSpeechWithIntl = injectIntl(TextToSpeechBase);
const TextToSpeechWithAlert = withAlert<TextToSpeechBase['props']>()(TextToSpeechWithIntl);
const TextToSpeechWithSession = withContext(SessionContext)(TextToSpeechWithAlert);
const TextToSpeechWithContext = withContext(EditAssistantContext)(TextToSpeechWithSession);
const TextToSpeechWithApi = RefreshApi(Api(TextToSpeechWithContext));
export const TextToSpeech = withRouter(TextToSpeechWithApi);
