import { NOTPLAYED, PLAYING } from '../constants/constants';
import audioFromFile from '../assets/audio/introAudioBase64';
import { isChrome } from 'react-device-detect';
import { Message } from '../modules/assistant/component/EditAssistant/test/model/Message';

export const isAudioPlaying = (message: Message) => {
  return message.status === PLAYING;
};

export const refAudioPlaying = (messages: Message[]): Message | null => {
  let ref: Message | null = null;
  messages.forEach((message: Message): void => {
    if (isAudioPlaying(message)) {
      ref = message;
    }
  });
  return ref;
};

export const isNotPlayed = (message: Message) => {
  return message.status === undefined || message.status === NOTPLAYED;
};

export const mainAudio = new Audio();

export const setAudio = (src: string) => {
  mainAudio.src = 'data:audio/mp3;base64, ' + src;
};

export const getAudio = () => {
  return mainAudio;
};

export const endGeneralAudio = () => {
  mainAudio.pause();
};

export const unlockAudio = () => {
  if (getAudio().paused) {
    setAudio(audioFromFile.base64);
    let playPromise = getAudio().play();

    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          getAudio().pause();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};

export const stopRecorderTracks = (mediaRecorder: any) => {
  mediaRecorder.stream.getAudioTracks().forEach((track: any) => {
    track.stop();
  });
};

export const isAudioEnabled = () => {
  return isChrome;
};
