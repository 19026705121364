export interface ApiKeyModel {
  uuid: string;
  label: string;
  status: string;
  created: string;
  scope: string;
  showKey?: boolean;
}

export enum Status {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}
