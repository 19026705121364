import React, { PropsWithChildren } from 'react';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { LayoutContext } from '../../state/Layout/LayoutContext';
import { LayoutStateModel } from '../../state/Layout/LayoutStateModel';
import { SessionContext } from './../../../auth/state/Session/SessionContext';
import { SessionStateModel } from './../../../auth/state/Session/SessionStateModel';
import LoadingBar from './../loadingBar/loadingBar';
import LayoutView from './Layout.view';

type Props =  SessionStateModel & PropsWithChildren<{}> & LayoutStateModel;

export class LayoutBase extends React.Component<Props, never> {
  public render = LayoutView.bind(this);

  public setRef = (ref: LoadingBar) => {
    this.props.setLoadBarRef(ref);
  }

}

export const LayoutWithContext = withContext(LayoutContext)<Props>(LayoutBase);
export const Layout = withContext(SessionContext)(LayoutWithContext);
