import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { GroupBotBase } from './GroupBot.component';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { GroupBotSelector } from './GroupBotSelector.component';
import { GroupAssistantMode } from './GroupAssistantModel';
import { Input } from '../../../../form/component/Input.component';
import { notEmpty } from '../../../../form/lib/rules';

function buildModeTooltip() {
  return (
    <Tooltip id={`tooltip-top-maximum-idle-conversation-time`}>
      In Foreground mode, the bot takes all conversations first and hands-over when it is uncertain.
    </Tooltip>
  );
}

function buildConfidenceTooltip() {
  return (
    <Tooltip id={`tooltip-top-maximum-idle-conversation-time`}>
      The confidence level above which the bots responds and doesn't handover to an agent.
    </Tooltip>
  );
}

export const GroupBotView: ViewFunction<GroupBotBase> = function() {
  return (
    <React.Fragment>
      <div>
        <div className='row'>
          <div className='col-12'>
            <div className='card single-card'>
              <div className='card-body'>
                {this.state.isloaded && (
                  <div className='form-group'>
                    <div className='toggle-status float-left' onClick={this.onStatusToggle()}>
                      <i
                        id=''
                        className={this.getToggleClass(this.isEnabled())}
                        title={this.getToggleTitle(this.isEnabled())}
                      />
                    </div>
                    <label className='mt-1'>{this.getToggleTitle(this.isEnabled())}</label>
                  </div>
                )}
                {this.isEnabled() && (
                  <>
                    <GroupBotSelector setBot={this.setBot} bot={this.state.bot} />
                    <div className='form-group'>
                      <label>
                        {this.formatMessage('GroupBotView.Mode')}{' '}
                        <OverlayTrigger key={'top'} placement={'top'} overlay={buildModeTooltip()}>
                          <i className='far fa-question-circle' data-toggle='tooltip' data-placement='top' />
                        </OverlayTrigger>
                      </label>
                      <br />
                      <div className='btn-group btn-group-toggle' data-toggle='buttons'>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            name='customRadio'
                            className='custom-control-input'
                            value={GroupAssistantMode.FOREGROUND}
                            checked={this.state.mode === GroupAssistantMode.FOREGROUND}
                            onChange={(e) => this.onModeChanged(e)}
                          />
                          <label className='custom-control-label' htmlFor='customRadio1'>
                            {this.formatMessage('GroupBotView.Foreground')}
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            name='customRadio'
                            className='custom-control-input'
                            value={GroupAssistantMode.BACKGROUND}
                            checked={this.state.mode === GroupAssistantMode.BACKGROUND}
                            onChange={(e) => this.onModeChanged(e)}
                          />
                          <label className='custom-control-label' htmlFor='customRadio2'>
                            {this.formatMessage('GroupBotView.Background')}
                          </label>
                        </div>
                      </div>
                    </div>
                    {this.state.mode === GroupAssistantMode.FOREGROUND && (
                      <div className='form-group'>
                        <label htmlFor='confidence'>
                          {this.formatMessage('GroupBotView.ConfidenceThreshold')}{' '}
                          <OverlayTrigger key={'top'} placement={'top'} overlay={buildConfidenceTooltip()}>
                            <i className='far fa-question-circle' data-toggle='tooltip' data-placement='top' />
                          </OverlayTrigger>
                        </label>
                        <Input
                          className='form-control'
                          type='number'
                          id='confidence'
                          style={{ fontFamily: 'Roboto, sans-serif', color: '#999' }}
                          placeholder={this.formatMessage('GroupBotView.EnterPercentage')}
                          value={this.state.confidenceThreshold.toString()}
                          onChange={(e) => this.setConfidenceThreshold(e)}
                          rules={[notEmpty()]}
                        />
                      </div>
                    )}
                    <div className='form-group'>
                      <div className='text-right'>
                        <button type='submit' className='btn btn-secondary' onClick={this.submitFields}>
                          {this.formatMessage('GroupBotView.Save')}
                        </button>
                      </div>
                    </div>{' '}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
