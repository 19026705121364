import React, { Component } from 'react';
import { LogsBubbleView } from './LogsBubble.view';
import { LOGS_BUBBLE_MAX_HEIGHT } from '../../../../../constants/constants';

interface LogsBubbleProps {
  answer: any;
}

interface MyState {
  height: number;
  fullView?: boolean;
}

export class LogsBubble extends Component<LogsBubbleProps, MyState> {
  public render = LogsBubbleView.bind(this);
  public containerRef: any = React.createRef();

  constructor(props: LogsBubbleProps) {
    super(props);
    this.state = {
      fullView: true,
      height: 0,
    };
  }

  public componentDidMount() {
    this.setheight();
  }

  public setheight() {
    if (this.state.height < LOGS_BUBBLE_MAX_HEIGHT) {
      const height = this.containerRef.current.clientHeight;
      if (height > LOGS_BUBBLE_MAX_HEIGHT) {
        this.setState({ fullView: false, height });
      }
    }
  }

  public componentDidUpdate = (prevprops: LogsBubbleProps) => {
    if (prevprops.answer !== this.props.answer) {
      this.setState({ fullView: true, height: 0 }, () => {
        this.setheight();
      });
    }
  }
}
