import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { GroupBotSelectorBase, Bot } from './GroupBotSelector.component';
import Select from 'react-select';

export const GroupBotSelectorView: ViewFunction<GroupBotSelectorBase> = function() {
  const { isClearable, isSearchable } = this.state;

  return (
    // tslint:disable-next-line:max-line-length
    // product manager wanted us to use a simple search and scroll rather than an infinate scroll, due to the small amount of bots expected.
    <React.Fragment>
      <p>Bot</p>
      {this.state.items && (
        <Select<Bot>
          className='basic-single mb-3'
          classNamePrefix='select'
          value={this.state.bot}
          isClearable={isClearable}
          isSearchable={isSearchable}
          name='bots'
          options={this.state.items}
          onChange={this.onBotSelected as Select<Bot>['onChange']}
        />
      )}
    </React.Fragment>
  );
};
