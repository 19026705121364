
import React, { Component } from 'react';
import './styles.css';

interface Props {
  onProgressChange: (progress?: number) => void;
  onLoaderFinished: () => void;
  progress: number;
  className: string;
  height: number;
  onRef: (ref: any) => void;
  color: string;
}

interface State {
  show: boolean;
  full: boolean;
  progress: number;
  wait: boolean;
  complete: boolean;
}

class LoadingBar extends Component<Props, State> {
  public static defaultProps: Partial<Props> = {
    progress: 0,
    color: '#f11946',
    height: 3,
    className: '',
  };
  public state = {
    show: true,
    full: false,
    progress: 0,
    wait: false,
    complete: false,
  };

  private _mounted: boolean = false;

  public add = (value: any) => {
    this.setState({ progress: this.state.progress + value }, () => {
      this.onProgressChange();
    });
  }

  public onProgressChange = () => {
    if (this.props.onProgressChange) {
      this.props.onProgressChange(this.state.progress);
    }

    this.checkIfFull();
  }

  public decrease = (value: number) => {
    this.setState({ progress: this.state.progress - value }, () => {
      this.onProgressChange();
    });
  }

  public continuousStart = (startingValue: number) => {
    const random = startingValue || this.randomInt(20, 30);
    this.setState({ progress: random, complete: false });

    const interval = setInterval(() => {
      if (this.state.progress < 90) {
        const randomProg = this.randomInt(2, 10);
        if (!this._mounted) { return false; }
        const progress = this.state.progress + randomProg;
        if (this.state.complete) {
          // progress = 100;
          clearInterval(interval);
        }
        this.setState({ progress }, () => {
          this.onProgressChange();
        });
      } else {
        clearInterval(interval);
      }
    }, 1000);
  }

  public staticStart = (startingValue: number) => {
    const random = startingValue || this.randomInt(30, 50);

    this.setState({ progress: random }, () => {
      this.onProgressChange();
    });
  }

  public complete = () => {
    this.setState({ progress: 100, complete: true }, () => {
      this.onProgressChange();
    });
    setTimeout(
      () => {
        this.setState({
          progress: 0,
        });
      },
      1000);
  }

  public onLoaderFinished = () => {
    if (this.props.onLoaderFinished) { this.props.onLoaderFinished(); }

    this.setState({ progress: 0 }, () => {
      this.onProgressChange();
    });
  }

  public render() {
    const { className, height } = this.props;
    const { show, full } = this.state;
    return (
      <div style={{ height}}>
        {show ? (
          <div
            className={
              'loading-bar' +
              ' ' +
              (className || '') +
              ' ' +
              (full ? 'loading-bar-full' : '')
            }
            style={this.barStyle()}
          />
        ) : null}
      </div>
    );
  }

  public componentWillReceiveProps(nextProps: Props) {
    // Watching Progress Changes
    if (nextProps.progress !== this.props.progress) {
      this.setState({ progress: nextProps.progress }, () => {
        if (this.props.onProgressChange != null) {
          this.props.onProgressChange();
        }
        this.checkIfFull();
      });
    }
  }

  public componentDidMount() {
    if (this.props.onRef) { this.props.onRef(this); }
    this._mounted = true; // fix cancel a fetch on componentWillUnmount
    if (this.state.progress !== this.props.progress) {
      this.setState({ progress: this.props.progress });
    }
  }

  public componentWillUnmount() {
    if (this.props.onRef) { this.props.onRef(undefined); }
    this._mounted = false;
  }
  // Check whether the proggress is full
  public checkIfFull = () => {
    if (!this._mounted) { return false; }
    if (this.state.progress >= 100) {
      // Prevent new progress change
      this.setState({ wait: true });

      // Start animate it
      setTimeout(() => {
        if (!this._mounted) { return false; }
        // animate when element removed
        this.setState({
          full: true,
        });

        setTimeout(() => {
          if (!this._mounted) { return false; }
          this.setState({
            // remove bar element
            show: false,
            progress: 0,
            wait: false,
          });

          setTimeout(() => {
            if (!this._mounted) { return false; }
            this.setState({
              // Show Bar
              full: false,
              show: true,
            });
            this.onLoaderFinished();
          });

          // Duration to Waiting for hiding animation
        }, 250);

        // Duration is depend on css animation-duration of loading-bar
      }, 700);
    }
  }

  private randomInt(low: number, high: number) {
    return Math.floor(Math.random() * (high - low) + low);
  }

  // apply width style to our element as inline style
  private barStyle() {
    // When loading bar still in progress
    const { color } = this.props;

    if (!this.state.wait) {
      return {
        width: `${this.state.progress}%`,
        backgroundColor: color,
      };
    } else {
      return { width: '100%', backgroundColor: color };
    }
  }
}

export default LoadingBar;
