import { withAlert } from 'react-alert';
import {injectIntl} from 'react-intl';
import { withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../../../api/Server';
import { SessionContext } from '../../../../../auth/state/Session/SessionContext';
import { LayoutContext } from '../../../../../base/state/Layout/LayoutContext';
import { withContext } from '../../../../../lib/component/withContext/withContext.hoc';
import { AnswerBody } from '../../../../model/AnswerModel';
import { AnswerType } from '../../../../model/AnswerType';
import { AnswerContext } from '../../../../state/AnswerContext';
import { EditAnswerBase } from '../../EditAnswer.component';
import { CmsAnswerView } from './CmsAnswer.view';

interface AnswerCmsJson extends AnswerBody {
  cmsId: string;
}

export class CmsAnswerBase extends EditAnswerBase<AnswerCmsJson> {

  public render = CmsAnswerView.bind(this);

  public answer: AnswerCmsJson = {
    cmsId: '',
    type: AnswerType.CMS,
  };

  public componentWillMount(): void {
    if (this.props.answer!.uuid) {

      const answer: AnswerCmsJson = {
        cmsId: this.props.answer!.answerContent.cmsId,
      };

      this.answer = answer;
    }
    this.setState({
      answer: this.answer,
    });

  }

  public changeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.cmsId = value;

    this.setState({
      answer: this.answer,
    });
  }

  public getContent(): AnswerCmsJson {
    const json: AnswerCmsJson = {
      cmsId: this.answer.cmsId,
    };
    return json;
  }

  public getAnswerCms(): string {
    return this.state.answer.cmsId;
  }

}

const CmsAnswerWithIntl = injectIntl(CmsAnswerBase);
const CmsAnswerWithAlert = withAlert<CmsAnswerBase['props']>()(CmsAnswerWithIntl);
const CmsAnswerWithSession = withContext(SessionContext)(CmsAnswerWithAlert);
const CmsWithContext = withContext(AnswerContext)(CmsAnswerWithSession);
const CmsWithLayout = withContext(LayoutContext)(CmsWithContext);
const CmsAnswerWithApi = RefreshApi(Api(CmsWithLayout));
export const CmsAnswer = withRouter(CmsAnswerWithApi);
