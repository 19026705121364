import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ViewFunction } from '../../../../model/component/ViewFunction';

import { ImportBotBase } from './ImportBot.component';

export const ImportBotView: ViewFunction<ImportBotBase> = function() {
  return (
    <Modal show={this.isModalVisible()} onHide={this.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title as='h5'>
          <FormattedMessage id='assistant.studio.import' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <input
            placeholder={this.formatMessage('importbot.fileName')}
            className='form-control'
            type='file'
            id='formFile'
            style={{ width: 'auto', paddingBottom: '33px' }}
            onChange={this.handleChange}
          />
        </div>
        {this.state.fileError && (
          <div>
            <small className='links mt-3'>
              <FormattedMessage id='assistant.studio.import.mustbejson' />
            </small>
          </div>
        )}
      </Modal.Body>
      <div className='modal-footer'>
        <button
          className='btn btn-primary'
          id={'confirmImport'}
          onClick={this.onClickImport}
          disabled={this.state.fileError}
        >
          <FormattedMessage id='assistant.studio.import' />
        </button>
      </div>
    </Modal>
  );
};
