import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { Line } from './Line/Line.component';
import { Table } from './Table.component';

export const TableView: ViewFunction<Table> = function() {
  const columns = this.getColumns().map((p, i) => {
    const capitalize = (s: string) => {
      if (typeof s !== 'string') {
        return '';
      }
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    return p !== 'status' ? (
      <th scope='col' key={'column' + i} style={p.style} className={p.className}>
        {capitalize(p.title)}
      </th>
    ) : null;
  });

  const lines = new Array<any>();
  if (this.getData() && this.getData().forEach) {
    this.getData().forEach((item, key) => {
      lines.push(
        <Line
          key={key}
          position={key}
          item={item}
          columns={this.getColumns()}
          dropDownMenuItems={this.props.dropDownMenuItems}
        />,
      );
      if (this.haveDetail()) {
        lines.push(this.getDetail().render(item));
      }
    });
  }

  return (
    <React.Fragment>
      <div className='table-responsive table-min-height'>
        <table id={`${this.props.tableId}-list`} className='table' style={{ marginBottom: '50px' }}>
          <thead>
            <tr data-type='item'>
              {columns}
              {this.props.dropDownMenuItems !== undefined ? <th style={{ width: '40px' }} scope='col' /> : null}
            </tr>
          </thead>
          <tbody>
            {lines.map((item, index) => (
              <tr key={index}>{item}</tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default TableView;
