import { MouseEvent } from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { Response } from '../../../../model/http/Response';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import {
  withPaginationRouting,
  WithPaginationRoutingProps,
} from '../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { Status } from '../../model/Status';
import { DropDownMenuItem } from '../../../base/component/Table/Table.component';
import { AssistantDataListView } from './AssistantListData.view';
import { AssistantModel } from './AssistantModel';
import { EditAssistantStateModel } from '../EditAssistant/state/EditAssistantStateModel';
import { PrimitiveType } from 'intl-messageformat';

interface GetAssistantList extends Response {
  content: AssistantModel[];
  timestamp: Date;
  size: string;
  page: number;
  pageElements: number;
  totalElements: number;
}

interface ListState extends ApiState {
  data: AssistantModel[];
  totalPages: number;
  showConfirmDialog: boolean;
  showConfirmArchiveDialog: boolean;
  currentItem: AssistantModel | null;
  statusToChange: string;
  botToArchive: string;
  clickPageDisabled: boolean;
}

interface IntlProps {
  intl: IntlShape;
}

export enum AssistantListOperationType {
  DELETE = 'DELETE_BOT',
  UPDATE = 'UPDATE_BOT',
  GET = 'GET_BOT_LIST',
  NONE = 'NONE',
  ARCHIVE = 'ARCHIVE',
}

type Props = IntlProps &
  ApiProps<GetAssistantList> &
  SessionStateModel &
  LayoutStateModel &
  WithPaginationRoutingProps<Navigation> &
  WithAlertProps &
  EditAssistantStateModel;

export class AssistantListDataBase extends ApiBase<GetAssistantList, Props, ListState> {
  public render = AssistantDataListView.bind(this);

  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      totalPages: 0,
      error: null,
      authError: null,
      showConfirmDialog: false,
      showConfirmArchiveDialog: false,
      currentItem: null,
      statusToChange: '',
      botToArchive: '',
      clickPageDisabled: false,
    };
  }

  public componentWillMount(): void {
    this.props.setPageTitle(this.formatMessage('topStats.assistants'));
    this.updateList();
  }

  public componentDidUpdate(prevProps: Props) {
    this.props.setPageTitle(this.formatMessage('topStats.assistants'));
    super.componentDidUpdate(prevProps);
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  public onRouteChanged() {
    this.updateList();
  }

  public getCurrentPage(): number {
    return this.props.page;
  }

  public getCurrentPageSize(): number {
    return this.props.pageSize;
  }

  public getCurrentCriteria(): string {
    return this.props.getExtraParam('criteria') || '';
  }

  public editHandler = (item: AssistantModel) => {
    this.props.history.push(`/bots/edit/${item.uuid}`);
  }

  public changePaginationHandler = (pageNumber: number) => {
    if (!this.state.clickPageDisabled) {
      this.setState({ clickPageDisabled: true });
      this.props.goToPage(pageNumber);
      setTimeout(() => {
        this.setState({ clickPageDisabled: false });
      }, 500);
    }
  }

  public searchHandler = (criteria: string) => {
    this.props.goToPage(0, {
      criteria,
    });
  }

  public updateStatus(item: AssistantModel, value: string): void {
    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload: null,
      relativePath: `/admin/assistants/${item.uuid}/status/${value}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: AssistantListOperationType.UPDATE,
    };
    this.doCall(request);
  }

  public updateList = () => {
    const criteria = encodeURIComponent(this.getCurrentCriteria());
    const currentPage = this.getCurrentPage();
    const currentPageSize = this.getCurrentPageSize();
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/assistants/?criteria=${criteria}&full=1&page=${currentPage}&size=${currentPageSize}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: AssistantListOperationType.GET,
    };
    this.doCall(request);
  }

  public changePageSize = (pageSize: number) => {
    this.props.changePageSize(pageSize);
  }

  public dropDownMenuItems = (): DropDownMenuItem[] => {
    const dropDownItemList: DropDownMenuItem[] = [
      {
        text: this.formatMessage('edit'),
        callBack: this.editHandler,
      },
      {
        text: this.formatMessage('archive'),
        callBack: this.archiveHandler,
      },
    ];
    return dropDownItemList;
  }

  public archiveHandler = (item: AssistantModel) => {
    this.setState({
      botToArchive: item.uuid,
    });
    this.setConfirmArchiveVisibility(true);
  }

  public setConfirmVisibility = (show: boolean) => {
    this.setState({ showConfirmDialog: show });
  }

  public setConfirmArchiveVisibility = (show: boolean) => {
    this.setState({ showConfirmArchiveDialog: show });
  }

  public confirm = (item: AssistantModel) => (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (item) {
      const statusToChange = item.status === Status.ENABLED
        ? this.formatMessage('disable').toLowerCase() : this.formatMessage('enable').toLowerCase();
      this.setState({
        currentItem: item,
        statusToChange,
      });
      this.setConfirmVisibility(true);
    }
  }

  public confirmCallback = () => {
    this.setToggleClass(this.state.currentItem);
  }

  public confirmArchiveCallback = () => {
    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload: null,
      relativePath: `/admin/assistants/${this.state.botToArchive}/archive`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: AssistantListOperationType.ARCHIVE,
    };
    this.doCall(request);
  }

  public setToggleClass = (item: AssistantModel | null) => {
    if (item) {
      const statusToChange = item.status === Status.ENABLED ? Status.DISABLED : Status.ENABLED;
      this.updateStatus(item, statusToChange);
    }
  }

  public getLineToggleClass(item: AssistantModel): string {
    if (item.status === Status.ENABLED) {
      return 'fas fa-toggle-on fa-2x on text-success';
    }
    return 'fas fa-toggle-off fa-2x off text-secondary';
  }

  public dataPropertyValue = (item: AssistantModel) => {
    if (item.status === Status.ENABLED) {
      return true;
    }
    return false;
  }

  public getLineToggleTitle(item: AssistantModel): string {
    if (item.status === Status.ENABLED) {
      return this.formatMessage('enabled');
    }
    return this.formatMessage('disabled');
  }

  public setResponseData() {
    const response = this.props.response;

    if (
      this.props.callId === AssistantListOperationType.DELETE ||
      this.props.callId === AssistantListOperationType.UPDATE
    ) {
      this.updateList();
    } else if (this.props.callId === AssistantListOperationType.GET) {
      this.setState({
        data: response.content,
        totalPages: Math.ceil(response.totalElements / Number(response.size)),
      });
    }
    if (this.props.callId === AssistantListOperationType.ARCHIVE) {
      this.updateList();
    } else if (response.page) {
      this.props.setBreadCrumbsInfo({
        currentPage: null,
        pageNumber: response.page.toString(),
      });
    }
  }

  public formatMessage(id: string, values?: Record<string, PrimitiveType>) {
    return this.props.intl.formatMessage({ id }, values);
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }
}

const AssistantsWithIntl = injectIntl(AssistantListDataBase);
const AssistantListWithAlert = withAlert<AssistantListDataBase['props']>()(AssistantsWithIntl);
const AssistantListWithApi = RefreshApi(Api(AssistantListWithAlert));
export const AssistantList = withPaginationRouting()(AssistantListWithApi);
