import { StatsTimeFrame } from '../modules/common/model/logs/LogsModel';

interface DataPoint {
  value: number;
  key: string;
}

type DataSet = DataPoint[];

export const getStartDate = (timeFrame: StatsTimeFrame) => {
  const date = new Date();
  switch (timeFrame) {
    case StatsTimeFrame.YEAR:
      date.setMonth(date.getMonth() - 12);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    case StatsTimeFrame.TRIMESTER:
      date.setMonth(date.getMonth() - 3);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    case StatsTimeFrame.MONTH:
      date.setMonth(date.getMonth() - 1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    case StatsTimeFrame.WEEK:
      date.setDate(date.getDate() - 7);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    case StatsTimeFrame.DAY:
    default:
      date.setDate(date.getDate() - 1);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
  }
};

export const getEndDate = () => {
  return new Date(); // now
};

export const getTimeUnit = (timeFrame: StatsTimeFrame) => {
  switch (timeFrame) {
    case StatsTimeFrame.DAY:
      return 60 * 60 * 1000; // 1 hour
    case StatsTimeFrame.WEEK:
      return 24 * 60 * 60 * 1000; // 1 day
    case StatsTimeFrame.MONTH:
      return 24 * 60 * 60 * 1000; // 1 day
    case StatsTimeFrame.TRIMESTER:
      return 24 * 60 * 60 * 1000; // 1 day
    case StatsTimeFrame.YEAR:
      return 24 * 60 * 60 * 1000;
    default:
      return 24 * 60 * 60 * 1000; // 1 day
  }
};

export const discretizeData = (timeFrame: StatsTimeFrame, startDate: Date, endDate: Date, inputDataSet: DataSet) => {
  const timeUnitInMilliseconds = getTimeUnit(timeFrame);
  const resultingDataSet = [];
  let iterationTime = new Date(startDate).getTime();

  let index = 0;

  const dataSet: Array<{ milliseconds: number; value: number }> = [...inputDataSet].map((point) => ({
    ...point,
    milliseconds: new Date(point.key).getTime(),
  }));

  // sort by date
  dataSet.sort((a, b) => a.milliseconds - b.milliseconds);

  do {
    const unitIntervalStart = iterationTime;
    const unitIntervalEnd = iterationTime + timeUnitInMilliseconds;
    const key = new Date(unitIntervalStart).toISOString();
    let value = 0;
    for (; index < dataSet.length && dataSet[index].milliseconds < unitIntervalEnd; index++) {
      if (dataSet[index].milliseconds >= unitIntervalStart) {
        value += dataSet[index].value;
      }
    }
    resultingDataSet.push({ key, value });
    iterationTime = unitIntervalEnd;
  } while (iterationTime < endDate.getTime());
  return resultingDataSet;
};
