import {AssistantParams, SttSelectable} from '../state/EditAssistantStateModel';

import {EngineInputsType, Engines} from '../../../model/engines';
import {FormattedMessage} from 'react-intl';
import React from 'react';
import {Select} from '../../../../form/component/Select.component';
import {StatusService} from './StatusService.component';
import {ViewFunction} from '../../../../../model/component/ViewFunction';
import {SpeechToTextBase} from './SpeechToText.component';

export const SpeechToTextView: ViewFunction<SpeechToTextBase> = function() {
  return (
    <div className='card card-body shadow-sm mobile-margin'>
      <h6>
        <FormattedMessage id='assistant.info.stt' />
      </h6>
      <StatusService
        reload={this.props.innerProps.reloadAssistant}
        type={this.getEngine()}
        status={this.getStatus()}
        authorities={this.props.authorities}
        property={AssistantParams.ENABLE_DISABLE_STT}
        idAppend='SpeechToText'
      />
      <form onSubmit={this.submit}>
        <div className='form-group'>
          <Select
            className='custom-select engine-select'
            id='engine'
            value={this.getEngine()}
            onChange={this.setEngine}
            disabled={!this.isAdmin()}
          >
            <option value={Engines.IBM.toUpperCase()}>{Engines.IBM}</option>
            <option value={Engines.MICROSOFT.toUpperCase()}>{Engines.MICROSOFT}</option>
            <option value={Engines.GOOGLE.toUpperCase()}>{Engines.GOOGLE}</option>
            <option value={Engines.ENDERTURING.toUpperCase()}>{Engines.ENDERTURING}</option>
          </Select>
        </div>
        <div className='form-group' style={{ display: this.checkEnderTuring(EngineInputsType.STT_MODEL)}}>
          <label htmlFor='language'>
            <FormattedMessage id='assistant.info.stt.language' />
          </label>
          <Select
            className='custom-select language-select'
            id='language'
            value={this.getLanguage()}
            onChange={this.setLanguage}
            disabled={!this.isAdmin()}
          >
            {this.getOptionsLanguage().map((option: SttSelectable) => (
              <option key={option.value} id={option.value} value={option.value}>
                {option.friendlyName}
              </option>
            ))}
          </Select>
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.STT_MODEL) }}>
          <label htmlFor='model'>
            <FormattedMessage id='assistant.info.stt.model' />
          </label>
          <Select
            className='custom-select model-select'
            id='model'
            value={this.getModel()}
            onChange={this.setModel}
            disabled={!this.isAdmin()}
          >
            {this.getOptionsModel().map((option: SttSelectable) => (
              <option key={option.value} id={option.value} value={option.value}>
                {option.friendlyName}
              </option>
            ))}
          </Select>
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.APIKEY) }}>
          <label htmlFor='api-key'>
            <FormattedMessage id='assistant.info.engine.apiKey' />
          </label>
          <input
            className='form-control'
            id='api-key'
            placeholder={this.formatMessage('assistant.info.engine.enterapiKey')}
            defaultValue={this.getApiKey()}
            onChange={this.setApiKey}
            disabled={!this.isAdmin()}
          />
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.URL) }}>
          <label htmlFor='url'>
            <FormattedMessage id='assistant.info.engine.url' />
          </label>
          <input
            className='form-control'
            id='url'
            placeholder={this.formatMessage('assistant.info.engine.enterurl')}
            defaultValue={this.getUrl()}
            onChange={this.setUrl}
            type='url'
            disabled={!this.isAdmin()}
          />
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.SERVICE_URL) }}>
          <label htmlFor='service-url'>
            <FormattedMessage id='assistant.info.engine.serviceurl' />
          </label>
          <input
            className='form-control'
            id='service-url'
            type='url'
            placeholder={this.formatMessage('assistant.info.engine.enterserviceurl')}
            defaultValue={this.getServiceUrl()}
            onChange={this.setServiceUrl}
            disabled={!this.isAdmin()}
          />
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.TOKEN_URL) }}>
          <label htmlFor='token-url'>
            <FormattedMessage id='assistant.info.engine.tokenurl' />
          </label>
          <input
            className='form-control'
            id='token-url'
            placeholder={this.formatMessage('assistant.info.engine.entertokenurl')}
            defaultValue={this.getTokenUrl()}
            onChange={this.setTokenUrl}
            disabled={!this.isAdmin()}
          />
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.ASR_TOKEN) }}>
          <label htmlFor='asr-token'>
            <FormattedMessage id='assistant.info.engine.asrToken' />
          </label>
          <input
            className='form-control'
            id='asr-token'
            placeholder={this.formatMessage('assistant.info.engine.enterAsrToken')}
            defaultValue={this.getAsrToken()}
            onChange={this.setAsrToken}
            disabled={!this.isAdmin()}
          />
        </div>
        {this.isAdmin() &&
          <div className='text-right'>
            <button id='saveSpeechToText' type='submit' className='btn btn-secondary'>
              <FormattedMessage id='save' />
            </button>
          </div>
        }
      </form>
    </div>
  );
};
