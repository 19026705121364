import React, {FormEvent} from 'react';
import { withAlert } from 'react-alert';
import {injectIntl} from 'react-intl';
import { withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../../../api/Server';
import { SessionContext } from '../../../../../auth/state/Session/SessionContext';
import { LayoutContext } from '../../../../../base/state/Layout/LayoutContext';
import { withContext } from '../../../../../lib/component/withContext/withContext.hoc';
import { AnswerBody } from '../../../../model/AnswerModel';
import { AnswerType } from '../../../../model/AnswerType';
import { FormTableState } from '../../../../model/FormTableState';
import { AnswerContext } from '../../../../state/AnswerContext';
import { EditAnswerBase } from '../../EditAnswer.component';
import { CmsAnswerBase } from '../CmsAnswer/CmsAnswer.component';
import { CommandAnswerView } from './CommandAnswer.view';

interface AnswerCommandJson extends AnswerBody {
  params: AnswerCommandOption[];
  name: string;
  key?: string;
  value?: string;
}

interface AnswerCommandOption {
  key: string;
  value: string;
}

export class CommandAnswerBase extends EditAnswerBase<AnswerCommandJson> {

  public render = CommandAnswerView.bind(this);
  public answer: AnswerCommandJson = {
    params: [],
    name: '',
    key: '',
    value: '',
    type: AnswerType.COMMAND,
  };

  private btnTitle: string = this.formatMessage('add');
  private editAnswerRow: number =  -1;
  private formTableState: FormTableState = FormTableState.CREATEROW;

  public componentWillMount(): void {

    if (this.props.answer!.uuid) {
      const answer: AnswerCommandJson = {
        params: this.props.answer!.answerContent.params,
        name: this.props.answer!.answerContent.name,
        key: '',
        value: '',
      };
      this.answer = answer;
    }
    this.setState({
      answer: this.answer,
    });
  }
  public changeCommandName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.name = value;

    this.setState({
      answer: this.answer,
    });
  }

  public changeParameterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.key = value;

    this.setState({
      answer: this.answer,
      parametersKeyClass: (this.state.answer.key === '') ? this.formatMessage('isInvalid') : '',
    });
  }

  public changeCommandValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.value = value;

    this.setState({
      answer: this.answer,
      parametersValueClass: (this.state.answer.value === '') ? this.formatMessage('isInvalid') : '',
    });
  }

  public getContent(): AnswerCommandJson {
    const json: AnswerCommandJson = {
      params: this.answer.params,
      name: this.answer.name,
    };
    return json;
  }

  public getAnswerParameterName(): string {
    return this.state.answer.key;
  }

  public getAnswerCommandName(): string {
    return this.state.answer.name;
  }

  public getAnswerCommandValue(): string {
    return this.state.answer.value;
  }

  public getCommandList(): AnswerCommandOption[] {
    return this.state.answer.params;
  }

  public getButtonText(): string {
    return this.btnTitle;
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.answer.params.length === 0) {
      this.setState({
        parametersKeyClass: this.formatMessage('isInvalid'),
        parametersValueClass: this.formatMessage('isInvalid'),
      });
      return false;
    }
    this.handleSave();
  }

  public addCommand = () => {
    const areInputFieldsNotValid = ((this.state.answer.key === '') || (this.state.answer.value === ''));
    if (areInputFieldsNotValid) {
      this.setState({
        parametersKeyClass: (this.state.answer.key === '') ? this.formatMessage('isInvalid') : '',
        parametersValueClass:  (this.state.answer.value === '') ? this.formatMessage('isInvalid') : '',
      });
      return;
    }
    const choice: AnswerCommandOption = {
      key: this.state.answer.key,
      value: this.state.answer.value,
    };
    this.btnTitle =  'Add';
     // EDIT
    if (this.formTableState === FormTableState.EDITROW) {
      this.answer.params[this.editAnswerRow!] = choice;
      this.formTableState = FormTableState.CREATEROW;
      this.editAnswerRow = -1;
    } else { // Add
      const newList: AnswerCommandOption[] = [...this.answer.params, choice];
      this.answer.params = newList;
    }
    this.answer.value = '';
    this.answer.key = '';

    this.setState({
      answer: this.answer,
    });
  }

  public handleDeleteCommandRow = (rowNumber: number) => () => {
    this.answer.params.splice(rowNumber, 1 );
    this.setState({
      answer: this.answer,
    });
  }

  public isInputValid(): boolean {
    const addButtonEnabled = ((this.state.answer.key !== '') && (this.state.answer.value !== ''));
    return addButtonEnabled;
  }

  public handleEditCommandRow = (rowNumber: number) => () => {
    const row = this.answer.params[rowNumber];
    this.answer.value = row.value;
    this.answer.key = row.key;
    this.btnTitle = this.formatMessage('update');
    this.editAnswerRow = rowNumber;
    this.formTableState = FormTableState.EDITROW;
    this.setState({
      parametersKeyClass: (this.state.answer.key === '') ? this.formatMessage('isInvalid') : '',
      parametersValueClass:  (this.state.answer.value === '') ? this.formatMessage('isInvalid') : '',
      answer: this.answer,
    });
  }
}

const CommandAnswerWithIntl = injectIntl(CommandAnswerBase);
const CommandAnswerWithAlert = withAlert<CmsAnswerBase['props']>()(CommandAnswerWithIntl);
const CommandAnswerWithSession = withContext(SessionContext)(CommandAnswerWithAlert);
const CommandWithContext = withContext(AnswerContext)(CommandAnswerWithSession);
const CommandWithLayout = withContext(LayoutContext)(CommandWithContext);
const CommandAnswerWithApi = RefreshApi(Api(CommandWithLayout));
export const CommandAnswer = withRouter(CommandAnswerWithApi);
