import React from 'react';
import { Modal } from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { Form } from '../../../form/component/Form.component';
import { Input } from '../../../form/component/Input.component';
import { notEmpty } from '../../../form/lib/rules';
import { CreateGroupBase } from './CreateGroup.component';

export const CreateGroupView: ViewFunction<CreateGroupBase> = function() {
  const formct = 'form-control';
  return (

    <Modal show={this.isModalVisible()} onHide={this.handleClose} className='shadow-sm'>
      <Modal.Header className='' closeButton>
        <Modal.Title as='h5'><FormattedMessage id='createGroup.createNewGroup'/></Modal.Title>
      </Modal.Header>

      <Form onSubmit={this.onSubmit}>{(isValid: boolean) => {
        this.setIsFormValid(isValid);
        return (
          <React.Fragment>
            <Modal.Body>
              {this.hasError() && (
                <div className='alert alert-danger'>
                  {this.state.appError!.message}
                </div>
              )}
              <div className='form-group'>
                <label htmlFor='name'><FormattedMessage id='name'/></label>
                <Input
                  type='text'
                  className={formct}
                  id='name'
                  placeholder={this.formatMessage('createAssistant.enterName')}
                  value={this.state.name}
                  onChange={this.changeName}
                  rules={[notEmpty()]}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='desc'><FormattedMessage id='description'/></label>
                <textarea
                  className={formct}
                  id='desc'
                  placeholder={this.formatMessage('createAssistant.enterDescription')}
                  value={this.state.description}
                  onChange={this.changeDescription}
                />
              </div>
            </Modal.Body>
            <div className='modal-footer'>
              <button
                id='cancel'
                type='button'
                className='btn btn-dark'
                data-dismiss='modal'
                onClick={this.handleClose}
              >
                {this.formatMessage('cancel')}
              </button>
              <button
                id='save'
                type='submit'
                className='btn btn-primary'
              >
                {this.formatMessage('saveChanges')}
              </button>
            </div>
          </React.Fragment>
        );
      }}
      </Form>
    </Modal>

  );
};
