import React, { MouseEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import SideBarView from './SideBar.view';
import { LayoutStateModel } from '../../state/Layout/LayoutStateModel';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { Roles } from '../../model/route/Role';

interface MenuProps {
  collapsed: boolean;
  visible: boolean;
}

interface State {
  menuOpened: boolean;
}

type Props = MenuProps & SessionStateModel & RouteComponentProps & LayoutStateModel;

export class SideBarBase extends React.Component<Props, State> {
  public readonly libraryPrefList = ['/intents', '/entities', '/answers'];

  public render = SideBarView.bind(this);
  private messengerUrl: string;
  private studioUrl: string;

  public constructor(props: Props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    // @ts-ignore
    this.messengerUrl = process.env.REACT_APP_MESSENGER_URL;
    // @ts-ignore
    this.studioUrl = process.env.REACT_APP_STUDIO_URL;

    this.state = {
      menuOpened: false,
    };
  }

  public componentDidMount(): void {
    if (this.isActive(this.libraryPrefList)) {
      this.setState({
        menuOpened: true,
      });
    }
  }

  public getMessengerURL(): string {
    return this.messengerUrl;
  }

  public getStudioURL(): string {
    return this.studioUrl;
  }

  public toggleMenu = () => (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.setState({ menuOpened: !this.state.menuOpened });
  }

  public isMenuOpened() {
    return this.state.menuOpened;
  }

  public getMenuOpenedClass() {
    return this.state.menuOpened ? '' : 'collapsed';
  }

  public haveAdminFunctions = () => {
    return this.props.authorities.includes(Roles.admin);
  }

  public haveSuperAdminFunctions = () => {
    return this.props.authorities.includes(Roles.super);
  }

  public isActive(prefixList: string[], pathName?: string): boolean {
    const currentRoute = pathName || this.props.location.pathname;
    return !!prefixList.find((prefix) => currentRoute.startsWith(prefix));
  }

  public getActiveClass(prefixList: string[]): string {
    const isActive: boolean = this.isActive(prefixList);
    const className = isActive ? 'active' : '';
    return className;
  }
}

export const SideBar = withRouter(SideBarBase);
