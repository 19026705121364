import React from 'react';
import {AnswerLinksOption} from '../../../../../../answer/model/AnswerModel';
import BubbleLinksView from './BubbleLinksView';

interface Props {
  links?: AnswerLinksOption[];
}

export class BubbleLinksBase extends React.Component<Props, {}> {

  public render = BubbleLinksView.bind(this);

  public getLinks() {
    return this.props.links ? this.props.links : [];
  }

}

export const BubbleLinks = BubbleLinksBase;
