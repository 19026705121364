import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { ExportBase } from './Export.component';

export const ExportView: ViewFunction<ExportBase> = function() {
  return (
    <>
       <div className='btn-group' style={{ float: 'right' }} role='group' aria-label='Fourth group'>
            <button
              id='export'
              type='button'
              title={this.getTitle()}
              className='btn btn-sm btn-secondary ml-1'
              onClick={this.export}
              disabled={this.state.isDisabled && this.state.disableExport}
            >
              {this.formatMessage('export')}
            </button>
          </div>
    </>
  );
};
