import React from 'react';
import {ViewFunction} from '../../../../model/component/ViewFunction';
import Confirm from '../../../base/component/confirm/Confirm.component';
import {TwoFactorBase} from './TwoFactor.component';

export const TwofactorView: ViewFunction<TwoFactorBase> = function() {
  return (

    <div>
      <Confirm
        show={this.state.showConfirmDialog}
        okLabel={this.formatMessage('ok')}
        cancelLabel={this.formatMessage('cancel')}
        confirmation={this.formatMessage('alert.confirm2FAAuthChangeStatus',
          { stateTwoFactorAuth: (this.state.twoFactorAuth === true ? 'disable' : 'enable')})}
        proceedFunc={this.confirmCallback}
        title={'Confirm'}
        setConfirmVisibility={this.setConfirmVisibility}
      />

      <div className='form-group'>
        <h6>2 Factor Authentication via SMS</h6>
        <div className='toggle-status' style={{float: 'left'}} onClick={this.confirm(this.props.userData)}>
          <i
            id={`state-${this.props.userData}`}
            data-property-name='statusBt'
            data-property-value={this.dataPropertyValue(this.state.currentItem)}
            className={this.getToggle(this.state.currentItem)}
            title={this.getToggleTitle(this.state.currentItem)}
          />
        </div>
      </div>
      <br/>
    </div>

  );
};
