import React, { ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { defaultPageSizeList } from '../PageSize/PageSize.component';

interface ExtraParams {
  [paramName: string]: string | null;
}

export interface WithPaginationRoutingProps<N = any> extends RouteComponentProps<N> {
  page: number;
  pageSize: number;
     goToPage: (page: number, extraParams?: ExtraParams) => void;
  changePageSize: (page: number, extraParams?: ExtraParams) => void;
  getExtraParam: (param: string) => string | null;
}

export interface WithPaginationRoutingOptions {
  prefix: string;
  firstPage: number;
  pageSizeList: number[];
  pageParam: string;
  pageSizeParam: string;
}

export const defaultOptions: WithPaginationRoutingOptions = {
  prefix: '',
  firstPage: 0,
  pageSizeList: defaultPageSizeList,
  pageParam: 'page',
  pageSizeParam: 'pageSize',
};

export const withPaginationRouting = (givenOptions: Partial<WithPaginationRoutingOptions> = {}) =>
  <P extends object>(WrappedComponent: ComponentType<P & WithPaginationRoutingProps>) => {

    const options: WithPaginationRoutingOptions = {
      ...defaultOptions,
      ...givenOptions,
    };

    const getParamName = (param: string) => options.prefix + param;

    const WithPaginationRouting = class extends React.Component<P & RouteComponentProps, never> {

      public render() {
        const page = this.getPageNumber();
        const pageSize = this.getPageSize();
        const props = this.props;
        return (
          <WrappedComponent
            page={page}
            pageSize={pageSize}
            goToPage={this.goToPage}
            changePageSize={this.changePageSize}
            getExtraParam={this.getExtraParam}
            {...props}
          />
        );
      }

      private changePageSize = (pageSize: number, extraParams: ExtraParams = {}) => {
        this.redirectWithDifferentParams({
          [options.pageSizeParam]: pageSize.toString(),
          [options.pageParam]: options.firstPage.toString(),
          ...extraParams,
        });
      }

      private goToPage = (page: number, extraParams: ExtraParams = {}) => {
        this.redirectWithDifferentParams({
          [options.pageParam]: page.toString(),
          ...extraParams,
        });
      }

      private redirectWithDifferentParams = (params: ExtraParams) => {
        const { location } = this.props;

        const searchParams = new URLSearchParams(location.search);
        for (const param in params) {
          if (!param) {
            continue;
          }

          const fullParamName = getParamName(param);
          const rawValue = params[param];
          if (rawValue !== null && rawValue !== undefined) {
            const paramValue = encodeURIComponent(rawValue);
            searchParams.set(fullParamName, paramValue);
          } else {
            searchParams.delete(fullParamName);
          }
        }

        const pathname = location.pathname;
        const search = searchParams.toString();
        this.props.history.push({
          pathname,
          search,
        });
      }

      private getParam = (param: string) => {
        const fullParamName = getParamName(param);
        const { location } = this.props;
        const searchParams = new URLSearchParams(location.search);

        if (!searchParams.has(fullParamName)) {
          return null;
        }

        const value = searchParams.get(fullParamName);
        if (value) {
          return decodeURIComponent(value);
        }

        return null;
      }

      private getExtraParam = (param: string) => {
        return this.getParam(param);
      }

      private getPageNumber = () => {
        const page = this.getParam(options.pageParam);
        if (page === null) {
          return options.firstPage;
        }
        return Number(page);
      }

      private getDefaultPageSize = () => {
        if (options.pageSizeList.length === 0) {
          return 0;
        }
        return options.pageSizeList[0];
      }

      private getPageSize = () => {
        const { location } = this.props;
        const searchParams = new URLSearchParams(location.search);
        if (!searchParams.has(getParamName(options.pageSizeParam))) {
          return this.getDefaultPageSize();
        }
        const pageSizeParam = searchParams.get(getParamName(options.pageSizeParam));
        return Number(pageSizeParam);
      }

    };
    return withRouter(WithPaginationRouting);
  };
