import React from 'react';
import {injectIntl, IntlShape} from 'react-intl';
import SearchView from './Search.view';

interface State {
  search?: string;
}

interface Props {
  searchHandler: (criteria: string) => void;
  search: string;
  intl: IntlShape;
}

const ENTER_KEYCODE = 13;

export class SearchBase extends React.Component<Props, State> {

  public static defaultProps: Partial<Props> = {
    search: '',
  };

  public render = SearchView.bind(this);

  public constructor(props: Props) {
    super(props);

    this.state = {
      search: this.props.search,
    };
  }

  public checkIfEnterWasPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === ENTER_KEYCODE) {
      this.executeSearch();
    }
  }

  public search = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.executeSearch();
  }

  public changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;
    this.setState({
      search: value,
    });
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({id});
  }

  private executeSearch = () => {
    this.props.searchHandler(this.state.search || '');
  }
}

export const Search = injectIntl(SearchBase);
