import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { UserProfileBase } from './UserProfile.component';
import { FormattedMessage } from 'react-intl';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';

export const UserProfileView: ViewFunction<UserProfileBase> = function() {
  return (
    <React.Fragment>
      <div className='card shadow-sm'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
              <form>
                <div className='form-group'>
                  <label htmlFor='name'>
                    <FormattedMessage id='registration.firstName' />
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    value={this.getFirstName()}
                    onChange={this.handleFirstNameChange}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='email'>
                    <FormattedMessage id='registration.lastName' />
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='email'
                    value={this.getLastName()}
                    onChange={this.handleLastNameChange}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='name'>
                    <FormattedMessage id='login.username' />
                  </label>
                  <input type='text' className='form-control' id='name' value={this.getName()} disabled />
                </div>
                <div className='form-group'>
                  <label htmlFor='email'>
                    <FormattedMessage id='account.profile.profile.email' />
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='email'
                    defaultValue={this.getEmail()}
                    onChange={this.handleEmailChange}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='mobileNumber'>
                    <FormattedMessage id='account.profile.profile.mobilephonenumber' />
                  </label>
                  <PhoneInput country={'us'} value={this.state.mobileNumber} onChange={this.handleMobileChange} />
                </div>
                <div className='form-group'>
                  <label htmlFor='timezone'>
                    <FormattedMessage id='account.profile.profile.timezone' />
                  </label>
                  <TimezonePicker
                    id='timezone'
                    style={{ display: 'block' }}
                    value={this.state.timezone}
                    onChange={this.handleTimezoneChange}
                  />
                </div>
              </form>
            </div>
            <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
              <form>
                <div className='form-group'>
                  <h6>
                    <FormattedMessage id='account.profile.profile.status' />
                  </h6>
                  <p>
                    {this.isUserActive() && <FormattedMessage id='account.profile.profile.enabled' />}
                    {!this.isUserActive() && <FormattedMessage id='account.profile.profile.disabled' />}
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div className='text-right'>
            <button type='button' className='btn btn-secondary' onClick={this.onSubmit}>
              <FormattedMessage id='save' />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
