import React from 'react';
import {FormattedMessage} from 'react-intl';
import { ViewFunction } from '../../../../../../model/component/ViewFunction';
import { Input } from '../../../../../form/component/Input.component';
import { notEmpty, url } from '../../../../../form/lib/rules';
import formLayout from '../formLayout';
import { ImageAnswerBase } from './ImageAnswer.component';

export const ImageAnswerView: ViewFunction<ImageAnswerBase> = function() {
  const childComponent = () => {
    return (
      <React.Fragment>
        <div className='form-group'>
          <label htmlFor='imageTitle'><FormattedMessage id='title'/></label>
          <span className='float-right text-danger'>*</span>
          <Input
            type='text'
            className='form-control'
            id='imageTitle'
            aria-describedby='imageTitle'
            placeholder={`${this.formatMessage('imageAnswerBase.enterYourTitleForThePictureHere')}...`}
            value={this.getAnswerTitle()}
            onChange={this.changeTitle}
            rules={[notEmpty()]}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='thumbnailUrl'><FormattedMessage id='imageAnswerBase.thumbnailImageURL'/></label>
          <span className='float-right text-danger'>*</span>
          <Input
            type='text'
            className='form-control'
            id='thumbnailUrl'
            aria-describedby='thumbnailUrl'
            placeholder={`${this.formatMessage('mageAnswerBase.enterYourThumbnailImageUrlHere')}...`}
            value={this.getAnswerMediaUrl()}
            onChange={this.changeMediaUrl}
            rules={[url()]}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='imageUrl'><FormattedMessage id='imageAnswerBase.imageUrl'/>L</label>
          <span className='float-right text-danger'>*</span>
          <Input
            type='text'
            className='form-control'
            id='imageUrl'
            aria-describedby='imageUrl'
            placeholder={`${this.formatMessage('mageAnswerBase.enterYourImageUrlHere')}...`}
            value={this.getAnswerImageUrl()}
            onChange={this.changeImageUrl}
            rules={[url()]}
          />
        </div>
        <div className='text-right'>
          <button
            id='saveBtn'
            type='submit'
            className='btn btn-primary'
          >
            {`${this.formatMessage('save')}`}
          </button>
        </div>
      </React.Fragment>
    ); };

  return (
      formLayout(this, childComponent)
    );
};
