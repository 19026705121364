import React, { FormHTMLAttributes, ReactNode } from 'react';

interface ComponentProps {
  children: (isValid: boolean) => ReactNode;
}

type Props = ComponentProps & FormHTMLAttributes<HTMLFormElement>;

interface State {
  isValid: boolean;
}

export const VALID_FORM_CLASS = 'valid';
export const INVALID_FORM_CLASS = 'invalid';

export class Form extends React.Component<Props, State> {

  public state: State = {
    isValid: false,
  };

  private formRef = React.createRef<HTMLFormElement>();

  public componentDidUpdate(): void {
    this.updateValidity();
  }

  public componentDidMount(): void {
    // Validation Bubble Replacements
    const form = this.formRef.current as HTMLFormElement;
    if (!form) {
      return;
    }
    // Suppress the default bubbles
    form.addEventListener('invalid', (event) => {
      event.preventDefault();
    }, true);

    // Support Safari, iOS Safari, and the Android browser—each of which do not prevent
    // form submissions by default
    form.addEventListener('submit', (event) => {
      if (!form.checkValidity()) {
        event.preventDefault();
      }
    });

    const submitButton = form.querySelector('button:not([type=button]), input[type=submit]');
    submitButton!.addEventListener('click', () => {
      const invalidFields = form.querySelectorAll(':invalid') as NodeListOf<HTMLElement>;

      for (const invalidField of invalidFields) { // trigger validation
        invalidField.focus();
        invalidField.blur();
      }
      if (invalidFields.length > 0) {
        (invalidFields[0] as HTMLElement).focus();
      }
    });
    this.updateValidity();
  }

  public render() {
    const { children, ...props } = this.props;
    const isValid = this.state.isValid;
    const validityClassName = isValid ? VALID_FORM_CLASS : INVALID_FORM_CLASS;
    const className = this.props.className ? `${this.props.className} ${validityClassName}` : validityClassName;
    return (
      <form  {...props} ref={this.formRef} className={className}>
        {children(this.state.isValid)}
      </form>
    );
  }

  private updateValidity() {
    const isValid = this.isValid();
    if (isValid !== this.state.isValid) {
      this.setState({
        isValid,
      });
    }
  }

  private isValid = () => {
    return !!this.formRef.current && this.formRef.current.checkValidity();
  }

}
