import { ChangeEvent, FormEvent } from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { Response } from '../../../../model/http/Response';
import { SessionContext } from '../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { Navigation } from '../../../base/model/route/Navigation';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { WithAlertProps } from './../../../base/model/WithAlertProps';
import { CreateApiKeyModalView } from './CreateApiKeyModal.view';
import { ApiKeyModel } from './../../model/ApiKey';

interface State extends ApiState {
  apiKey: string;
  isValid: boolean;
  scope: string;
}

interface Payload {
  apiKey: string;
  scope: string;
}

interface CreateResponse extends Response {
  content: ApiKeyModel;
  timestamp: Date;
}

interface IntlProps {
  intl: IntlShape;
  removeFromTopBar?: boolean;
  enable?: boolean;
}

interface ComponentOwnProps {
  onSuccess?: (uuid: string) => void;
  onError?: () => void;
  onClose?: () => void;
  isVisible: boolean;
}

export enum Scope {
  CONVERSATIONS = 'CONVERSATIONS',
  BOT_RUNTIME_MANAGEMENT = 'BOT_RUNTIME_MANAGEMENT',
}

type Props = ComponentOwnProps &
  IntlProps &
  ApiProps<CreateResponse> &
  SessionStateModel &
  RouteComponentProps<Navigation> &
  WithAlertProps;

export class CreateApiKeyModalBase extends ApiBase<CreateResponse, Props, State> {
  public render = CreateApiKeyModalView.bind(this);

  public state: State = {
    apiKey: '',
    error: null,
    authError: null,
    isValid: false,
    scope: Scope.CONVERSATIONS,
  };

  public handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  public isModalVisible = () => {
    return this.props.isVisible;
  }

  public hasError = () => {
    return this.state.appError !== null;
  }

  public setIsFormValid = (isValid: boolean) => {
    if (this.state.isValid !== isValid) {
      this.setState({
        isValid,
      });
    }
  }

  public changeApiKey = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      apiKey: value,
    });
  }

  public updateScope = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const { value } = event.target;
    this.setState({
      scope: value,
    });
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { apiKey } = this.state;
    this.setState(
      {
        error: null,
      },
      () => {
        this.saveApiKey(apiKey);
      },
    );
  }

  public saveApiKey = (apiKey: string) => {
    const payload: Payload = {
      apiKey,
      scope: this.state.scope,
    };

    const request: Request<{}> = {
      id: 'saveApiKey',
      method: HttpRequestType.POST,
      payload,
      relativePath: `/admin/currentuser/saveApiKey`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };
    this.doCall(request);
  }

  public setResponseData = () => {
    if (this.props.callId === 'saveApiKey') {
      if (this.props.onSuccess) {
        this.props.onSuccess(this.props.response.content.uuid);
      }
      this.props.alert.success(this.formatMessage('alert.successfullyCreated'));
      this.setState({
        apiKey: '',
      });
    }
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  protected setErrorResponseData = () => {
    if (this.props.onError) {
      this.props.onError();
    }
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }
}

const CreateApiKeyModalWithIntl = injectIntl(CreateApiKeyModalBase);
const CreateApiKeyModalWithAlert = withAlert<CreateApiKeyModalBase['props']>()(CreateApiKeyModalWithIntl);
const CreateApiKeyModalWithRouting = withRouter(CreateApiKeyModalWithAlert);
const CreateApiKeyModalWithSession = withContext(SessionContext)(CreateApiKeyModalWithRouting);
export const CreateApiKeyModal = RefreshApi(Api(CreateApiKeyModalWithSession));
