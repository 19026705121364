import { HttpRequestType } from './HttpRequestType';

export enum RequestActions {
  BOOT = 'BOOT',
  CHANGE = 'CHANGE',
  DOWNLOAD = 'DOWNLOAD',
}

export enum ResponseType {
  JSON = 'json',
  ARRAYBUFFER = 'arraybuffer',
}

export interface Request<T extends object> {
  method: HttpRequestType;
  token: string | null;
  refreshToken: string | null;
  payload: T | null;
  relativePath: string;
  id?: string | number;
  action?: RequestActions;
  mock?: boolean;
  body?: any;
}
