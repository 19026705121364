import React from 'react';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { NavLink } from 'react-router-dom';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import BreadcrumbsBase from './Breadcrumbs.component';

export const BreadcrumbsView: ViewFunction<BreadcrumbsBase> = function() {

  const {currentPage, pageNumber, currentId} = this.props.breadCrumbsInfo;

  const sample = [
    { path: '/', breadcrumb: 'Dashboard' },
    { path: '/bots/edit/:' + currentId, breadcrumb: currentPage },
    { path: '/account/users/:' + currentId, breadcrumb: currentPage },
    { path: '/answers/edit/*', breadcrumb: currentPage },
  ];

  const options = {
    excludePaths: [
      '/answers/edit',
      '/bots/edit',
      '/answers/list/' + pageNumber ,
      '/answers/list/0',
      '/answers/list',
      '/bots/list/' + pageNumber,
      '/bots/list/0',
      '/bots/list',
      '/bots/edit/*/info/*',
      '/bots/edit/*/studio/*',
    ],       // disable default breadcrumb generation for specific paths
    disableDefaults: false,  // disable all default breadcrumb generation
  };

  const Breadcrumbs = withBreadcrumbs(sample, options)(({ breadcrumbs }) => (
    <React.Fragment>
       <nav aria-label='breadcrumb'>
        <ol className='breadcrumb'>
          {breadcrumbs.map(({ match, breadcrumb }, key) => {
            if (breadcrumbs[1] && breadcrumbs[1].match && breadcrumbs[1].match.path === '/dashboard') {
              return null;
            }
            if (currentPage === 'pageNotFound') {
              return null;
            }
            if (match.path === '/404') {
              return null;
            }
            if (key < breadcrumbs.length - 1) {
              return (
                <li key={'breadcrumb-item' + key} className='breadcrumb-item' aria-current='page'>
                  <span>
                    <NavLink className='links' id={'follow-breadcrump-link-level-' + key} to={match.url}>{breadcrumb}</NavLink>
                  </span>
                </li>
              );
            } else {
              return (
                <li key={'breadcrumb-item' + key} className='breadcrumb-item' aria-current='page'>
                  <span>
                    {breadcrumb}
                  </span>
                </li>
              );
            }
          })}
        </ol>
      </nav>
    </React.Fragment>
  ));
  return <Breadcrumbs />;
};

export default BreadcrumbsView;
