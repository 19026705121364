import PasswordValidator from 'password-validator';
import Validator from 'validator';
const passwordPolicy = new PasswordValidator();
passwordPolicy
      .is().min(8) // Minimum length 8
      .is().max(100) // Maximum length 100
      .has().uppercase() // Must have uppercase letters
      .has().lowercase() // Must have lowercase letters
      .has().digits() // Must have digits
      .has().not().spaces();
const specialCharacters = [
  '^', '$', '*', '.', '[', ']', '{', '}', '(', ')', '?', '-', '"', '!', '@', '#', '%', '&', '/', '\\', ',', '>',
  '<', "'", ':', ';', '|', '_', '~', '`',
];

export const isTruthy = (message = 'Invalid value') => ({
  test: (value: any) => !!value,
  message,
});

export const isFalsy = (message = 'Invalid value') => ({
  test: (value: any) => !value,
  message,
});

export const notEmpty = (message = 'It cannot be empty') => ({
  test: (x: string) => x !== undefined && x !== null && x !== '',
  message,
});

export const minLength = (length: number, message = undefined) => ({
  test: (x: string) => x && x.length >= length,
  message: message || `It requires at least ${length} characters`,
});

export const maxLength = (length: number, message = undefined) => ({
  test: (x: string) => x && x.length <= length,
  message: message || `It requires up to ${length} characters`,
});

export const strictLength = (length: number, message = undefined) => ({
  test: (x: string) => x && x.length <= length,
  message: message || `It requires exactly ${length} characters`,
});

export const equalsTo = (y: number | string, message = 'The values do not match') => ({
  test: (x: number | string) => x === y,
  message,
});

export const isEmail = (message = 'The email is invalid') => ({
  test: (x: string) => Validator.isEmail(x),
  message,
});
// accepts comma separated email addresses
export const isCsEmail = (message = 'Please enter valid email addresses separated by commas') => ({
  test: (x: string) => {
    const emailArray: string[] = x.split(',');
    const errorFound = emailArray.find((email: string) => {
      return !Validator.isEmail(email.trim());
    });
    return !errorFound;
  },
  message,
});

export const lengthBetween = (min: number= 0, max: number= 100,
                              message = `Field must have between ${min} and ${max} characters`) => ({
  test: (x: string) => {
    return  (x.length >= min && x.length <= max);
  },
  message,
});

export const passwordValidity = (message = `Your password must have at least 8 characters,
 and at least one lowercase, one uppercase, no spaces, and digit character`) => ({
  test: (x: string) => {
    return  passwordPolicy.validate(x);
  },
  message,
});

export const ConfirmPassword = (password: string, message = `Passwords do not match`) => ({
  test: (x: string) => {
    return  x === password;
  },
  message,
});

export const regex = (r: RegExp, message = 'The value is invalid') => ({
  test: (x: string) => x.match(r),
  message,
});

export const isInteger = (message = 'It must be a non decimal number') => ({
  test: (x: string) => !x || Validator.isInt(x, {gt: 0}),
  message,
});

export const url = (message = 'Malformed URL (http, https)') => ({
  test: (x: string) => (x !== null && x !== '' && Validator.isURL(x,
    { require_tld: false, protocols: ['http', 'https'], require_protocol: true })),
  message,
});

export const hasSpecialCharacter =
  (message = `One of the following characters is required ${specialCharacters.join(', ')}`) => ({
  test: (x: string) =>
    typeof specialCharacters.find((char) => x.indexOf(char) !== -1) === 'string',
  message,
});

export const hasUpperCaseLetter = (message = 'It requires at least an upper-case letter') => ({
  test: (x: string) => x && x.match(/.*[A-Z].*/),
  message,
});

export const hasLowerCaseLetter = (message = 'It requires at least a lower-case letter') => ({
  test: (x: string) => x && x.match(/.*[a-z].*/),
  message,
});

export const hasDigit = (message = 'It requires at least a lower-case letter') => ({
  test: (x: string) => x && x.match(/.*[0-9].*/),
  message,
});
