import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import Confirm from '../../../../base/component/confirm/Confirm.component';
import { Status } from '../../../model/Status';
import { ChannelApiBase } from './ChannelApi.component';

export const ChannelApiView: ViewFunction<ChannelApiBase> = function() {
  return (
    <div className='col-12'>
      <div className='card'>
        <Confirm
          show={this.state.showConfirmDialog}
          okLabel={this.formatMessage('ok')}
          cancelLabel={this.formatMessage('cancel')}
          confirmation={this.formatMessage(
            'status.confirm.channel',
            this.state.statusToChange === Status.ENABLED ? 'enable' : 'disable',
          )}
          proceedFunc={this.confirmCallback}
          title={this.formatMessage('status.confirm')}
          setConfirmVisibility={this.setConfirmVisibility}
        />
        <div className='card-header'>
          <h6>Public API</h6>
        </div>
        <div className='card-body'>
          <div className='form-group'>
            <div className='toggle-status float-left' onClick={this.confirm(this.state.data)}>
              <i
                id={`state-${this.state.data}`}
                data-property-name='statusBt'
                data-property-value={this.dataPropertyValue(this.state.data)}
                className={this.getToggle(this.state.data)}
                title={this.getToggleTitle(this.state.data)}
              />
            </div>
            <label className='mt-1'>{this.getToggleTitle(this.state.data)}</label>
          </div>
        </div>
      </div>
    </div>
  );
};
