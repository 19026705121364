import { AssistantModel, AssistantParams, EditAssistantStateModel, Languages } from './EditAssistantStateModel';
import React, { PropsWithChildren } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { EditAssistantContext } from './EditAssistantContext';
import { LayoutContext } from '../../../../base/state/Layout/LayoutContext';
import { LayoutStateModel } from '../../../../base/state/Layout/LayoutStateModel';
import { Bubble, Message } from '../test/model/Message';
import { Status } from '../../../model/Status';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { getAudio } from '../../../../../util/audioUtils';
import { isIOS, isIOS13, isSafari } from 'react-device-detect';
import browser from 'browser-detect';
import { PLAYEDALREADY, PLAYING } from '../../../../../constants/constants';
import { AnswerType } from '../../../../answer/model/AnswerType';

export enum Type {
  SDK = 'SDK',
  STUDIO = 'STUDIO',
}

type Props = PropsWithChildren<{}> & RouteComponentProps<{ botId: string }> & LayoutStateModel;

export class EditAssistantStoreProviderBase extends React.Component<Props, EditAssistantStateModel> {
  constructor(props: Props) {
    super(props);

    const botId = this.props.match.params.botId;
    this.state = {
      isToggleActive: this.isToggleActive,
      setToggleState: this.setToggleState,
      setAssistantInfo: this.setAssistantInfo,
      setConfirmVisibility: this.setConfirmVisibility,
      confirm: this.confirm,
      setBubbles: this.setBubbles,
      setRecordingTime: this.setRecordingTime,
      toggleRecordState: this.toggleRecordState,
      closeKeyboardSection: this.closeKeyboardSection,
      openKeyboardSection: this.openKeyboardSection,
      setUserAudio: this.setUserAudio,
      setIsForCancelAudio: this.setIsForCancelAudio,
      audioPlayerPlay: this.audioPlayerPlay,
      toggleAutoPlayState: this.toggleAutoPlayState,
      playAudio: this.playAudio,
      changeAudioState: this.changeAudioState,
      setAllMessagesToPlayed: this.setAllMessagesToPlayed,
      hasProcessingMessage: this.hasProcessingMessage,
      currentAssistant: botId,
      assistantData: null,
      showConfirmDialog: false,
      currentItem: null,
      statusToChange: '',
      bubbles: [],
      isKeyboardOpen: false,
      isRecording: false,
      isForCancelAudio: false,
      isAutoPlayOn: true,
      time: '',
      userAudio: undefined,
    };
  }

  public render() {
    return <EditAssistantContext.Provider value={this.state}>{this.props.children}</EditAssistantContext.Provider>;
  }

  public componentWillUnmount(): void {
    this.props.setPageTitle(null);
  }

  private hasProcessingMessage = () =>
    this.state.bubbles.filter(
      (bubble) => bubble.messages.filter((message) => message.messageBody.type === AnswerType.PROCESSING).length > 0,
    ).length > 0

  private setAllMessagesToPlayed = () =>
    this.setState({
      ...this.state,
      bubbles: this.state.bubbles.map((bubble) => ({
        ...bubble,
        messages: bubble.messages.map((message) => ({
          ...message,
          status: PLAYEDALREADY,
        })),
      })),
    })

  private changeAudioState = (selectedMessage: Message, isForPlaying: boolean) => {
    if (isForPlaying) {
      selectedMessage.status = PLAYING;
      this.setState({
        bubbles: this.state.bubbles.map((bubble) => ({
          ...bubble,
          messages: bubble.messages.map((message) => ({
            ...message,
            status:
              selectedMessage.uuid === message.uuid
                ? PLAYING
                : message.status === PLAYING
                ? PLAYEDALREADY
                : message.status,
          })),
        })),
      });
    } else {
      selectedMessage.status = PLAYEDALREADY;
      this.setState({
        bubbles: this.state.bubbles.map((bubble) => ({
          ...bubble,
          messages: bubble.messages.map((message) => ({
            ...message,
            status: selectedMessage.uuid === message.uuid ? PLAYEDALREADY : message.status,
          })),
        })),
      });
    }
  }

  private playAudio = (message: Message) => {
    const audioPlayer = getAudio();
    this.audioPlayerPlay(audioPlayer).then(() => {
      if (!(isIOS || isIOS13 || isSafari)) {
        if (browser().name !== 'firefox') {
          message.audioStream = (audioPlayer as any).captureStream();
        } else {
          message.audioStream = (audioPlayer as any).mozCaptureStream();
        }
      }
    });
  }

  private setIsForCancelAudio = (isForCancelAudio: boolean) =>
    this.setState({
      userAudio: undefined,
      isRecording: false,
      time: '',
      isForCancelAudio,
    })

  private async audioPlayerPlay(audioPlayer: HTMLAudioElement): Promise<void> {
    audioPlayer.volume = 1;
    return await audioPlayer.play();
  }

  private toggleAutoPlayState = () =>
    this.setState({
      isAutoPlayOn: !this.state.isAutoPlayOn,
    })

  private setConfirmVisibility = (show: boolean) => this.setState({ showConfirmDialog: show });

  private setBubbles = (dialogueBubbles: Bubble[]) => this.setState({ bubbles: dialogueBubbles });

  private setRecordingTime = (time: string) => this.setState({ time });

  private toggleRecordState = () => {
    this.setState({
      isForCancelAudio: this.state.isRecording,
      isRecording: !this.state.isRecording,
    });
  }

  private closeKeyboardSection = () => this.setState({ isKeyboardOpen: false });

  private openKeyboardSection = () => this.setState({ isKeyboardOpen: true });

  private setUserAudio = (userAudio: MediaStream) => this.setState({ userAudio });

  private confirm = () => {
    const statusToChange = this.isToggleActive() ? 'disable' : 'enable';
    this.setState({
      statusToChange,
    });
    this.setConfirmVisibility(true);
  }

  private setToggleState = (isToggleActive: boolean) => {
    if (!this.state.assistantData) {
      return;
    }
    this.setState((prevState: Readonly<EditAssistantStateModel>) => {
      const assistantData: AssistantModel = {
        ...prevState.assistantData!,
        status: isToggleActive ? Status.ENABLED : Status.DISABLED,
      };
      return {
        assistantData,
      };
    });
  }

  private isToggleActive = () => {
    if (!this.state.assistantData) {
      return false;
    }
    return this.state.assistantData.status === Status.ENABLED;
  }

  private setAssistantInfo = (assistantData: AssistantModel) => {
    if (assistantData === undefined || assistantData.name === undefined) {
      return; // invalid set, so we will return
    }
    const maxIdleTimeProps = assistantData.params.find((x) => x.name === AssistantParams.MAX_IDLE_TIME);
    const maxIdleTime = maxIdleTimeProps !== undefined ? maxIdleTimeProps.value : '0';
    const studioConfidenceProps = assistantData.params.find((x) => x.name === AssistantParams.STUDIO_CONFIDENCE);
    const studioConfidence = studioConfidenceProps !== undefined ? studioConfidenceProps.value : '';
    const maxDataProps = assistantData.params.find((x) => x.name === AssistantParams.DATA_EXPIRATION_DAYS);
    const maxData = maxDataProps !== undefined ? maxDataProps.value : '0';
    const typeProps = assistantData.params.find((param) => param.name === AssistantParams.TYPE);
    const type = typeProps?.value === Type.STUDIO ? Type.STUDIO : Type.SDK;
    const studioLangProps = assistantData.params.find((param) => param.name === AssistantParams.STUDIO_LANGUAGE);
    const language = (studioLangProps?.value as keyof typeof Languages) || Languages.en;
    assistantData.studioConfidence = studioConfidence;
    assistantData.maxData = maxData;
    assistantData.maxIdleTime = maxIdleTime;
    assistantData.type = type;
    assistantData.language = language as Languages;
    this.setState({
      assistantData,
    });

    this.props.setPageTitle(`Edit ${assistantData.name}`);
    this.props.setBreadCrumbsInfo({
      currentPage: assistantData.name,
      currentId: assistantData.uuid,
      pageNumber: null,
    });
  }
}

export const EditAssistantStoreProviderWithRouting = withRouter(EditAssistantStoreProviderBase);
export const EditAssistantStoreProvider = withContext(LayoutContext)(EditAssistantStoreProviderWithRouting);
