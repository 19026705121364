import React from 'react';

export const SuccessIcon = () => (
  <svg
    width={24}
    height={24}
    fill='none'
    stroke='#28a745'
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    style={{marginRight: 20, minWidth: 24}}
  >
    <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'/>
    <polyline points='22 4 12 14.01 9 11.01'/>
  </svg>
);
