import Confirm from '../../../../base/component/confirm/Confirm.component';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { StatusServiceBase } from './StatusService.component';
import { ViewFunction } from '../../../../../model/component/ViewFunction';

export const StatusServiceView: ViewFunction<StatusServiceBase> = function() {
  return (
    <React.Fragment>
      <Confirm
        show={this.getConfirmDialog()}
        okLabel={this.formatMessage('status.ok')}
        cancelLabel={this.formatMessage('status.cancel')}
        confirmation={this.formatMessage('status.confirm.service', this.getStatusToChange())}
        proceedFunc={this.confirmCallback}
        title={this.formatMessage('status.confirm')}
        setConfirmVisibility={this.setConfirmVisibility}
      />
      <div className='form-group'>
        <label className='mt-1'>
          <FormattedMessage id='status.enabled' />
        </label>
        {/* tslint:disable-next-line:max-line-length */}
        <div className='toggle-status float-left' onClick={(e) => { this.isAdmin() ? this.confirm() : e.preventDefault(); }}>
          <i
            id={this.componentId()}
            data-property-value={this.getToggleTitle()}
            className={this.getToggleClass()}
            title={this.getToggleTitle()}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
