import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import {FormattedMessage} from 'react-intl';
import { ViewFunction } from '../../../../../../model/component/ViewFunction';
import formLayout from '../formLayout';
import { LinksAnswerBase } from './LinksAnswer.component';

export const LinksAnswerView: ViewFunction<LinksAnswerBase> = function() {
  const childComponent = () => {
    return (
      <React.Fragment>
        <div className='form-group'>
          <h6 className='mb-0'><FormattedMessage id='linksAnswerBase.links'/></h6>
        </div>
        <div className='form-group'>
          <label htmlFor='linkTitle'><FormattedMessage id='title'/></label>
          <input
            type='text'
            className={'form-control ' + this.state.parametersKeyClass}
            id='linkTitle'
            aria-describedby='linkTitle'
            placeholder={`${this.formatMessage('linksAnswerBase.enterYourTitleHere')}...`}
            value={this.getAnswerLinkTitle()}
            onChange={this.changeLinkTitle}
          />
          {this.state.parametersKeyClass !== '' && <div className='invalid-feedback'>
            {`${this.formatMessage('form.itCannotBeEmpty')}`}</div>}
        </div>
        <div className='form-group'>
          <label htmlFor='linkUrl'><FormattedMessage id='url'/></label>
          <input
            type='text'
            className={'form-control ' + this.state.parametersValueClass}
            id='linkUrl'
            aria-describedby='linkUrl'
            placeholder={`${this.formatMessage('linksAnswerBase.enterYourUrlLinkHere')}...`}
            value={this.getAnswerLinkUrl()}
            onChange={this.changeLinkUrl}
          />
          {this.state.parametersValueClass !== '' && (
            <div className='invalid-feedback'>{'Malformed URL (http, https)'}</div>
          )}
        </div>
        <div className='form-group'>
          <div className='text-right'>
            <button id='addLinkBtn' type='button' className='btn btn-primary' onClick={this.addLink}>
              {`${this.getButtoLinkText()} Link`}
            </button>
          </div>
        </div>
        <div className='height-control'>
          <div className='table-responsive'>
            <table className='table mb-4' id='links-list'>
              <thead>
                <tr>
                  <th scope='col'><FormattedMessage id='title'/></th>
                  <th scope='col'><FormattedMessage id='url'/></th>
                  <th style={{ width: '40px' }} scope='col' />
                </tr>
              </thead>
              <tbody>
                {this.getLinksList().map((item, i) => {
                  return (
                    <tr key={i} data-type='item'>
                      <td data-property-value={item.title} data-property-name='title'>
                        {item.title}
                      </td>
                      <td data-property-value={item.url} data-property-name='url'>
                        {item.url}
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            data-action='view-options'
                            bsPrefix={'btn-icon'}
                            id={`dropdownMenuButton-${i}`}
                          >
                            <i className='fas fa-ellipsis-v' />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              bsPrefix={'dropdown-item'}
                              data-action='Edit'
                              onClick={this.handleEditLinkRow(i)}
                            >
                              {`${this.formatMessage('edit')}`}
                            </Dropdown.Item>
                            <Dropdown.Item
                              bsPrefix={'dropdown-item'}
                              data-action='Delete'
                              onClick={this.handleDeleteLinkRow(i)}
                            >
                              {`${this.formatMessage('delete')}`}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className='text-right'>
          <button id='saveBtn' type='submit' className='btn btn-primary'>
            {`${this.formatMessage('save')}`}
          </button>
        </div>
      </React.Fragment>
    );
  };

  return formLayout(this, childComponent);
};
