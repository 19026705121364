import { Api, RefreshApi } from '../../../../../api/Server';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { AppRouteComponentProps } from '../../../../base/model/route/AppRouteComponentProps';
import { AssistantModel, EditAssistantStateModel } from '../state/EditAssistantStateModel';
import { IntlShape, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { EditAssistantContext } from '../state/EditAssistantContext';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { StudioAssistantView } from './StudioAssistant.view';
import { Navigation } from '../../../../base/model/route/Navigation';
import { Request } from '../../../../../model/http/Request';
import { Response } from '../../../../../model/http/Response';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { withAlert } from 'react-alert';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';

enum FormOption {
  WELCOMEMESSAGES = 'welcomemessages',
  FAQ = 'faq',
  fALLBACK = 'fallback',
}

interface ResponseUpdate extends Response {
  content: AssistantModel;
  timestamp: Date;
}
interface ResponseUpdateTraining extends Response {
  content: TrainingModel;
  timestamp: Date;
}
interface TrainingModel {
  status: TrainingStatus;
  modified: boolean;
  message: string | null;
}
interface InfoState extends ApiState {
  isValid: boolean;
  trainingStatus: TrainingStatus;
  modified: boolean;
}

export enum TrainingStatus {
  ONLINE = 'ONLINE',
  DRAFT = 'DRAFT',
  TRAINING = 'TRAINING',
  ERROR = 'ERROR',
  CREATING = 'CREATING',
  PROCESSING = 'PROCESSING',
  WAITING = 'WAITING',
}

interface IntlProps {
  intl: IntlShape;
}

type Props = IntlProps &
  ApiProps<ResponseUpdate | ResponseUpdateTraining> &
  SessionStateModel &
  EditAssistantStateModel &
  RouteComponentProps<Navigation> &
  RouteComponentProps<{ botId: string; settingId: string }> &
  WithAlertProps &
  AppRouteComponentProps;

export class StudioAssistantBase extends ApiBase<ResponseUpdate | ResponseUpdateTraining, Props, InfoState> {
  public render = StudioAssistantView.bind(this);

  public interval: number | undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      authError: null,
      isValid: false,
      trainingStatus: TrainingStatus.DRAFT,
      modified: false,
    };

    this.reloadAssistant = this.reloadAssistant.bind(this);
  }

  public reloadAssistant() {
    const assistantData = this.props.assistantData !== undefined ? this.props.assistantData : undefined;
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/assistants/uuid/${
        assistantData !== undefined && assistantData !== null ? assistantData.uuid : null
      }`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'reload-assistant',
    };
    this.doCall(request);
  }

  public getBotStatus = () => {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/studio/assistant/${this.getAssistantId()}/train/status`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'get-training-status',
    };
    this.doCall(request);
  }

  public hasError(): boolean {
    return this.state.error !== null;
  }

  public setTrainingStatusDraft = () => {
    this.setState({trainingStatus: TrainingStatus.DRAFT});
  }

  public isATrainingStatus = (status: string) => {

    if (status === TrainingStatus.DRAFT || status === TrainingStatus.TRAINING) {
      this.emiter('disableTabs');
    } else { this.emiter('enableTabs'); }

    if (
      status === TrainingStatus.TRAINING ||
      status === TrainingStatus.PROCESSING ||
      status === TrainingStatus.WAITING ||
      status === TrainingStatus.CREATING
    ) {
      return true;
    } else {
      return false;
    }
  }

  public setResponseData() {
    if (this.props.callId === 'get-training-status') {
      const response = this.props.response.content as TrainingModel;
      this.setState({
        trainingStatus: response.status,
        modified: response.modified,
      });
      if (this.isATrainingStatus(this.props.response.content.status) && !this.interval) {
        this.interval = window.setInterval(() => this.getBotStatus(), 60000);
      }
    } else if (this.props.callId === 'reload-assistant') {
      const { uuid, name, description, displayName, status, maxIdleTime, studioConfidence,
        maxData, params, type, language, trainingStatus} =
        this.props.response.content as AssistantModel;
      const assistantData: AssistantModel = {
        uuid,
        name,
        displayName,
        description,
        status,
        maxIdleTime,
        studioConfidence,
        maxData,
        params,
        type,
        language,
        trainingStatus,
      };
      this.props.setAssistantInfo(assistantData);
    } else {
      return;
    }
  }

  public getAssistantId() {
    return this.props.match.params.botId;
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public getSectionRoute = (option: FormOption) => {
    return `/bots/edit/${this.getAssistantId()}/studio/${option}`;
  }

  public getFormOptionType() {
    return FormOption;
  }

  public getFormActiveClass(option: FormOption) {
    return this.props.location.pathname === this.getSectionRoute(option) ? 'active' : '';
  }

  public componentWillUnmount(): void {
    clearInterval(this.interval);
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
  }

  protected isAdmin(): boolean {
    return super.isAdmin(this.props.authorities);
  }
}

const StudioAssistantWithIntl = injectIntl(StudioAssistantBase);
const StudioAssistantWithAlert = withAlert<StudioAssistantBase['props']>()(StudioAssistantWithIntl);
const StudioAssistantWithSession = withContext(SessionContext)(StudioAssistantWithAlert);
const StudioAssistantWithContext = withContext(EditAssistantContext)(StudioAssistantWithSession);
const StudioAssistantWithApi = RefreshApi(Api(StudioAssistantWithContext));
export const StudioAssistant = withRouter(StudioAssistantWithApi);
