import React from 'react';
import { FormattedMessage } from 'react-intl';

export const LogsBubbleFeedback: any = (props: any) => {
  return (
    <React.Fragment>
      <div className='speech-bubble-robo-grey' data-property-name='author' data-property-value='user'>
        <p> <FormattedMessage id={'LogsBubbleFeedback.expectedAnswer'} /> : {props.item.expectedAnswer}</p>
        <p>
          {' '}
          <FormattedMessage id={'LogsBubbleFeedback.questions'} />{' '}
          {props.item.questions.map((item: string) => (
            <span>{item}, </span>
          ))}
        </p>
        <p> <FormattedMessage id={'LogsBubbleFeedback.trustedSource'} /> {props.item.trustedSourceUrl}</p>
      </div>
      <small><FormattedMessage id={'LogsBubbleFeedback.feedback'} /></small>
    </React.Fragment>
  );
};
