import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { AnswerType } from '../../../model/AnswerType';
import { Languages } from '../../../model/Languages';
import {AnswerFiltersBase} from './AnswerFilters.component';

export const AnswerFiltersView: ViewFunction<AnswerFiltersBase> = function() {
  return (
    <React.Fragment>
      <div className='col-lg-4 col-xl-2 mb-3'>
        <select
          className='form-control'
          id='language'
          value={this.state.filterLang}
          onChange={this.changeLanguage}
        >
          <option data-property-value='Language' value='' >{this.formatMessage('answerFilters.language')}</option>
          {this.getAnswerLangs().map((item, idx) => {
            const code = this.getAnswerLanguageCode(item as Languages);
            return (
              <option
                data-property-value={code}
                value={code}
                key={idx}
              >
                {item}
              </option>
            );
          })}
        </select>
      </div>
      <div className='col-lg-4 col-xl-2 mb-3'>
        <select
          id='type'
          className='form-control'
          value={this.state.filterType}
          onChange={this.changeType}
        >
          <option data-property-value='Type' value=''>{this.formatMessage('answerFilters.type')}</option>
          {this.getAnswerTypes().map((item, idx) => {
            const code = this.getAnswerTypeCode(item as AnswerType);
            return (
              <option
                data-property-value={code}
                value={code}
                key={idx}
              >
                {item}
              </option>
            );
          })}
        </select>
      </div>
    </React.Fragment>
  );
};
