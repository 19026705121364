import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { Response } from '../../../../model/http/Response';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import {
  withPaginationRouting,
  WithPaginationRoutingProps,
} from '../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { DropDownMenuItem } from './../../../base/component/Table/Table.component';
import { ApiKeyModel, Status } from './../../model/ApiKey';
import { ApiKeyListView } from './ApiKeyList.view';
import { Scope } from '../CreateApiKey/CreateApiKeyModal.component';

interface State extends ApiState {
  data: ApiKeyModel[];
  totalPages: number;
  showConfirmDialog: boolean;
  currentItem: ApiKeyModel | null;
  confirmAction: string | null;
  confirmMsg: string | null;
}

interface GetApiKeyList extends Response {
  content: ApiKeyModel[];
  timestamp: Date;
  size: string;
  page: number;
  pageElements: number;
  totalElements: number;
}

interface IntlProps {
  intl: IntlShape;
}

type Props = IntlProps &
  ApiProps<GetApiKeyList> &
  LayoutStateModel &
  SessionStateModel &
  RouteComponentProps<Navigation> &
  WithAlertProps &
  WithPaginationRoutingProps;

export class ApiKeyListBase extends ApiBase<GetApiKeyList, Props, State> {
  public render = ApiKeyListView.bind(this);

  constructor(props: Props) {
    super(props);

    this.state = {
      data: [],
      totalPages: 0,
      error: null,
      showConfirmDialog: false,
      currentItem: null,
      authError: null,
      confirmAction: null,
      confirmMsg: '',
    };
  }

  public componentDidMount = async () => {
    this.props.setPageTitle(this.formatMessage('topBarMenu.apiKeys'));

    await this.requestApiKeys();
  }

  public componentDidUpdate(prevProps: Props) {
    super.componentDidUpdate(prevProps);
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  public onRouteChanged() {
    this.requestApiKeys();
  }

  public getPage() {
    return this.props.page;
  }

  public getTotalPages() {
    return this.state.totalPages;
  }

  public getPageSize() {
    return this.props.pageSize;
  }

  public getCurrentCriteria(): string {
    return this.props.getExtraParam('criteria') || '';
  }

  public changePageHandler = (pageNumber: number) => {
    this.props.goToPage(pageNumber);
  }

  public changePageSizeHandler = (pageSize: number) => {
    this.props.changePageSize(pageSize);
  }

  public searchHandler = (criteria: string) => {
    this.props.goToPage(0, {
      criteria,
    });
  }

  public getData() {
    return this.state.data;
  }

  public getScope(scope: string) {
    if (scope === Scope.BOT_RUNTIME_MANAGEMENT) {
      return this.formatMessage('account.api.runtime');
    } else {
      return this.formatMessage('account.api.conversation');
    }
  }

  public dropDownMenuItems = (): DropDownMenuItem[] => {
    const dropDownItemList: DropDownMenuItem[] = [
      {
        text: this.getStatusDropDownText,
        callBack: this.confirmStatusChange,
      },
    ];
    return dropDownItemList;
  }

  public getStatusDropDownText = (item: ApiKeyModel) => {
    switch (item.status) {
      case Status.ENABLED:
        return this.formatMessage('disable');
      case Status.DISABLED:
        return this.formatMessage('enable');
      default:
        return 'n/a';
    }
  }

  public requestApiKeys = async () => {
    const criteria = encodeURIComponent(this.getCurrentCriteria());
    const page = this.getPage();
    const pageSize = this.getPageSize();
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/currentuser/apikeys?search=${criteria}&page=${page}&size=${pageSize}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'requestApiKeys',
    };

    await this.doCall(request);
  }

  public updateStatus = async (item: ApiKeyModel, value: string) => {
    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload: null,
      relativePath: `/admin/currentuser/apikeys/${item.uuid}/status/${value}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'updateApiKeyStatus',
    };
    await this.doCall(request);
  }

  public setConfirmVisibility = (show: boolean) => {
    this.setState({ showConfirmDialog: show });
  }

  public confirmStatusChange = (item: ApiKeyModel) => {
    if (item) {
      const { confirmMsg } = this.getStatusInfo(item);
      this.setState(
        {
          currentItem: item,
          confirmAction: 'statusChange',
          confirmMsg,
        },
        () => {
          this.setConfirmVisibility(true);
        },
      );
    }
  }

  public getStatusInfo = (item: ApiKeyModel) => {
    let confirmMsg = null;
    if (item) {
      switch (item.status) {
        case Status.ENABLED:
          confirmMsg = this.formatMessage('apiKeyListBase.areYouSureDisableApiKey');
          break;
        case Status.DISABLED:
          confirmMsg = this.formatMessage('apiKeyListBase.areYouSureEnableApiKey');
          break;
        default:
          confirmMsg = this.formatMessage('apiKeyListBase.statusNotSupported');
          break;
      }
    }
    return {
      confirmMsg,
    };
  }

  public confirmCallback = () => {
    if (this.state.currentItem) {
      if (this.state.confirmAction === 'statusChange') {
        const changeStatus = this.state.currentItem.status === Status.DISABLED ? 'ENABLED' : 'DISABLED';
        this.updateStatus(this.state.currentItem, changeStatus);
      }
    }
  }

  public getCurrentItemName = () => {
    if (this.state.currentItem) {
      return this.state.currentItem.label;
    }
    return '';
  }

  public toggleShowKey = (item: ApiKeyModel) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (item) {
      const dataItem = this.state.data.find((element) => {
        return element.uuid === item.uuid;
      });
      dataItem!.showKey = !item.showKey;
      this.setState({
        data: this.state.data,
      });
    }
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  protected setResponseData(): void {
    const response = this.props.response;

    if (this.props.callId === 'updateApiKeyStatus') {
      this.requestApiKeys();
    } else if (this.props.callId === 'requestApiKeys') {
      this.setState({
        data: response.content,
        totalPages: Math.ceil(response.totalElements / Number(response.size)),
      });
    }
    if (response.page) {
      this.props.setBreadCrumbsInfo({
        currentPage: null,
        pageNumber: response.page.toString(),
      });
    }
  }

  protected setErrorResponseData(): void {
    if (this.props.callId === 'updateApiKeyStatus' || this.props.callId === 'requestApiKeys') {
      this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
    }
  }
}

const ApiKeyListWithIntl = injectIntl(ApiKeyListBase);
const ApiKeyListWithAlert = withAlert<ApiKeyListBase['props']>()(ApiKeyListWithIntl);
const ApiKeyListWithApi = RefreshApi(Api(ApiKeyListWithAlert));
export const ApiKeyList = withPaginationRouting()(ApiKeyListWithApi);
