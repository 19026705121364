import React from 'react';

export type SetStateFunction<T> = (state: Partial<T>) => void;

interface MapFunctionToProps {
  [identifier: string]: (setState: any) => (...args: any[]) => void;
}

type MapStateToProps = (state: any) => any;

interface WrapperState {
  [identifier: string]: object;
}

export const withApi = (mapStateToProps: MapStateToProps, mapFunctionToProps: MapFunctionToProps) =>
  <P extends object = any>(WrappedComponent: React.ComponentType<any>) => {

    const initialState: { [key: string]: object } = {};
    const functionIdentifiers = Object.keys(mapFunctionToProps);
    for (const identifier of functionIdentifiers) {
      initialState[identifier] = {};
    }

    return class ApiFunctionWrapper extends React.Component<P, WrapperState> {
      public state: WrapperState = initialState;
      private functionProps: {[key: string]: (...args: any[]) => void} = {};

      public componentWillMount(): void {
        for (const identifier of functionIdentifiers) {
          const func = mapFunctionToProps[identifier];
          const setState = (state: object) => this.setState({
            ...this.state,
            [identifier]: {
              ...this.state[identifier],
              ...state,
            },
          });
          this.functionProps[identifier] = (...args: any[]) => func(setState)(...args);
        }
      }

      public render() {
        const stateProps = mapStateToProps(this.state);
        const functionProps = this.functionProps;
        return (
          <WrappedComponent
            {...functionProps}
            {...stateProps}
            {...this.props}
          />
        );
      }
    };

  };
