import React from 'react';
import { TestChatContainerView } from './TestChatContainer.view';
import { WidgetResponse } from './model/WidgetResponse';

interface Props {
  botId: string | undefined;
  apiKey: string | undefined;
  addToUserDebugger: (message: Message) => void;
  addToBotDebugger: (response: WidgetResponse) => void;
}

interface State {
  loaded: boolean;
}

interface Message {
  text: string;
  type: string;
}

export class TestChatContainerBase extends React.Component<Props, State> {
  public render = TestChatContainerView.bind(this);

  public state: State = {
    loaded: false,
  };

  public componentDidMount() {
    const script = document.createElement('script');
    script.setAttribute('id', 'robo-widget');
    script.src = `https://cdn.robo-ai.com/widget/widget-robo-ai-1.2.0-11.js`;
    script.async = true;
    script.onload = () => {
      let config = {
        bot: this.props.botId,
        key: this.props.apiKey,
        api: {
          password: process.env.REACT_APP_PUBLIC_API_AUTH_PASSWORD,
          username: process.env.REACT_APP_PUBLIC_API_AUTH_USERNAME,
          url: process.env.REACT_APP_STUDIO_URL,
        },
        language: 'en',
        viewMode: 'embedded',
        parentElement: '.robo-chat-here',
        debug: true,
        immediateInitialization: true,
      };
      RoboAi.init(config);
      RoboAi.on('message-sent', (message: Message) => {
        this.props.addToUserDebugger(message);
      });
      RoboAi.on('message-received', (response: WidgetResponse) => {
        this.props.addToBotDebugger(response);
      });
    };
    document.body.appendChild(script);
    this.setState({
      loaded: true,
    });
  }

  public componentWillUnmount() {
    if (this.state.loaded) {
      RoboAi.endConversation();
      RoboAi.clearMessageHistory();
      delete window.RoboAi;
      const script = document.getElementById('robo-widget');
      script?.remove();
    } else {
      return;
    }
  }
}

export const TestChatContainer = TestChatContainerBase;
