import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AppError, AuthErrorType, ConnectionErrorType, GenericErrorType } from '../model/AppError';
import { AsyncState } from '../model/AsyncState';
import { HttpError } from '../model/http/HttpError';
import { HttpRequestType } from '../model/http/HttpRequestType';
import { Request, RequestActions, ResponseType } from '../model/http/Request';
import { CallState, ResponseState } from '../model/http/ResponseState';
import {
  Authenticate,
  AuthenticateState,
  Refresh,
  RefreshState,
  Authenticate2FAState,
  Authenticate2FA,
} from '../model/http/TokenState';
import { OauthTokenResponse } from '../modules/auth/model/OauthTokenResponse';
import { SetStateFunction } from '../modules/lib/component/withApi/withApi.hoc';
import { withApi } from '../modules/lib/component/withApi/withApi.hoc';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const mockBackendUrl = process.env.REACT_APP_MOCK_BACKEND_URL;

export const call = (setState: SetStateFunction<CallState>) => {
  return async (request: Request<object>, newToken?: string, acceptAudio?: boolean) => {
    setState({
      state: AsyncState.REQUESTED,
    });
    try {
      const token = newToken ? newToken : request.token;

      const config: AxiosRequestConfig = {
        headers:
          acceptAudio && acceptAudio === true
            ? {
                Accept: 'audio/mp3',
                Authorization: `bearer ${token}`,
                'Content-Type': 'application/json;charset=UTF-8',
              }
            : { Authorization: `bearer ${token}`, 'Content-Type': 'application/json;charset=UTF-8' },
        responseType: request.action === RequestActions.DOWNLOAD ? ResponseType.ARRAYBUFFER : ResponseType.JSON,
      };

      let response: any;
      const backendUrlConfig = request.mock ? mockBackendUrl : backendUrl;
      switch (request.method) {
        case HttpRequestType.GET:
          response = await axios.get<any>(`${backendUrlConfig}${request.relativePath}`, config);
          break;
        case HttpRequestType.POST:
          response = await axios.post<any>(`${backendUrlConfig}${request.relativePath}`, request.payload, config);
          break;
        case HttpRequestType.PUT:
          response = await axios.put<any>(`${backendUrlConfig}${request.relativePath}`, request.payload, config);
          break;
        case HttpRequestType.DELETE:
          response = await axios.delete(`${backendUrlConfig}${request.relativePath}`, config);
          break;
        case HttpRequestType.PATCH:
          response = await axios.patch<any>(`${backendUrlConfig}${request.relativePath}`, request.payload, config);
          break;
        default:
          response = await axios.get<any>(`${backendUrl}${request.relativePath}`, config);
          break;
      }

      setState({
        state: AsyncState.SUCCESS,
        response: response.data,
        statusCode: response.status,
        callId: request.id,
        action: request.action,
      });
    } catch (e) {
      const axiosError = e as AxiosError;
      let status: number = 0;
      let error: AppError = new AppError(GenericErrorType.GENERIC_ERROR);
      error.message = e.message;
      if (e instanceof TypeError) {
        error = new AppError(GenericErrorType.TYPE_ERROR);
        error.message = e.message;
      } else if (axiosError.isAxiosError) {
        if (axiosError.response) {
          status = (axiosError.response as AxiosResponse).status;
          error.code = (axiosError.response as AxiosResponse).status;
        }

        if (
          (axiosError.response as AxiosResponse) &&
          (axiosError.response as AxiosResponse).data &&
          (axiosError.response as AxiosResponse).data.error &&
          (axiosError.response as AxiosResponse).data.error.message
        ) {
          error.message = (axiosError.response as AxiosResponse).data!.error!.message;
        }
        if (e.name === 'NetworkError') {
          error = new AppError(ConnectionErrorType.NETWORK_ERROR);
        } else if (
          !(axiosError.response as AxiosResponse) ||
          (axiosError.response as AxiosResponse).status === HttpError.UNAUTHORIZED
        ) {
          error = new AppError(AuthErrorType.BAD_TOKEN);
        }
      }

      setState({
        state: AsyncState.ERROR,
        error,
        originalRequest: request,
        callId: request.id,
        statusCode: status,
        action: request.action,
      });
    }
  };
};

export const refresh = (setState: SetStateFunction<RefreshState>) => async (refreshToken: string) => {
  setState({
    refreshState: AsyncState.REQUESTED,
  });
  try {
    const formData = new FormData();
    formData.set('grant_type', 'refresh_token');
    formData.set('refresh_token', refreshToken);

    const response = await axios.post<OauthTokenResponse>(backendUrl + '/oauth/token', formData, {
      auth: {
        username: process.env.REACT_APP_BACKEND_BASIC_AUTH_USERNAME!,
        password: process.env.REACT_APP_BACKEND_BASIC_AUTH_PASSWORD!,
      },
    });

    const chkFormData = new FormData();
    chkFormData.set('token', response.data.access_token);
    const checkToken = await axios.post<OauthTokenResponse>(backendUrl + '/oauth/check_token/', chkFormData, {
      // the only way to fetch a user role
      auth: {
        username: process.env.REACT_APP_BACKEND_BASIC_AUTH_USERNAME!,
        password: process.env.REACT_APP_BACKEND_BASIC_AUTH_PASSWORD!,
      },
    });

    setState({
      refreshState: AsyncState.SUCCESS,
      newToken: response.data.access_token,
      newRefreshToken: response.data.refresh_token,
      newExpiresIn: new Date(Date.now() + response.data.expires_in * 1000),
      authorities: checkToken.data.authorities,
    });
  } catch (e) {
    const axiosError = e as AxiosError;
    let error: AppError = new AppError(GenericErrorType.GENERIC_ERROR);
    error.message = e.message;
    if (e instanceof TypeError) {
      error = new AppError(GenericErrorType.TYPE_ERROR);
      error.message = e.message;
    } else if (axiosError.isAxiosError) {
      if (
        (axiosError.response as AxiosResponse) &&
        (axiosError.response as AxiosResponse).data &&
        (axiosError.response as AxiosResponse).data.error &&
        (axiosError.response as AxiosResponse).data.error.message
      ) {
        error.message = (axiosError.response as AxiosResponse).data!.error!.message;
      }
      if (e.name === 'NetworkError') {
        error = new AppError(ConnectionErrorType.NETWORK_ERROR);
      } else if (
        !(axiosError.response as AxiosResponse) ||
        (axiosError.response as AxiosResponse).status === HttpError.UNAUTHORIZED
      ) {
        error = new AppError(AuthErrorType.EXPIRED_REFRESH_TOKEN);
      }
    }

    setState({
      refreshState: AsyncState.ERROR,
      refreshError: error,
    });
  }
};

export const authenticate = (setState: SetStateFunction<AuthenticateState>) => async (
  username: string,
  password: string,
) => {
  await doAuthenticate(setState, form(username, password));
};

export const authenticate2FA = (setState: SetStateFunction<Authenticate2FAState>) => async (
  username: string,
  password: string,
  code: string,
) => {
  await doAuthenticate(setState, form2FA(username, password, code));
};

export const setResponse = (responseState: ResponseState & Authenticate & Refresh, functionToLoad: any) => {
  functionToLoad(responseState);
};

export const mapStateToPros = (state: ResponseState) => {
  return {
    state: state.call.state,
    response: state.call.response,
    error: state.call.error,
    status: state.call.statusCode,
    originalRequest: state.call.originalRequest,
    callId: state.call.callId,
    action: state.call.action,
  };
};

export const mapFunctionToProps = {
  call,
};
export const Api = withApi(mapStateToPros, mapFunctionToProps);

export const mapStateToProsAuth = (state: Authenticate) => {
  return {
    authError: state.authenticate.authError,
    authState: state.authenticate.authState,
    authToken: state.authenticate.token,
    authExpiresIn: state.authenticate.expiresIn,
    authRefreshToken: state.authenticate.refreshToken,
    authAuthorities: state.authenticate.authorities,
  };
};

export const mapStateToProsAuth2FA = (state: Authenticate2FA) => {
  return {
    authError: state.authenticate2FA.authError,
    authState: state.authenticate2FA.authState,
    authToken: state.authenticate2FA.token,
    authExpiresIn: state.authenticate2FA.expiresIn,
    authRefreshToken: state.authenticate2FA.refreshToken,
    authAuthorities: state.authenticate2FA.authorities,
  };
};

export const mapFunctionToPropsAuth = {
  authenticate,
};

export const mapFunctionToProps2FAAuth = {
  authenticate2FA,
};

export const AuthApi = withApi(mapStateToProsAuth, mapFunctionToPropsAuth);

export const Auth2FAApi = withApi(mapStateToProsAuth2FA, mapFunctionToProps2FAAuth);

export const mapStateToProsRefresh = (state: Refresh) => {
  return {
    refreshError: state.refresh.refreshError,
    refreshState: state.refresh.refreshState,
    newToken: state.refresh.newToken,
    newExpiresIn: state.refresh.newExpiresIn,
    newRefreshToken: state.refresh.newRefreshToken,
    authorities: state.refresh.authorities,
  };
};

export const mapFunctionToPropsRefresh = {
  refresh,
};

export const RefreshApi = withApi(mapStateToProsRefresh, mapFunctionToPropsRefresh);

async function doAuthenticate(setState: SetStateFunction<AuthenticateState>, form: FormData) {
  setState({
    authState: AsyncState.REQUESTED,
  });
  try {
    const response = await axios.post<OauthTokenResponse>(backendUrl + '/oauth/token', form, {
      auth: {
        username: process.env.REACT_APP_BACKEND_BASIC_AUTH_USERNAME!,
        password: process.env.REACT_APP_BACKEND_BASIC_AUTH_PASSWORD!,
      },
    });
    const chkFormData = new FormData();
    chkFormData.set('token', response.data.access_token);
    const checkToken = await axios.post<OauthTokenResponse>(backendUrl + '/oauth/check_token/', chkFormData, {
      // the only way to fetch a user role
      auth: {
        username: process.env.REACT_APP_BACKEND_BASIC_AUTH_USERNAME!,
        password: process.env.REACT_APP_BACKEND_BASIC_AUTH_PASSWORD!,
      },
    });
    setState({
      authState: AsyncState.SUCCESS,
      token: response.data.access_token,
      refreshToken: response.data.refresh_token,
      expiresIn: new Date(Date.now() + response.data.expires_in * 1000),
      authorities: checkToken.data.authorities,
    });
  } catch (e) {
    const axiosError = e as AxiosError;
    let error: AppError = new AppError(GenericErrorType.GENERIC_ERROR);
    error.message = e.message;
    if (e instanceof TypeError) {
      error = new AppError(GenericErrorType.TYPE_ERROR);
      error.message = e.message;
    } else if (axiosError.isAxiosError) {
      if (
        (axiosError.response as AxiosResponse) &&
        (axiosError.response as AxiosResponse).data &&
        (axiosError.response as AxiosResponse).data.error &&
        (axiosError.response as AxiosResponse).data.error.message
      ) {
        error.message = (axiosError.response as AxiosResponse).data!.error!.message;
      }
      if (e.name === 'NetworkError' || e.message === 'Network Error') {
        error = new AppError(ConnectionErrorType.NETWORK_ERROR);
        error.message = e.message;
      } else if (
        !(axiosError.response as AxiosResponse) ||
        (axiosError.response as AxiosResponse).status === HttpError.UNAUTHORIZED
      ) {
        error = new AppError(AuthErrorType.BAD_CREDENTIALS);
        if (
          (axiosError.response as AxiosResponse).data &&
          (axiosError.response as AxiosResponse).data.error &&
          (axiosError.response as AxiosResponse).data.error.message
        ) {
          error.message = (axiosError.response as AxiosResponse).data!.error!.message;
        } else {
          error.message = 'Bad credentials';
        }
      }
    }
    setState({
      authState: AsyncState.ERROR,
      authError: error,
    });
  }
}

function form(username: string, password: string) {
  const formData = new FormData();
  formData.set('grant_type', 'password');
  formData.set('username', username);
  formData.set('password', password);
  return formData;
}

function form2FA(username: string, password: string, code: string) {
  const formData = new FormData();
  formData.set('grant_type', 'password');
  formData.set('username', username);
  formData.set('password', password);
  formData.set('verificationCode', code);
  return formData;
}
