import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { ChooseStatsBase } from './ChooseStats.component';

export const ChooseStatsView: ViewFunction<ChooseStatsBase> = function() {
  return (
    <Modal show={this.isModalVisible()} onHide={() => this.props.onClose()} className='shadow-sm'>
      <Modal.Header className='' closeButton>
        <Modal.Title as='h5'>
          <FormattedMessage id='dashboardsettings' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className='form'>
          <div className='form-group'>
            <small><FormattedMessage id='statsAssistantChart.select.label'/></small>
          </div>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              value='numberOfClicks'
              id='number-clicks-check'
              onChange={this.onRadioChange}
              checked={this.isInSelectedStats('numberOfClicks')}
            />
            <label className='form-check-label'>
              <FormattedMessage id='statsAssistantChart.select.label.numberOfClicks'/>
            </label>
          </div>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              value='activeConversations'
              id='number-conversations-check'
              onChange={this.onRadioChange}
              checked={this.isInSelectedStats('activeConversations')}
            />
            <label className='form-check-label'>
              <FormattedMessage id='statsAssistantChart.select.label.numberOfStartedConversations'/>
            </label>
          </div>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              value='numberOfInputs'
              id='number-inputs-check'
              onChange={this.onRadioChange}
              checked={this.isInSelectedStats('numberOfInputs')}
            />
            <label className='form-check-label'>
              <FormattedMessage id='statsAssistantChart.select.label.numberOfInputs'/>
            </label>
          </div>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              value='avgConfidence'
              id='avg-bot-confidence-check'
              onChange={this.onRadioChange}
              checked={this.isInSelectedStats('avgConfidence')}
            />
            <label className='form-check-label'>
              <FormattedMessage id='statsAssistantChart.select.label.avgConfidence'/>
            </label>
          </div>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              value='numberOfThumbsUp'
              id='avg-bot-confidence-check'
              onChange={this.onRadioChange}
              checked={this.isInSelectedStats('numberOfThumbsUp')}
            />
            <label className='form-check-label'>
              <FormattedMessage id='statsAssistantChart.select.label.numberOfThumbsUp'/>
            </label>
          </div>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              value='numberOfThumbsDown'
              id='avg-bot-confidence-check'
              onChange={this.onRadioChange}
              checked={this.isInSelectedStats('numberOfThumbsDown')}
            />
            <label className='form-check-label'>
              <FormattedMessage id='statsAssistantChart.select.label.numberOfThumbsDown'/>
            </label>
          </div>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              value='numberOfNegative'
              id='avg-bot-confidence-check'
              onChange={this.onRadioChange}
              checked={this.isInSelectedStats('numberOfNegative')}
            />
            <label className='form-check-label'>
              <FormattedMessage id='statsAssistantChart.select.label.numberOfNegative'/>
            </label>
          </div>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              value='numberOfNeutral'
              id='avg-bot-confidence-check'
              onChange={this.onRadioChange}
              checked={this.isInSelectedStats('numberOfNeutral')}
            />
            <label className='form-check-label'>
              <FormattedMessage id='statsAssistantChart.select.label.numberOfNeutral'/>
            </label>
          </div>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              value='numberOfPositive'
              id='avg-bot-confidence-check'
              onChange={this.onRadioChange}
              checked={this.isInSelectedStats('numberOfPositive')}
            />
            <label className='form-check-label'>
              <FormattedMessage id='statsAssistantChart.select.label.numberOfPositive'/>
            </label>
          </div>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              value='avgNumberOfInputs'
              id='avg-bot-confidence-check'
              onChange={this.onRadioChange}
              checked={this.isInSelectedStats('avgNumberOfInputs')}
            />
            <label className='form-check-label'>
              <FormattedMessage id='statsAssistantChart.select.label.avgNumberOfInputs'/>
            </label>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
