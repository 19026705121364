import React from 'react';
import { LogsBubbleDetail } from '../bubbledetail/LogsBubble.component';

export const LogsDetailAnswerList: any = (props: any) => {
  const getAnswers = () => {
    if (props.item.thumbs) {
      const ans =  props.item.thumbs.answers.map((value: string) => JSON.parse(value));
      return ans.filter((value: any) => value.type !== 'ssml' && value.type !== 'hints');
    } else {
      return props.item.answers.filter((value: any) => value.type !== 'ssml' && value.type !== 'hints');
    }
  };

  return (
    <React.Fragment>
      {getAnswers().map((value: any) => {
        return (
          <div
            style={{ minWidth: '300px' }}
            className='speech-bubble-robo'
            data-property-name='author'
            data-property-value='robo'
          >
            <LogsBubbleDetail answer={value} />
          </div>
        );
      })}
    </React.Fragment>
  );
};
