import React from 'react';
import {ViewFunction} from '../../../../../../../model/component/ViewFunction';
import {BubbleText} from '../bubbleText/BubbleText.component';
import {BubbleYoutubeBase} from './BubbleYoutube.component';

export const BubbleYoutubeView: ViewFunction<BubbleYoutubeBase> = function() {
  return (
    <div className='youtube'>
      <BubbleText text={this.getTitle()}/>
      <iframe title={this.getTitle()} width='62%' height='100%' src={this.getSrc()} frameBorder='0'/>
    </div>
  );
};

export default BubbleYoutubeView;
