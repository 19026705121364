import { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { HeaderBtn } from '../../../base/component/PageHeader/model/HeaderBtn';
import { Navigation } from '../../../base/model/route/Navigation';
import { LayoutContext } from '../../../base/state/Layout/LayoutContext';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { CreateApiKeyView } from './CreateApiKey.view';

interface State {
  isModalVisible: boolean;
}

interface IntlProps {
  intl: IntlShape;
}

type Props = IntlProps & LayoutStateModel & RouteComponentProps<Navigation>;

export class CreateApiKeyBase extends Component<Props, State> {
  public render = CreateApiKeyView.bind(this);

  public state: State = {
    isModalVisible: false,
  };

  public componentDidMount = () => {
    const CreateApiKeyButton: HeaderBtn = {
      title: this.formatMessage('createApiKeyBase.newApiKey'),
      hint: this.formatMessage('createApiKeyBase.newApiKey'),
      callback: this.handleOpen,
      enabled: true,
      id: 'new-api-key',
    };

    this.props.addHeaderBtn(CreateApiKeyButton);
  }

  public componentWillUnmount = () => {
    this.props.removeHeaderBtn('new-api-key');
  }

  public handleClose = () => {
    this.setState({ isModalVisible: false });
  }

  public handleSuccess = () => {
    this.setState({ isModalVisible: false });
    this.props.history.push('/account/apikeys');
  }

  public handleOpen = () => {
    this.setState({
      isModalVisible: true,
    });
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }
}

const CreateApiKeyWithIntl = injectIntl(CreateApiKeyBase);
const CreateApiKeyWithRoute = withRouter(CreateApiKeyWithIntl);
const CreateApiKeyWithLayout = withContext(LayoutContext)(CreateApiKeyWithRoute);
export const CreateApiKey = CreateApiKeyWithLayout;
