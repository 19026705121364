import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../../api/Server';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../model/http/Request';
import { Response } from '../../../../../model/http/Response';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { Navigation } from '../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { EditAssistantContext } from '../state/EditAssistantContext';
import { LayoutStateModel } from '../../../../base/state/Layout/LayoutStateModel';
import { WidgetSnippetView } from './WidgetSnippet.view';
import { ApiKeyModel } from './../../../../../modules/account/model/ApiKey';

interface ResponseApiList extends Response {
  content: ApiKeyModel[];
  timestamp: Date;
}

interface ResponseApiSingle extends Response {
  content: ApiKeyModel;
  timestamp: Date;
}

interface WidgetSnippetState extends ApiState {
  codeSnippet: string;
  bot: string;
  language: string;
  apiKey: string;
  apiList: ApiKeyModel[] | null;
  isModalVisible: boolean;
}

interface IntlProps {
  intl: IntlShape;
  assistantId: string;
  isAdmin: boolean;
}

type Props = IntlProps &
  ApiProps<ResponseApiList | ResponseApiSingle> &
  SessionStateModel &
  LayoutStateModel &
  RouteComponentProps<{ botId: string }> &
  RouteComponentProps<Navigation> &
  WithAlertProps;

export class WidgetSnippetBase extends ApiBase<ResponseApiList | ResponseApiSingle, Props, WidgetSnippetState> {
  public render = WidgetSnippetView.bind(this);

  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
      authError: null,
      codeSnippet: '',
      bot: this.props.match.params.botId,
      language: '',
      apiKey: '',
      apiList: null,
      isModalVisible: false,
    };
  }

  public componentDidMount(): void {
    this.updateCodeSnippet();
    this.requestApiKeys();
  }

  public requestApiKeys = async () => {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/currentuser/apikeys?size=100`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'requestApiKeys',
    };

    await this.doCall(request);
  }

  public getAssistantId() {
    return this.props.match.params.botId;
  }

  public setLanguage(e: any) {
    this.setState(
      {
        language: e.target.value,
      },
      () => {
        this.updateCodeSnippet();
      },
    );
  }

  public setApiKey(e: any) {
    if (e.target.value === 'newKey') {
      this.setState({
        isModalVisible: true,
      });
    } else {
      this.setState(
        {
          apiKey: e.target.value,
        },
        () => {
          this.updateCodeSnippet();
        },
      );
    }
  }

  public handleClose = () => {
    this.setState({ isModalVisible: false });
  }

  public onSucess = (uuid: string) => {
    this.setState({
      isModalVisible: false,
    });
    this.enableApi(uuid);
  }

  public enableApi = async (uuid: string) => {
    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload: null,
      relativePath: `/admin/currentuser/apikeys/${uuid}/status/ENABLED`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'updateApiKeyStatus',
    };
    await this.doCall(request);
  }

  public getChannelApi() {
    const payload = null;
    const botId = this.getAssistantId();
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload,
      relativePath: `/admin/assistants/${botId}/channel-api`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };
    this.doCall(request);
  }

  public hasError(): boolean {
    return this.state.error !== null;
  }

  public async setResponseData() {
    const response = this.props.response;
    if (this.props.callId === 'updateApiKeyStatus') {
      await this.requestApiKeys();
      if (this.state.apiList) {
        const content = response.content as ApiKeyModel;
        this.setState(
          {
            apiKey: content.uuid,
          },
          () => this.updateCodeSnippet(),
        );
      }
    } else if (this.props.callId === 'requestApiKeys') {
      this.setState({
        apiList: response.content as ApiKeyModel[],
      });
    }
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public updateCodeSnippet() {
    const snippet: string = `<script src="https://cdn.robo-ai.com/widget/widget-robo-ai-1.2.0-11.js"></script>
<script>
  var config = {
    bot: "${this.state.bot}",
    key: "${this.state.apiKey}",
    language: "${this.state.language}",
    api: {
          password: "${process.env.REACT_APP_PUBLIC_API_AUTH_PASSWORD}",
          username: "${process.env.REACT_APP_PUBLIC_API_AUTH_USERNAME}",
          url: "${process.env.REACT_APP_STUDIO_URL}",
        }
  };
  RoboAi.init(config);
</script>`;

    this.setState({
      codeSnippet: snippet,
    });
  }

  public isAdmin(): boolean {
    return this.props.isAdmin;
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
  }
}

const WidgetSnippetWithIntl = injectIntl(WidgetSnippetBase);
const WidgetSnippetWithAlert = withAlert<WidgetSnippetBase['props']>()(WidgetSnippetWithIntl);
const WidgetSnippetWithSession = withContext(SessionContext)(WidgetSnippetWithAlert);
const WidgetSnippetWithContext = withContext(EditAssistantContext)(WidgetSnippetWithSession);
const WidgetSnippetWithApi = RefreshApi(Api(WidgetSnippetWithContext));
export const WidgetSnippet = withRouter(WidgetSnippetWithApi);
