import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request, RequestActions } from '../../../../model/http/Request';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { StatsTimeFrame } from '../../../common/model/logs/LogsModel';
import { AssistantModel } from '../../../assistant/component/AssistantList/AssistantModel';
import { ExportView } from './Export.view';
import download from 'downloadjs';
import { ALL } from '../../../../constants/constants';
import { OrganizationTrial, OrganizationParamType } from '../../../auth/state/Session/SessionStateModel';

type Props = IntlProps & ApiProps<ExportResponse & string> & SessionStateModel & WithAlertProps & OtherProps;

export interface ExportResponse extends Response {
  content: AssistantModel[] | null;
  timestamp: Date;
}

interface State extends ApiState {
  isDisabled: boolean;
  disableExport: boolean;
}

interface IntlProps {
  intl: IntlShape;
}

interface OtherProps {
  searchTimeFrame: StatsTimeFrame;
  botView: string;
  fromDate: Date | null;
  toDate: Date | null;
}

export class ExportBase extends ApiBase<ExportResponse & string, Props, State> {

  private trialProp = this.props.organization?.parameters.find((p) => p.key === OrganizationParamType.TRIAL)?.value;
  private isTrial = (this.trialProp === OrganizationTrial.ENABLED);

  public render = ExportView.bind(this);

  public state: State = {
    isDisabled:  this.isDisabled(),
    authError: null,
    error: null,
    disableExport: this.isDisabled(),
  };

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

   public export = () => {
    this.setState({
      isDisabled: !this.isDisabled(),
      disableExport: true,
    });
    const payload = {
      startDate: this.props.fromDate,
      endDate: this.props.toDate,
      botView: this.props.botView === ALL ? null : this.props.botView,
    };
    const request: Request<{}> = {
      id: 'exportStats',
      method: HttpRequestType.POST,
      payload,
      relativePath: `/admin/stats/assistants/exportstats`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      action: RequestActions.DOWNLOAD,
    };
    this.doCall(request);
  }

  public setResponseData() {
    this.setState({
      isDisabled: false,
    });
    const response = this.props.response;
    if (response) {
      download(response, 'dashboard.xlsx', 'application/vnd.ms-excel');
      this.props.alert.success(this.formatMessage('alert.successfullyCreated'));
    }
  }

  public getTitle(): string {
    let res =  this.formatMessage('dashboard.export.enabled');
    if (this.isTrial) {
        res = this.formatMessage('dashboard.export.disabled');
     }
    return res;
  }

  protected isDisabled(): boolean {
    return this.isTrial;
  }

  protected setErrorResponseData(): void {
    this.setState({
      isDisabled: false,
    });
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }
}

const ExportWithIntl = injectIntl(ExportBase);
const ExportWithAlert = withAlert<ExportBase['props']>()(ExportWithIntl);
const ExportWithApi = RefreshApi(Api(ExportWithAlert));
export const Export = ExportWithApi;
