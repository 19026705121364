import React from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteChildrenProps } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { AppError } from '../../../model/AppError';
import { AsyncState } from '../../../model/AsyncState';
import { Api, AuthApi } from '../../../api/Server';
import { Request } from '../../../model/http/Request';
import { SessionContext } from '../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../auth/state/Session/SessionStateModel';
import { AppRouteComponentProps } from '../../base/model/route/AppRouteComponentProps';
import { WithAlertProps } from '../../base/model/WithAlertProps';
import { withContext } from '../../lib/component/withContext/withContext.hoc';
import { InvitationSentView } from './InvitationSent.view';

interface ComponentProps {
  authenticate: (username: string, password: string) => void;
  call: (Request: Request<{}>, newToken?: string, acceptAudio?: boolean) => void;
  authError: AppError;
  authState: AsyncState;
  authToken: string;
  authRefreshToken: string;
  authExpiresIn: Date;
  authAuthorities: string[];
  statusCode: number;
  status: number;
  callId: string;
  state: AsyncState;
  error: AppError;
}
interface IntlProps {
  intl: IntlShape;
}
type Props = IntlProps &
  ComponentProps &
  SessionStateModel &
  RouteChildrenProps &
  AppRouteComponentProps &
  WithAlertProps &
  RouteComponentProps<{ emailToken: string }>;

export class InvitationSentBase extends React.Component<Props> {

  public render = InvitationSentView.bind(this);
  private _loadingBar: any = null;

  constructor(props: Props) {
    super(props);
    this._loadingBar = null;
  }

  public redirectLogin = () => {
    this.props.history.push(`/auth/login`);
  }

  public setRef = (ref: any) => {
    this._loadingBar = ref;
  }

  public componentWillUnmount() {
    this._loadingBar.complete();
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

}

const InvitationSentBaseWithAlert = withAlert<InvitationSentBase['props']>()(InvitationSentBase);
const InvitationSentWithContext = withContext(SessionContext)(InvitationSentBaseWithAlert);
const InvitationSentCall = Api(InvitationSentWithContext);
const InvitationSentBaseWithIntl = injectIntl(InvitationSentCall);
export const InvitationSent = AuthApi(InvitationSentBaseWithIntl);
