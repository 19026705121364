import { ChangeEvent, FormEvent } from 'react';
import { withAlert } from 'react-alert';
import {injectIntl, IntlShape} from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../../api/Server';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../model/http/Request';
import { Response } from '../../../../../model/http/Response';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { Navigation } from '../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { GroupModel } from '../../../model/GroupModel';
import { EditGroupStateModel } from '../state/EditGroupStateModel';
import { EditGroupContext } from '../state/EditGroupContext';
import { InfoGroupView } from './InfoGroup.view';

interface ResponseUpdate extends  Response {
  content: GroupModel;
  timestamp: Date;
}

interface InfoState extends ApiState {
  name: string;
  description: string;
  isValid: boolean;
}

interface Payload {
  description: string;
}

interface IntlProps {
  intl: IntlShape;
}

type Props =
  IntlProps
  & ApiProps<ResponseUpdate>
  & SessionStateModel
  & EditGroupStateModel
  & RouteComponentProps<Navigation>
  & WithAlertProps;

export class InfoGroupBase extends ApiBase<ResponseUpdate, Props, InfoState> {

  public render = InfoGroupView.bind(this);

  constructor(props: Props) {
    super(props);
    const { name = '', description = '' } = this.props.groupData || {};
    this.state = {
      name,
      description,
      error: null,
      authError: null,
      isValid: false,
    };
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (nextProps.groupData !== this.props.groupData) {
      const { name = '', description = '' } = nextProps.groupData || {};
      this.setState({
        name,
        description,
      });
    }
  }

  public updateGroup() {

    const payload: Payload = {
      description: this.state.description,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/group/${this.props.currentGroup}/`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };

    this.doCall(request);
  }

  public getName = (): string => {
    return this.state.name;
  }

  public getDescription = (): string => {
    return this.state.description;
  }

  public changeNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      name: value,
    });
  }

  public changeDescriptionHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      description: value,
    });
  }

  public setIsFormValid(isValid: boolean) {
    if (this.state.isValid !== isValid) {
      this.setState({
        isValid,
      });
    }
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({
      error: null,
    }, () => {
      this.updateGroup();
    });
  }

  public hasError(): boolean {
    return this.state.error !== null;
  }

  public setResponseData() {
    const groupInfo: GroupModel = this.props.response.content;
    const { name, description } = groupInfo;

    this.setState({
      name,
      description,
    });

    this.props.setGroupInfo(groupInfo);
    this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({id});
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred') +
    (this.props.error ? ': ' + this.props.error!.message : ''));
  }

}

const InfoGroupWithIntl = injectIntl(InfoGroupBase);
const InfoGroupWithAlert = withAlert<InfoGroupBase['props']>()(InfoGroupWithIntl);
const InfoGroupWithSession = withContext(SessionContext)(InfoGroupWithAlert);
const InfoGroupWithContext = withContext(EditGroupContext)(InfoGroupWithSession);
const GroupInfoWithApi = RefreshApi(Api(InfoGroupWithContext));
export const GroupInfo = withRouter(GroupInfoWithApi);
