import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { StatsTimeFrame } from '../../../common/model/logs/LogsModel';
import { AssistantModel } from '../../../assistant/component/AssistantList/AssistantModel';
import { BotSelectorView } from './BotSelector.view';

type Props = IntlProps & ApiProps<BotResponse> & SessionStateModel & WithAlertProps;

export interface BotResponse extends Response {
  content: AssistantModel[] | null;
  timestamp: Date;
}

interface State extends ApiState {
  allBots: AssistantModel[] | null;
  botView: string;
}

interface IntlProps {
  intl: IntlShape;
  searchTimeFrame: StatsTimeFrame;
  updateBotView: (botView: string) => void;
}

export class BotSelectorBase extends ApiBase<BotResponse, Props, State> {
  public state: State = {
    botView: 'ALL',
    allBots: null,
    error: null,
    authError: null,
  };

  public render = BotSelectorView.bind(this);

  public componentDidMount(): void {
    this.getBotList();
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public getBotList = () => {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/assistants?size=100000`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'getBotsList',
    };
    this.doCall(request);
  }

  public getBots = () => {
    return this.state.allBots;
  }

  public selectBotView = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.setState({
      botView: value,
    });
    this.props.updateBotView(value);
  }

  public setResponseData() {
    const { content } = this.props.response;
    this.setState({
      allBots: content,
    });
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }
}

const BotSelectorWithIntl = injectIntl(BotSelectorBase);
const ComponentWithAlert = withAlert<BotSelectorBase['props']>()(BotSelectorWithIntl);
const BotSelectorWithApi = RefreshApi(Api(ComponentWithAlert));
export const BotSelector = withRouter(BotSelectorWithApi);
