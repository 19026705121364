import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';

import Confirm from '../../../../base/component/confirm/Confirm.component';
import { PageSize } from '../../../../base/component/PageSize/PageSize.component';
import { Pagination } from '../../../../base/component/Pagination/Pagination.component';
import { Status } from '../../../model/Status';
import { ChannelBase } from './Channel.component';
import { ChannelApi } from './ChannelApi.component';
import { ChannelModel } from './state/ChannelStateModel';

import { GeneralProviderChannel } from './GeneralProviderChannel.view';
import { GeneralProps } from './model/ChannelProviders';

export const ChannelView: ViewFunction<ChannelBase> = function() {
  const renderChannel = (item: ChannelModel) => {
    const props: GeneralProps = {
      item,
      isAdmin: this.isAdmin(),
      changeApikey: this.changeApikey,
      confirm: this.confirm,
    };

    return (
      <div className='col-6' key={item.uuid}>
        <div className='card single-card'>
          <div className='card-header'>
            <h6>{this.formatMessage(item.channelType)}</h6>
          </div>
          <div className='card-body'>
            <GeneralProviderChannel {...props} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className='row'>
        <ChannelApi assistantId={this.getAssistantId()} isAdmin={this.isAdmin()}/>

        <Confirm
          show={this.state.showConfirmDialog}
          okLabel={this.formatMessage('ok')}
          cancelLabel={this.formatMessage('cancel')}
          confirmation={this.formatMessage( 'channels.confirm.changeStatus',
            { statusToChange: this.state.statusToChange === Status.ENABLED
                ? this.formatMessage('enable').toLowerCase() : this.formatMessage('disable').toLowerCase()})}
          proceedFunc={this.confirmCallback}
          title={this.formatMessage('confirm')}
          setConfirmVisibility={this.setConfirmVisibility}
        />
        {this.state.data.map(renderChannel)}
      </div>
      <div className='row'>
        <div className='col'>
          <PageSize changePageSize={this.changePageSize} size={this.getCurrentPageSize()} />
        </div>
        <div className='col-sm-6 mb-1 display-view'>
          <Pagination
            totalPages={this.getTotalPages()}
            changePaginationHandler={this.changePaginationHandler}
            page={this.getCurrentPage()}
          />
        </div>{' '}
      </div>
    </React.Fragment>
  );
};
