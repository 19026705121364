import React from 'react';
import {AnswerMultiChoiceOption} from '../../../../../../answer/model/AnswerModel';
import BubbleMultiChoiceView from './BubbleMultiChoiceView';

interface Props {
  options?: AnswerMultiChoiceOption[];
  addMultiChoiceUserMessage: (option: string) => void;
}

export class BubbleMultiChoiceBase extends React.Component<Props, {}> {

  public render = BubbleMultiChoiceView.bind(this);

  public getOptions() {
    return this.props.options ? this.props.options : [];
  }

  public onClickHandler = (value: string) => () => {
    this.props.addMultiChoiceUserMessage(value);
  }

}

export const BubbleMultiChoice = BubbleMultiChoiceBase;
