import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { PageSizeBase } from './PageSize.component';

export const PageSizeView: ViewFunction<PageSizeBase> = function() {
  return (
      <form className='form-inline mb-1 justify-content-start' >
        <label htmlFor='pageSize'>{this.formatMessage('pageSize.display')}</label>
        <select
          id='pageSize'
          className='form-control ml-1'
          style={{width: 'auto'}}
          value={this.props.size}
          onChange={this.changePageSizeHandler}
        >
          {this.getPageSizes().map((item, idx) => {
            return (
              <option id={item.toString()} data-property-value={item} key={idx} >{item}</option>
            );
          })}
        </select>
      </form>
  );
};
