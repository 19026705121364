import React, { PropsWithChildren } from 'react';
import { ReactCookieProps, withCookies } from 'react-cookie';
import LoadingBar from './../../../base/component/loadingBar/loadingBar';
import { SessionContext } from './SessionContext';
import { Build, SessionStateModel, Organization } from './SessionStateModel';

type Props = ReactCookieProps & PropsWithChildren<{}>;

export class SessionStoreProviderClass extends React.Component<Props, SessionStateModel> {
  private readonly cookieName = 'auth_session';

  constructor(props: Props) {
    super(props);
    this.state = {
      initialized: false,
      token: null,
      refreshToken: null,
      expiresIn: null,
      isRefreshing: false,
      setSessionData: this.setSessionData,
      clearCookie: this.clearCookie,
      clearSession: this.clearSession,
      setRequiredRefresh: this.setRequiredRefresh,
      isRefreshingToken: this.isRefreshingToken,
      setLoadBarRef: this.setLoadBarRef,
      setBackendBuild: this.setBackendBuild,
      loadBarRef: null,
      authorities: [],
      backendBuild: null,
      currentUsername: '',
      setOrganization: this.setOrganization,
      organization: null,
    };
  }

  public componentDidMount(): void {
    this.loadData();
  }

  public render() {
    const children = this.state.initialized ? this.props.children : null;
    return <SessionContext.Provider value={this.state}>{children}</SessionContext.Provider>;
  }

  public setLoadBarRef = (ref: LoadingBar) => {
    this.setState({
      loadBarRef: ref,
    });
  }

  public setBackendBuild = (backendBuild: Build) => {
    this.setState({
      backendBuild,
    });
  }

  private storeData() {
    const { cookies } = this.props;
    if (!cookies) {
      return;
    }
    const { token, refreshToken, expiresIn, authorities, backendBuild, currentUsername, organization } = this.state;
    const sessionData = {
      token,
      refreshToken,
      expiresIn,
      authorities,
      backendBuild,
      currentUsername,
      organization,
    };
    cookies.set(this.cookieName, sessionData, {
      path: '/',
    });
  }

  private loadData() {
    const { cookies } = this.props;
    if (!cookies) {
      return;
    }
    const data = cookies.get(this.cookieName);
    if (data) {
      const sessionData = {
        ...data,
        expiresIn: new Date(data.expiresIn),
      };
      this.setState(sessionData);
    }

    this.setState({
      initialized: true,
    });
  }

  private setSessionData = (
    token: string,
    refreshToken: string,
    expiresIn: Date,
    authorities: string[],
    currentUsername: string,
  ) => {
    this.setState(
      {
        token,
        refreshToken,
        expiresIn,
        authorities,
        currentUsername,
      },
      () => {
        this.storeData();
      },
    );
  }

  private setOrganization = (organization: Organization) => {
    this.setState(
      {
        organization,
      },
      () => {
        this.storeData();
      },
    );
  }

  private clearSession = () => {
    this.setState(
      {
        token: null,
        refreshToken: null,
        expiresIn: null,
        authorities: [],
        currentUsername: '',
        organization: null,
      },
      () => {
        this.clearCookie();
      },
    );
  }

  private clearCookie = () => {
    const { cookies } = this.props;
    if (cookies) {
      cookies.set(this.cookieName, null, {
        path: '/',
      });
    }
  }

  private setRequiredRefresh = (marker: boolean) => {
    this.setState({
      isRefreshing: marker,
    });
  }
  private isRefreshingToken = () => {
    return this.state.isRefreshing;
  }
}

export const SessionStoreProvider = withCookies(SessionStoreProviderClass);
