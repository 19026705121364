import { LayoutStateModel } from '../../state/Layout/LayoutStateModel';
import { TopBarView } from './TopBar.view';
import { OrganizationTrial, OrganizationParamType, SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { injectIntl, IntlShape } from 'react-intl';
import {ApiBase, ApiProps, ApiState} from '../../ApiComponent';
import {Status, UserModel} from '../../../account/model/AccountModel';
import {Request} from '../../../../model/http/Request';
import {HttpRequestType} from '../../../../model/http/HttpRequestType';
import {Response} from '../../../../model/http/Response';
import {WithAlertProps} from '../../model/WithAlertProps';
import {Api, RefreshApi} from '../../../../api/Server';
import {Roles} from '../../model/route/Role';

declare global {
  interface Window {
    hbspt: any;
  }
}

// @ts-ignore
window.jQuery = window.jQuery || (() => ({
  // these are all methods required by HubSpot
  change: () => {},
  trigger: () => {},
}));

interface ResponseUpdate extends Response {
  content: UserModel;
  timestamp: Date;
}

interface MenuProps {
  sidebarLinksVisible: boolean;
}

interface IntlProps {
  intl: IntlShape;
}

interface State extends ApiState {
  data: UserModel | null;
  name: string;
  email: string;
  mobileNumber: string;
  firstName: string;
  lastName: string;
  timezone: string;
  isValid: boolean;
  statusToChange: string;
  selectedRole: string;
  showConfirmDialog: boolean;
  dialCode: string;
  showUpgrade: boolean;
  free: boolean;
  startup: boolean;
  pro: boolean;
  enterprise: boolean;
  upgraded: false;
  selectedPlan: string;
  hubspotRegion: any;
  hubspotPortalId: any;
  hubspotFormId: any;
}

type Props = IntlProps &
  ApiProps<ResponseUpdate> &
  LayoutStateModel &
  MenuProps &
  WithAlertProps &
  SessionStateModel;

export class TopBarBase extends ApiBase<ResponseUpdate, Props, State> {

  public state: State = {
    error: null,
    authError: null,
    data: null,
    name: '',
    email: '',
    mobileNumber: '',
    firstName: '',
    lastName: '',
    timezone: '',
    isValid: false,
    statusToChange: Status.DISABLED,
    selectedRole: 'user',
    showConfirmDialog: false,
    dialCode: '',
    showUpgrade: false,
    free: true,
    startup: false,
    pro: false,
    enterprise: false,
    upgraded: false,
    selectedPlan: 'Free',
    hubspotRegion: process.env.REACT_APP_HUBSPOT_REGION,
    hubspotPortalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID,
    hubspotFormId: process.env.REACT_APP_HUBSPOT_FORM_ID,
  };

  public render = TopBarView.bind(this);

  private trialProp = this.props.organization?.parameters.find((p) => p.key === OrganizationParamType.TRIAL)?.value;
  private trial = (this.trialProp === OrganizationTrial.ENABLED);

  public isTrialAndAdmin() {
    const isAdmin = this.state.selectedRole === Roles.admin;
    return this.trial && isAdmin;
  }

  public componentDidMount = async () => {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/users/profile/${this.props.currentUsername}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };
    await this.doCall(request);
  }

  public openUpgrade = () => {
    this.setState({
      showUpgrade: true,
    });
  }

  public closeUpgrade = () => {
    this.setState({
      showUpgrade: false,
    });
  }

  public selectFreePlan = () => {
    this.setState({
      free: true,
      startup: false,
      pro: false,
      enterprise: false,
      selectedPlan: 'Free',
    });
  }

  public selectProPlan = () => {
    this.setState({
      free: false,
      startup: false,
      pro: true,
      enterprise: false,
      selectedPlan: 'Pro',
    });

    this.loadHubspotForm();
  }

  public selectStartupPlan = () => {
    this.setState({
      free: false,
      startup: true,
      pro: false,
      enterprise: false,
      selectedPlan: 'Startup',
    });

    this.loadHubspotForm();
  }

  public selectEnterprisePlan = () => {
    this.setState({
      free: false,
      startup: false,
      pro: false,
      enterprise: true,
      selectedPlan: 'Enterprise',
    });

    this.loadHubspotForm();
  }

  public getName = (): string => {
    if (this.state.data) {
      return this.state.data.username;
    }
    return '';
  }

  public getSelectedPlan = (): string => {
     return this.state.selectedPlan;
  }

  public getFirstName = (): string => {
    if (this.state.data) {
      return this.state.firstName;
    }
    return '';
  }

  public getLastName = (): string => {
    if (this.state.data) {
      return this.state.lastName;
    }
    return '';
  }

  public getEmail = (): string => {
    if (this.state.data) {
      return this.state.data!.email;
    }
    return '';
  }

  public getCompany = (): string => {
    if (this.state.data) {
      return this.state.data!.organization;
    }
    return '';
  }

  public getMobile = (): string => {
    if (this.state.data) {
      return this.state.data!.mobileNumber;
    }
    return '';
  }

  public setResponseData() {
    const { content } = this.props.response;
    this.setState({
      data: content,
      statusToChange: content.status,
      selectedRole: content.roles[0],
      mobileNumber: content.mobileNumber,
      firstName: content.firstName,
      lastName: content.lastName,
      email: content.email,
      timezone: content.timezone,
      showUpgrade: false,
    });
    this.props.setBreadCrumbsInfo({
      currentPage: `${content.firstName} ${content.lastName}`,
      currentId: content.uuid,
      pageNumber: null,
    });

    if (this.props.callId === 'update-user') {
      this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
    }
  }

  public componentWillUnmount(): void {
    this.props.setBreadCrumbsInfo({
      currentPage: null,
    });
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public toggleSidebar = () => {
    this.props.toggleSidebar();
  }

  protected setErrorResponseData(): void {
      console.error(this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''));
  }

  private loadHubspotForm() {
    const elements = document.getElementsByClassName('hs-form-iframe');
    if (elements.length > 0) { // @ts-ignore
      // @ts-ignore
      const iframe = elements.item(0);
      // @ts-ignore
      iframe.parentNode.removeChild(iframe);
    }

    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: this.state.hubspotRegion,
          portalId: this.state.hubspotPortalId,
          formId: this.state.hubspotFormId,
          target: '.hubspot',
        });
      }
    });

    window.addEventListener('message', (event) => {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
        const elements = document.getElementsByClassName('hs-form-iframe');
        if (elements.length > 0) { // @ts-ignore
          // @ts-ignore
          const iframe = elements.item(0);
          // @ts-ignore
          // @ts-ignore
          const iframeDocument = iframe.contentWindow.document;

          const companyInput = iframeDocument.querySelector("input[name='company']");
          if (companyInput !== undefined) {
            // @ts-ignore
            companyInput.value = this.getCompany();
          }

          const emailInput = iframeDocument.querySelector("input[name='email']");
          if (emailInput !== undefined) {
            // @ts-ignore
            emailInput.value = this.getEmail();
          }
          const planInput = iframeDocument.querySelector("input[name='subscription_plan']");
          if (planInput !== undefined) {
            // @ts-ignore
            planInput.value = this.getSelectedPlan();
          }
        }

      }

      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {

        setTimeout(() => {
          this.closeUpgrade();
        }, 3000);

      }

      return true;
    });
  }
}

const TopBarWithIntl = injectIntl(TopBarBase);
const TopBarWithApi = RefreshApi(Api(TopBarWithIntl));
export const TopBar = TopBarWithApi;
