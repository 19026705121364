import { FormEvent, MouseEvent } from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Api, RefreshApi } from '../../../../../api/Server';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../model/http/Request';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { Navigation } from '../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { EditAssistantContext } from '../state/EditAssistantContext';
import { FreshchatAPIProviderChannelView } from './FreshchatAPIProviderChannel.view';
import { ChannelParameter, ParamType, TokenName, WelcomeMessageIgnoredName } from './model/UpdateParams';
import { ChannelModel, ChannelStateModel } from './state/ChannelStateModel';
import { Status } from '../../../model/Status';

interface IntlProps {
  intl: IntlShape;
}

interface FreshchatProviderResponse extends Response {
  timestamp: Date;
}

interface FreshchatProviderState extends ApiState {
  apiToken: string;
  item: ChannelModel | null;
  welcomeMessageIgnored: string;
}

interface Payload {
  parameters: ChannelParameter[];
}

type Props = IntlProps &
  ApiProps<any> &
  SessionStateModel &
  ChannelStateModel &
  LayoutStateModel &
  RouteComponentProps<{ botId: string }> &
  RouteComponentProps<Navigation> &
  WithAlertProps;

const requestId = 'update-freshchat-channel';
// tslint:disable-next-line:max-line-length
export class FreshchatAPIProviderChannelBase extends ApiBase<FreshchatProviderResponse, Props & ChannelModel, FreshchatProviderState> {
  public render = FreshchatAPIProviderChannelView.bind(this);

  constructor(props: Props & ChannelModel) {
    super(props);

    this.state = {
      item: null,
      apiToken: props.apiToken !== undefined ? props.apiToken.toString() : '',
      appError: null,
      error: null,
      authError: null,
      welcomeMessageIgnored: props.welcomeMessageIgnored !== undefined ? props.welcomeMessageIgnored : Status.ENABLED,
    };
  }

  public getApiToken(): string {
    return this.state.apiToken;
  }

  public setApiToken = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const value = target.value;

    this.setState({
      apiToken: value,
    });
  }

  public submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const apiTokenParam: ChannelParameter = {
      name: TokenName,
      type: ParamType.LONGTEXT,
      value: this.getApiToken(),
    };

    const ignoreWelcomeMsgParam: ChannelParameter = {
      name: WelcomeMessageIgnoredName,
      type: ParamType.TEXT,
      value: this.state.welcomeMessageIgnored,
    };

    const parameters: ChannelParameter[] = [ apiTokenParam, ignoreWelcomeMsgParam ];

    const payload: Payload = {
      parameters,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/assistants/channel/${this.props.uuid}/assistant/${this.props.currentAssistant}/update`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: requestId,
    };
    this.doCall(request);
  }

  public formatMessage(id: string): string {
    return this.props.intl.formatMessage({ id });
  }

  public buildUrl(): string {
    return `${process.env.REACT_APP_STUDIO_URL}/external/channels/${this.props.uuid}?X-API-Key=${this.props.selectedApiKey}`;
  }

  public getUuid(): string {
    return this.props.uuid;
  }

  public setErrorResponseData() {
    const callId = this.props.callId;
    if (callId === requestId) {
      this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
    }
  }

  public setResponseData() {
    const callId = this.props.callId;
    if (callId === requestId) {
      this.props.alert.success(this.formatMessage('alert.successfullyCreated'));
    }
  }

  public isAdmin(): boolean {
    return this.props.isAdmin;
  }

  public setIgnoreWelcomeMessage = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (this.state.welcomeMessageIgnored === Status.ENABLED) {
      this.setState({
        welcomeMessageIgnored: Status.DISABLED,
      });
    } else {
      this.setState({
        welcomeMessageIgnored: Status.ENABLED,
      });
    }
  }

  public getToggleTitle(): string {
    if (this.state.welcomeMessageIgnored && this.state.welcomeMessageIgnored === Status.ENABLED) {
      return 'Enabled';
    }
    return 'Disabled';
  }

  public getToggle(): string {
    if (this.state.welcomeMessageIgnored && this.state.welcomeMessageIgnored === Status.ENABLED) {
      return 'fas fa-toggle-on fa-2x on text-success';
    }
    return 'fas fa-toggle-off fa-2x off text-secondary';
  }

  public dataPropertyValue() {
    if (this.state.welcomeMessageIgnored && this.state.welcomeMessageIgnored === Status.ENABLED) {
      return true;
    }
    return false;
  }

}

const FreshchatProviderWithIntl = injectIntl(FreshchatAPIProviderChannelBase);
const FreshchatProviderWithAlert = withAlert<FreshchatAPIProviderChannelBase['props']>()(FreshchatProviderWithIntl);
const FreshchatProviderWithSession = withContext(SessionContext)(FreshchatProviderWithAlert);
const FreshchatProviderWithContext = withContext(EditAssistantContext)(FreshchatProviderWithSession);
const FreshchatProviderWithApi = RefreshApi(Api(FreshchatProviderWithContext));
export const FreshchatProviderChannel = withRouter(FreshchatProviderWithApi);
