import { Api, RefreshApi } from '../../../../../api/Server';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { FallbackModel, EditAssistantStateModel } from '../state/EditAssistantStateModel';
import { IntlShape, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { EditAssistantContext } from '../state/EditAssistantContext';
import { FallbackView } from './Fallback.view';
import { Navigation } from '../../../../base/model/route/Navigation';
import { Request } from '../../../../../model/http/Request';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Response } from '../../../../../model/http/Response';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { withAlert } from 'react-alert';
import { ChangeEvent } from 'react';
import {
  withPaginationRouting,
  WithPaginationRoutingProps,
} from '../../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { AssistantParams } from '../state/EditAssistantStateModel';
import { InnerProps } from '../../../model/Status';

interface ResponseUpdate extends Response {
  content: FallbackModel;
  timestamp: Date;
}

interface FallbackState extends ApiState {
  answers: Message[];
  groupInput: string;
  group: string | null;
  delayedFallbackCount: number;
  thirdFallback: ThirdFallback;
  isModalVisible: boolean;
  answerToEdit: Message | null;
  confidence: number;
  isValid: boolean;
  totalPages: number;
}

export interface Message {
  message: string;
  uuid?: string;
}

export enum ThirdFallback {
  SIMPLE = 'SIMPLE',
  HANDOFF = 'HANDOFF',
}

interface IntlProps {
  intl: IntlShape;
  innerProps: InnerProps;
}

type Props = IntlProps &
  ApiProps<ResponseUpdate> &
  SessionStateModel &
  EditAssistantStateModel &
  WithPaginationRoutingProps<Navigation> &
  RouteComponentProps<Navigation> &
  RouteComponentProps<{ botId: string }> &
  WithAlertProps;

export class FallbackBase extends ApiBase<ResponseUpdate, Props, FallbackState> {
  public render = FallbackView.bind(this);

  constructor(props: Props) {
    super(props);
    this.state = {
      answers: [
        { message: '<p> this is the message 1 </p>' },
        { message: '<p> this is the message 2 </p>' },
        { message: '<p> this is the message 3 </p>' },
      ],
      groupInput: '',
      group: null,
      thirdFallback: ThirdFallback.SIMPLE,
      delayedFallbackCount: 3,
      isModalVisible: false,
      answerToEdit: null,
      confidence: 0,
      error: null,
      totalPages: 0,
      authError: null,
      isValid: false,
    };
  }

  public componentDidMount() {
    this.onEmiter(this.updateList , 'importSuccess');
  }

  public componentWillMount(): void {
    this.setState({
      confidence: this.props.assistantData ? parseFloat(this.props.assistantData.studioConfidence) * 100 : 0,
    });
    this.updateList();
  }

  public updateList = () => {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/studio/assistant/${this.getAssistantId()}/fallback`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'getFallback',
    };
    this.doCall(request);
  }

  public changeHandoff = (value: string) => {
    const param = value === ThirdFallback.SIMPLE ? this.formatMessage('assistant.studio.fallback.simple')
      : this.formatMessage('assistant.studio.fallback.handoff');
    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload: null,
      relativePath: `/admin/studio/assistant/${this.getAssistantId()}/fallback/select?type=${param}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'changeHandOff',
    };
    this.doCall(request);
  }

  public getAssistantId() {
    return this.props.match.params.botId;
  }

  public changeConfidence = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      // tslint:disable-next-line:radix
      confidence: parseInt(value),
    });
  }

  public updateConfidence = () => {
    const value = (this.state.confidence / 100).toString();
    const params = {
      [`${AssistantParams.STUDIO_CONFIDENCE}`]: value,
    };

    const payload = {
      params,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/assistants/${this.props.currentAssistant}/`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };

    this.doCall(request);
  }

  public handoffChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value === ThirdFallback.SIMPLE ? ThirdFallback.SIMPLE : ThirdFallback.HANDOFF;
    this.setState(
      {
        thirdFallback: value,
      },
      () => {
        this.changeHandoff(value);
      },
    );
  }

  public handleGroup = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;
    this.setState({
      groupInput: value,
    });
  }

  public addGroup = () => {
    this.setState({
      group: this.state.groupInput,
      groupInput: '',
    });
    this.updateGroup();
  }

  public removeGroup = () => {
    this.setState({
      group: '',
    });
    this.deleteGroup();
  }

  public updateGroup = () => {
    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload: null,
      relativePath: `/admin/studio/assistant/${this.getAssistantId()}/fallback/group?group=${this.state.groupInput}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'updateGroup',
    };
    this.doCall(request);
  }

  public deleteGroup = () => {
    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload: null,
      relativePath: `/admin/studio/assistant/${this.getAssistantId()}/fallback/group?group=`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'deleteGroup',
    };
    this.doCall(request);
  }

  public edit = (index: number) => {
    this.setState({
      answerToEdit: this.state.answers[index],
      isModalVisible: true,
    });
  }

  public saveEdits = (newItem: Message) => {
    let ansIndex = 0;
    const newAnswers = this.state.answers.map((answer, index) => {
      if (answer === this.state.answerToEdit) {
        ansIndex = index;
        return newItem;
      } else {
        return answer;
      }
    });
    this.setState(
      {
        answers: newAnswers,
        answerToEdit: null,
      },
      () => {
        this.handleClose();
      },
    );
    this.updateFallBackAnswer(newItem, ansIndex);
  }

  public updateFallBackAnswer = (newMessage: Message, index: number) => {
    const payload = {
      message: newMessage.message,
      type: this.getAnswerTypeName(index),
    };
    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/studio/assistant/${this.getAssistantId()}/fallback/message`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'updateFallback',
    };
    this.doCall(request);
  }

  public getAnswerTypeName = (index: number) => {
    switch (index) {
      case 0:
        return this.formatMessage('assistant.studio.fallback.standard');
      case 1:
        return this.formatMessage('assistant.studio.fallback.simple');
      case 2:
        return this.formatMessage('assistant.studio.fallback.handoff');
      default:
        return '';
    }
  }

  // tslint:disable-next-line:no-empty
  public save = () => {};

  public handleClose = () => {
    this.setState({ isModalVisible: false });
  }

  public handleOpen = () => {
    this.setState({
      isModalVisible: true,
    });
  }

  public hasError(): boolean {
    return this.state.error !== null;
  }

  public setResponseData() {
    if (this.props.callId === 'getFallback') {
      const response = this.props.response.content;
      const answers = [
        { message: response.standardMessage },
        { message: response.simpleMessage },
        { message: response.handoffMessage },
      ];
      this.setState({
        thirdFallback:
          response.delayedFallbackType === this.formatMessage('assistant.studio.fallback.simple')
            ? ThirdFallback.SIMPLE : ThirdFallback.HANDOFF,
        answers,
        group: response.handoffGroup,
        delayedFallbackCount: response.delayedFallbackCount,
      });
      // set confidence
    } else if (
      this.props.callId === 'updateFallback' ||
      this.props.callId === 'changeHandOff' ||
      this.props.callId === 'updateGroup' ||
      this.props.callId === 'deleteGroup'
    ) {
      this.props.innerProps.getBotStatus();
      this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
      this.updateList();
    }
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public isAdmin(): boolean {
    return super.isAdmin(this.props.authorities);
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
  }
}

const FallbackWithIntl = injectIntl(FallbackBase);
const FallbackWithAlert = withAlert<FallbackBase['props']>()(FallbackWithIntl);
const FallbackWithSession = withContext(SessionContext)(FallbackWithAlert);
const FallbackWithContext = withContext(EditAssistantContext)(FallbackWithSession);
const FallbackWithApi = RefreshApi(Api(FallbackWithContext));
export const Fallback = withPaginationRouting()(FallbackWithApi);
