import moment from 'moment';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { SessionContext } from '../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { ChartResponse } from '../../model/ChartResponse';
import { NumberOfConversationsChartView } from './NumberOfConversationsChart.view';
import { StatsTimeFrame } from '../../../common/model/logs/LogsModel';
import { getStartDate, getEndDate, discretizeData } from '../../../../util/graphDataComplete';
import { UsageData, DataPoint } from '../../model/ChartResponse';

import d3 from 'd3';

interface State extends ApiState {
  chartData: any;
  allData: UsageData[];
  searchTimeFrame: StatsTimeFrame;
  axisLabel: string;
  loaded: boolean;
}

interface Values {
  label: Date;
  series: number;
  value: number;
}

interface IntlProps {
  intl: IntlShape;
  searchTimeFrame: StatsTimeFrame;
  botView: string;
  fromDate: Date;
  toDate: Date;
  location: any;
}

type Props = IntlProps & ApiProps<ChartResponse> & SessionStateModel & WithAlertProps;

const chartColor = process.env.REACT_APP_CHART_COLOR_1;
const chartColor2 = process.env.REACT_APP_CHART_COLOR_2;

export class NumberOfConversationsChartBase extends ApiBase<ChartResponse, Props, State> {
  public state: State = {
    chartData: [],
    allData: [],
    error: null,
    authError: null,
    searchTimeFrame: StatsTimeFrame.WEEK,
    axisLabel: 'Last 7 days',
    loaded: false,
  };

  public render = NumberOfConversationsChartView.bind(this);

  public componentDidMount(): void {
    this.setState({ searchTimeFrame: this.props.searchTimeFrame }, async () => {
      this.setAxisLabel();
      await this.requestChartData();
    });
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (nextProps.searchTimeFrame !== this.props.searchTimeFrame) {
      this.setState({ searchTimeFrame: nextProps.searchTimeFrame }, () => {
        this.setAxisLabel();
        this.setState({
          loaded: false,
        });
        this.requestChartData();
      });
    }
  }

  public componentDidUpdate(prevProps: Props) {
    super.componentDidUpdate(prevProps);
    if (this.props.location !== prevProps.location) {
      this.setState(
        {
          searchTimeFrame: this.props.searchTimeFrame,
          loaded: false,
        },
        () => {
          this.requestChartData();
          this.setAxisLabel();
        },
      );
    }
    if (this.props.botView !== prevProps.botView) {
      this.setState(() => ({
        chartData: this.getSingleOrAll(this.state.allData),
      }));
    }
  }

  public getChartData = () => {
    return this.state.chartData ? this.state.chartData : [];
  }

  public setAxisLabel() {
    switch (this.state.searchTimeFrame) {
      case StatsTimeFrame.ALL:
        this.setState({
          axisLabel: this.formatMessage('statsAssistantChart.filter.label.all'),
        });
        break;
      case StatsTimeFrame.YEAR:
        this.setState({
          axisLabel: this.formatMessage('statsAssistantChart.filter.label.lastYear'),
        });
        break;
      case StatsTimeFrame.TRIMESTER:
        this.setState({
          axisLabel: this.formatMessage('statsAssistantChart.filter.label.trimester'),
        });
        break;
      case StatsTimeFrame.MONTH:
        this.setState({
          axisLabel: this.formatMessage('statsAssistantChart.filter.label.month'),
        });
        break;
      case StatsTimeFrame.WEEK:
        this.setState({
          axisLabel: this.formatMessage('statsAssistantChart.filter.label.week'),
        });
        break;
      case StatsTimeFrame.DAY:
        this.setState({
          axisLabel: this.formatMessage('statsAssistantChart.filter.label.day'),
        });
        break;
      case StatsTimeFrame.CUSTOM:
        this.setState({
          axisLabel: this.formatMessage('statsAssistantChart.filter.button.custom'),
        });
        break;
    }
  }

  public getRouteEnding = () => {
    if (this.props.searchTimeFrame === StatsTimeFrame.CUSTOM) {
      const startDateString = this.props.fromDate?.toISOString().substring(0, 10) || '';
      const endDateString = this.props.toDate?.toISOString().substring(0, 10) || '';
      return `?startDate=${startDateString}&endDate=${endDateString}`;
    } else {
      return `/${this.state.searchTimeFrame}`;
    }
  }

  public requestChartData() {
    const route = this.getRouteEnding();
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/stats/conversationshistogram${route}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };
    this.doCall(request);
  }

  public setResponseData() {
    const { content } = this.props.response;

    this.setState(
      () => ({
        allData: content,
        chartData: this.getSingleOrAll(content),
      }),
      () => {
        setTimeout(() => {
          this.setState({ loaded: true });
        }, 1500);
      },
    );
  }

  public getSingleOrAll = (content: UsageData[]) => {
    if (this.props.botView !== 'ALL') {
      const data = content.find((bot: UsageData) => bot.botUUID === this.props.botView);
      if (data) {
        return this.processChartData(data.dataSet);
      }
    } else {
      return this.processAllChartData(content);
    }
  }

  public processAllChartData = (content: UsageData[]) => {
    const chartData = content.map((bot: UsageData) => {
      const d = bot.dataSet;
      let startDate: Date | undefined;
      let endDate: Date | undefined;
      if (this.state.searchTimeFrame === StatsTimeFrame.CUSTOM) {
        startDate = this.props.fromDate;
        endDate = this.props.toDate;
      } else if (this.state.searchTimeFrame !== StatsTimeFrame.ALL) {
        startDate = getStartDate(this.state.searchTimeFrame);
        endDate = getEndDate();
      } else {
        startDate = new Date(d[0].key);
        endDate = getEndDate();
      }
      const resultingData = discretizeData(this.state.searchTimeFrame, startDate, endDate, d);

      const values: Array<{ label: Date; value: number }> = resultingData.map((e: DataPoint) => {
        return {
          label: new Date(e.key),
          value: e.value,
        };
      });

      return {
        area: false,
        key: bot.botName,
        values,
      };
    });

    return chartData;
  }

  public processChartData(d: DataPoint[]) {
    let startDate: Date | undefined;
    let endDate: Date | undefined;
    if (this.state.searchTimeFrame === StatsTimeFrame.CUSTOM) {
      startDate = this.props.fromDate;
      endDate = this.props.toDate;
    } else if (this.state.searchTimeFrame !== StatsTimeFrame.ALL) {
      startDate = getStartDate(this.state.searchTimeFrame);
      endDate = getEndDate();
    } else {
      startDate = new Date(d[0].key);
      endDate = getEndDate();
    }
    const resultingData = discretizeData(this.state.searchTimeFrame, startDate, endDate, d);

    const values: Array<{ label: Date; value: number }> = resultingData.map((e: DataPoint) => {
      return {
        label: new Date(e.key),
        value: e.value,
      };
    });

    return [
      {
        color: chartColor,
        area: false,
        key: this.props.botView,
        values,
      },
    ];
  }

  public getX(d: Values) {
    return d.label;
  }

  public getY(d: Values) {
    return d.value;
  }

  public getTickFormat() {
    switch (this.state.searchTimeFrame) {
      case StatsTimeFrame.ALL:
        return '%d/%m/%y';
      case StatsTimeFrame.YEAR:
        return '%d/%m/%y';
      case StatsTimeFrame.TRIMESTER:
        return '%d/%m';
      case StatsTimeFrame.MONTH:
        return '%d/%m';
      case StatsTimeFrame.WEEK:
        return '%d/%m';
      case StatsTimeFrame.DAY:
        return '%H:%M';
      default:
        return '%d/%m';
    }
  }

  public getChartColor(d: { label: Date; value: number }) {
    if (moment(d.label).isSame(moment(), 'day')) {
      return chartColor;
    }
    return chartColor2;
  }

  public xTickFormat() {
    switch (this.state.searchTimeFrame) {
      case StatsTimeFrame.ALL:
        return d3.time.format('%Y');
      case StatsTimeFrame.YEAR:
        return d3.time.format('%m');
      case StatsTimeFrame.TRIMESTER:
        return d3.time.format('%W');
      case StatsTimeFrame.MONTH:
        return d3.time.format('%d');
      case StatsTimeFrame.WEEK:
        return d3.time.format('%d/%m');
      case StatsTimeFrame.DAY:
        return d3.time.format('%h');
      default:
        return d3.time.format('%d');
    }
  }

  public xScale(): Date[] {
    const curDate = new Date();

    curDate.setHours(0);
    curDate.setMinutes(0);
    curDate.setSeconds(0);
    curDate.setMilliseconds(0);

    if (!this.state.searchTimeFrame) {
      return [];
    }
    switch (this.state.searchTimeFrame) {
      case StatsTimeFrame.ALL:
        return [];
      case StatsTimeFrame.YEAR:
        return [];
      case StatsTimeFrame.TRIMESTER:
        return [];
      case StatsTimeFrame.MONTH:
        return [];
      case StatsTimeFrame.WEEK:
        return [];
      case StatsTimeFrame.DAY:
        return [];
      case StatsTimeFrame.CUSTOM:
        return [];
    }
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }
}

const ComponentWithIntl = injectIntl(NumberOfConversationsChartBase);
const ComponentWithAlert = withAlert<NumberOfConversationsChartBase['props']>()(ComponentWithIntl);
const ComponentWithSession = withContext(SessionContext)(ComponentWithAlert);
const NumberOfConversationsChartWithApi = RefreshApi(Api(ComponentWithSession));
export const NumberOfConversationsChart = withRouter(NumberOfConversationsChartWithApi);
