import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { LogsBubbleDetail } from './LogsBubble.component';
import { AnswerType, answerTypeCode } from '../../../../answer/model/AnswerType';
import { BubbleCarousel } from '../../../../assistant/component/EditAssistant/test/bubbleComponents/bubbleCarousel/BubbleCarousel.component';
import { BubbleCMS } from '../../../../assistant/component/EditAssistant/test/bubbleComponents/bubbleCMS/BubbleCMS.component';
import { BubbleCommand } from '../../../../assistant/component/EditAssistant/test/bubbleComponents/bubbleCommand/BubbleCommand.component';
import { BubbleHTML } from '../../../../assistant/component/EditAssistant/test/bubbleComponents/bubbleHTML/BubbleHTML.component';
import { BubbleImage } from '../../../../assistant/component/EditAssistant/test/bubbleComponents/bubbleImage/BubbleImage.component';
import { BubbleLinks } from '../../../../assistant/component/EditAssistant/test/bubbleComponents/bubbleLinks/BubbleLinks.component';
import { BubbleMultiChoice } from '../../../../assistant/component/EditAssistant/test/bubbleComponents/bubbleMultiChoice/BubbleMultiChoice.component';
import { BubbleProcessing } from '../../../../assistant/component/EditAssistant/test/bubbleComponents/bubbleProcessing/BubbleProcessing.component';
import { BubbleText } from '../../../../assistant/component/EditAssistant/test/bubbleComponents/bubbleText/BubbleText.component';
import { BubbleVideo } from '../../../../assistant/component/EditAssistant/test/bubbleComponents/bubbleVideo/BubbleVideo.component';
import { BubbleYoutube } from '../../../../assistant/component/EditAssistant/test/bubbleComponents/bubbleYoutube/BubbleYoutube.component';

export const LogsBubbleDetailView: ViewFunction<LogsBubbleDetail> = function() {
  const renderElements = (answer: any) => {
    if (answer) {
      switch (answer.type) {
        case answerTypeCode[AnswerType.TEXT]:
          return <BubbleText text={answer.text} />;
        case answerTypeCode[AnswerType.VIDEO]:
          return <BubbleVideo title={answer.title} src={answer.url} />;
        case answerTypeCode[AnswerType.YOUTUBE]:
          return <BubbleYoutube title={answer.title} youtubeId={answer.youtubeId} />;
        case answerTypeCode[AnswerType.LINKS]:
          return <BubbleLinks links={answer.links} />;
        case answerTypeCode[AnswerType.HTML]:
          return <BubbleHTML text={answer.text} />;
        case answerTypeCode[AnswerType.IMAGE]:
          return <BubbleImage title={answer.title} url={answer.url} />;
        case answerTypeCode[AnswerType.MULTICHOICE]:
          return (
            <BubbleMultiChoice options={answer.options} addMultiChoiceUserMessage={addEmptyMultiChoiceUserMessage} />
          );
        case answerTypeCode[AnswerType.CMS]:
          return <BubbleCMS cmsId={answer.cmsId} />;
        case answerTypeCode[AnswerType.COMMAND]:
          return <BubbleCommand name={answer.name} params={answer.params} />;
        case answerTypeCode[AnswerType.PROCESSING]:
          return <BubbleProcessing />;
        case answerTypeCode[AnswerType.CAROUSEL]:
          return <BubbleCarousel options={answer.options} />;
        default:
          return <BubbleText text={answer.text} />;
      }
    } else {
      return (
        <p>
          <span>
            <FormattedMessage id={'logsAssistantView.emptyMessage'} />
          </span>
        </p>
      );
    }
  };

  const addEmptyMultiChoiceUserMessage = () => {
    // nothing to do here
  };

  return (
    <React.Fragment>
      <div>
        <div className='speech-bubble-robo'>{renderElements(this.props.answer)}</div>
      </div>
    </React.Fragment>
  );
};
