import React from 'react';
import {ViewFunction} from '../../../../../../../model/component/ViewFunction';
import {BubbleCMSBase} from './BubbleCMS.component';

export const BubbleCMSView: ViewFunction<BubbleCMSBase> = function() {
  return (
    <div className='cmsClass'>
      <span dangerouslySetInnerHTML={{ __html: `${this.getCMSId()}`}}/>
    </div>
  );
};
