export enum ParamType {
  TEXT = 'TEXT',
  INTEGER = 'INTEGER',
  BOOLEAN = 'BOOLEAN',
  DOUBLE = 'DOUBLE',
  PASSWORD = 'PASSWORD',
}

export interface ChannelParameter {
  type: ParamType;
  name: string;
  value: string;
}

export const TokenName = 'token';
export const NumberName = 'number';
export const PageAccessTokenName = 'pageAccessToken';

export const AccountSIDName = 'accountSID';
export const AuthTokeName = 'authToken';
export const PhoneNumberName = 'phoneNumber';
