import React, { FormEvent } from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteChildrenProps } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Api, AuthApi } from '../../../../api/Server';
import { AppError, AppErrorType } from '../../../../model/AppError';
import { AsyncState } from '../../../../model/AsyncState';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { WithPaginationRoutingProps } from '../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { SessionContext } from '../../state/Session/SessionContext';
import { Build, SessionStateModel, Organization } from '../../state/Session/SessionStateModel';
import { Request } from '../../../../model/http/Request';
import { ResetPasswordView } from './ResetPassword.view';

interface ComponentProps {
  authenticate: (username: string, password: string) => void;
  call: (Request: Request<{}>, newToken?: string, acceptAudio?: boolean) => void;
  authError: AppError;
  authState: AsyncState;
  authToken: string;
  authRefreshToken: string;
  authExpiresIn: Date;
  authAuthorities: string[];
  response: { build: Build };
  statusCode: number;
  callId: string;
  state: AsyncState;
  intl: IntlShape;
}

interface State extends ApiState {
  isTokenValid: boolean;
  password: string;
  confirmPassword: string;
  error: AppErrorType | null;
  build: Build | null;
  showSuccessReset: boolean;
}

interface ResetResponse extends Response {
  content: string;
  timestamp: Date;
}

interface OrganizationResponse extends Response {
  content: Organization;
  timestamp: Date;
}

interface IntlProps {
  intl: IntlShape;
}

type Props = IntlProps &
  ComponentProps &
  SessionStateModel &
  RouteChildrenProps &
  ApiProps<ResetResponse & OrganizationResponse> &
  LayoutStateModel &
  SessionStateModel &
  RouteComponentProps<Navigation> &
  WithAlertProps &
  WithPaginationRoutingProps;

interface Payload {
  password: string;
  confirmPassword: string;
}

const resetUserPassword: string = 'resetUserPassword';
const validateToken: string = 'validateToken';

export class ResetPasswordBase extends ApiBase<ResetResponse & OrganizationResponse, Props, State> {
  public state: State = {
    isTokenValid: false,
    password: '',
    confirmPassword: '',
    error: null,
    build: null,
    authError: null,
    showSuccessReset: false,
  };

  public render = ResetPasswordView.bind(this);

  private _loadingBar: any = null;

  constructor(props: Props) {
    super(props);
    this._loadingBar = null;
  }

  public componentDidMount = async () => {
    this.props.clearSession();
    this.validateToken();
  }

  public validateToken = async () => {
    const token = this.props.match.params.token;
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/password/reset/validate/${token}`,
      token: this.props.authToken,
      refreshToken: this.props.authRefreshToken,
      id: validateToken,
    };
    await this.props.call(request);
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.reset();
  }

  public reset = async () => {
    const token = this.props.match.params.token;
    const payload: Payload = { password: this.state.password, confirmPassword: this.state.confirmPassword};
    const request: Request<{}> = {
      method: HttpRequestType.PATCH,
      payload,
      relativePath: `/password/auth/resetPassword/${token}`,
      token: this.props.authToken,
      refreshToken: this.props.authRefreshToken,
      id: resetUserPassword,
    };
    await this.props.call(request);
  }

  public changePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      password: value,
    });
  }

  public changeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      confirmPassword: value,
    });
  }

  public hasError(): boolean {
    return this.state.error !== null;
  }

  public setRef = (ref: any) => {
    this._loadingBar = ref;
  }

  public setResponseData() {
    this._loadingBar.complete();
    if (this.props.callId === resetUserPassword) {
      this.props.history.push('/auth/resetPasswordSuccess/password.resetSuccess');
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps !== this.props) {
      switch (this.props.state) {
        case AsyncState.REQUESTED:
          this._loadingBar.continuousStart();

          break;
        case AsyncState.ERROR:
            this.setErrorResponseData();
            break;
        case AsyncState.SUCCESS:
          this.setResponseData();
          break;
      }
    }
  }

public componentWillUnmount() {
  this._loadingBar.complete();
}

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  protected setErrorResponseData() {
    this._loadingBar.complete();
    if (this.props.callId === validateToken) {
      this.props.history.push('/auth/resetPasswordExpired');
    }
  }
}
const ResetPasswordWithIntl = injectIntl(ResetPasswordBase);
const ResetPasswordWithIntlWithAlert = withAlert<ResetPasswordBase['props']>()(ResetPasswordWithIntl);
const ResetPasswordWithContext = withContext(SessionContext)(ResetPasswordWithIntlWithAlert);
const ResetPasswordCall = Api(ResetPasswordWithContext);
export const ResetPassword = AuthApi(ResetPasswordCall);
