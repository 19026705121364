import React from 'react';
import { withAlert } from 'react-alert';
import {injectIntl, IntlShape} from 'react-intl';
import { Api, RefreshApi } from '../../../../../../api/Server';
import { HttpRequestType } from '../../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../../model/http/Request';
import { SessionContext } from '../../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../../../base/ApiComponent';
import { withPaginationRouting, WithPaginationRoutingProps } from '../../../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../../base/model/WithAlertProps';
import { withContext } from '../../../../../lib/component/withContext/withContext.hoc';
import { EditAssistantContext } from '../../state/EditAssistantContext';
import { EditAssistantStateModel } from '../../state/EditAssistantStateModel';
import { GetLogsList, LogsModel } from '../../../../../common/model/logs/LogsModel';
import { LogsAssistantDialogueLogsView } from './LogsAssistantDialogueLogs.view';

interface LogsAssistantDetailProps {
    dialogueUuid: string;
}

interface IntlProps {
  intl: IntlShape;
}

type Props =
  IntlProps
  & ApiProps<GetLogsList>
  & SessionStateModel
  & EditAssistantStateModel
  & WithPaginationRoutingProps<Navigation>
  & WithAlertProps & LogsAssistantDetailProps;

interface LogsDetailState extends ApiState {
    listData: LogsModel[];
    totalPages: number;
    currentPage: number;
}

interface LogsPayload {
    dialogue_uuid: string;
  }

export class LogsAssistantDialogueLogsBase extends ApiBase<GetLogsList, Props, LogsDetailState> {

    public render = LogsAssistantDialogueLogsView.bind(this);
    public containerRef: any = React.createRef();

    private readonly GETLOGS = 'getLogs';

    constructor(props: Props) {
        super(props);

        this.state = {
            listData: [],
            error: null,
            authError: null,
            totalPages: 0,
            currentPage: 0,
        };
    }

    public componentDidMount() {
        this.callLogsForDialogue();
    }

    public componentDidUpdate(prevProps: Props) {
        super.componentDidUpdate(prevProps);
        if (prevProps.dialogueUuid !== this.props.dialogueUuid) {
            this.callLogsForDialogue();
        }
    }

    public callLogsForDialogue() {
        const payload: LogsPayload = {
            dialogue_uuid: this.props.dialogueUuid,
        };

        const { currentAssistant } = this.props;
        const page = this.getCurrentPage();
        const pageSize = this.getCurrentPageSize();
        const request: Request<{}> = {
            id: this.GETLOGS,
            method: HttpRequestType.POST,
            payload,
            relativePath: `/admin/assistants/${currentAssistant}/logs?page=${page}&size=${pageSize}`,
            token: this.props.token,
            refreshToken: this.props.refreshToken,
        };
        this.doCall(request);
    }

    public getCurrentPage() {
        return this.state.currentPage;
    }

    public getCurrentPageSize(): number {
        return this.props.pageSize;
    }

    public changePageSize = (size: number) => {
        this.props.changePageSize(size);
    }

    public changePaginationHandler = (pageNumber: number) => {
        this.setState(() => ({
            currentPage: pageNumber,
        }), () => {
            this.callLogsForDialogue();
            this.containerRef.current.scrollIntoView();
        });
    }

    public getDialogueList(): LogsModel[] {
        return this.state.listData !== null ? this.state.listData : [];
    }

    public formatMessage(id: string) {
      return this.props.intl.formatMessage({id});
    }

    protected setResponseData(): void {
        const response = this.props.response;
        this.setState(() => ({
            listData: response.content,
            totalPages: Math.ceil(response.totalElements / Number(response.size)),
        }));
    }

    protected setErrorResponseData(): void {
       this.props.alert.error(this.formatMessage('logsAssistantDialogueLogsBase.errorFetchingLogsDetail') +
       (this.props.error ? ': ' + this.props.error!.message : ''));
    }

}

const LogsAssistantDialogueLogsWithIntl = injectIntl(LogsAssistantDialogueLogsBase);
const LogsAssistantDialogueLogsWithAlert =
  withAlert<LogsAssistantDialogueLogsBase['props']>()(LogsAssistantDialogueLogsWithIntl);
const LogsAssistantDialogueLogsWithSession = withContext(SessionContext)(LogsAssistantDialogueLogsWithAlert);
const LogsAssistantDialogueLogsWithAssistant = withContext(EditAssistantContext)(LogsAssistantDialogueLogsWithSession);
const LogsAssistantDialogueLogsWithApi = RefreshApi(Api(LogsAssistantDialogueLogsWithAssistant));
export const LogsAssistantDialogueLogsDetail = withPaginationRouting()(LogsAssistantDialogueLogsWithApi);
