import React, { FormEvent } from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteChildrenProps } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Api, AuthApi } from '../../../../api/Server';
import { AppError, AppErrorType } from '../../../../model/AppError';
import { AsyncState } from '../../../../model/AsyncState';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { WithPaginationRoutingProps } from '../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../base/model/route/Navigation';
import { Roles } from '../../../base/model/route/Role';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { SessionContext } from '../../state/Session/SessionContext';
import { Build, SessionStateModel, Organization } from '../../state/Session/SessionStateModel';
import { Request } from '../../../../model/http/Request';
import { LoginFormView } from './LoginForm.view';

interface ComponentProps {
  authenticate: (username: string, password: string) => void;
  call: (Request: Request<{}>, newToken?: string, acceptAudio?: boolean) => void;
  authError: AppError;
  authState: AsyncState;
  authToken: string;
  authRefreshToken: string;
  authExpiresIn: Date;
  authAuthorities: string[];
  response: { build: Build };
  statusCode: number;
  callId: string;
  state: AsyncState;
  intl: IntlShape;
}

interface State extends ApiState {
  username: string;
  password: string;
  email: string;
  error: AppErrorType | null;
  build: Build | null;
  showRegistration: Boolean;
}

interface LoginResponse extends Response {
  timestamp: Date;
}

interface OrganizationResponse extends Response {
  content: Organization;
  timestamp: Date;
}

interface ResetResponse extends Response {
  content: string;
  timestamp: Date;
}

interface IntlProps {
  intl: IntlShape;
}

type Props = IntlProps &
  ComponentProps &
  SessionStateModel &
  RouteChildrenProps &
  ApiProps<LoginResponse & OrganizationResponse & ResetResponse> &
  LayoutStateModel &
  RouteComponentProps<Navigation> &
  WithAlertProps &
  WithPaginationRoutingProps;

interface Payload {
  name: string;
  description: string;
  maxIdleTime: string;
}

interface EmailPayload {
  email: string;
}

const backendVersion: string = 'backendVersion';
const userOrganization: string = 'userOrganization';
const resetUserPassword: string = 'resetUserPassword';

export class LoginFormBase extends ApiBase<LoginResponse & OrganizationResponse & ResetResponse, Props, State> {
  public state: State = {
    username: '',
    password: '',
    email: '',
    error: null,
    build: null,
    authError: null,
    showRegistration: process.env.REACT_APP_IS_REGISTRATION_VISIBLE === 'yes',
  };
  public payload: Payload = {
    name: '',
    description: '',
    maxIdleTime: '',
  };

  public render = LoginFormView.bind(this);

  private _loadingBar: any = null;

  constructor(props: Props) {
    super(props);
    this._loadingBar = null;
  }

  public chooseSubmit = (e: FormEvent<HTMLFormElement>) => {
    this.onSubmitLogin(e);
  }

  public onSubmitLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { username, password } = this.state;
    this.setState(
      {
        error: null,
      },
      () => {
        this.props.authenticate(username, password);
      },
    );
  }

  public resetPassword = async () => {
    const payload: EmailPayload = { email: this.state.email };
    const request: Request<{}> = {
      method: HttpRequestType.POST,
      payload,
      relativePath: `/password/reset`,
      token: this.props.authToken,
      refreshToken: this.props.authRefreshToken,
      id: resetUserPassword,
    };
    await this.props.call(request);
  }
  public changeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      username: value,
    });
  }

  public changePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      password: value,
    });
  }

  public changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      email: value,
    });
  }

  public hasError(): boolean {
    return this.state.error !== null;
  }

  public componentWillUpdate = async (nextProps: Readonly<Props>) => {
    if (nextProps.organization) {
      await this.redirectAfterLogin();
    }
  }

  public setRef = (ref: any) => {
    this._loadingBar = ref;
  }

  public setResponseData() {
    this._loadingBar.complete();
    const { authToken, authRefreshToken, authExpiresIn, authAuthorities } = this.props;
    this.props.setSessionData(authToken, authRefreshToken, authExpiresIn, authAuthorities, this.state.username);
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.state !== this.props.state) {
      if (this.props.callId === backendVersion) {
        if (this.props.response && this.props.response.build) {
          this.props.setBackendBuild(this.props.response.build);
        }
      } else if (this.props.callId === userOrganization) {
        const organizationResponse: Organization = this.props.response.content;
        this.props.setOrganization(organizationResponse);
      }
    }
    if (prevProps.authState !== this.props.authState) {
      switch (this.props.authState) {
        case AsyncState.REQUESTED:
          this._loadingBar.continuousStart();

          break;
        case AsyncState.ERROR:
          if (this.props.authError) {
            this.setState({
              error: this.props.authError.type,
            });

            this.setErrorResponseData();
          }

          break;

        case AsyncState.SUCCESS:
          this.setResponseData();
          this.getOrganizationForUser();
          break;
      }
    }
  }

  public componentDidMount = async () => {
    this.props.clearSession();
    await this.getBackendVersion();
  }

  public getOrganizationForUser = async () => {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/organizations/myorganization`,
      token: this.props.authToken,
      refreshToken: this.props.authRefreshToken,
      id: userOrganization,
    };
    await this.props.call(request);
  }

  public getBackendVersion = async () => {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/actuator/info`,
      token: null,
      refreshToken: null,
      id: backendVersion,
    };
    await this.props.call(request);
  }
  public componentWillUnmount() {
    this._loadingBar.complete();
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  protected setErrorResponseData() {
    this._loadingBar.complete();
    if ('SMS token was sent' === this.props.authError.message) {
      sessionStorage.setItem('username', this.state.username);
      sessionStorage.setItem('password', this.state.password);
      this.props.history.push('/auth/verifyCode');
    }
  }

  private redirectAfterLogin() {
    if (this.props.authAuthorities.includes(Roles.super)) {
      this.props.history.push('/organizations/list');
    } else {
      this.props.history.push('/dashboard');
    }
  }
}
const loginWithIntl = injectIntl(LoginFormBase);
const loginWithIntlWithAlert = withAlert<LoginFormBase['props']>()(loginWithIntl);
const LoginFormWithContext = withContext(SessionContext)(loginWithIntlWithAlert);
const LoginFormCall = Api(LoginFormWithContext);
export const LoginForm = AuthApi(LoginFormCall);
