import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { ActiveConversationsByAssistantBase } from './ActiveConversationsByAssistant.component';

export const ActiveConversationsByAssistantView: ViewFunction<ActiveConversationsByAssistantBase> = function() {
  const activeConversations = this.getChartData();
  const noData = activeConversations.length === 0;

  return (
    <div className='card-active-chats card shadow-sm' id='top-active-conversations'>
      <div className='card-header'>
        <div className='d-flex justify-content-between'>
          <div>
            <h6 className='mb-0 text-left'>
              <FormattedMessage id='activeConversationsByAssistant.activeconversations' />
            </h6>
          </div>
          {/* tslint:disable-next-line:max-line-length */}
          <span className='badge badge-primary badge-pill mr-2' style={{paddingTop: '7px'}}>{this.getTotalChats()}</span>
        </div>
      </div>
      <div className='card-body' style={{ padding: '0 0 1.5rem 0' }}>
        <div className='active-mod-height-control'>
          {!noData && (
            <ul className='list-group list-active-conv' id='top-active-conversations-list'>
              {activeConversations.map((conversation, idx) => (
                <li
                  key={idx}
                  className='list-group-item d-flex justify-content-between align-items-center'
                  data-type='item'
                >
                  <Link
                    style={{ color: '#999' }}
                    data-property-name='name'
                    data-property-value={conversation.assistantName}
                    data-action='edit-assistant'
                    to={`/bots/edit/${conversation.key}`}
                    id={conversation.assistantName}
                  >
                    {conversation.assistantName}
                  </Link>
                  <span className='badge badge-primary badge-pill'>{conversation.value}</span>
                </li>
              ))}
            </ul>
          )}
          {noData && (
            <div
              className='h-100 d-flex align-items-center justify-content-center'
              style={{
                color: '#000',
                fontSize: '0.9em',
                fontWeight: 'bolder',
              }}
            >
              No Data Available.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
