import React, { MouseEvent } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import cm from '../../../../../assets/img/cm.png';
import facebook from '../../../../../assets/img/facebook.png';
import test from '../../../../../assets/img/logo-vivid-red_png@1x.png';
import twilio from '../../../../../assets/img/twilio.png';
import freshworks from '../../../../../assets/img/freshworks.png';
import { Status } from '../../../model/Status';
import { CmWhatsappProvider } from './CmWhatsappProviderChannel.component';
import { Channels, GeneralProps, Providers } from './model/ChannelProviders';
import { ChannelModel } from './state/ChannelStateModel';
import { FacebookAPIProviderChannel } from './FacebookAPIProviderChannel.component';
import { SmsProviderChannel } from './SmsProviderChannel.component';
import { PhoneProviderChannel } from './PhoneProviderChannel.component';
import { FreshchatProviderChannel } from './FreshchatAPIProviderChannel.component';

function logo(item: ChannelModel) {
  if (item.provider === Providers.Facebook) {
    return facebook;
  } else if (item.provider === Providers.Twilio) {
    return twilio;
  } else if (item.provider === Providers.CM) {
    return cm;
  } else if (item.provider === Providers.Freshworks) {
    return freshworks;
  }
  return test;
}

function getToggleTitle(item: ChannelModel | null): string {
  if (item && item.status === Status.ENABLED) {
    return 'Enabled';
  }
  return 'Disabled';
}

function getToggle(item: ChannelModel | null): string {
  if (item && item.status === Status.ENABLED) {
    return 'fas fa-toggle-on fa-2x on text-success';
  }
  return 'fas fa-toggle-off fa-2x off text-secondary';
}

function dataPropertyValue(item: ChannelModel | null) {
  if (item && item.status === Status.ENABLED) {
    return true;
  }
  return false;
}

function renderGeneralInput(currentItem: ChannelModel) {
  const url = `${process.env.REACT_APP_STUDIO_URL}/external/channels/${currentItem.uuid}?X-API-Key=${currentItem.selectedApiKey}`;
  return (
    <div className='form-group'>
      <div className='input-group'>
        <input type='text' readOnly className='form-control' id={currentItem.uuid} placeholder={url} />
        <div className='input-group-append'>
          <CopyToClipboard text={url}>
            <button className='btn btn-secondary' type='button'>
              Copy
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}

function renderExtraInputs(item: ChannelModel, isAdmin: boolean) {
  item.isAdmin = isAdmin;
  switch (item.channelType) {
    case Channels.FACEBOOK:
      return <FacebookAPIProviderChannel {...item} />;
    case Channels.WHATSAPP:
      return <CmWhatsappProvider {...item} />;
    case Channels.SMS:
      return <SmsProviderChannel {...item} />;
    case Channels.PHONE:
      return <PhoneProviderChannel {...item} />;
    case Channels.FRESHCHAT:
      return <FreshchatProviderChannel {...item} />;
    default:
      return renderGeneralInput(item);
  }
}

export function GeneralProviderChannel(data: GeneralProps) {
  const item = data.item;

  const onConfirm = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (data.isAdmin) {
      data.confirm(item);
    }
  };

  const onChangeApiKey = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value;
    data.changeApikey(value, item);
  };

  return (
    <React.Fragment>
      <div className='form-group'>
        <div className='toggle-status float-left' onClick={onConfirm}>
          <i
            id={`state-${item.uuid}`}
            data-property-name='statusBt'
            data-property-value={dataPropertyValue(item)}
            className={getToggle(item)}
            title={getToggleTitle(item)}
          />
        </div>
        <label className='mt-1'>{getToggleTitle(item)}</label>
      </div>
      <div className='form-group'>
        <label>Provider</label>
        <br />
        <span>
          <img className='channel-provider-logo' alt='' src={logo(item)} />
        </span>
      </div>

      <div className='form-group'>
        <label>API Key</label>
        <select
          className='form-control'
          id='api-key'
          onChange={onChangeApiKey}
          style={{ width: 'auto' }}
          disabled={!data.isAdmin}
        >
          {(Object.keys(item.apiKeys) as Array<keyof typeof item.apiKeys>).map((key, idx) => {
            const name = item.apiKeys[key];
            return (
              <option
                data-property-value={key}
                value={key as string}
                key={idx}
                id={name.toString().replace(/ /g, '').concat(`-${item.uuid}`)}
              >
                {name}
              </option>
            );
          })}
        </select>
      </div>
      {renderExtraInputs(item, data.isAdmin)}
    </React.Fragment>
  );
}
