import { FallbackBase, ThirdFallback } from './Fallback.component';
import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { FormattedMessage } from 'react-intl';
import { AddWelcome } from './AddWelcome.component';
import RangeSlider from 'react-bootstrap-range-slider';

export const FallbackView: ViewFunction<FallbackBase> = function() {
  return (
    <>
      <AddWelcome
        isVisible={this.state.isModalVisible}
        handleClose={this.handleClose}
        save={this.save}
        messageToEdit={this.state.answerToEdit}
        saveEdits={this.saveEdits}
        title={this.formatMessage('assistant.studio.fallbackmessage')}
      />
      <div className='card card-body shadow-sm'>
        <div className='form-group'>
          <label htmlFor='customRange2' className='form-label'>
            <FormattedMessage id='assistant.studio.confidence' />
          </label>
          <div style={{ width: '60%' }}>
            <RangeSlider
              value={this.state.confidence}
              max={100}
              min={0}
              step={1}
              id='maximum-idle-conversation-time'
              disabled={!this.isAdmin()}
              onChange={this.changeConfidence}
              variant='secondary'
              tooltip='off'
            />
            <div className='text-center'>{this.state.confidence + '%'}</div>
          </div>
          <button className='btn btn-secondary float-right' id='saveConfidence' onClick={() => this.updateConfidence()}>
            <FormattedMessage id='save' />
          </button>
        </div>
        <h6>
          <FormattedMessage id='assistant.studio.fallbackstandard' />
        </h6>
        <div className='d-flex justify-content-start'>
          <div className='speech-bubble-robo speech-bubble-studio mb-3'>
            <p>
            {<span dangerouslySetInnerHTML={{ __html: this.state.answers[0].message }} />}
            </p>
          </div>
          <span
            className='pointer'
            onClick={() => {
              this.edit(0);
            }}
          >
            <i className='fas fa-edit x2' id={'fallbackStandard'}/>
          </span>
        </div>
        <h6>
          <FormattedMessage id='assistant.studio.fallbackattempts' /> {this.state.delayedFallbackCount}{' '}
          <FormattedMessage id='assistant.studio.messages' />
        </h6>
        <p>
          <FormattedMessage id='assistant.studio.simple' />
        </p>
        <div className='d-flex justify-content-start mb-3'>
          <input
            className='mr-3'
            type='radio'
            value={ThirdFallback.SIMPLE}
            checked={this.state.thirdFallback === ThirdFallback.SIMPLE}
            onChange={this.handoffChange}
          />
          <div className='speech-bubble-robo speech-bubble-studio'>
            <p>
            {<span dangerouslySetInnerHTML={{ __html: this.state.answers[1].message }} />}
            </p>
          </div>
          <span
            className='pointer'
            onClick={() => {
              this.edit(1);
            }}
          >
            <i className='fas fa-edit x2' id='fallbackSimple'/>
          </span>
        </div>
        <p>
          <FormattedMessage id='assistant.studio.handoff' />
        </p>
        <div className='d-flex justify-content-start'>
          <input
            className='mr-3'
            type='radio'
            value={ThirdFallback.HANDOFF}
            checked={this.state.thirdFallback === ThirdFallback.HANDOFF}
            onChange={this.handoffChange}
          />
          <div className='speech-bubble-robo speech-bubble-studio'>
            <p>
            {<span dangerouslySetInnerHTML={{ __html: this.state.answers[2].message }} />}
            </p>
          </div>
          <span
            className='pointer'
            onClick={() => {
              this.edit(2);
            }}
          >
            <i className='fas fa-edit x2' id='fallbackHandoff'/>
          </span>
        </div>
        {this.state.thirdFallback === ThirdFallback.HANDOFF && (
          <>
            <p className='mb-1 mt-3 ml-4'>Group</p>
            {!this.state.group && (
              <div className='ml-4 d-flex justify-content-start input-group' style={{ width: '200px' }}>
                <input
                  id={'inputGroup'}
                  className='form-control'
                  type='text'
                  value={this.state.groupInput}
                  name='group'
                  onChange={this.handleGroup}
                />
                <div className='input-group-append'>
                  <button className='btn btn-light' type='button' id='addGroup' onClick={this.addGroup}>
                    <FormattedMessage id='add' />
                  </button>
                </div>
              </div>
            )}
            {this.state.group && (
              <p className='ml-4 mt-1' id={'removeGroup'} onClick={this.removeGroup}>
                {this.state.group}
                <span className='links'>
                  <i className='far fa-trash-alt x2 ml-2' />
                </span>
              </p>
            )}
          </>
        )}
      </div>
    </>
  );
};
