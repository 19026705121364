import { withAlert } from 'react-alert';
import { RouteComponentProps, withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../api/Server';
import { AppErrorType } from '../../../../model/AppError';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request, RequestActions } from '../../../../model/http/Request';
import { Response } from '../../../../model/http/Response';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { AppRouteComponentProps } from '../../../base/model/route/AppRouteComponentProps';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { EditAssistantView } from './EditAssistant.view';
import { EditAssistantContext } from './state/EditAssistantContext';
import { AssistantModel, EditAssistantStateModel } from './state/EditAssistantStateModel';
import { injectIntl, IntlShape } from 'react-intl';
import { AssistantListOperationType } from '../AssistantList/AssistantListData.component';
import { Navigation } from '../../../base/model/route/Navigation';
import { SessionContext } from '../../../auth/state/Session/SessionContext';
import download from 'downloadjs';
import {TrainingStatus} from '../../model/Status';

interface ResponseUpdate extends Response {
  content: AssistantModel;
  timestamp: Date;
}

interface IntlProps {
  intl: IntlShape;
}
interface AssistantSection {
  name: string;
  identifier: string;
}

interface State extends ApiState {
  isStudioBot: boolean;
  error: AppErrorType | null;
  modalVisibility: boolean;
  showConfirmDialog: boolean;
  importFile: File | null;
  disableTab: boolean;
  exportButtonEnable: boolean;
}

type Props = IntlProps &
  ApiProps<ResponseUpdate & string> &
  SessionStateModel &
  LayoutStateModel &
  EditAssistantStateModel &
  RouteComponentProps<Navigation> &
  RouteComponentProps<{ botId: string }> &
  WithAlertProps &
  AppRouteComponentProps;

export class EditAssistantBase extends ApiBase<ResponseUpdate & string, Props, State> {
  public render = EditAssistantView.bind(this);

  public state: State = {
    isStudioBot: false,
    authError: null,
    error: null,
    modalVisibility: false,
    showConfirmDialog: false,
    importFile: null,
    disableTab: false,
    exportButtonEnable: true,
  };

  public sections: AssistantSection[] = [
    { identifier: 'info', name: this.formatMessage('assistant.info.edit.info')},
    { identifier: 'studio', name: this.formatMessage('assistant.info.edit.studio')},
    { identifier: 'logs', name: this.formatMessage('assistant.info.edit.logs')},
    { identifier: 'test', name: this.formatMessage('assistant.info.edit.test')},
    { identifier: 'channels', name: this.formatMessage('assistant.info.edit.channels')},
  ];

  public getTextTitle(): string {
    return this.formatMessage('assistant.studio.disable.title');
  }

  public disableTab = (ev: any) => {
      if (ev.detail === 'disableTabs') {
       this.setState({
         disableTab: true,
       });
      } else if (ev.detail === 'enableTabs') {
       this.setState({
         disableTab: false,
       });
      }
  }

  public componentDidMount = async () => {
    this.getAssistant();
    this.onEmiter(this.disableTab);
  }

  public getAssistant = async () => {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/assistants/uuid/${this.getAssistantId()}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: AssistantListOperationType.GET,
    };
    await this.doCall(request);
  }

  public getAssistantId() {
    return this.props.match.params.botId;
  }

  public botExport = async () => {

    this.setState({
      exportButtonEnable: false,
    }, () => this.updateExportButton('export-bot'));

    const request: Request<{}> = {
      method: HttpRequestType.POST,
      payload: null,
      relativePath: `/admin/studio/assistant/${this.getAssistantId()}/export`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      action: RequestActions.DOWNLOAD,
      id: 'export-bot',
    };
    await this.doCall(request);
  }

  public botImport() {
    this.setModalVisibility(true);
  }

  public onClickImport = (file: File | null) => {
    this.setState({
      importFile: file,
    });
    this.setModalVisibility(false);
    this.setConfirmVisibility(true);
  }

  public confirmCallback = async () => {
    if (this.state.importFile) {
      const formData = new FormData();
      formData.append('file', this.state.importFile);
      formData.append('assistantUuid', this.getAssistantId());
      const request: Request<{}> = {
        method: HttpRequestType.POST,
        payload: formData,
        relativePath: `/admin/studio/assistant/import`,
        token: this.props.token,
        refreshToken: this.props.refreshToken,
        id: 'import-bot',
      };
      await this.doCall(request);
    }
  }

  public setConfirmVisibility = (show: boolean) => {
    this.setState({ showConfirmDialog: show });
  }

  public onClose = () => {
    this.setModalVisibility(false);
  }

  public setModalVisibility(boolean: boolean) {
    this.setState({
      modalVisibility: boolean,
    });
  }

  public setResponseData() {
    if (this.props.callId === 'import-bot') {
      this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
      this.getAssistant();
      this.emiter( 'import refresh' , 'importSuccess');
    }
    if (this.props.callId === 'export-bot') {
      const response = this.props.response;
      if (response) {
        download(response, `${this.getAssistantId()}-export.json`, 'application/text');
      }
      this.setState({
        exportButtonEnable: true,
      }, () => this.updateExportButton('export-bot'));

    }
    if (this.props.callId === AssistantListOperationType.GET) {
      const { uuid, name, description, status, maxIdleTime, studioConfidence, maxData, params, displayName,
        language, type, trainingStatus } =
        this.props.response.content;
      const assistantData: AssistantModel = {
        uuid,
        name,
        displayName,
        description,
        status,
        maxIdleTime,
        studioConfidence,
        maxData,
        params,
        language,
        type,
        trainingStatus,
      };
      this.props.setAssistantInfo(assistantData);
      const isStudio = this.props.response.content.params.find((param) => param.value === 'STUDIO');
      if (isStudio) {
        const importTitleHint = this.formatMessage('assistant.studio.import');
        const exportTitleHint = this.formatMessage('assistant.studio.export');

        this.props.addHeaderBtn({ id: 'import-bot', title: importTitleHint , hint: importTitleHint,
          callback: () => this.botImport(), enabled: true});
        this.props.addHeaderBtn({ id: 'export-bot', title: exportTitleHint, hint: exportTitleHint,
          callback: () => this.botExport(), enabled: true});
      }
      this.setState({
        isStudioBot: isStudio ? true : false,
      });

      const disable = this.props.response.content.trainingStatus === TrainingStatus.DRAFT ||
        this.props.response.content.trainingStatus === TrainingStatus.TRAINING;

      this.setState({
        disableTab: disable ,
      });
    }
  }

  public componentWillUnmount(): void {
    this.props.setBreadCrumbsInfo({
      currentPage: null,
    });
    this.props.removeHeaderBtn('export-bot');
    this.props.removeHeaderBtn('import-bot');
  }

  public getSectionRoute = (section: string) => {
    return `/bots/edit/${this.getAssistantId()}/${section}`;
  }

  public isActive = (section: string) => this.props.location.pathname.startsWith(this.getSectionRoute(section));

  public getAriaSelected = (section: string) => {
    return this.isActive(section) ? 'true' : 'false';
  }

  public getActiveClass = (section: string) => {
    return this.isActive(section) ? 'active' : '';
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
  }

  private updateExportButton(id: string) {
    const exportTitleHint = this.formatMessage('assistant.studio.export');

    this.props.updateHeaderBtn( id, { id, title: exportTitleHint, hint: exportTitleHint,
      callback: () => this.botExport(), enabled: this.state.exportButtonEnable});
  }
}
const EditAssistantWithIntl = injectIntl(EditAssistantBase);
const EditAssistantWithAlert = withAlert<EditAssistantBase['props']>()(EditAssistantWithIntl);
const EditAssistantWithSession = withContext(SessionContext)(EditAssistantWithAlert);
const EditAssistantStatusWithContext = withContext(EditAssistantContext)(EditAssistantWithSession);
const EditAssistantWithApi = RefreshApi(Api(EditAssistantStatusWithContext));
export const EditAssistant = withRouter(EditAssistantWithApi);
