import { ChangeEvent, FormEvent } from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Api, RefreshApi } from '../../../../api/Server';
import { AppError } from '../../../../model/AppError';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { Response } from '../../../../model/http/Response';
import { SessionContext } from '../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { HeaderBtn } from '../../../base/component/PageHeader/model/HeaderBtn';
import { Navigation } from '../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutContext } from '../../../base/state/Layout/LayoutContext';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { CreateOrganizationView } from './CreateOrganization.view';
import { Payload } from '../../model/AccountModel';

interface State extends ApiState {
  companyName: string;
  streetAddressOne: string;
  streetAddressTwo: string;
  country: number;
  postcode: string;
  vatId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  username: string;
  adminEmail: string;
  password: string;
  isValid: boolean;
  appError?: AppError | null;
  countrySubmitError: boolean;
}

interface CreateResponse extends Response {
  content: null;
  timestamp: Date;
}

interface IntlProps {
  intl: IntlShape;
}

interface ComponentProps {
  refresh: () => void;
}

type Props = IntlProps &
  ApiProps<CreateResponse> &
  SessionStateModel &
  LayoutStateModel &
  RouteComponentProps<Navigation> &
  WithAlertProps &
  ComponentProps;

export class CreateOrganizationBase extends ApiBase<CreateResponse, Props, State> {
  public render = CreateOrganizationView.bind(this);

  public state: State = {
    companyName: '',
    streetAddressOne: '',
    streetAddressTwo: '',
    country: 0,
    postcode: '',
    vatId: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    username: '',
    adminEmail: '',
    password: '',
    error: null,
    authError: null,
    isValid: false,
    appError: null,
    countrySubmitError: false,
  };

  public componentDidMount = () => {
    const createAssistantBtn: HeaderBtn = {
      title: this.formatMessage('create'),
      hint: this.formatMessage('create'),
      callback: this.handleOpen,
      id: 'create-organization',
      enabled: true,
    };
    this.props.addHeaderBtn(createAssistantBtn);
  }

  public componentWillReceiveProps = (nextProps: Props) => {
    if (nextProps.error !== this.props.error) {
      this.setState({
        appError: nextProps.error,
      });
    }
  }

  public componentWillUnmount = () => {
    this.props.removeHeaderBtn('create-organization');
  }

  public isModalVisible = () => {
    return this.props.showModal;
  }

  public hasError = () => {
    return this.state.appError !== null;
  }

  public handleClose = () => {
    this.props.setModalVisibility(false);
    this.setState({
      countrySubmitError: false,
    });
  }

  public handleOpen = () => {
    this.setState({
      companyName: '',
      streetAddressOne: '',
      streetAddressTwo: '',
      country: 0,
      postcode: '',
      vatId: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      username: '',
      adminEmail: '',
      password: '',
      appError: null,
    });

    this.props.setModalVisibility(true);
  }

  public setIsFormValid = (isValid: boolean) => {
    if (this.state.isValid !== isValid) {
      this.setState({
        isValid,
      });
    }
  }

  public handleCountryChange = (event: any) => {
    const target = event.target;
    const value = target.value;
    this.setState({
      country: value,
    });
  }

  public handlePhoneChange = (value: any) => {
    this.setState({
      phone: value,
    });
  }

  public changeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const key = event.currentTarget.name as keyof State;
    const value = event.currentTarget.value as string;

    if (Object.keys(this.state).includes(key)) {
      this.setState({
        ...this.state,
        [key]: value,
      });
    }
  }

  public checkCountryError = () => {
    if (this.state.country === 0) {
      this.setState({
        countrySubmitError: true,
      });
    } else {
      this.setState({
        countrySubmitError: false,
      });
    }
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!this.state.countrySubmitError) {
      this.setState(
        {
          error: null,
        },
        () => {
          this.createCompany();
        },
      );
    }
  }

  public createCompany = () => {
    const payload: Payload = {
      company: {
        countryCode: this.state.country,
        name: this.state.companyName,
        streetAddress1: this.state.streetAddressOne,
        streetAddress2: this.state.streetAddressTwo,
        vatId: this.state.vatId,
        zip: this.state.postcode,
      },
      primaryAccountHolder: {
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phone: this.state.phone,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      adminUser: {
        username: this.state.username,
        email: this.state.adminEmail,
        password: this.state.password,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };

    const request: Request<{}> = {
      method: HttpRequestType.POST,
      payload,
      relativePath: `/admin/organization/administration`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };
    this.doCall(request);
  }

  public setResponseData = () => {
    this.handleClose();
    this.props.refresh();
    this.props.alert.success(this.formatMessage('alert.successfullyCreated'));
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  protected setErrorResponseData = () => {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }

}

export const CreateOrganizationWithIntl = injectIntl(CreateOrganizationBase);
const CreateOrganizationWithAlert = withAlert<CreateOrganizationBase['props']>()(CreateOrganizationWithIntl);
const CreateOrganizationWithLayout = withContext(LayoutContext)<CreateOrganizationBase['props']>(
  CreateOrganizationWithAlert,
);
const CreateOrganizationWithSession = withContext(SessionContext)<CreateOrganizationBase['props']>(
  CreateOrganizationWithLayout,
);
const CreateOrganizationWithApi = RefreshApi(Api(CreateOrganizationWithSession));
export const CreateOrganization = withRouter(CreateOrganizationWithApi);
