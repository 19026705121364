import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import Confirm from '../../../base/component/confirm/Confirm.component';
import { PageSize } from '../../../base/component/PageSize/PageSize.component';
import { Pagination } from '../../../base/component/Pagination/Pagination.component';
import { Search } from '../../../base/component/Search/Search.component';
import { ColumnObj, Table } from '../../../base/component/Table/Table.component';
import { CreateApiKey } from '../CreateApiKey/CreateApiKey.component';
import { utcDateFormat } from './../../../../util/dateFormat';
import { ApiKeyModel } from './../../model/ApiKey';
import { ApiKeyListBase } from './ApiKeyList.component';

export const ApiKeyListView: ViewFunction<ApiKeyListBase> = function() {
  const columns: ColumnObj[] = [
    {
      title: this.formatMessage('label'),
      propertyName: 'label',
      style: { width: '300px' },
      render: (item: ApiKeyModel) => <span>{item.label}</span>,
    },
    {
      title: this.formatMessage('key'),
      propertyName: 'apiKey',
      render: (item: ApiKeyModel) => {
        return (
          <React.Fragment>
            <button className='btn btn-sm btn-light' id='showhideApikey' onClick={this.toggleShowKey(item)}>
              {item.showKey ? this.formatMessage('createApiKeyBase.hideApiKey') : this.formatMessage('createApiKeyBase.showApiKey')}
            </button>
            <span className='hidden ml-3' id='apikey' style={{ display: item.showKey ? 'inline-block' : 'none' }}>
              {item.uuid}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      title: this.formatMessage('account.api.scope'),
      propertyName: 'scope',
      render: (item: ApiKeyModel) => {
        return <span>{this.getScope(item.scope)}</span>;
      },
    },
    {
      title: this.formatMessage('apiKeyListBase.created'),
      propertyName: 'created',
      style: { width: '200px' },
      render: (item: ApiKeyModel) => {
        return <span>{utcDateFormat(item.created)}</span>;
      },
    },
    {
      title: this.formatMessage('status'),
      style: { textTransform: 'capitalize', width: '80px' },
      propertyName: 'status',
      render: (item: ApiKeyModel) => <span id={'apikeyStatus#' + item.label.replace(/ /g, '')}>{item.status.toLocaleLowerCase()}</span>,
    },
  ];

  return (
    <React.Fragment>
      <Confirm
        show={this.state.showConfirmDialog}
        okLabel={this.formatMessage('ok')}
        cancelLabel={this.formatMessage('cancel')}
        confirmation={this.state.confirmMsg!}
        proceedFunc={this.confirmCallback}
        title={this.formatMessage('confirm')}
        setConfirmVisibility={this.setConfirmVisibility}
      />
      <CreateApiKey/>
      <div className='card shadow-sm'>
        <div className='card-body'>
          <div className='row'>
            <Search searchHandler={this.searchHandler} search={this.getCurrentCriteria()} />
          </div>
          <Table
            tableId={'ApiKeys'}
            data={this.getData()}
            columns={columns}
            page={this.getPage()}
            dropDownMenuItems={this.dropDownMenuItems}
          />
          <div className='row mt-3'>
            <div className='col-sm-6'>
              <PageSize changePageSize={this.changePageSizeHandler} size={this.getPageSize()} />
            </div>
            <div className='col-sm-6'>
              <Pagination
                totalPages={this.getTotalPages()}
                changePaginationHandler={this.changePageHandler}
                page={this.getPage()}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
