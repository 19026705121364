import React from 'react';
import { ChannelType } from '../../../../../common/model/logs/LogsModel';
import { ViewFunction } from '../../../../../../model/component/ViewFunction';
import { LogsGroupChannel } from './LogsGroupChannel.component';

export const LogsGroupChannelView: ViewFunction<LogsGroupChannel> = function() {
    return (
        <React.Fragment>
            {this.getChannelType() === ChannelType.FACEBOOK &&  <i className='fab fa-2x fa-facebook-f'/>}
            {this.getChannelType() === ChannelType.MESSENGER &&  <i className='fab fa-2x fa-facebook-messenger'/>}
            {this.getChannelType() === ChannelType.WHATSAPP &&  <i className='fab fa-2x fa-whatsapp'/>}
            {this.getChannelType() === ChannelType.API &&  <i className='fas fa-2x fa-globe'/>}
            {this.getChannelType() === ChannelType.SMS &&  <i className='fas fa-2x fa-sms'/>}
            {this.getChannelType() === ChannelType.PHONE &&  <i className='fas fa-2x fa-phone'/>}
            {this.getChannelType() === ChannelType.INSTAGRAM &&  <i className='fab fa-2x fa-instragram'/>}
            {this.getChannelType() === ChannelType.SKYPE &&  <i className='fab fa-2x fa-skype'/>}
            {this.getChannelType() === ChannelType.TELEGRAM &&  <i className='fab fa-2x fa-telegram'/>}
            {this.getChannelType() === ChannelType.VIBER &&  <i className='fab fa-2x fa-viber'/>}
            {this.getChannelType() === ChannelType.RCS && <span>{'RCS'}</span>}
            {this.getChannelType() === ChannelType.TEST &&  <i className='fas fa-2x fa-vial'/>}
            {this.getChannelType() === ChannelType.TWITTER &&  <i className='fab fa-2x fa-twitter'/>}
            {this.getChannelType() === ChannelType.WE_CHAT &&  <i className='fab fa-2x fa-wechat'/>}
            {this.getChannelType() === ChannelType.PUSH_NOTIFICATIONS &&  <i className='fas fa-2x fa-bell'/>}
            {this.getChannelType() === ChannelType.NONE &&  <span/>}
        </React.Fragment>
    );
};
