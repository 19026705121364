import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import './assets/css/bootstrap.min.css';
import './assets/css/react-datepicker.css';
import './assets/css/style.css';
import messages_de from './locale/lang/de.json';
import messages_en from './locale/lang/en.json';
import AppComponent from './modules/base/component/App/App.component';
import * as serviceWorker from './serviceWorker';

export const messages = {
  en: messages_en,
  de: messages_de,
};

ReactDOM.render(
  <BrowserRouter>
    <IntlProvider locale={'en'} messages={messages.en}>
      <AppComponent />
    </IntlProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
