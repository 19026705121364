import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { ActiveConversationByAssistantDataPoint } from '../../model/ActiveConversationByAssistantDataPoint';
import { ActiveConversationsByAssistantResponse } from '../../model/ActiveConversationsByAssistantResponse';
import { ActiveConversationsByAssistantView } from './ActiveConversationsByAssistant.view';
import { StatsTimeFrame } from '../../../common/model/logs/LogsModel';

type Props = IntlProps & ApiProps<ActiveConversationsByAssistantResponse> & SessionStateModel & WithAlertProps;

interface State extends ApiState {
  chartData: ActiveConversationsByAssistantResponse['content'] | undefined;
}

interface IntlProps {
  intl: IntlShape;
  searchTimeFrame: StatsTimeFrame;
}

export class ActiveConversationsByAssistantBase extends ApiBase<ActiveConversationsByAssistantResponse, Props, State> {
  public state: State = {
    chartData: [],
    error: null,
    authError: null,
  };

  public render = ActiveConversationsByAssistantView.bind(this);

  public componentDidMount(): void {
    this.requestData();
  }

  public getChartData(): ActiveConversationByAssistantDataPoint[] {
    return this.state.chartData || [];
  }

  public getTotalChats = () => {
    return this.state.chartData?.reduce((acc, bot) => {
      return (acc += bot.value);
    }, 0);
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  protected requestData() {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/stats/activeconversationsperassistant`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };
    this.doCall(request);
  }

  protected setResponseData(): void {
    const { content } = this.props.response;

    const sortedContent = content.sort((a, b) => {
      if (a.value === b.value) {
        return 0;
      }
      return a.value > b.value ? -1 : 1;
    });

    this.setState(() => ({
      chartData: sortedContent,
    }));
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }
}

const ActiveConversationsByAssistantWithIntl = injectIntl(ActiveConversationsByAssistantBase);
const ComponentWithAlert = withAlert<ActiveConversationsByAssistantBase['props']>()(
  ActiveConversationsByAssistantWithIntl,
);
const ActiveConversationsByAssistantWithApi = RefreshApi(Api(ComponentWithAlert));
export const ActiveConversationsByAssistant = withRouter(ActiveConversationsByAssistantWithApi);
