import React from 'react';
import { withAlert } from 'react-alert';
import {injectIntl} from 'react-intl';
import { withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../../../api/Server';
import { SessionContext } from '../../../../../auth/state/Session/SessionContext';
import { LayoutContext } from '../../../../../base/state/Layout/LayoutContext';
import { withContext } from '../../../../../lib/component/withContext/withContext.hoc';
import { AnswerBody } from '../../../../model/AnswerModel';
import { AnswerType } from '../../../../model/AnswerType';
import { AnswerContext } from '../../../../state/AnswerContext';
import { EditAnswerBase } from '../../EditAnswer.component';
import { TextAnswerView } from './TextAnswer.view';

interface AnswerTextJson extends AnswerBody {
  text: string;
}

export class TextAnswerBase extends EditAnswerBase<AnswerTextJson> {
  public render = TextAnswerView.bind(this);

  public answer: AnswerTextJson = {
    text: '',
    type: AnswerType.TEXT,
  };

  public componentWillMount(): void {
    if (this.props.answer!.uuid) {
      const answer: AnswerTextJson = {
        text: this.props.answer!.answerContent.text,
      };

      this.answer = answer;
    }

    this.setState({
      answer: this.answer,
    });
  }

  public changeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.text = value;

    this.setState({
      answer: this.answer,
    });
  }

  public getContent(): AnswerTextJson {
    const json: AnswerTextJson = {
      text: this.answer.text,
    };
    return json;
  }

  public getAnswerText(): string {
    return this.state.answer.text;
  }
}

const TextAnswerWithIntl = injectIntl(TextAnswerBase);
const TextAnswerWithAlert = withAlert<TextAnswerBase['props']>()(TextAnswerWithIntl);
const TextAnswerWithSession = withContext(SessionContext)(TextAnswerWithAlert);
const TextWithContext = withContext(AnswerContext)(TextAnswerWithSession);
const TextWithLayout = withContext(LayoutContext)(TextWithContext);
const TextAnswerWithApi = RefreshApi(Api(TextWithLayout));
export const TextAnswer = withRouter(TextAnswerWithApi);
