import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Api, RefreshApi } from '../../../../../api/Server';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../model/http/Request';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { withPaginationRouting, WithPaginationRoutingProps } from '../../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../../base/state/Layout/LayoutStateModel';
import { GroupAgent } from '../../../model/GroupModel';
import { EditGroupStateModel } from '../state/EditGroupStateModel';
import { GroupAgentsView } from './GroupAgents.view';

interface ResponseAgents extends  Response {
  content: GroupAgent[];
  timestamp: Date;
  size: string;
  page: number;
  pageElements: number;
  totalElements: number;
}

interface IntlProps {
  intl: IntlShape;
}

interface ListState extends ApiState {
  data: GroupAgent[];
  totalPages: number;
  currentItem: GroupAgent | null;
}

type Props =
  IntlProps
  & ApiProps<ResponseAgents>
  & SessionStateModel
  & EditGroupStateModel
  & RouteComponentProps<Navigation>
  & WithPaginationRoutingProps<Navigation>
  & WithAlertProps
  & LayoutStateModel;

export class GroupAgentsBase extends ApiBase<ResponseAgents, Props, ListState> {

  public render = GroupAgentsView.bind(this);

  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      totalPages: 0,
      error: null,
      authError: null,
      currentItem: null,
    };
  }

  public componentDidMount(): void {
    this.updateList();
  }

  public componentDidUpdate(prevProps: Props) {
    super.componentDidUpdate(prevProps);
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  public onRouteChanged() {
    this.updateList();
  }

  public getCurrentPage(): number {
    return this.props.page;
  }

  public getCurrentPageSize(): number {
    return this.props.pageSize;
  }

  public changePageSize = (pageSize: number) => {
    this.props.changePageSize(pageSize);
  }
  public changePaginationHandler = (pageNumber: number) => {

    this.props.goToPage(pageNumber);
  }

  public updateList = () => {
    const currentPage = this.getCurrentPage();
    const currentPageSize = this.getCurrentPageSize();
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/groups/${this.props.currentGroup}/operators/?full=1&page=${currentPage}&size=${currentPageSize}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };
    this.doCall(request);
  }

  public setResponseData() {
    const response = this.props.response;
    const size = Number(response.size);
    const totalPages = size > 0 ? Math.ceil(response.totalElements / size) : 0;

    this.setState({
      data: response.content,
      totalPages,
    });

    if (response.page) {
      this.props.setBreadCrumbsInfo({
        currentPage: null,
        pageNumber: response.page.toString(),
      });
    }
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({id});
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }
}

const GroupsAgentsWithIntl = injectIntl(GroupAgentsBase);
const GroupsAgentsWithAlert = withAlert<GroupAgentsBase['props']>()(GroupsAgentsWithIntl);
const GroupsAgentsWithApi = RefreshApi(Api(GroupsAgentsWithAlert));
export const GroupsAgentsList = withPaginationRouting()(GroupsAgentsWithApi);
