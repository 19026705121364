import React from 'react';
import {FormattedMessage} from 'react-intl';
import { ViewFunction } from '../../../../../../model/component/ViewFunction';
import { Input } from '../../../../../form/component/Input.component';
import { notEmpty, url } from '../../../../../form/lib/rules';
import formLayout from '../formLayout';
import { VideoAnswerBase } from './VideoAnswer.component';

export const VideoAnswerView: ViewFunction<VideoAnswerBase> = function() {
  const childComponent = () => {
    return (
      <React.Fragment>
        <div className='form-group'>
          <label htmlFor='videoTitle'><FormattedMessage id='title'/></label>
          <span className='float-right text-danger'>*</span>
          <Input
            type='text'
            className='form-control'
            id='videoTitle'
            aria-describedby='videoTitle'
            placeholder={`${this.formatMessage('videoAnswerBase.enterYourTitleForTheVideoHere')}...`}
            value={this.getAnswerVideoTitle()}
            onChange={this.changeTitle}
            rules={[notEmpty()]}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='videoImageUrl'><FormattedMessage id='videoAnswerBase.videoImageUrl'/></label>
          <span className='float-right text-danger'>*</span>
          <Input
            type='text'
            className={'form-control'}
            id='videoImageUrl'
            aria-describedby='videoImageUrl'
            placeholder={`${this.formatMessage('videoAnswerBase.enterYourThumbnailVideoImageUrlHere')}...`}
            value={this.getAnswerVideoMediaUrl()}
            onChange={this.changeMediaUrl}
            rules={[url()]}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='videoUrl'><FormattedMessage id='videoAnswerBase.videoUrl'/></label>
          <span className='float-right text-danger'>*</span>
          <Input
            type='text'
            className='form-control'
            id='videoUrl'
            aria-describedby='videoUrl'
            placeholder={`${this.formatMessage('videoAnswerBase.enterYourVideoUrlHere')}...`}
            value={this.getAnswerVideoImageUrl()}
            onChange={this.changeImageUrl}
            rules={[url()]}
          />
        </div>
        <div className='text-right'>
          <button
            id='saveBtn'
            type='submit'
            className='btn btn-primary'
          >
            {`${this.formatMessage('save')}`}
          </button>
        </div>
      </React.Fragment>
    );
  };

  return (
    formLayout(this, childComponent)
  );
};
