import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';

import Confirm from '../../../../base/component/confirm/Confirm.component';
import { PageSize } from '../../../../base/component/PageSize/PageSize.component';
import { Pagination } from '../../../../base/component/Pagination/Pagination.component';
import { Status } from '../../../model/Status';
import { GroupChannelBase } from './GroupChannel.component';
import { ChannelApi } from './ChannelApi.component';
import { ChannelModel } from './state/ChannelStateModel';

import { GeneralProps } from './model/ChannelProviders';
import { GroupGeneralProviderChannel } from './GeneralProviderChannel.view';

export const GroupChannelView: ViewFunction<GroupChannelBase> = function() {
  const renderChannel = (item: ChannelModel) => {
    const props: GeneralProps = {
      item,
      changeApikey: this.changeApikey,
      confirm: this.confirm,
    };

    return (
      <div className='col-6' key={item.uuid}>
        <div className='card'>
          <div className='card-header'>
            <h6>{this.formatMessage(item.channelType)}</h6>
          </div>
          <div className='card-body'>
            <GroupGeneralProviderChannel {...props} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className='row'>
        <ChannelApi isAdmin={this.isAdmin()} />

        <Confirm
          show={this.state.showConfirmDialog}
          okLabel={'Ok'}
          cancelLabel={'Cancel'}
          confirmation={this.formatMessage(
            'status.confirm.channel',
            this.state.statusToChange === Status.ENABLED ? 'enable' : 'disable',
          )}
          proceedFunc={this.confirmCallback}
          title={'Confirm'}
          setConfirmVisibility={this.setConfirmVisibility}
        />
        {this.state.data.map(renderChannel)}
      </div>
      <div className='row'>
        <div className='col'>
          <PageSize changePageSize={this.changePageSize} size={this.getCurrentPageSize()} />
        </div>
        <div className='col-sm-6 mb-1 display-view'>
          <Pagination
            totalPages={this.getTotalPages()}
            changePaginationHandler={this.changePaginationHandler}
            page={this.getCurrentPage()}
          />
        </div>{' '}
      </div>
    </React.Fragment>
  );
};
