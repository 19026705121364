import React, { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Layout } from '../Layout/Layout.component';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { LayoutContext } from '../../state/Layout/LayoutContext';
import { LayoutStateModel } from '../../state/Layout/LayoutStateModel';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { SessionContext } from '../../../auth/state/Session/SessionContext';
const containerStyle: CSSProperties = {
  maxWidth: '724px',
};

type Props = LayoutStateModel & SessionStateModel;
export class PageNotFoundClass extends React.Component<Props, never> {
  public componentDidMount(): void {
    if (this.hasSuperFunctions()) {
      this.props.setSidebarLinkVisibility(false);
    }
    this.props.setBreadCrumbsInfo({
      currentPage: 'pageNotFound',
    });
  }

  public componentWillUnmount(): void {
    this.props.setBreadCrumbsInfo({
      currentPage: null,
    });
  }

  public hasSuperFunctions = () => {
    return this.props.authorities.includes('super');
  }

  public render() {
    return (
      <Layout>
        <div className='container mt-5' style={containerStyle}>
          <h1 className='text-center mb-3'>404 - Page not found!</h1>
          <div className='card single-card shadow-sm'>
            <div className='card-body text-center'>
              <p>
                <FormattedMessage id={'pageNotFound.msg'} />
              </p>
              {this.hasSuperFunctions() ? (
                <NavLink id='view-organizationlist' className='btn btn-primary' to='/organizations/list' role='button'>
                  <FormattedMessage id={'organizations'} />
                </NavLink>
              ) : (
                <NavLink id='pageNotfound-dashboard' className='btn btn-primary' to='/dashboard' role='button'>
                  <FormattedMessage id={'sidebar.dashboard'} />
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export const PageNotFoundSession = withContext(SessionContext)(PageNotFoundClass);
export const PageNotFound = withContext(LayoutContext)(PageNotFoundSession);
export default PageNotFound;
