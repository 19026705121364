import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import CreateConfirmBase from './Confirm.component';
export const CreateConfirmView: ViewFunction<CreateConfirmBase> = function() {
  return (
      <Modal data-type='modal' dialogClassName='modal-sm' show={this.props.show} onHide={this.cancelClick}>
        <Modal.Header closeButton>
          <Modal.Title as='h5'>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <><div dangerouslySetInnerHTML={{ __html: this.props.confirmation}}/></>
        </Modal.Body>
        <Modal.Footer>
          <button
            id='cancel'
            className='btn btn-dark'
            onClick={this.cancelClick}
          >
            {this.props.cancelLabel}
          </button>
          <button
            id='confirm'
            className='btn btn-primary'
            onClick={this.proceedClick}
          >
            {this.props.okLabel}
          </button>
        </Modal.Footer>
      </Modal>
  );
};

export default CreateConfirmView;
