import React from 'react';
import {AnswerCommandOption} from '../../../../../../answer/model/AnswerModel';
import BubbleCommandView from './BubbleCommandView';

interface Props {
  name?: string;
  params?: AnswerCommandOption[];
}

export class BubbleCommandBase extends React.Component<Props, {}> {

  public render = BubbleCommandView.bind(this);

  public getCommandName() {
    return this.props.name;
  }

  public getParams() {
    return this.props.params ? this.props.params : [];
  }

}

export const BubbleCommand = BubbleCommandBase;
