import React from 'react';
import {BubbleCMSView} from './BubbleCMSView';

interface Props {
  cmsId?: string;
}

export class BubbleCMSBase extends React.Component<Props, {}> {

  public render = BubbleCMSView.bind(this);

  public getCMSId() {
    return this.props.cmsId;
  }

}

export const BubbleCMS = BubbleCMSBase;
