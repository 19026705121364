import { withAlert } from 'react-alert';
import {injectIntl, IntlShape} from 'react-intl';
import { Api, RefreshApi } from '../../../../../../api/Server';
import { HttpRequestType } from '../../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../../model/http/Request';
import { SessionContext } from '../../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../../../base/ApiComponent';
import { withPaginationRouting, WithPaginationRoutingProps } from '../../../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../../base/model/WithAlertProps';
import { withContext } from '../../../../../lib/component/withContext/withContext.hoc';
import { EditGroupContext } from '../../state/EditGroupContext';
import { EditGroupStateModel } from '../../state/EditGroupStateModel';
import { GroupDialogueDetails, GroupDialogueDetailsResponse } from '../../../../model/LogsModel';
import { GetLogsList, SentimentEnum, ChannelType } from '../../../../../common/model/logs/LogsModel';
import { LogsGroupDetailView } from './LogsGroupDetail.view';

interface LogsGroupDetailProps {
    dialogueUuid: string;
}

interface IntlProps {
  intl: IntlShape;
}

type Props =
  IntlProps
  & ApiProps<GetLogsList
  & GroupDialogueDetailsResponse>
  & SessionStateModel
  & EditGroupStateModel
  & WithPaginationRoutingProps<Navigation>
  & WithAlertProps & LogsGroupDetailProps;

interface LogsDetailState extends ApiState {
    detailData: GroupDialogueDetails | null;
    totalPages: number;
    currentPage: number;
}

export class LogsGroupDetailBase
  extends ApiBase<GetLogsList & GroupDialogueDetailsResponse, Props, LogsDetailState> {

    public render = LogsGroupDetailView.bind(this);

    private readonly GETDETAILS = 'getDetails';

    constructor(props: Props) {
        super(props);

        this.state = {
            detailData: null,
            error: null,
            authError: null,
            totalPages: 0,
            currentPage: 0,
        };
    }

    public componentDidMount() {
        this.callDetail();
    }

    public componentDidUpdate(prevProps: Props) {
        super.componentDidUpdate(prevProps);
        if (prevProps.dialogueUuid !== this.props.dialogueUuid) {
            this.callDetail();
        }
    }

    public getStarted(): string {
        return this.state.detailData !== null ? this.state.detailData.started.toString() : '';
    }

    public getEnded(): string {
        return this.state.detailData !== null ? this.state.detailData.ended.toString() : '';
    }

    public getDuration(): number {
        return this.state.detailData !== null ? this.state.detailData.duration : 0;
    }

    public getMessages(): number {
        return this.state.detailData !== null ? this.state.detailData.messages : 0;
    }

    public getConfidence(): number {
        return this.state.detailData !== null ? (Math.round(this.state.detailData.confidence * 100.0)) : 0.0;
    }

    public getSentiment(): SentimentEnum {
        return this.state.detailData !== null ? this.state.detailData.sentiment : SentimentEnum.NEUTRAL;
    }

    public getChannelType(): ChannelType {
        return this.state.detailData !== null ? this.state.detailData.channelType : ChannelType.NONE;
    }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({id});
  }

    protected setResponseData(): void {
        const response = this.props.response;
        this.setState(() => ({
            detailData: response.content,
        }));
    }

    protected setErrorResponseData(): void {
       this.props.alert.error(this.formatMessage('logsGroupDetailBase.errorFetchingDialogueDetail') +
       (this.props.error ? ': ' + this.props.error.message : ''));
    }

    private callDetail(): void {
        const request: Request<{}> = {
            id: this.GETDETAILS,
            method: HttpRequestType.GET,
            relativePath: `/admin/groups/detail/${this.props.currentGroup}/${this.props.dialogueUuid}`,
            payload: null,
            token: this.props.token,
            refreshToken: this.props.refreshToken,
          };
        this.doCall(request);
    }

}

const LogsGroupDetailWithIntl = injectIntl(LogsGroupDetailBase);
const LogsGroupDetailWithAlert = withAlert<LogsGroupDetailBase['props']>()(LogsGroupDetailWithIntl);
const LogsGroupDetailWithSession = withContext(SessionContext)(LogsGroupDetailWithAlert);
const LogsGroupDetailWithAssistant = withContext(EditGroupContext)(LogsGroupDetailWithSession);
const LogsGroupDetailWithApi = RefreshApi(Api(LogsGroupDetailWithAssistant));
export const LogsGroupDetail = withPaginationRouting()(LogsGroupDetailWithApi);
