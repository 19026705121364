import React from 'react';
import BubbleYoutubeView from './BubbleYoutubeView';

interface Props {
  title?: string;
  youtubeId?: string;
}

export class BubbleYoutubeBase extends React.Component<Props, {}> {

  public render = BubbleYoutubeView.bind(this);

  public getTitle() {
    return this.props.title;
  }

  public getSrc() {
    return `//www.youtube.com/embed/${this.props.youtubeId!}`;
  }

}

export const BubbleYoutube = BubbleYoutubeBase;
