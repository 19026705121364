import React from 'react';
import {ViewFunction} from '../../../../../../../model/component/ViewFunction';
import {BubbleMultiChoiceBase} from './BubbleMultiChoice.component';

export const BubbleMultiChoiceView: ViewFunction<BubbleMultiChoiceBase> = function() {
  return (
    <>
      <div className='speech-bubble-buttons'>
        {this.getOptions().map((option) => (

              <button
                className='robo-button'
                value={option.value}
                data-action={`multiChoice-${option.value!}`}
                onClick={this.onClickHandler(option.value!)}
              >
                {option.label}
              </button>

          ))
        }
      </div>
    </>
  );
};

export default BubbleMultiChoiceView;
