import { ChangeEvent, FormEvent } from 'react';
import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Api, RefreshApi } from '../../../../../api/Server';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Request } from '../../../../../model/http/Request';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { Navigation } from '../../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { EditAssistantContext } from '../state/EditAssistantContext';
import { ChannelParameter, ParamType, Voice } from './model/UpdateParams';
import { ChannelModel, ChannelStateModel } from './state/ChannelStateModel';
import { PhoneProviderChannelView } from './PhoneAPIProviderChannel.view';
import { CharacterVoice, Language } from '../state/EditAssistantStateModel';
import { HttpError } from '../../../../../model/http/HttpError';

interface IntlProps {
  intl: IntlShape;
}

interface FacebookAPIResponse extends Response {
  timestamp: Date;
}

interface PhoneAPIState extends ApiState {
  characterOptions: CharacterVoice[];
  languages: Language[];
  character: string;
  language: string;
}

interface Payload {
  parameters: ChannelParameter[];
}

type Props = IntlProps &
  ApiProps<any> &
  SessionStateModel &
  ChannelStateModel &
  LayoutStateModel &
  RouteComponentProps<{ botId: string }> &
  RouteComponentProps<Navigation> &
  WithAlertProps;

const requestLoadCharacters = 'LOAD_TWILLIO_CHARACTERS';
const requestLoadLanguage = 'LOAD_TWILLIO_LANGUAGE';
const requestSaveVoice = 'SAVE_VOICE';

export class PhoneProviderChannelBase extends ApiBase<FacebookAPIResponse, Props & ChannelModel, PhoneAPIState> {
  public render = PhoneProviderChannelView.bind(this);

  constructor(props: Props & ChannelModel) {
    super(props);

    this.state = {
      appError: null,
      languages: [],
      characterOptions: [],
      character: props.voice !== null && props.voice !== undefined ? props.voice.toString() : '',
      language: '',
      error: null,
      authError: null,
    };

    this.loadPhoneVoices();
  }

  public submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const voiceParam: ChannelParameter = {
      name: Voice,
      type: ParamType.TEXT,
      value: this.state.character,
    };

    const parameters: ChannelParameter[] = [voiceParam];

    const payload: Payload = {
      parameters,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/assistants/channel/${this.props.uuid}/assistant/${this.props.currentAssistant}/update`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: requestSaveVoice,
    };

    this.doCall(request);
  }

  public formatMessage(id: string): string {
    return this.props.intl.formatMessage({ id });
  }

  public buildUrl(): string {
    return `${process.env.REACT_APP_STUDIO_URL}/external/channels/${this.getUuid()}?X-API-Key=${
      this.props.selectedApiKey
    }`;
  }

  public getUuid(): string {
    return this.props.uuid;
  }

  public getCharacters(): CharacterVoice[] {
    return this.state.characterOptions;
  }

  public getLanguage(): string {
    return this.state.language;
  }

  public setErrorResponseData() {
    if (this.props.callId === requestLoadLanguage && this.props.error!.code === HttpError.BAD_REQUEST) {
      this.setState({
        language: this.formatMessage('channel.voice.defaultLang'),
      });
    } else {
      this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
    }
  }

  public setCharacter = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    this.setState({
      character: value,
    });
    this.loadLanguage(value);
  }

  public getCharacter(): string {
    return this.state.character;
  }

  public loadPhoneVoices() {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/assistants/AMAZON_POLLY/text-to-speech/characters`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: requestLoadCharacters,
    };
    this.doCall(request);
  }

  public loadLanguage = (character: string) => {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/assistants/text-to-speech/language/${character}/AMAZON_POLLY`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: requestLoadLanguage,
    };
    this.doCall(request);
  }

  public setResponseData() {
    const callId = this.props.callId;
    if (callId === requestSaveVoice) {
      this.props.alert.success(this.formatMessage('alert.successfullyCreated'));
    } else if (callId === requestLoadCharacters) {
      const options: CharacterVoice[] = this.props.response.content as CharacterVoice[];

      const curCharacter = this.getCharacter() === '' ? 'POLLY_EMMA_NEURAL' : this.getCharacter();
      this.setState(
        {
          characterOptions: [ ...options],
          character: curCharacter,
        },
        () => this.loadLanguage(curCharacter),
      );
    } else if (callId === requestLoadLanguage) {
      const options: Language[] = this.props.response.content as Language[];
      if (options == null) {
        this.setState({
          languages: [],
          language: this.formatMessage('channel.voice.defaultLang'),
        });
      } else {
        const curLanguage: string = options[0].friendlyName;
        this.setState({
          languages: options,
          language: curLanguage,
        });
      }
    }
  }

  public isAdmin(): boolean {
    return this.props.isAdmin;
  }
}

const PhoneProviderChannelIntl = injectIntl(PhoneProviderChannelBase);
const PhoneProviderChannelWithAlert = withAlert<PhoneProviderChannelBase['props']>()(PhoneProviderChannelIntl);
const PhoneProviderChannelWithSession = withContext(SessionContext)(PhoneProviderChannelWithAlert);
const PhoneProviderChannelWithContext = withContext(EditAssistantContext)(PhoneProviderChannelWithSession);
const PhoneProviderChannelWithApi = RefreshApi(Api(PhoneProviderChannelWithContext));
export const PhoneProviderChannel = withRouter(PhoneProviderChannelWithApi);
