import React from 'react';

export const CloseIcon = () => (
  <svg
    width={24}
    height={24}
    fill='none'
    stroke='#FFF'
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    style={{ marginRight: 0, minWidth: 24 }}
  >
    <line x1={18} y1={6} x2={6} y2={18} />
    <line x1={6} y1={6} x2={18} y2={18} />
  </svg>
);
