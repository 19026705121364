import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-bootstrap';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { Form } from '../../../form/component/Form.component';
import { Input } from '../../../form/component/Input.component';
import { isEmail, notEmpty, passwordValidity } from '../../../form/lib/rules';
import { CreateOrganizationBase } from './CreateOrganization.component';
import { CountrySelect } from '../CountriesSelect/CountrySelect.component';
import PhoneInput from 'react-phone-input-2';

export const CreateOrganizationView: ViewFunction<CreateOrganizationBase> = function() {
  return (
    // tslint:disable-next-line:max-line-length
    <Modal show={this.isModalVisible()} onHide={this.handleClose} className='shadow-sm' backdrop='static' dialogClassName='wide-modal'>
      <Modal.Header className='' closeButton>
        <Modal.Title as='h5'>
          <FormattedMessage id='organization.createNew' />
        </Modal.Title>
      </Modal.Header>

      <Form onSubmit={this.onSubmit}>
        {(isValid: boolean) => {
          this.setIsFormValid(isValid);
          return (
            <React.Fragment>
              <Modal.Body>
                {this.hasError() && <div className='alert alert-danger'>{this.state.appError!.message}</div>}
                <div className='row'>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='company-name'>
                        <FormattedMessage id='organization.companyName' />
                      </label>
                      <Input
                        type='text'
                        className={'form-control'}
                        id='company-name'
                        name='companyName'
                        placeholder=''
                        value={this.state.companyName}
                        onChange={this.changeValue}
                        rules={[notEmpty()]}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='steet-address-one'>
                        <FormattedMessage id='organization.streetAddress1' />
                      </label>
                      <Input
                        type='text'
                        className={'form-control'}
                        id='steet-address-one'
                        name='streetAddressOne'
                        placeholder=''
                        value={this.state.streetAddressOne}
                        onChange={this.changeValue}
                        rules={[notEmpty()]}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='steet-address-two'>
                        <FormattedMessage id='organization.streetAddress2' />
                      </label>
                      <Input
                        type='text'
                        className={'form-control'}
                        id='steet-address-two'
                        name='streetAddressTwo'
                        placeholder=''
                        value={this.state.streetAddressTwo}
                        onChange={this.changeValue}
                      />
                    </div>
                    <div className='row'>
                      <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                        <div className='form-group'>
                          <label>
                            <FormattedMessage id='organization.country' />
                          </label>
                          <CountrySelect
                            handleCountryChange={this.handleCountryChange}
                            countrySubmitError={this.state.countrySubmitError}
                          />
                        </div>
                      </div>
                      <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                        <div className='form-group'>
                          <label htmlFor='postcode'>
                            <FormattedMessage id='organization.postcode' />
                          </label>
                          <Input
                            type='text'
                            className={'form-control'}
                            id='postcode'
                            name='postcode'
                            placeholder=''
                            value={this.state.postcode}
                            onChange={this.changeValue}
                            rules={[notEmpty()]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='vat-id'>
                        <FormattedMessage id='organization.vatId' />
                      </label>
                      <Input
                        type='text'
                        className={'form-control'}
                        id='vat-id'
                        name='vatId'
                        placeholder=''
                        value={this.state.vatId}
                        onChange={this.changeValue}
                      />
                    </div>
                    <h5 className='mt-2'>
                      <FormattedMessage id='organization.primaryAccountHolder' />
                    </h5>
                    <div className='form-group'>
                      <label htmlFor='name'>
                        <FormattedMessage id='organization.firstName' />
                      </label>
                      <Input
                        type='text'
                        className={'form-control'}
                        id='firstName'
                        name='firstName'
                        placeholder=''
                        value={this.state.firstName}
                        onChange={this.changeValue}
                        rules={[notEmpty()]}
                      />
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='name'>
                        <FormattedMessage id='organization.lastName' />
                      </label>
                      <Input
                        type='text'
                        className={'form-control'}
                        id='lastName'
                        name='lastName'
                        placeholder=''
                        value={this.state.lastName}
                        onChange={this.changeValue}
                        rules={[notEmpty()]}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='email'>
                        <FormattedMessage id='organization.email' />
                      </label>
                      <Input
                        type='text'
                        className={'form-control'}
                        id='email'
                        name='email'
                        placeholder=''
                        value={this.state.email}
                        onChange={this.changeValue}
                        rules={[notEmpty(), isEmail()]}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='phone'>
                        <FormattedMessage id='organization.phone' />
                      </label>
                      <PhoneInput country={'us'} value={this.state.phone} onChange={this.handlePhoneChange} />
                    </div>
                    <h5>
                      <FormattedMessage id='organization.adminAccount' />
                    </h5>
                    <div className='form-group'>
                      <label htmlFor='username'>
                        <FormattedMessage id='organization.username' />
                      </label>
                      <Input
                        type='text'
                        className={'form-control'}
                        id='username'
                        name='username'
                        placeholder=''
                        value={this.state.username}
                        onChange={this.changeValue}
                        rules={[notEmpty()]}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='admin-email'>
                        <FormattedMessage id='organization.email' />
                      </label>
                      <Input
                        type='text'
                        className={'form-control'}
                        id='admin-email'
                        name='adminEmail'
                        placeholder=''
                        value={this.state.adminEmail}
                        onChange={this.changeValue}
                        rules={[notEmpty(), isEmail()]}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='password'>
                        <FormattedMessage id='organization.password' />
                      </label>
                      <Input
                        className={'form-control'}
                        id='password'
                        type='password'
                        name='password'                        
                        placeholder=''
                        value={this.state.password}
                        onChange={this.changeValue}
                        rules={[notEmpty(), passwordValidity()]}
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <div className='modal-footer'>
                <button
                  id='cancel'
                  type='button'
                  className='btn btn-dark'
                  data-dismiss='modal'
                  onClick={this.handleClose}
                >
                  <FormattedMessage id='cancel' />
                </button>
                <button id='save' type='submit' className='btn btn-primary' onClick={this.checkCountryError}>
                  <FormattedMessage id='save' />
                </button>
              </div>
            </React.Fragment>
          );
        }}
      </Form>
    </Modal>
  );
};
