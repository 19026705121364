import React from 'react';
import { withAlert } from 'react-alert';
import {injectIntl} from 'react-intl';
import { withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../../../api/Server';
import { SessionContext } from '../../../../../auth/state/Session/SessionContext';
import { LayoutContext } from '../../../../../base/state/Layout/LayoutContext';
import { withContext } from '../../../../../lib/component/withContext/withContext.hoc';
import { AnswerBody } from '../../../../model/AnswerModel';
import { AnswerType } from '../../../../model/AnswerType';
import { AnswerContext } from '../../../../state/AnswerContext';
import { EditAnswerBase } from '../../EditAnswer.component';
import { VideoAnswerView } from './VideoAnswer.view';

interface AnswerVideoJson extends AnswerBody {
  title: string;
  url: string;
  thumbnailURL: string;
}

export class VideoAnswerBase extends EditAnswerBase<AnswerVideoJson> {

  public render = VideoAnswerView.bind(this);

  public answer: AnswerVideoJson = {
    title: '',
    url: '',
    thumbnailURL: '',
    type: AnswerType.VIDEO,
  };

  public componentWillMount(): void {
    if (this.props.answer!.uuid) {
      const answer: AnswerVideoJson = {
        title: this.props.answer!.answerContent.title,
        url: this.props.answer!.answerContent.url,
        thumbnailURL: this.props.answer!.answerContent.thumbnailURL,
      };

      this.answer = answer;
    }
    this.setState({
      answer: this.answer,
    });
  }

  public changeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.title = value;

    this.setState({
      answer: this.answer,
    });
  }

  public changeImageUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.url = value;

    this.setState({
      answer: this.answer,
    });
  }

  public changeMediaUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.thumbnailURL = value;

    this.setState({
      answer: this.answer,
    });
  }

  public getContent(): AnswerVideoJson {
    const json: AnswerVideoJson = {
      thumbnailURL: this.answer.thumbnailURL,
      title: this.answer.title,
      url: this.answer.url,
    };
    return json;
  }

  public getAnswerVideoTitle(): string {
    return this.state.answer.title;
  }

  public getAnswerVideoImageUrl(): string {
    return this.state.answer.url;
  }

  public getAnswerVideoMediaUrl(): string {
    return this.state.answer.thumbnailURL;
  }

}

const VideoAnswerWithIntl = injectIntl(VideoAnswerBase);
const VideoAnswerWithAlert = withAlert<VideoAnswerBase['props']>()(VideoAnswerWithIntl);
const VideoAnswerWithSession = withContext(SessionContext)(VideoAnswerWithAlert);
const VideoWithContext = withContext(AnswerContext)(VideoAnswerWithSession);
const VideoWithLayout = withContext(LayoutContext)(VideoWithContext);
const VideoAnswerWithApi = RefreshApi(Api(VideoWithLayout));
export const VideoAnswer = withRouter(VideoAnswerWithApi);
