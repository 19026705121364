import { Api, RefreshApi } from '../../../../../api/Server';
import { ApiBase, ApiProps, ApiState } from '../../../../base/ApiComponent';
import { AssistantParams, EditAssistantStateModel, SttSelectable } from '../state/EditAssistantStateModel';
import { ChangeEvent, FormEvent } from 'react';
import {
  Engines,
  ServiceInfo,
  ServiceParam,
  ServiceType,
  EngineInputsType,
  SpeechToTextInputs,
} from '../../../model/engines';
import { IntlShape, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { EditAssistantContext } from '../state/EditAssistantContext';
import { HttpRequestType } from '../../../../../model/http/HttpRequestType';
import { Navigation } from '../../../../base/model/route/Navigation';
import { ParamValue } from '../../../../group/component/edit/state/EditGroupStateModel';
import { Request, RequestActions } from '../../../../../model/http/Request';
import { SessionContext } from '../../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../../auth/state/Session/SessionStateModel';
import { Status } from '../../../model/Status';
import { WithAlertProps } from '../../../../base/model/WithAlertProps';
import { withAlert } from 'react-alert';
import { withContext } from '../../../../lib/component/withContext/withContext.hoc';
import { SpeechToTextView } from './SpeechToText.view';

interface ResponseSTTUpdate extends Response {
  content: ServiceInfo;
  timestamp: Date;
}

interface ResponseSTTModel extends Response {
  content: SttSelectable[];
  timestamp: Date;
}

interface InfoState extends ApiState {
  engine: Engines;
  language: string;
  model: string;
  languageOptions: SttSelectable[];
  modelOptions: SttSelectable[];
  apiKey?: string;
  url?: string;
  serviceUrl?: string;
  tokenUrl?: string;
  asrToken?: string;
}

interface PayloadAssistantParams {
  params: {};
}

interface PayloadServiceParams {
  type: ServiceType;
  subtype: Engines;
  parameters: ServiceParam[];
}

interface IntlProps {
  intl: IntlShape;
}

interface InnerPropsFunctions {
  reloadAssistant: () => void;
}

interface InnerProps {
  innerProps: InnerPropsFunctions;
}

interface Submit {
  [id: string]: string | number;
}

type Props = IntlProps &
  ApiProps<ResponseSTTUpdate | ResponseSTTModel> &
  SessionStateModel &
  EditAssistantStateModel &
  RouteComponentProps<Navigation> &
  WithAlertProps &
  InnerProps;

const updateAssistant = 'updateAssistant';
const updateService = 'update-tts';
const load = 'load';
const loadLanguage = 'loadLanguage';
const loadModels = 'loadModels';

export class SpeechToTextBase extends ApiBase<ResponseSTTUpdate | ResponseSTTModel, Props, InfoState> {
  public render = SpeechToTextView.bind(this);

  public constructor(props: Props) {
    super(props);

    const model = this.getValue(AssistantParams.MODEL);
    const language = this.getValue(AssistantParams.STT_LANGUAGE);
    this.state = {
      authError: null,
      error: null,
      appError: null,
      engine: Engines.IBM,
      language: language !== undefined ? language.value : '',
      model: model !== undefined ? model.value : '',
      languageOptions: [],
      modelOptions: [],
    };
    this.loadEngineProps();
  }

  public loadSpeechToTextLanguages(value: Engines, action: RequestActions) {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/assistants/${value}/speech-to-text/languages`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: loadLanguage,
      action,
    };
    this.doCall(request);
  }

  public loadSpeechToTextModels(language: string, action?: RequestActions) {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/assistants/${language}/speech-to-text/models`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: loadModels,
      action,
    };
    this.doCall(request);
  }

  public loadEngineProps = () => {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/assistants/${this.props.currentAssistant}/${ServiceType.SPEECH_TO_TEXT}/service`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: load,
    };
    this.doCall(request);
  }
  public getStatus(): Status {
    const status =
      this.props.assistantData !== undefined && this.props.assistantData !== null
        ? this.props.assistantData.params.find((x: ParamValue) => x.name === AssistantParams.ENABLE_DISABLE_STT)
        : undefined;
    return status !== undefined && JSON.parse(status.value) ? Status.ENABLED : Status.DISABLED;
  }

  public getOptionsModel(): SttSelectable[] {
    return this.state.modelOptions;
  }

  public getOptionsLanguage(): SttSelectable[] {
    return this.state.languageOptions;
  }

  public setEngine = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    this.setState({
      engine: value as Engines,
    });
    this.loadSpeechToTextLanguages(value as Engines, RequestActions.CHANGE);
  }
  public getEngine(): Engines {
    return this.state.engine;
  }

  public setModel = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    this.setState({
      model: value,
    });
  }

  public getModel(): string {
    return this.state.model;
  }

  public setLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    this.setState({
      language: value,
    });
    this.loadSpeechToTextModels(value, RequestActions.CHANGE);
  }
  public getLanguage(): string {
    return this.state.language;
  }

  public setApiKey = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      apiKey: value,
    });
  }
  public getApiKey(): string {
    return this.state.apiKey === undefined ? '' : this.state.apiKey.valueOf();
  }

  public setUrl = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      url: value,
    });
  }
  public getUrl(): string {
    return this.state.url === undefined ? '' : this.state.url.valueOf();
  }

  public setServiceUrl = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      serviceUrl: value,
    });
  }
  public getServiceUrl(): string {
    return this.state.serviceUrl === undefined ? '' : this.state.serviceUrl.valueOf();
  }

  public setTokenUrl = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      tokenUrl: value,
    });
  }
  public getTokenUrl(): string {
    return this.state.tokenUrl === undefined ? '' : this.state.tokenUrl.valueOf();
  }

  public setAsrToken = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      asrToken: value,
    });
  }
  public getAsrToken(): string {
    return this.state.asrToken === undefined ? '' : this.state.asrToken.valueOf();
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public checkDisplay(input: EngineInputsType): string {
    switch (this.state.engine.toUpperCase()) {
      case Engines.IBM.toUpperCase():
        return SpeechToTextInputs.ibm.values.indexOf(input) > -1 ? '' : this.formatMessage('none').toLowerCase();
      case Engines.MICROSOFT.toUpperCase():
        return SpeechToTextInputs.microsoft.values.indexOf(input) > -1 ? '' : this.formatMessage('none').toLowerCase();
      case Engines.GOOGLE.toUpperCase():
        return SpeechToTextInputs.google.values.indexOf(input) > -1 ? '' : this.formatMessage('none').toLowerCase();
      case Engines.ENDERTURING.toUpperCase():
        return SpeechToTextInputs.enderturing.values.indexOf(input) > -1
          ? '' : this.formatMessage('none').toLowerCase();
      default:
        return this.formatMessage('none').toLowerCase();
    }
  }

  public checkEnderTuring(_input: EngineInputsType): string {
    return (Engines.ENDERTURING.toUpperCase() === this.getEngine()) ? this.formatMessage('none').toLowerCase() : '';
  }

  public submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const params: Submit = {};

    if (this.getEngine().toUpperCase() !== Engines.ENDERTURING.toUpperCase()) {
      params[`${AssistantParams.STT_LANGUAGE}`] = this.getLanguage();
    }

    if (this.checkDisplay(EngineInputsType.STT_MODEL).length === 0) {
      params[`${AssistantParams.MODEL}`] = this.getModel();
    }

    const payload: PayloadAssistantParams = {
      params,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/assistants/${this.props.currentAssistant}/`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: updateAssistant,
    };

    this.doCall(request);
  }
  public updateService() {
    const params: ServiceParam[] = [];

    let engineInputSelected = SpeechToTextInputs.ibm;
    switch (this.getEngine().toUpperCase()) {
      case Engines.IBM.toUpperCase():
        engineInputSelected = SpeechToTextInputs.ibm;
        break;
      case Engines.MICROSOFT.toUpperCase():
        engineInputSelected = SpeechToTextInputs.microsoft;
        break;
      case Engines.GOOGLE.toUpperCase():
        engineInputSelected = SpeechToTextInputs.google;
        break;
      case Engines.ENDERTURING.toUpperCase():
        engineInputSelected = SpeechToTextInputs.enderturing;
        break;
    }

    if (engineInputSelected.values.indexOf(EngineInputsType.APIKEY) > -1) {
      params.push({
        name: EngineInputsType.APIKEY,
        value: this.getApiKey(),
      });
    }

    if (engineInputSelected.values.indexOf(EngineInputsType.URL) > -1) {
      params.push({
        name: EngineInputsType.URL,
        value: this.getUrl(),
      });
    }

    if (engineInputSelected.values.indexOf(EngineInputsType.SERVICE_URL) > -1) {
      params.push({
        name: EngineInputsType.SERVICE_URL,
        value: this.getServiceUrl(),
      });
    }

    if (engineInputSelected.values.indexOf(EngineInputsType.TOKEN_URL) > -1) {
      params.push({
        name: EngineInputsType.TOKEN_URL,
        value: this.getTokenUrl(),
      });
    }

    if (engineInputSelected.values.indexOf(EngineInputsType.ASR_TOKEN) > -1) {
      params.push({
        name: EngineInputsType.ASR_TOKEN,
        value: this.getAsrToken(),
      });
    }

    const payload: PayloadServiceParams = {
      type: ServiceType.SPEECH_TO_TEXT,
      subtype: this.state.engine,
      parameters: params,
    };

    const request: Request<{}> = {
      method: HttpRequestType.PUT,
      payload,
      relativePath: `/admin/assistants/${this.props.currentAssistant}/service`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: updateService,
    };

    this.doCall(request);
  }

  public setResponseData() {
    if (this.props.callId === updateAssistant) {
      this.updateService();
    } else if (this.props.callId === updateService) {
      this.props.innerProps.reloadAssistant();
      this.props.alert.success(this.formatMessage('alert.successfullyUpdated'));
    } else if (this.props.callId === load) {
      const info: ServiceInfo = this.props.response.content as ServiceInfo;
      let apiKey: ServiceParam | undefined;
      if (info.parameters !== null && info.parameters.length > 0) {
        apiKey = info.parameters.find((x: ServiceParam) => x.name === EngineInputsType.APIKEY);
      }
      switch (info.subtype) {
        case Engines.IBM.toUpperCase():
          let url: ServiceParam | undefined;
          if (info.parameters !== null && info.parameters.length > 0) {
            url = info.parameters.find((x: ServiceParam) => x.name === EngineInputsType.URL);
          }
          this.setState({
            engine: info.subtype,
            apiKey: apiKey !== undefined ? apiKey.value : '',
            url: url !== undefined ? url.value : '',
          });
          break;
        case Engines.MICROSOFT.toUpperCase():
          let serviceUrl: ServiceParam | undefined;
          let tokenUrl: ServiceParam | undefined;
          if (info.parameters !== null && info.parameters.length > 0) {
            serviceUrl = info.parameters.find((x: ServiceParam) => x.name === EngineInputsType.SERVICE_URL);
            tokenUrl = info.parameters.find((x: ServiceParam) => x.name === EngineInputsType.TOKEN_URL);
          }
          this.setState({
            engine: info.subtype,
            apiKey: apiKey !== undefined ? apiKey.value : '',
            serviceUrl: serviceUrl !== undefined ? serviceUrl.value : '',
            tokenUrl: tokenUrl !== undefined ? tokenUrl.value : '',
          });
          break;
        case Engines.GOOGLE.toUpperCase():
          this.setState({
            engine: info.subtype,
          });
          break;
        case Engines.ENDERTURING.toUpperCase():
          let asrToken: ServiceParam | undefined;
          if (info.parameters !== null && info.parameters.length > 0) {
            asrToken = info.parameters.find((x: ServiceParam) => x.name === EngineInputsType.ASR_TOKEN);
          }
          this.setState({
            engine: info.subtype,
            asrToken: asrToken !== undefined ? asrToken.value : '',
          });
          break;
      }
      this.loadSpeechToTextLanguages(info.subtype, RequestActions.BOOT);
    } else if (this.props.callId === loadLanguage) {
      const options: SttSelectable[] = this.props.response.content as SttSelectable[];
      const curLanguage = this.props.action === RequestActions.BOOT ? this.getLanguage() : options[0].value;
      this.setState(
        {
          languageOptions: options,
          language: curLanguage,
        },
        () => this.loadSpeechToTextModels(curLanguage, this.props.action),
      );
    } else if (this.props.callId === loadModels) {
      const options: SttSelectable[] = this.props.response.content as SttSelectable[];
      if (options.length > 0) {
        const curModel = this.props.action === RequestActions.BOOT ? this.getModel() : options[0].value;
        this.setState({
          modelOptions: options,
          model: curModel,
        });
      }
    }
  }

  public setErrorResponseData() {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
  }

  public isAdmin(): boolean {
    return super.isAdmin(this.props.authorities);
  }

  private getValue(param: AssistantParams): ParamValue | undefined {
    return this.props.assistantData !== undefined && this.props.assistantData !== null
      ? this.props.assistantData.params.find((x: ParamValue) => x.name === param)
      : undefined;
  }
}

const SpeechToTextWithIntl = injectIntl(SpeechToTextBase);
const SpeechToTextWithAlert = withAlert<SpeechToTextBase['props']>()(SpeechToTextWithIntl);
const SpeechToTextWithSession = withContext(SessionContext)(SpeechToTextWithAlert);
const SpeechToTextWithContext = withContext(EditAssistantContext)(SpeechToTextWithSession);
const SpeechToTextWithApi = RefreshApi(Api(SpeechToTextWithContext));
export const SpeechToText = withRouter(SpeechToTextWithApi);
