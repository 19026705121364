export interface GroupAssistantModel {
  botUuid: string;
  status: GroupAssistantStatus;
  mode: GroupAssistantMode;
  confidenceThreshold: number;
}

export enum GroupAssistantStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum GroupAssistantMode {
  FOREGROUND = 'FOREGROUND',
  BACKGROUND = 'BACKGROUND',
}
