import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { ActiveConversationsByAssistant } from '../ActiveConversationsByAssistant/ActiveConversationsByAssistant.component';
import { NumberOfConversationsChart } from '../NumberOfConversationsChart/NumberOfConversationsChart.component';
import { TopStats } from '../TopStats/TopStats.component';
import { DashboardBase } from './Dashboard.component';
import { FormattedMessage } from 'react-intl';
import { StatsTimeFrame } from '../../../common/model/logs/LogsModel';
import { DatePickerRange } from '../../../base/component/DatePickerRange/DatePickerRange.component';
import { BotSelector } from '../BotSelector/BotSelector.component';
import { Export } from '../Export/Export.component';
import { ChooseStats } from './ChooseStats.component';

export const DashboardView: ViewFunction<DashboardBase> = function() {
  const getActiveClassForTimeFrame = (statsTimeFrame: string) => {
    return this.state.searchTimeFrame === statsTimeFrame ? 'active' : '';
  };

  return (
    <React.Fragment>
      <ChooseStats
        isVisible={this.state.isModalVisible}
        onClose={this.handleClose}
        updateStatsSelected={this.updateStatsSelected}
        statsSelected={this.state.statsSelected}
      />
      <div className='text-center mb-3'>
        <div className='d-flex justify-content-end mb-3 export-buttons'>
          <Export
            {...this.props}
            botView={this.state.botView}
            toDate={this.state.toDate}
            fromDate={this.state.fromDate}
          />

        </div>
        <div
          id='filter-bar'
          className='btn-toolbar d-flex justify-content-around mb-3'
          role='toolbar'
        >
          <BotSelector {...this.props} updateBotView={this.updateBotView} />
          <div className='time-filter btn-group mb-3' role='group'>
            <button
              type='button'
              className={`btn btn-sm btn-light ${getActiveClassForTimeFrame(StatsTimeFrame.ALL)}`}
              title={getActiveClassForTimeFrame(StatsTimeFrame.ALL)}
              onClick={this.selectTimeFrame(StatsTimeFrame.ALL)}
            >
              <FormattedMessage id={'statsAssistantChart.filter.button.all'} />
            </button>
            <button
              type='button'
              className={`btn btn btn-light ${getActiveClassForTimeFrame(StatsTimeFrame.YEAR)} filter-mobile`}
              title={getActiveClassForTimeFrame(StatsTimeFrame.YEAR)}
              onClick={this.selectTimeFrame(StatsTimeFrame.YEAR)}
            >
              <FormattedMessage id={'statsAssistantChart.filter.button.year'} />
            </button>
            <button
              type='button'
              className={`btn btn btn-light ${getActiveClassForTimeFrame(StatsTimeFrame.TRIMESTER)} filter-mobile`}
              title={getActiveClassForTimeFrame(StatsTimeFrame.TRIMESTER)}
              onClick={this.selectTimeFrame(StatsTimeFrame.TRIMESTER)}
            >
              <FormattedMessage id={'statsAssistantChart.filter.button.trimester'} />
            </button>
            <button
              type='button'
              className={`btn btn btn-light ${getActiveClassForTimeFrame(StatsTimeFrame.MONTH)} filter-mobile`}
              title={getActiveClassForTimeFrame(StatsTimeFrame.MONTH)}
              onClick={this.selectTimeFrame(StatsTimeFrame.MONTH)}
            >
              <FormattedMessage id={'statsAssistantChart.filter.button.month'} />
            </button>
            <button
              type='button'
              className={`btn btn btn-light ${getActiveClassForTimeFrame(StatsTimeFrame.WEEK)} filter-mobile`}
              title={getActiveClassForTimeFrame(StatsTimeFrame.WEEK)}
              onClick={this.selectTimeFrame(StatsTimeFrame.WEEK)}
            >
              <FormattedMessage id={'statsAssistantChart.filter.button.week'} />
            </button>
            <button
              type='button'
              className={`btn btn btn-light ${getActiveClassForTimeFrame(StatsTimeFrame.DAY)} filter-mobile`}
              title={getActiveClassForTimeFrame(StatsTimeFrame.DAY)}
              onClick={this.selectTimeFrame(StatsTimeFrame.DAY)}
            >
              <FormattedMessage id={'statsAssistantChart.filter.button.day'} />
            </button>
          </div>
          <div className='d-flex'>
            <DatePickerRange
              onFromDateChangeHandler={this.onFromDateChangeHandler}
              onToDateChangeHandler={this.onToDateChangeHandler}
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
            />

            <div className='reset-filter btn-group mb-3' role='group'  >
            <button
              id='clean-filter'
              type='button'
              title={`${this.formatMessage('cleanFilters')}`}
              className='btn btn-sm btn-secondary'
              onClick={this.clearFilters}
            >
              <i className='fas fa-sync'/>
            </button>
              <button
                id='filter'
                type='button'
                title='Filter'
                className='btn btn-sm btn-secondary'
                onClick={this.onSubmitFilter}
              >
                <i className='fas fa-filter' />
              </button>
              </div>
              <div className='ml-2 btn-group mb-3' role='group'  >
              <button
                id='settingsFilterButton'
                type='button'
                title='Filter'
                className='stats-settings btn btn-sm btn-secondary'
                onClick={this.openStatsSettings}
              >
                <i className='fas fa-cog' />
              </button>
            </div>
          </div>
        </div>
        <TopStats
          botView={this.state.botView}
          searchTimeFrame={this.state.searchTimeFrame}
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
          statsSelected={this.state.statsSelected}
        />
        <div className='row'>
          <div className='col-12 col-sm-12 col-md-12 col-lg-8'>
            <NumberOfConversationsChart
              {...this.props}
              searchTimeFrame={this.state.searchTimeFrame}
              botView={this.state.botView}
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
            />
          </div>
          <div className='col-12 col-sm-12 col-md-12 col-lg-4 single-card'>
            <ActiveConversationsByAssistant
              {...this.props}
              searchTimeFrame={this.state.searchTimeFrame}
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
