import React from 'react';
import {ViewFunction} from '../../../../../../../model/component/ViewFunction';
import {BubbleTextBase} from './BubbleText.component';

export const BubbleTextView: ViewFunction<BubbleTextBase> = function() {
  return (
    <p>
      <span>{this.getTextMessage()}</span>
    </p>
  );
};

export default BubbleTextView;
