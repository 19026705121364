import React from 'react';
import { withAlert } from 'react-alert';
import {injectIntl} from 'react-intl';
import { withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../../../api/Server';
import { SessionContext } from '../../../../../auth/state/Session/SessionContext';
import { LayoutContext } from '../../../../../base/state/Layout/LayoutContext';
import { withContext } from '../../../../../lib/component/withContext/withContext.hoc';
import { AnswerBody } from '../../../../model/AnswerModel';
import { AnswerType } from '../../../../model/AnswerType';
import { AnswerContext } from '../../../../state/AnswerContext';
import { EditAnswerBase } from '../../EditAnswer.component';
import { YoutubeAnswerView } from './YoutubeAnswer.view';

interface AnswerYoutubeJson extends AnswerBody {
  title: string;
  thumbnailURL: string;
  youtubeId: string;
}

export class YoutubeAnswerBase extends EditAnswerBase<AnswerYoutubeJson> {

  public render = YoutubeAnswerView.bind(this);

  public answer: AnswerYoutubeJson = {
    title: '',
    thumbnailURL: '',
    youtubeId: '',
    type: AnswerType.YOUTUBE,
  };

  public componentWillMount(): void {
    if (this.props.answer!.uuid) {

      const answer: AnswerYoutubeJson = {
        title: this.props.answer!.answerContent.title,
        thumbnailURL: this.props.answer!.answerContent.thumbnailURL,
        youtubeId: this.props.answer!.answerContent.youtubeId,
      };

      this.answer = answer;
    }
    this.setState({
      answer: this.answer,
    });
  }

  public changeVideoTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.title = value;

    this.setState({
      answer: this.answer,
    });
  }

  public changeVideoImageUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.thumbnailURL = value;

    this.setState({
      answer: this.answer,
    });
  }

  public changeYoutubeMediaUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: string = target.value;
    this.answer.youtubeId = value;

    this.setState({
      answer: this.answer,
    });
  }

  public getContent(): AnswerYoutubeJson {
    const json: AnswerYoutubeJson = {
      youtubeId: this.answer.youtubeId,
      thumbnailURL: this.answer.thumbnailURL,
      title: this.answer.title,
    };
    return json;
  }

  public getAnswerYoutubeTitle(): string {
    return this.state.answer.title;
  }

  public getAnswerYoutubeImageUrl(): string {
    return this.state.answer.thumbnailURL;
  }

  public getAnswerYoutubeMediaUrl(): string {
    return this.state.answer.youtubeId;
  }

}

const YoutubeAnswerWithIntl = injectIntl(YoutubeAnswerBase);
const YoutubeAnswerWithAlert = withAlert<YoutubeAnswerBase['props']>()(YoutubeAnswerWithIntl);
const YoutubeAnswerWithSession = withContext(SessionContext)(YoutubeAnswerWithAlert);
const YoutubeWithContext = withContext(AnswerContext)(YoutubeAnswerWithSession);
const YoutubeWithLayout = withContext(LayoutContext)(YoutubeWithContext);
const YoutubeAnswerWithApi = RefreshApi(Api(YoutubeWithLayout));
export const YoutubeAnswer = withRouter(YoutubeAnswerWithApi);
