import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { Form } from '../../../form/component/Form.component';
import { Input } from '../../../form/component/Input.component';
import { notEmpty } from '../../../form/lib/rules';
import { CreateApiKeyModalBase, Scope } from './CreateApiKeyModal.component';

export const CreateApiKeyModalView: ViewFunction<CreateApiKeyModalBase> = function() {
  return (
    <Modal show={this.isModalVisible()} onHide={this.handleClose} className='shadow-sm'>
      <Modal.Header className='' closeButton>
        <Modal.Title as='h5'>
          <FormattedMessage id='createApiKeyBase.newApiKey' />
        </Modal.Title>
      </Modal.Header>

      <Form onSubmit={this.onSubmit}>
        {(isValid: boolean) => {
          this.setIsFormValid(isValid);
          return (
            <React.Fragment>
              <Modal.Body>
                <div className='form-group'>
                  <label htmlFor='apiKey'>
                    <FormattedMessage id='label' />
                  </label>
                  <Input
                    type='text'
                    className={'form-control'}
                    id='apiKey'
                    placeholder=''
                    value={this.state.apiKey}
                    onChange={this.changeApiKey}
                    rules={[notEmpty()]}
                  />
                </div>
                <label>
                  <FormattedMessage id='account.api.scope' />
                </label>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='exampleRadios'
                    id='conversationalApikey'
                    value={Scope.CONVERSATIONS}
                    onChange={this.updateScope}
                    checked={this.state.scope === Scope.CONVERSATIONS}
                  />
                  <label className='form-check-label' htmlFor='exampleRadios1'>
                    {this.formatMessage('account.api.conversation')}
                  </label>
                </div>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='exampleRadios'
                    id='deploymentApikey'
                    value={Scope.BOT_RUNTIME_MANAGEMENT}
                    onChange={this.updateScope}
                    checked={this.state.scope === Scope.BOT_RUNTIME_MANAGEMENT}
                  />
                  <label className='form-check-label' htmlFor='exampleRadios2'>
                    {this.formatMessage('account.api.runtime')}
                  </label>
                </div>
              </Modal.Body>
              <div className='modal-footer'>
                <button
                  id='cancel'
                  type='button'
                  className='btn btn-dark'
                  data-dismiss='modal'
                  onClick={this.handleClose}
                >
                  {this.formatMessage('cancel')}
                </button>
                <button id='save' type='submit' className='btn btn-primary'>
                  {this.formatMessage('save')}
                </button>
              </div>
            </React.Fragment>
          );
        }}
      </Form>
    </Modal>
  );
};
