import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import Confirm from '../../../../base/component/confirm/Confirm.component';
import { AssistantStatusBase } from './Status.component';

export const StatusView: ViewFunction<AssistantStatusBase> = function() {
  return (
        <div className='card'>
          <Confirm
            show={this.props.showConfirmDialog}
            okLabel={this.formatMessage('ok')}
            cancelLabel={this.formatMessage('cancel')}
            confirmation={this.formatMessage( 'assistant.info.confirm.changeStatus',
              { statusToChange: this.props.statusToChange})}
            proceedFunc={this.confirmCallback}
            title={this.formatMessage('confirm')}
            setConfirmVisibility={this.props.setConfirmVisibility}
          />
          <div className='card-body' style={{paddingTop: '1rem', paddingBottom: '1rem'}}>
            <div className='row mobile-status-assistant'>
              <div className='col-sm-8'>
                <h4 className='mb-0'>Status
                  <span style={{fontSize: '0.9rem', color: '#999', fontWeight: 400}}/>
                </h4>
              </div>
              <div className='col-sm-4'>
                <div className='text-right'>
                  {/* tslint:disable-next-line:max-line-length */}
                  <div className='toggle-status' onClick={(e) => this.isAdmin() ? this.props.confirm() : e.preventDefault()}>
                    <i
                      id='status'
                      data-property-value={this.getToggleTitle()}
                      className={this.getToggle(this.props.assistantData)}
                      title={this.getToggleTitle()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
};

export default StatusView;
