import React, { CSSProperties } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { withContext } from './../../../lib/component/withContext/withContext.hoc';
import { SessionContext } from '../../../auth/state/Session/SessionContext';

const containerStyle: CSSProperties = {
  maxWidth: '724px',
};

interface IntlProps {
  intl: IntlShape;
}

type Props = IntlProps & SessionStateModel;

export class PageUnauthorizedClass extends React.Component<Props, never> {
  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  public hasSuperFunctions = () => {
    return this.props.authorities.includes('super');
  }

  public render() {
    return (
      <div className='container mt-5' style={containerStyle}>
        <h1 className='text-center mb-3'>
          401 - <FormattedMessage id={'pageUnauthorized.unauthorized'} />!
        </h1>
        <div className='card single-card shadow-sm'>
          <div className='card-body text-center'>
            <p>
              <FormattedMessage id={'pageUnauthorized.msg'} />
            </p>
            {this.hasSuperFunctions() ? (
              <NavLink id='view-organizationlist' className='btn btn-primary' to='/organizations/list' role='button'>
                <FormattedMessage id={'organizations'} />
              </NavLink>
            ) : (
              <NavLink id='pageNotfound-dashboard' className='btn btn-primary' to='/dashboard' role='button'>
                <FormattedMessage id={'sidebar.dashboard'} />
              </NavLink>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const PageUnauthorizedSession = injectIntl(PageUnauthorizedClass);
export const PageUnauthorized = withContext(SessionContext)(PageUnauthorizedSession);
export default PageUnauthorized;
