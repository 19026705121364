import React from 'react';
import {FormattedMessage} from 'react-intl';
import { Link } from 'react-router-dom';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import LoadingBar from '../../../base/component/loadingBar/loadingBar';
import { Form } from '../../../form/component/Form.component';
import { Input } from '../../../form/component/Input.component';
import { notEmpty } from '../../../form/lib/rules';
import { VerifyCodeFormBase } from './VerifyCodeForm.component';
import logo from '../../../../assets/img/logo-vivid-red_png@1x.png';

export const VerifyCodeFormView: ViewFunction<VerifyCodeFormBase> = function() {
  return (
    <React.Fragment>
      <LoadingBar
        height={4}
        className='loading loading-login'
        color={'#ff3366'}
        onRef={this.setRef}

      />
      <Form onSubmit={this.onSubmit} style={{ marginTop: '100px' }}>{() => (
        <React.Fragment>
          {this.hasError() && (
            <div className='alert alert-danger'>
              {this.props.authError ? this.props.authError.message : 'Bad credentials'}
          </div>
          )}

      {this.smsTokenSent() && (
            <div className='alert alert-success'>
              {this.state.smsTokenSent}
          </div>
          )}

          <div className='card shadow-sm'>
            <div className='card-body'>
              <div className='form-group'>
                <div className='text-center mb-3'>
                  <img className='logo-login' src={logo} alt='Two Impulse' />
                  <h5>{this.formatMessage('conversationalai.message')}</h5>
                </div>
              <label><FormattedMessage id='verify.code'/></label>
                <Input
                  id='code'
                  type='text'
                  className='form-control'
                  placeholder={this.formatMessage('verify.enterConfirmationCode')}
                  value={this.state.code}
                  onChange={this.changeCode}
                  rules={[notEmpty()]}
                />
              </div>

              <div className='row'>

                <div className='col-12' />

                <div className='col-6 text-left'>
              <button
                id='resend'
                type='button'
                className='btn btn-light'
                data-dismiss='modal'
                onClick={this.resendCode}
              >
                {this.formatMessage('verify.resendCode')}
              </button>
              </div>
              <div className='col-6 text-right'>
              <button
                id='save'
                type='submit'
                className='btn btn-primary'
              >
                {this.formatMessage('verify.confirmCode')}
              </button>
            </div>

              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      </Form>
      <div className='text-center'>
      <small> <Link to='/auth/login'>{this.formatMessage('login.backToLogin')}</Link></small>
        <div className='text-center'>
          <small>{this.formatMessage('roboai.date')}</small>
        </div>
    </div>
    </React.Fragment>
  );
};

export default VerifyCodeFormView;
