import React, { PropsWithChildren } from 'react';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { LayoutStateModel } from '../../state/Layout/LayoutStateModel';
import { SessionContext } from './../../../auth/state/Session/SessionContext';
import { SessionStateModel } from './../../../auth/state/Session/SessionStateModel';
import FooterView from './Footer.view';

type Props =  SessionStateModel & PropsWithChildren<{}> & LayoutStateModel;

export class FooterBase extends React.Component<Props, never> {
  public render = FooterView.bind(this);
}

export const Footer = withContext(SessionContext)(FooterBase);
