import React from 'react';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { LayoutContext } from '../../state/Layout/LayoutContext';
import { LayoutStateModel } from '../../state/Layout/LayoutStateModel';
import { PageHeaderView } from './PageHeader.view';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { SessionContext } from '../../../auth/state/Session/SessionContext';

type Props = LayoutStateModel & SessionStateModel;
export class PageHeaderBase extends React.Component<Props> {
  public render = PageHeaderView.bind(this);
  public getHeaderTitle() {
    return this.props.pageTitle;
  }

  public userIsSuper() {
    return !this.props.authorities.includes('super');
  }

  public getBreadCrumbsInfo() {
    return this.props.breadCrumbsPayload;
  }
}
export const PageHeaderLayout = withContext(LayoutContext)(PageHeaderBase);
export const PageHeader = withContext(SessionContext)(PageHeaderLayout);
export default PageHeader;
