import { Layout } from '../base/component/Layout/Layout.component';
import { Roles } from '../base/model/route/Role';
import { Route } from '../base/model/route/Route';
import { AssistantList } from './component/AssistantList/AssistantListData.component';
import { CreateAssistant } from './component/CreateAssistant/CreateAssistant.component';
import { EditAssistant } from './component/EditAssistant/EditAssistant.component';
import { AssistantInfo } from './component/EditAssistant/info/InfoAssistant.component';
import { Sentiment } from './component/EditAssistant/info/Sentiment.component';
import { SpeechToText } from './component/EditAssistant/info/SpeechToText.component';
import { TextToSpeech } from './component/EditAssistant/info/TextToSpeech.component';
import { LogsAssistant } from './component/EditAssistant/logs/LogsAssistant.component';
import { EditAssistantStoreProvider } from './component/EditAssistant/state/EditAssistantStoreProvider';
import { TestAssistant } from './component/EditAssistant/test/TestAssistant.component';
import { StudioAssistant } from './component/EditAssistant/studio/StudioAssistant.component';
import { WelcomeMessages } from './component/EditAssistant/studio/WelcomeMessages.component';
import { FAQ } from './component/EditAssistant/studio/FAQ.component';
import { Fallback } from './component/EditAssistant/studio/Fallback.component';
import { Channel } from './component/EditAssistant/channels/Channel.component';

const allRoles = [Roles.admin, Roles.agent, Roles.user];

const routes: Route[] = [
  {
    path: '/bots',
    default: '/bots/list/?page=0',
    routes: [
      {
        path: '/bots/list/',
        component: AssistantList,
        authorize: allRoles,
        redirectNonAuthorized: '/unauthorized',
        layout: Layout,
      },
      {
        path: '/bots/create/',
        component: CreateAssistant,
        authorize: [Roles.admin],
        redirectNonAuthorized: '/unauthorized',
        layout: Layout,
      },
      {
        path: '/bots/edit/:botId',
        component: EditAssistant,
        authorize: allRoles,
        redirectNonAuthorized: '/unauthorized',
        layout: Layout,
        provider: EditAssistantStoreProvider,
        default: '/bots/edit/:botId/info',
        routes: [
          {
            path: '/bots/edit/:botId/info',
            component: AssistantInfo,
            default: '/bots/edit/:botId/info/texttospeech',
            innerProps: {},
            routes: [
              {
                path: '/bots/edit/:botId/info/texttospeech',
                component: TextToSpeech,
              },
              {
                path: '/bots/edit/:botId/info/speechtotext',
                component: SpeechToText,
              },
              {
                path: '/bots/edit/:botId/info/sentiment',
                component: Sentiment,
              },
            ],
          },
          {
            path: '/bots/edit/:botId/studio',
            component: StudioAssistant,
            default: '/bots/edit/:botId/studio/welcomemessages',
            innerProps: {},
            routes: [
              {
                path: '/bots/edit/:botId/studio/welcomemessages',
                component: WelcomeMessages,
              },
              {
                path: '/bots/edit/:botId/studio/faq',
                component: FAQ,
              },
              {
                path: '/bots/edit/:botId/studio/fallback',
                component: Fallback,
              },
            ],
          },
          {
            path: '/bots/edit/:botId/logs',
            component: LogsAssistant,
          },
          {
            path: '/bots/edit/:botId/test',
            component: TestAssistant,
          },
          {
            path: '/bots/edit/:botId/channels',
            component: Channel,
          },
        ],
      },
    ],
  },
];

export default routes;
