import { AssistantParams, CharacterVoice, Language } from '../state/EditAssistantStateModel';

import { Engines, EngineInputsType } from '../../../model/engines';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Select } from '../../../../form/component/Select.component';
import { StatusService } from './StatusService.component';
import { TextToSpeechBase } from './TextToSpeech.component';
import { ViewFunction } from '../../../../../model/component/ViewFunction';

export const TextToSpeechView: ViewFunction<TextToSpeechBase> = function() {
  return (
    <div className='card card-body shadow-sm mobile-margin'>
      <h6>
        <FormattedMessage id='assistant.info.tts' />
      </h6>
      <StatusService
        reload={this.props.innerProps.reloadAssistant}
        type={this.getEngine()}
        status={this.getStatus()}
        authorities={this.props.authorities}
        property={AssistantParams.ENABLE_DISABLE_TTS}
        idAppend='TextToSpeech'
      />
      <form onSubmit={this.submit}>
        <div className='form-group'>
          <Select
            className='custom-select engine-select'
            id='engine'
            value={this.getEngine()}
            onChange={this.setEngine}
            disabled={!this.isAdmin()}
          >
            <option value={Engines.IBM.toUpperCase()}>{Engines.IBM}</option>
            <option value={Engines.MICROSOFT.toUpperCase()}>{Engines.MICROSOFT}</option>
            <option value={Engines.GOOGLE.toUpperCase()}>{Engines.GOOGLE}</option>
            <option value={Engines.READSPEAKER.toUpperCase()}>{Engines.READSPEAKER}</option>
          </Select>
        </div>
        <div className='form-group'>
          <label htmlFor='api-key'>
            <FormattedMessage id='assistant.info.tts.character' />
          </label>
          <Select
            className='custom-select engine-select'
            id='engine'
            value={this.getCharacter()}
            onChange={this.setCharacter}
            disabled={!this.isAdmin()}
          >
            {this.getOptionsCharacters().map((option: CharacterVoice) => (
              <option key={option.value} id={option.value} value={option.value}>
                {option.friendlyName}
              </option>
            ))}
          </Select>
        </div>
        <div className='form-group'>
          <label htmlFor='api-key'>
            <FormattedMessage id='assistant.info.tts.language' />
          </label>
          <Select
            className='custom-select engine-select'
            id='engine'
            value={this.getLanguage()}
            onChange={this.setLanguage}
            disabled={!this.isAdmin()}
          >
            {this.getOptionsLanguages().map((option: Language) => (
              <option key={option.value} id={option.value} value={option.value}>
                {option.friendlyName}
              </option>
            ))}
          </Select>
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.APIKEY) }}>
          <label htmlFor='api-key'>
            <FormattedMessage id='assistant.info.engine.apiKey' />
          </label>
          <input
            className='form-control'
            id='api-key'
            placeholder={this.formatMessage('assistant.info.engine.enterapiKey')}
            defaultValue={this.getApiKey()}
            onChange={this.setApiKey}
            disabled={!this.isAdmin()}
          />
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.URL) }}>
          <label htmlFor='url'>
            <FormattedMessage id='assistant.info.engine.url' />
          </label>
          <input
            className='form-control'
            id='url'
            placeholder={this.formatMessage('assistant.info.engine.enterurl')}
            defaultValue={this.getUrl()}
            onChange={this.setUrl}
            disabled={!this.isAdmin()}
            type='url'
          />
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.SERVICE_URL) }}>
          <label htmlFor='service-url'>
            <FormattedMessage id='assistant.info.engine.serviceurl' />
          </label>
          <input
            className='form-control'
            id='service-url'
            type='url'
            placeholder={this.formatMessage('assistant.info.engine.enterserviceurl')}
            defaultValue={this.getServiceUrl()}
            onChange={this.setServiceUrl}
            disabled={!this.isAdmin()}
          />
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.TOKEN_URL) }}>
          <label htmlFor='token-url'>
            <FormattedMessage id='assistant.info.engine.tokenurl' />
          </label>
          <input
            className='form-control'
            id='token-url'
            placeholder={this.formatMessage('assistant.info.engine.entertokenurl')}
            defaultValue={this.getTokenUrl()}
            onChange={this.setTokenUrl}
            disabled={!this.isAdmin()}
          />
        </div>
        <div
          className='form-group'
          style={{
            display: this.checkDisplayMultiple([EngineInputsType.PITCH, EngineInputsType.ASSISTANT_PITCH], 0),
          }}
        >
          <label htmlFor='token-url'>
            <FormattedMessage id='assistant.info.engine.pitch' />
          </label>
          <input
            className='form-control'
            id='token-url'
            placeholder={this.formatMessage('assistant.info.engine.enterpitch')}
            defaultValue={this.getPitch()}
            onChange={this.setPitch}
            disabled={!this.isAdmin()}
          />
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.PITCH) }}>
          <label htmlFor='token-url'>
            <FormattedMessage id='assistant.info.engine.speakingrate' />
          </label>
          <input
            className='form-control'
            id='token-url'
            placeholder={this.formatMessage('assistant.info.engine.enterspeakingrate')}
            defaultValue={this.getSpeakingRate()}
            onChange={this.setSpeakingRate}
            disabled={!this.isAdmin()}
          />
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.ASSISTANT_VOLUME) }}>
          <label htmlFor='token-url'>
            <FormattedMessage id='assistant.info.engine.volume' />
          </label>
          <input
            className='form-control'
            id='volume'
            placeholder={this.formatMessage('assistant.info.engine.entervolume')}
            defaultValue={this.getVolume()}
            onChange={this.setVolume}
            disabled={!this.isAdmin()}
          />
        </div>
        <div className='form-group' style={{ display: this.checkDisplay(EngineInputsType.ASSISTANT_SPEED) }}>
          <label htmlFor='token-url'>
            <FormattedMessage id='assistant.info.engine.speed' />
          </label>
          <input
            className='form-control'
            id='speed'
            placeholder={this.formatMessage('assistant.info.engine.enterspeed')}
            defaultValue={this.getSpeed()}
            onChange={this.setSpeed}
            disabled={!this.isAdmin()}
          />
        </div>
        {this.isAdmin() &&
          <div className='text-right'>
            <button id='saveTextToSpeech' type='submit' className='btn btn-secondary'>
              <FormattedMessage id='save' />
            </button>
          </div>
        }
      </form>
    </div>
  );
};
