import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { Api, RefreshApi } from '../../../../api/Server';
import { Response } from '../../../../model/http/Response';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import {
  withPaginationRouting,
  WithPaginationRoutingProps,
} from '../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { Navigation } from '../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { OrganizationAssistantListView } from './OrganizationAssistantList.view';
import { AssistantModel } from '../../../assistant/component/AssistantList/AssistantModel';
import { PrimitiveType } from 'intl-messageformat';

interface GetAssistantList extends Response {
  content: AssistantModel[];
  timestamp: Date;
  size: string;
  page: number;
  pageElements: number;
  totalElements: number;
}

interface ListState extends ApiState {
  data: AssistantModel[];
  totalPages: number;
  currentItem: AssistantModel | null;
  clickPageDisabled: boolean;
}

interface IntlProps {
  intl: IntlShape;
}

export enum AssistantListOperationType {
  GET = 'GET_BOT_LIST',
  NONE = 'NONE',
}

type Props = IntlProps &
  ApiProps<GetAssistantList> &
  SessionStateModel &
  LayoutStateModel &
  WithPaginationRoutingProps<Navigation> &
  WithAlertProps;

export class OrganizationAssistantListBase extends ApiBase<GetAssistantList, Props, ListState> {
  public render = OrganizationAssistantListView.bind(this);

  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      totalPages: 0,
      error: null,
      authError: null,
      currentItem: null,
      clickPageDisabled: false,
    };
  }

  public componentWillMount(): void {
    this.props.setPageTitle(this.formatMessage('topStats.assistants'));
    this.updateList();
  }

  public componentDidUpdate(prevProps: Props) {
    this.props.setPageTitle(this.formatMessage('topStats.assistants'));
    super.componentDidUpdate(prevProps);
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  public onRouteChanged() {
    this.updateList();
  }

  public getCurrentPage(): number {
    return this.props.page;
  }

  public getCurrentPageSize(): number {
    return this.props.pageSize;
  }

  public getCurrentCriteria(): string {
    return this.props.getExtraParam('criteria') || '';
  }

  public changePaginationHandler = (pageNumber: number) => {
    if (!this.state.clickPageDisabled) {
      this.setState({ clickPageDisabled: true });
      this.props.goToPage(pageNumber);
      setTimeout(() => {
        this.setState({ clickPageDisabled: false });
      }, 500);
    }
  }

  public searchHandler = (criteria: string) => {
    this.props.goToPage(0, {
      criteria,
    });
  }

  public updateList = () => {
    const uuid = this.props.match.params.organization;
    const criteria = encodeURIComponent(this.getCurrentCriteria());
    const currentPage = this.getCurrentPage();
    const currentPageSize = this.getCurrentPageSize();
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: `/admin/organizations/${uuid}/assistants/?name=${criteria}&full=1&page=${currentPage}&size=${currentPageSize}`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: AssistantListOperationType.GET,
    };
    this.doCall(request);
  }

  public changePageSize = (pageSize: number) => {
    this.props.changePageSize(pageSize);
  }

  public setResponseData() {
    const response = this.props.response;

   if (this.props.callId === AssistantListOperationType.GET) {
      this.setState({
        data: response.content,
        totalPages: Math.ceil(response.totalElements / Number(response.size)),
      });
    }
    else if (response.page) {
      this.props.setBreadCrumbsInfo({
        currentPage: null,
        pageNumber: response.page.toString(),
      });
    }
  }

  public formatMessage(id: string, values?: Record<string, PrimitiveType>) {
    return this.props.intl.formatMessage({ id }, values);
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }
}

const OrganizationAssistantListIntl = injectIntl(OrganizationAssistantListBase);
const OrganizationAssistantListWithAlert = withAlert<OrganizationAssistantListBase['props']>()(OrganizationAssistantListIntl);
const OrganizationAssistantListWithApi = RefreshApi(Api(OrganizationAssistantListWithAlert));
export const OrganizationAssistantList = withPaginationRouting()(OrganizationAssistantListWithApi);
