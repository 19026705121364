
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ViewFunction } from '../../../../../../model/component/ViewFunction';
import formLayout from '../formLayout';
import { HtmlAnswerBase } from './HtmlAnswer.component';

export const HtmlAnswerView: ViewFunction<HtmlAnswerBase> = function() {
  const childComponent = () => {
    return (
      <React.Fragment>
        <div className='form-group' id='textEditor'>
          <Editor
            editorState={this.state.editorState}
            wrapperClassName='demo-wrapper'
            editorClassName={'form-control ' + this.state.parametersKeyClass}
            onEditorStateChange={this.onEditorStateChange}
          />
          <textarea
            id='editor'
            className='form-control mt-3'
            disabled
            style={{ marginTop: '0px', marginBottom: '0px', height: '67px' }}
            value={this.getAnswerHtml()}
          />
        </div>
        <div className='text-right'>
          <button
            id='saveBtn'
            type='submit'
            className='btn btn-primary'
          >
            {`${this.formatMessage('save')}`}
          </button>
        </div>
      </React.Fragment>
    );
  };

  return (
    formLayout(this, childComponent)
  );
};
