import React from 'react';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { utcDateFormat } from '../../../../../util/dateFormat';
import { LogsBubble } from '../../../../common/component/logs/bubble/LogsBubble.component';
import { LogsBubbleInput } from '../../../../common/component/logs/inputbubble/LogsBubbleInput';
import { LogsBubbleNumber } from '../../../../common/component/logs/bubblenumber/LogsBubbleNumber';
import { PageSize } from '../../../../base/component/PageSize/PageSize.component';
import { Pagination } from '../../../../base/component/Pagination/Pagination.component';
import { ColumnObj, Table } from '../../../../base/component/Table/Table.component';
import { LogsGroupDetail } from './detail/LogsGroupDetail.component';
import { LogsGroupDialogueLogsDetail } from './detail/LogsGroupDialogueLogs.component';
import { LogsGroupBase } from './LogsGroup.component';
import { LogsModel, SentimentEnum, StatsTimeFrame } from '../../../../common/model/logs/LogsModel';
import { LogsDatePicker } from '../../../../common/component/logs/datepicker/LogsDatePicker';
import { LogsConfidence } from '../../../../common/component/logs/confidence/LogsConfidence';
import { LogsSentiment } from '../../../../common/component/logs/sentiment/LogsSentiment';

export const LogsGroupView: ViewFunction<LogsGroupBase> = function() {
  const rowDetail = {
    render: (item: LogsModel) => (
      <td colSpan={7} className='detail-row'>
        <div
          className={`detail-row-content collapse ${this.show(item)}`}
          id={`collapseConversationDetail-${item.dialogue_uuid}`}
        >
          <div className='conversation-summary card card-body shadow'>
            <div className='row'>
              <LogsGroupDetail dialogueUuid={item.dialogue_uuid} />
              <LogsGroupDialogueLogsDetail dialogueUuid={item.dialogue_uuid} />
            </div>
          </div>
        </div>
      </td>
    ),
  };
  const columns: ColumnObj[] = [
    {
      title: `${this.formatMessage('date')}/${this.formatMessage('time')}`,
      propertyName: 'timestamp',
      className: 'text-nowrap',
      render: (item: LogsModel) => {
        return (
          <div>
            <div>{utcDateFormat(item.timestamp)}</div>
            <div>
              <i className='fas fa-chevron-down' />
            </div>
          </div>
        );
      },
    },
    {
      title: this.formatMessage('input'),
      propertyName: 'message',
      render: (item: LogsModel) => {
        if (item.message) {
          return <LogsBubbleInput item={item} />;
        }
      },
    },
    {
      title: this.formatMessage('logsGroupDetailBase.sentiment'),
      propertyName: 'nluAnalysis',
      className: 'text-center',
      render: (item: LogsModel) => {
        return <LogsSentiment item={item} SentimentEnum={SentimentEnum} />;
      },
    },
    {
      title: this.formatMessage('logsGroupDialogueLogsBase.topIntent'),
      propertyName: 'intents',
      render: (item: LogsModel) => {
        if (item.intents && item.intents.length > 0) {
          return <span>{item.intents[0].identifier}</span>;
        }
        return <span />;
      },
    },
    {
      title: this.formatMessage('logsGroupDetailBase.confidence'),
      propertyName: 'confidence',
      render: (item: LogsModel) => {
        if (item.intents && item.intents.length > 0) {
          return <LogsConfidence item={item} />;
        }
        return <span />;
      },
    },
    {
      title: this.formatMessage('logsGroupDialogueLogsBase.answers'),
      propertyName: 'answers',
      render: (item: LogsModel) => {
        if (item.answers) {
          const firstTextAnswer: any = item.answers.find((value) => value.type !== 'ssml');
          return (
            <>
              <div style={{ minWidth: '300px' }} data-property-name='author' data-property-value='robo'>
                <LogsBubble answer={firstTextAnswer} />
              </div>
              <LogsBubbleNumber item={item} handleConversationDetail={this.handleConversationDetail} />
            </>
          );
        }
        return <span />;
      },
    },
  ];

  const getActiveClassForTimeFrame = (statsTimeFrame: string) => {
    return this.state.searchTimeFrame === statsTimeFrame ? 'active' : '';
  };

  return (
    <React.Fragment>
      <div className='card card-body shadow-sm'>
        <LogsDatePicker
          getActiveClassForTimeFrame={getActiveClassForTimeFrame}
          getAllData={this.getAllData}
          getTimeFrameData={this.getTimeFrameData}
          StatsTimeFrame={StatsTimeFrame}
          onFromDateChangeHandler={this.onFromDateChangeHandler}
          onToDateChangeHandler={this.onToDateChangeHandler}
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
          value={this.getConversationId()}
          onChangeConversationID={this.onChangeConversationID}
          onSubmitFilter={this.onSubmitFilter}
          export={this.export}
          clearFilters={this.clearFilters}
        />
        <Table tableId={'Logs'} columns={columns} rowDetail={rowDetail} data={this.getData()} page={0} />
        <div className='row mt-3'>
          <div className='col-sm-6'>
            <PageSize changePageSize={this.changePageSize} size={this.getCurrentPageSize()} />
          </div>
          <div className='col-sm-6'>
            <Pagination
              totalPages={this.state.totalPages}
              changePaginationHandler={this.changePaginationHandler}
              page={this.getCurrentPage()}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LogsGroupView;
