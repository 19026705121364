import React from 'react';
import BubbleProcessingView from './BubbleProcessingView';

interface Props {
  text?: string;
}

export class BubbleProcessingBase extends React.Component<Props, {}> {

  public render = BubbleProcessingView.bind(this);

}

export const BubbleProcessing = BubbleProcessingBase;
