import React from 'react';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import { Pagination } from './Pagination.component';
const MAX_PAGES = 2; // display 5 button 2 to the left and right
export const PaginationView: ViewFunction<Pagination> = function() {

  const totalPagesNumber = this.getTotalPages();
  const currentPage = this.getPage();
  const pages = [];
  for (let i = 0; i < totalPagesNumber; i++) {
    if (i < currentPage) {
      if (currentPage - i > MAX_PAGES) { continue; }
      pages.push(
        <li key={'li_key_' + i} className='page-item' onClick={this.changePageNumber(i)}>
          <div data-action={`open-page-${i + 1}`} className='page-link'>{i + 1}</div></li>);
    } else if (i === currentPage) {
      pages.push(
        <li data-type='item' key={'li_key_' + i} className='page-item active' onClick={this.changePageNumber(i)}>
          <div data-action={`open-page-${i + 1}`} className='page-link'>{i + 1}</div></li>);
    } else if (i > currentPage) {
      if (i - currentPage > MAX_PAGES) { continue; }
      pages.push(
        <li key={'li_key_' + i} className='page-item' onClick={this.changePageNumber(i)}>
          <div data-action={`open-page-${i + 1}`} className='page-link'>{i + 1}</div></li>);
    }
  }

  return (
    <nav aria-label='Page navigation example'>
      <ul id='pagination-list' className='pagination justify-content-end'>
        <li
          data-type='item'
          key={'li_key_previous'}
          id='prev'
          className='page-item'
          onClick={this.changePageNumber(currentPage - 1)}
        >
          <div className='page-link' aria-label='Previous'>
            <span aria-hidden='true'>&laquo;</span>
          </div>
        </li>
        {pages}
        <li
          data-type='item'
          key={'li_key_next'}
          id='next'
          className='page-item'
          onClick={this.changePageNumber(currentPage + 1)}
        >
          <div className='page-link' aria-label='Next'>
            <span aria-hidden='true'>&raquo;</span>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default PaginationView;
