import React from 'react';
import {FormattedMessage} from 'react-intl';
import { ViewFunction } from '../../../../../../model/component/ViewFunction';
import { Input } from '../../../../../form/component/Input.component';
import { notEmpty } from '../../../../../form/lib/rules';
import formLayout from '../formLayout';
import { CmsAnswerBase } from './CmsAnswer.component';

export const CmsAnswerView: ViewFunction<CmsAnswerBase> = function() {
  const childComponent = () => {
    return (
    <React.Fragment>
        <div className='form-group'>
          <label htmlFor='textInput'><FormattedMessage id='cmsAnswerBase'/></label>
          <span className='float-right text-danger'>*</span>
          <Input
            type='text'
            className='form-control'
            id='cmsInput'
            placeholder={`${this.formatMessage('cmsAnswerBase.enterTheIdToYourCmsArticleHere')}...`}
            aria-describedby='cmsAnswer'
            value={this.getAnswerCms()}
            onChange={this.changeText}
            rules={[notEmpty()]}
          />
        </div>
        <div className='text-right'>
          <button
            id='saveBtn'
            type='submit'
            className='btn btn-primary'
          >
            {`${this.formatMessage('save')}`}
          </button>
        </div>
      </React.Fragment>
  );
};

  return (
    formLayout(this, childComponent)
  );
};
