import { ChangeEvent, FormEvent } from 'react';
import { withAlert } from 'react-alert';
import {injectIntl, IntlShape} from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Api, RefreshApi } from '../../../../api/Server';
import { AppError } from '../../../../model/AppError';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { Response } from '../../../../model/http/Response';
import { SessionContext } from '../../../auth/state/Session/SessionContext';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { HeaderBtn } from '../../../base/component/PageHeader/model/HeaderBtn';
import { Navigation } from '../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutContext } from '../../../base/state/Layout/LayoutContext';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { InviteUserView } from './InviteUser.view';

interface State extends ApiState {
  emails: string;
  isValid: boolean;
  appError?: AppError | null;
}

interface Payload {
  emails: string[];
}

interface CreateResponse extends Response {
  content: null;
  timestamp: Date;
}

interface IntlProps {
  intl: IntlShape;
  requestUsers: () => void;
}

type Props = IntlProps & ApiProps<CreateResponse> & SessionStateModel &  LayoutStateModel &
 RouteComponentProps<Navigation> & WithAlertProps;

export class InviteUserBase extends ApiBase<CreateResponse, Props, State> {

  public render = InviteUserView.bind(this);

  public state: State = {
    emails: '',
    error: null,
    authError: null,
    isValid: false,
    appError: null,
  };

  public componentDidMount = () => {
    const createAssistantBtn: HeaderBtn = {
      title: this.formatMessage('organization.invite'),
      hint: this.formatMessage('organization.invite'),
      callback: this.handleOpen,
      id: 'invite-user',
      enabled: true,
    };

    this.props.addHeaderBtn(createAssistantBtn);
  }

  public componentWillReceiveProps = (nextProps: Props) => {
    if (nextProps.error !== this.props.error) {
      this.setState({
        appError: nextProps.error,
      });
    }

  }

  public componentWillUnmount = () => {
    this.props.removeHeaderBtn('invite-user');
  }

  public isModalVisible = () => {
    return this.props.showModal;
  }

  public hasError = () => {
    return this.state.appError !== null;
  }

  public handleClose = () => {
    this.props.setModalVisibility(false);
  }

  public handleOpen = () => {
    this.setState({
      emails: '',
      appError: null,
    });

    this.props.setModalVisibility(true);
  }

  public setIsFormValid = (isValid: boolean) => {
    if (this.state.isValid !== isValid) {
      this.setState({
        isValid,
      });
    }
  }

  public changeEmails = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      emails: value,
    });
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { emails } = this.state;
    this.setState({
      error: null,
    }, () => {
      this.sendInvitation(emails);
    });
  }

  public sendInvitation = (emails: string) => {

    const payload: Payload = {
      emails: emails
      .replace(/\s/g, '') // remove spaces
      .split(','),
    };

    const request: Request<{}> = {
        method: HttpRequestType.POST,
        payload,
        relativePath: `/admin/users/sendInvitation`,
        token: this.props.token,
        refreshToken: this.props.refreshToken,
      };
    this.doCall(request);
  }

  public setResponseData = () => {
    this.props.alert.success(this.formatMessage('inviteUserBase.successfullySent'));
    this.props.requestUsers();
    this.handleClose();
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({id});
  }

  protected setErrorResponseData = () => {
    this.props.alert.error(this.formatMessage('alert.anErrorOccurred'));
  }

}

export const InviteUserWithIntl = injectIntl(InviteUserBase);
const InviteUserWithAlert = withAlert<InviteUserBase['props']>()(InviteUserWithIntl);
const InviteUserWithLayout = withContext(LayoutContext)<InviteUserBase['props']>(InviteUserWithAlert);
const InviteUserWithSession = withContext(SessionContext)<InviteUserBase['props']>(InviteUserWithLayout);
const InviteUserWithApi = RefreshApi(Api(InviteUserWithSession));
export const InviteUser = withRouter(InviteUserWithApi);
