import { withAlert } from 'react-alert';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { Response } from '../../../../model/http/Response';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { AppRouteComponentProps } from '../../../base/model/route/AppRouteComponentProps';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { CountrySelectView } from './CountrySelect.view';
import { SessionContext } from '../../../auth/state/Session/SessionContext';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';

interface ResponseUpdate extends Response {
  content: Country[];
  timestamp: Date;
}

interface Country {
  nicename: string;
  numcode: number;
}

interface IntlProps {
  intl: IntlShape;
}

interface OtherProps {
  handleCountryChange: (e: any) => void;
  isUserView?: boolean;
  numcode: number;
  nicename: string;
  countrySubmitError: boolean;
}

interface State extends ApiState {
  data: Country[] | null;
  countryCode: number;
  clicked: boolean;
}

type Props = IntlProps &
  OtherProps &
  ApiProps<ResponseUpdate> &
  LayoutStateModel &
  RouteComponentProps<{ userId: string }> &
  SessionStateModel &
  WithAlertProps &
  AppRouteComponentProps;

export class CountrySelectBase extends ApiBase<ResponseUpdate, Props, State> {
  public render = CountrySelectView.bind(this);

  public state: State = {
    error: null,
    authError: null,
    data: null,
    countryCode: 0,
    clicked: false,
  };

  public componentDidMount = () => {
    const request: Request<{}> = {
      method: HttpRequestType.GET,
      payload: null,
      relativePath: this.props.isUserView
        ? '/admin/organizations/countries'
        : `/admin/organization/administration/countries`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
      id: 'get-countries',
    };
    this.doCall(request);
  }

  public setResponseData() {
    const { content } = this.props.response;
    this.setState({
      data: content,
    });
  }

  public handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.props.handleCountryChange(e);
    this.setState({
      countryCode: parseInt(e.target.value),
    });
  }

  public hasClick = () => {
    this.setState({
      clicked: true,
    });
  }

  public isCountryEmptyMessage = (code: number) => {
    return code === 0 && (this.state.clicked || this.props.countrySubmitError)
      ? this.formatMessage('organization.emptyMessage')
      : '';
  }

  public isCountryEmptyStyle = (code: number) => {
    return code === 0 && (this.state.clicked || this.props.countrySubmitError) ? 'invalid-feedback-select' : '';
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  protected setErrorResponseData(): void {
    this.props.alert.error(
      this.formatMessage('alert.anErrorOccurred') + (this.props.error ? ': ' + this.props.error!.message : ''),
    );
  }
}

const CountrySelectWithIntl = injectIntl(CountrySelectBase);
const CountrySelectWithAlert = withAlert<CountrySelectBase['props']>()(CountrySelectWithIntl);
const CountrySelectWithSession = withContext(SessionContext)(CountrySelectWithAlert);
const CountrySelectWithApi = RefreshApi(Api(CountrySelectWithSession));
export const CountrySelect = withRouter(CountrySelectWithApi);
