import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ViewFunction } from '../../../../../../model/component/ViewFunction';
import { utcDateFormat } from '../../../../../../util/dateFormat';
import { LogsAssistantDetailBase } from './LogsAssistantDetail.component';
import { LOGS_UUID_MAX_VISIBLE_LENGTH } from '../../../../../../constants/constants';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';

export const LogsAssistantDetailView: ViewFunction<LogsAssistantDetailBase> = function() {
  return (
    <div className='col-lg-12 col-sm-12'>
      <div className='d-flex mb-4'>
        <b>
          <FormattedMessage id={'logsAssistantView.conversationId'} />
        </b>
        <div className='d-flex mr-2'>
          <OverlayTrigger
            key={'top'}
            placement={'top'}
            overlay={<Tooltip id={`tooltip-top-maximum-idle-conversation-time`}>{this.getUuid()}</Tooltip>}
          >
            <div data-toggle='collapse' role='button' aria-expanded='false' className='ml-3'>
              {this.getUuid()?.substring(0, LOGS_UUID_MAX_VISIBLE_LENGTH) + '...'}
            </div>
          </OverlayTrigger>
          <CopyToClipboard text={this.getUuid()}>
            <button
              className='d-flex flex-column'
              style={{ border: 'none', backgroundColor: 'transparent', outline: 'none', width: '40px' }}
              onClick={this.showCopyAlert()}
            >
              <i className='far fa-12 fa-clone' style={{ color: '#999' }} />
              {this.state.showCopyAlert && <small style={{ color: 'red' }}> <FormattedMessage id={'copied'} /></small>}
            </button>
          </CopyToClipboard>
        </div>
        <b>{this.state.detailData && this.getChannelType()}</b>
      </div>

      <div className='row'>
        <div className='col-2 text-center card conversation-summary-kpi'>
          <div>
            <i className='far fs-15 fa-clock text-center' />
          </div>
          <div>
            <b>
              <FormattedMessage id={'logsAssistantDetailBase.started'} />
            </b>
          </div>
          <div> {utcDateFormat(this.getStarted())}</div>
        </div>

        <div className='col-2 text-center card conversation-summary-kpi'>
          <div>
            <i className='far fs-15 fa-clock text-center' />
          </div>
          <div>
            <b>
              <FormattedMessage id={'logsAssistantDetailBase.ended'} />
            </b>
          </div>
          <div>{utcDateFormat(this.getEnded())}</div>
        </div>

        <div className='col-2 text-center card conversation-summary-kpi'>
          <div>
            <i className='far fs-15 fa-clock text-center' />
          </div>
          <div>
            <b>
              <FormattedMessage id={'logsAssistantDetailBase.duration'} />
            </b>
          </div>
          <div>
            {this.getDuration()} <FormattedMessage id={'topStats.avgConvTime.seconds'} />
          </div>
        </div>

        <div className='col-2 text-center card conversation-summary-kpi'>
          <div>
            <i className='far fs-15 fa-comments text-center' />
          </div>
          <div>
            <b>
              <FormattedMessage id={'logsAssistantDetailBase.messages'} />
            </b>
          </div>
          <div>{this.getMessages()}</div>
        </div>

        <div className='col-2 text-center card conversation-summary-kpi'>
          <div>
            <i className='far fs-15 fa-thumbs-up text-center' />
          </div>
          <div>
            <b>
              <FormattedMessage id={'logsAssistantDetailBase.confidence'} />
            </b>
          </div>
          <div>{this.getConfidence()}%</div>
        </div>

        <div className='col-2 text-center card conversation-summary-kpi'>
          <div>
            <div>
              <i className='far fs-15 fa-meh ' />
            </div>
            <b>
              <FormattedMessage id={'logsAssistantDetailBase.sentiment'} />
            </b>
          </div>
          <div>{this.getSentiment()}</div>
        </div>
      </div>
    </div>
  );
};
