import React from 'react';
import { Link } from 'react-router-dom';
import { ViewFunction } from '../../../../model/component/ViewFunction';
import Confirm from '../../../base/component/confirm/Confirm.component';
import { PageSize } from '../../../base/component/PageSize/PageSize.component';
import { Pagination } from '../../../base/component/Pagination/Pagination.component';
import { Search } from '../../../base/component/Search/Search.component';
import { ColumnObj, Table } from '../../../base/component/Table/Table.component';
import { UserModel } from '../../model/AccountModel';
import { InviteUser } from '../inviteUser/InviteUser.component';
import { utcDateFormat } from './../../../../util/dateFormat';
import { UserListBase } from './UserList.component';
export const UserListView: ViewFunction<UserListBase> = function() {
  const columns: ColumnObj[] = [
    {
      title: this.formatMessage('account.profile.profile.name'),
      propertyName: this.formatMessage('account.profile.profile.name').toLowerCase(),
      render: (item: UserModel) => (
        <Link data-action='edit-User' to={`/account/users/${item.uuid}`}>
          {item.username}
        </Link>
      ),
    },
    {
      title: this.formatMessage('account.profile.profile.email'),
      propertyName: this.formatMessage('account.profile.profile.email').toLowerCase(),
      render: (item: UserModel) => <span>{item.email}</span>,
    },
    {
      title: this.formatMessage('account.profile.profile.mobileNumber'),
      propertyName: this.formatMessage('account.profile.profile.mobileNumber.property'),
      render: (item: UserModel) => <span>{item.mobileNumber}</span>,
    },
    {
      title: this.formatMessage('account.users.profile.2FAuthentication'),
      propertyName: this.formatMessage('account.users.profile.2FAuthentication.property'),
      render: (item: UserModel) => (
        <span>{item.twoFactorAuth ?
        this.formatMessage('status.enabled') : this.formatMessage('disabled')}</span>
      ),
    },
    {
      title: this.formatMessage('account.users.profile.lastLogin'),
      propertyName: this.formatMessage('account.users.profile.lastLogin.property'),
      render: (item: UserModel) => <span>{utcDateFormat(item.lastLogin)}</span>,
    },
    {
      title: this.formatMessage('account.profile.profile.status'),
      style: { textTransform: 'capitalize' },
      propertyName: this.formatMessage('account.profile.profile.status').toLowerCase(),
      render: (item: UserModel) => <span>{item.status.toLocaleLowerCase()}</span>,
    },
  ];

  return (
    <React.Fragment>
      <Confirm
        show={this.state.showConfirmDialog}
        okLabel={this.formatMessage('ok')}
        cancelLabel={this.formatMessage('cancel')}
        confirmation={this.state.confirmMsg!}
        proceedFunc={this.confirmCallback}
        title={this.formatMessage('confirm')}
        setConfirmVisibility={this.setConfirmVisibility}
      />
      <InviteUser requestUsers={this.requestUsers} />
      <div className='card shadow-sm'>
        <div className='card-body'>
          <div className='row'>
            <Search searchHandler={this.searchHandler} search={this.getCurrentCriteria()} />
          </div>
          <Table
            tableId={'Users'}
            data={this.getData()}
            columns={columns}
            page={this.getPage()}
            dropDownMenuItems={this.dropDownMenuItems}
          />
          <div className='row mt-3'>
            <div className='col-sm-6'>
              <PageSize changePageSize={this.changePageSizeHandler} size={this.getPageSize()} />
            </div>
            <div className='col-sm-6'>
              <Pagination
                totalPages={this.getTotalPages()}
                changePaginationHandler={this.changePageHandler}
                page={this.getPage()}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
