import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { AddWelcomeBase } from './AddWelcome.component';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export const AddWelcomeView: ViewFunction<AddWelcomeBase> = function() {
  const getHtmlString = () => {
    return draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
  };
  const createMarkup = () => {
    return { __html: getHtmlString() };
  };

  return (
    <Modal show={this.isModalVisible()} onHide={this.clearAndClose} className='shadow-sm'>
      <Modal.Header className='' closeButton>
        <Modal.Title as='h5'>
         {this.props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='card card-body shadow-sm'>
          <Editor
            editorState={this.state.editorState}
            wrapperClassName='demo-wrapper'
            editorClassName='demo-editor welcome-message-editor-help'
            onEditorStateChange={this.onEditorStateChange}
            toolbar={{
              options: ['inline', 'fontSize', 'textAlign', 'list', 'link'],
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
            }}
          />
        </div>
        <h5>
          <FormattedMessage id='assistant.studio.preview' />
        </h5>
        <div className='speech-bubble-robo speech-bubble-studio welcome-message-editor-help'>
          <span dangerouslySetInnerHTML={createMarkup()} />
        </div>
      </Modal.Body>
      <div className='modal-footer'>
        <button id='cancel' type='button' className='btn btn-dark' data-dismiss='modal' onClick={this.clearAndClose}>
          <FormattedMessage id='cancel' />
        </button>
        <button type='button' className='btn btn-primary' id='save' onClick={() => this.clearAndSave(getHtmlString())}>
          {<FormattedMessage id='save' />}
        </button>
      </div>
    </Modal>
  );
};
