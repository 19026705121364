import React from 'react';
import BreadcrumbsView from './Breadcrumbs.view';
import {BreadCrumbsPayload} from './model';
interface State {
  breadCrumbsInfo: BreadCrumbsPayload;
}

interface Props {
  breadCrumbsInfo: BreadCrumbsPayload;
}

export class Breadcrumbs extends React.Component<Props, State> {

  public render = BreadcrumbsView.bind(this);

}

export default Breadcrumbs;
