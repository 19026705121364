export enum OrganizationTrial {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum OrganizationParamType {
  TRIAL = 'TRIAL',
  TRIAL_BOT_ALLOWED = 'TRIAL_BOT_ALLOWED',
}

export interface OrganizationParams {
  key: OrganizationParamType;
  value: string;
}

export interface Organization {
  id: number;
  uuid: string;
  name: string;
  created: Date;
  updated: Date;
  parameters: OrganizationParams[];
  adminApiKey: string;
}

export interface SessionStateModel {
  initialized: boolean;
  token: string | null;
  refreshToken: string | null;
  expiresIn: Date | null;
  isRefreshing: boolean;
  loadBarRef: any | null;
  authorities: string[];
  setSessionData: (
    token: string,
    refreshToken: string,
    expiresIn: Date,
    authorities: string[],
    currentUsername: string,
  ) => void;
  setRequiredRefresh: (marker: boolean) => void;
  isRefreshingToken: () => boolean;
  clearCookie: () => void;
  clearSession: () => void;
  setLoadBarRef: (ref: any) => void;
  backendBuild?: Build | null;
  setBackendBuild: (backendBuild: Build) => void;
  currentUsername: string;
  setOrganization: (organization: Organization) => void;
  organization: Organization | null;
}

export interface Build {
  version: string;
  time: string;
}
