import React from 'react';
import { ViewFunction } from '../../../../../../model/component/ViewFunction';
import { utcDateFormat } from '../../../../../../util/dateFormat';
import { PageSize } from '../../../../../base/component/PageSize/PageSize.component';
import { Pagination } from '../../../../../base/component/Pagination/Pagination.component';
import { LogsBubbleInput } from '../../../../../common/component/logs/inputbubble/LogsBubbleInput';
import { LogsConfidence } from '../../../../../common/component/logs/confidence/LogsConfidence';
import { ColumnObj, Table } from '../../../../../base/component/Table/Table.component';
import { LogsModel, SentimentEnum } from '../../../../../common/model/logs/LogsModel';
import { LogsGroupDialogueLogsBase } from './LogsGroupDialogueLogs.component';
import { LogsSentiment } from '../../../../../common/component/logs/sentiment/LogsSentiment';
import { LogsDetailAnswerList } from '../../../../../common/component/logs/answerlist/LogsDetailAnswerList';

export const LogsGroupDialogueLogsView: ViewFunction<LogsGroupDialogueLogsBase> = function() {
  const getTopIntent = (item: LogsModel) => {
    const intents = item.intents ? [...item.intents] : [];
    intents.sort((a, b) => b.confidence - a.confidence);
    if (intents.length > 0) {
      return intents[0];
    }
    return null;
  };

  const columns: ColumnObj[] = [
    {
      title: `${this.formatMessage('date')}/${this.formatMessage('time')}`,
      propertyName: 'timestamp',
      className: 'text-nowrap',
      render: (item: LogsModel) => <span>{utcDateFormat(item.timestamp)}</span>,
    },
    {
      title: this.formatMessage('input'),
      propertyName: 'message',
      render: (item: LogsModel) => {
        if (item.message) {
          return <LogsBubbleInput item={item} />;
        }
      },
    },
    {
      title: this.formatMessage('logsGroupDetailBase.sentiment'),
      propertyName: 'nluAnalysis',
      className: 'text-center',
      render: (item: LogsModel) => {
        return <LogsSentiment item={item} SentimentEnum={SentimentEnum} />;
      },
    },
    {
      title: this.formatMessage('logsGroupDialogueLogsBase.topIntent'),
      propertyName: 'intents',
      render: (item: LogsModel) => {
        const intent = getTopIntent(item);
        if (intent !== null) {
          return <span>{intent.identifier}</span>;
        }
        return <span />;
      },
    },
    {
      title: this.formatMessage('logsGroupDetailBase.confidence'),
      propertyName: 'confidence',
      render: (item: LogsModel) => {
        const intent = getTopIntent(item);
        if (intent !== null) {
          return <LogsConfidence item={item} />;
        }
        return <span />;
      },
    },
    {
      title: this.formatMessage('logsGroupDialogueLogsBase.answers'),
      propertyName: 'answers',
      render: (item: LogsModel) => {
        if (item.answers) {
          return <LogsDetailAnswerList item={item} />;
        }
        return <span />;
      },
    },
  ];

  return (
    <div className='col-lg-12 col-sm-12'>
      <div className='col-12 text-center'>
        <h5>
          <h5>{this.formatMessage('logsAssistantDetailBase.messages')}</h5>
        </h5>
      </div>
      <Table tableId={'Logs'} columns={columns} data={this.getDialogueList()} page={0} />
      <div className='row mt-3'>
        <div className='col-sm-6'>
          <PageSize changePageSize={this.changePageSize} size={this.getCurrentPageSize()} />
        </div>
        <div className='col-sm-6'>
          <Pagination
            totalPages={this.state.totalPages}
            changePaginationHandler={this.changePaginationHandler}
            page={this.getCurrentPage()}
          />
        </div>
      </div>
    </div>
  );
};
