import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { ViewFunction } from '../../../../../model/component/ViewFunction';
import { Line } from './Line.component';

/*type Props = PropsWithChildren<{}> & {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  id: string;
  customClass: string;
};*/
const CustomToggle = React.forwardRef<HTMLButtonElement, any>(({ onClick, children, id, customClass }, ref) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <button
      ref={ref}
      data-action='options'
      aria-haspopup='true'
      aria-expanded='false'
      onClick={handleClick}
      id={id}
      className={customClass}
    >
      {children}
    </button>
  );
});

export const LineView: ViewFunction<Line> = function() {
  const columns = this.getColumns().map((item: any, index: number) => {
    return (
      <td
        style={item.style}
        data-property-value={this.getItem()[item.propertyName]}
        data-property-name={item.propertyName}
        key={index + item.propertyName}
        className={item.className}
      >{item.render(this.getItem(), this.props.position)}
      </td>
    );
  });

  return (
    <React.Fragment>
      {columns}
      {(this.props.dropDownMenuItems !== undefined) ? (
        <td>
          <Dropdown drop='down'>
            <Dropdown.Toggle
              as={CustomToggle}
              customClass={'btn-icon'}
              id={`dropdownMenuButton-${this.props.position}`}
            >
              <i className='fas fa-ellipsis-v'/>
            </Dropdown.Toggle>
            <Dropdown.Menu className='dropdown-menu-right shadow-sm' flip>
              {this.props.dropDownMenuItems!(this.getItem()).map((dropDownItem, key) => {
                return (
                  <Dropdown.Item
                    key={key}
                    data-action={`${dropDownItem.text}`}
                    id={`${dropDownItem.text}-${this.props.position}`}
                    bsPrefix={'dropdown-item'}
                    onClick={this.onClickHandler(dropDownItem)}
                  >
                    {this.getText(dropDownItem)}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      ) : null}
    </React.Fragment>

  );
};

export default LineView;
