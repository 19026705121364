
import {FormEvent} from 'react';
import { withAlert } from 'react-alert';
import {injectIntl, IntlShape} from 'react-intl';
import { RouteChildrenProps } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Api, Auth2FAApi } from '../../../../api/Server';
import { AppError, AppErrorType } from '../../../../model/AppError';
import { AsyncState } from '../../../../model/AsyncState';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { WithPaginationRoutingProps } from '../../../base/component/withPaginationRouting/withPaginationRouting.hoc';
import { Navigation } from '../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { withContext } from '../../../lib/component/withContext/withContext.hoc';
import { SessionContext } from '../../state/Session/SessionContext';
import { Build, SessionStateModel } from '../../state/Session/SessionStateModel';
import { Request } from './../../../../model/http/Request';
import { VerifyCodeFormView } from './VerifyCodeForm.view';

interface ComponentProps {
  authenticate2FA: (username: string, password: string, code: string) => void;
  call: (Request: Request<{}>, newToken?: string, acceptAudio?: boolean) => void;
  authError: AppError;
  authState: AsyncState;
  authToken: string;
  authRefreshToken: string;
  authExpiresIn: Date;
  authAuthorities: string[];
  response: {build: Build};
  statusCode: number;
  callId: string;
  state: AsyncState;
  intl: IntlShape;
}

interface State extends ApiState {
  username: string;
  password: string;
  code: string;
  error: AppErrorType | null;
  smsTokenSent: string;
  build: Build | null;
}

interface VerifyCodeResponse extends Response {

  timestamp: Date;

}

interface IntlProps {
  intl: IntlShape;
}

type Props =
  IntlProps
  & ComponentProps & SessionStateModel & RouteChildrenProps &ApiProps<VerifyCodeResponse>
  & LayoutStateModel
  & SessionStateModel
  & RouteComponentProps<Navigation>
  & WithAlertProps
  & WithPaginationRoutingProps;

interface Payload {
    name: string;
    description: string;
    maxIdleTime: string;
  }

export class VerifyCodeFormBase extends ApiBase<VerifyCodeResponse, Props, State> {

  public state: State = {
    username: '',
    password: '',
    code: '',
    error: null,
    build: null,
    authError: null,
    smsTokenSent: '',

  };
  public payload: Payload = {
    name: '',
    description: '',
    maxIdleTime: '',
  };

  public render = VerifyCodeFormView.bind(this);

  private _loadingBar: any = null;

  constructor(props: Props) {
    super(props);
    this._loadingBar = null;

  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {code} = this.state;

    this.setState({
      error: null,
    }, () => {

      // tslint:disable-next-line:max-line-length
      this.props.authenticate2FA(sessionStorage.getItem('username') || '', sessionStorage.getItem('password') || '', code);

    });
  }

  public resendCode = () => {
    this.props.authenticate2FA(sessionStorage.getItem('username') || '', sessionStorage.getItem('password') || '', '');
    this.setState({
      code: '',
    });

  }

  public changeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      code: value,
    });
  }
  public hasError(): boolean {
    return this.state.error !== null;
  }

  public smsTokenSent(): boolean {
    return this.state.smsTokenSent !== '';
  }

  public setRef = (ref: any) => {
    this._loadingBar = ref;
  }
  public setResponseData() {

    this._loadingBar.complete();
    const { authToken, authRefreshToken, authExpiresIn, authAuthorities } = this.props;
    this.props.setSessionData(authToken, authRefreshToken, authExpiresIn, authAuthorities, this.state.username);

    if (authToken) {
      this.redirectAfterVerifyCode();
  }

 }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.state !== this.props.state) {
      if (this.props.callId === 'backendVersion') {
        if (this.props.response && this.props.response.build) {
          this.props.setBackendBuild(this.props.response.build);
        }
      }
    }
    if (prevProps.authState !== this.props.authState) {

      switch (this.props.authState) {

        case AsyncState.REQUESTED:
          this._loadingBar.continuousStart();

          break;
        case AsyncState.ERROR:

          this.setErrorResponseData();

          break;

        case AsyncState.SUCCESS:

            this.setResponseData();

            break;
      }
    }
  }

  public componentDidMount = async () => {

    this.props.clearSession();
    await this.getBackendVersion();

  }

  public getBackendVersion = async  () => {
    const request: Request<{}> = {
        method: HttpRequestType.GET,
        payload: null,
        relativePath: `/actuator/info`,
        token: 'not needed',
        refreshToken: 'not needed',
        id: 'backendVersion',
      };
    await this.props.call(request);

  }

  public componentWillUnmount() {
    this._loadingBar.complete();
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({id});
  }

  protected setErrorResponseData(): void {
    this._loadingBar.complete();
    if (this.props.authError) {
      if (this.props.authError.message === this.formatMessage('verify.codeSmsTokenSent')) {

        this.setState({

          error: null,
          smsTokenSent: this.formatMessage('verify.codeSmsTokenSent'),

        });
      } else {
      this.setState({

        error: this.props.authError.type,
        smsTokenSent: '',

      });
    }

}

  }

  private redirectAfterVerifyCode() {
    this.props.history.push('/dashboard');
  }

}

const VerifyCodeWithIntl = injectIntl(VerifyCodeFormBase);
const VerifyCodeWithAlert = withAlert<VerifyCodeFormBase['props']>()(VerifyCodeWithIntl);
const VerifyCodeFormWithContext = withContext(SessionContext)(VerifyCodeWithAlert);
const VerifyCodeFormCall = Api(VerifyCodeFormWithContext);
export const VerifyCodeForm = Auth2FAApi(VerifyCodeFormCall);
