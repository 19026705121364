import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppRouteComponentProps } from '../../../base/model/route/AppRouteComponentProps';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { EditOrganizationView } from './EditOrganization.view';
import { injectIntl, IntlShape } from 'react-intl';

interface IntlProps {
  intl: IntlShape;
}

interface OrganizationSection {
  name: string;
  identifier: string;
}

type Props = IntlProps &
  LayoutStateModel &
  RouteComponentProps<{ organization: string }> &
  WithAlertProps &
  AppRouteComponentProps;

export class EditOrganizationBase extends Component <Props> {
  public render = EditOrganizationView.bind(this);

  public sections: OrganizationSection[] = [
    { identifier: 'info', name: 'Info' },
    { identifier: 'users', name: 'Users' },
    { identifier: 'bot-list', name: 'Bots' },
  ];

  public getOrganizationId() {
    return this.props.match.params.organization;
  }

  public setResponseData() {
  }

  public componentWillUnmount(): void {
    this.props.setBreadCrumbsInfo({
      currentPage: null,
    });
  }

  public getSectionRoute = (section: string) => {
    return `/organizations/${this.getOrganizationId()}/${section}`;
  }

  public isActive = (section: string) => this.props.location.pathname.startsWith(this.getSectionRoute(section));

  public getAriaSelected = (section: string) => {
    return this.isActive(section) ? 'true' : 'false';
  }

  public getActiveClass = (section: string) => {
    return this.isActive(section) ? 'active' : '';
  }

  protected setErrorResponseData(): void {
    this.props.alert.error('error');
  }
}
const EditOrganizationWithIntl = injectIntl(EditOrganizationBase);
export const EditOrganization = withRouter(EditOrganizationWithIntl);
