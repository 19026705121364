import { ChannelModel } from '../state/ChannelStateModel';

export enum Providers {
  Twilio = 'Twilio',
  Facebook = 'Facebook',
  CM = 'CM',
  Core = 'Core',
  Freshworks = 'Freshworks',
}

export enum Channels {
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
  MESSENGER = 'MESSENGER',
  FACEBOOK = 'FACEBOOK',
  SKYPE = 'SKYPE',
  TELEGRAM = 'TELEGRAM',
  RCS = 'RCS',
  VIBER = 'VIBER',
  TWITTER = 'TWITTER',
  INSTAGRAM = 'INSTAGRAM',
  PHONE = 'PHONE',
  API = 'API',
  FRESHCHAT = 'FRESHCHAT',
}

export interface GeneralProps {
  item: ChannelModel;
  isAdmin: boolean;
  changeApikey(value: string, item: ChannelModel): void;
  confirm(item: ChannelModel): void;
}
