import React from 'react';
import PaginationView from './Pagination.view';

interface State {
  page: number;
}

interface Props {
  totalPages: number;
  page: number;
  changePaginationHandler: (page: number) => void;
}

export class Pagination extends React.Component<Props, State> {

  public render = PaginationView.bind(this);

  public changePageNumber = (pageNumber: number) => (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault();
    if (pageNumber >= 0 && pageNumber <= this.getTotalPages() - 1) {
      this.props.changePaginationHandler(pageNumber);
    }
  }

  public getTotalPages(): any {
    return this.props.totalPages;
  }

  public getPage() {
    return this.props.page;
  }

}
