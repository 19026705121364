import { ChangeEvent, FormEvent } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Api, RefreshApi } from '../../../../api/Server';
import { HttpRequestType } from '../../../../model/http/HttpRequestType';
import { Request } from '../../../../model/http/Request';
import { Response } from '../../../../model/http/Response';
import { SessionStateModel } from '../../../auth/state/Session/SessionStateModel';
import { ApiBase, ApiProps, ApiState } from '../../../base/ApiComponent';
import { HeaderBtn } from '../../../base/component/PageHeader/model/HeaderBtn';
import { Navigation } from '../../../base/model/route/Navigation';
import { WithAlertProps } from '../../../base/model/WithAlertProps';
import { LayoutStateModel } from '../../../base/state/Layout/LayoutStateModel';
import { CreateGroupView } from './CreateGroup.view';
import { HttpError } from '../../../../model/http/HttpError';
import { AppError, GenericErrorType } from '../../../../model/AppError';
import { Status } from '../../model/Status';

interface State extends ApiState {
  name: string;
  description: string;
  isValid: boolean;
}

interface Payload {
  name: string;
  description: string;
}

interface Content {
  uuid: string;
  name: string;
  description: string;
  created: Date;
  updated: Date;
  status: Status;
}

interface CreateResponse extends Response {
  content: Content;
  timestamp: Date;
}

interface CreateGroupProps extends ApiProps<CreateResponse> {
  updateList: () => void;
  intl: IntlShape;
}

type Props = ApiProps<CreateResponse> &
  CreateGroupProps &
  SessionStateModel &
  LayoutStateModel &
  RouteComponentProps<Navigation> &
  WithAlertProps;

export class CreateGroupBase extends ApiBase<CreateResponse, Props, State> {
  public render = CreateGroupView.bind(this);

  public payload: Payload = {
    name: '',
    description: '',
  };

  public state: State = {
    name: '',
    description: '',
    error: null,
    authError: null,
    isValid: false,
    appError: null,
  };

  constructor(props: Props) {
    super(props);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
  }

  public componentDidMount(): void {
    const createGroupBtn: HeaderBtn = {
      title: this.formatMessage('createGroup.createNewGroup'),
      hint: this.formatMessage('createGroup.createNewGroup'),
      callback: this.handleOpen,
      id: 'create-new-group',
      enabled: true,
    };

    this.props.addHeaderBtn(createGroupBtn);
  }

  public componentWillUnmount(): void {
    this.props.removeHeaderBtn('create-new-group');
  }

  public isModalVisible(): boolean {
    return this.props.showModal;
  }

  public hasError(): boolean {
    return this.state.appError !== null;
  }

  public handleClose() {
    this.props.setModalVisibility(false);
  }

  public handleOpen() {
    this.setState({
      name: '',
      description: '',
      appError: null,
    });

    this.props.setModalVisibility(true);
  }

  public setIsFormValid(isValid: boolean) {
    if (this.state.isValid !== isValid) {
      this.setState({
        isValid,
      });
    }
  }

  public changeName = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      name: value,
    });
  }

  public changeDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const target = event.target;
    const value = target.value;

    this.setState({
      description: value,
    });
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { name, description } = this.state;
    this.setState(
      {
        error: null,
      },
      () => {
        this.saveGroup(name, description);
      },
    );
  }

  public saveGroup = (name: string, description: string) => {
    this.payload = {
      name,
      description,
    };

    const request: Request<{}> = {
      id: 'saveGroup',
      method: HttpRequestType.POST,
      payload: this.payload,
      relativePath: `/admin/group`,
      token: this.props.token,
      refreshToken: this.props.refreshToken,
    };

    this.doCall(request);
  }

  public setResponseData() {
    const callId = this.props.callId;
    if (callId === 'saveGroup') {
      this.props.alert.success(this.formatMessage('alert.successfullyCreated'));

      this.setState({
        name: '',
        description: '',
      });

      this.props.history.push('/groups/list/0');
      this.handleClose();
    }
  }

  public formatMessage(id: string) {
    return this.props.intl.formatMessage({ id });
  }

  protected setErrorResponseData(): void {
    const callId = this.props.callId;
    if (callId === 'saveGroup' && this.props.status === HttpError.BAD_REQUEST.valueOf()) {
      const appError = new AppError(GenericErrorType.GENERIC_ERROR);
      appError.message = this.formatMessage('createGroup.nameNotAcceptedMessenger');
      this.setState({
        appError,
      });
    }
  }
}

const CreateGroupWithApi = RefreshApi(Api(CreateGroupBase));
export const CreateGroup = injectIntl(CreateGroupWithApi);
