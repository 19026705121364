import { Component } from 'react';
import { LogsBubbleDetailView } from './LogsBubble.view';

interface LogsBubbleProps {
  answer: any;
}

interface MyState {
  height: number;
  fullView?: boolean;
}

export class LogsBubbleDetail extends Component<LogsBubbleProps, MyState> {
  public render = LogsBubbleDetailView.bind(this);

}
