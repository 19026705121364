import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { FormEvent } from 'react';
import { withAlert } from 'react-alert';
import {injectIntl} from 'react-intl';
import { withRouter } from 'react-router';
import { Api, RefreshApi } from '../../../../../../api/Server';
import { SessionContext } from '../../../../../auth/state/Session/SessionContext';
import { LayoutContext } from '../../../../../base/state/Layout/LayoutContext';
import { withContext } from '../../../../../lib/component/withContext/withContext.hoc';
import { AnswerBody } from '../../../../model/AnswerModel';
import { AnswerType } from '../../../../model/AnswerType';
import { AnswerContext } from '../../../../state/AnswerContext';
import { EditAnswerBase } from '../../EditAnswer.component';
import { HtmlAnswerView } from './HtmlAnswer.view';

interface AnswerHtmlJson extends AnswerBody {
  text: string;
}

export class HtmlAnswerBase extends EditAnswerBase<AnswerHtmlJson> {
  public render = HtmlAnswerView.bind(this);
  public answer: AnswerHtmlJson = {
    text: '',
    type: AnswerType.HTML,
  };

  public componentWillMount(): void {
    let html: string = '';
    if (this.props.answer!.uuid) {
      html = this.props.answer!.answerContent.text;
    } else {
      html = this.answer.text;
    }
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      const answer: AnswerHtmlJson = {
        text: html,
      };
      this.setState({
        editorState,
        answer,
      });
    }
  }

  public onEditorStateChange = (editorState: any) => {

    let html: string = '';
    html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const answer: AnswerHtmlJson = {
      text: html,
    };
    this.setState({
      editorState,
      answer,
      parametersKeyClass: ((html === '<p></p>\n') || (html.trim() === '<p></p>'))
        ? this.formatMessage('isInvalid') : '',
    });
  }

  public onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.state.answer.text === '' ||
      this.state.answer.text === '<p></p>\n' ||
      this.state.answer.text === '<p></p>') {
      this.setState({
        parametersKeyClass: this.formatMessage('isInvalid'),
      });
      return false;
    }
    this.handleSave();
  }

  public getAnswerHtml(): string {
    let content = '';
    if (this.state.editorState) {
      content = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
      this.answer.text = content;
    }
    return content;
  }
  public isAllValid() {
    const saveButtonEnabled = ((this.state.answer !== '<p></p>')
      && (this.props.answer!.language !== '') && (this.props.answer!.code !== ''));
    return saveButtonEnabled;
  }

  protected getContent(): AnswerHtmlJson {
    const json: AnswerHtmlJson = {
      text: this.answer.text,
    };
    return json;
  }
}

const HtmlAnswerWithIntl = injectIntl(HtmlAnswerBase);
const HtmlAnswerWithAlert = withAlert<HtmlAnswerBase['props']>()(HtmlAnswerWithIntl);
const HtmlAnswerWithSession = withContext(SessionContext)(HtmlAnswerWithAlert);
const HtmlWithContext = withContext(AnswerContext)(HtmlAnswerWithSession);
const HtmlWithLayout = withContext(LayoutContext)(HtmlWithContext);
const HtmlAnswerWithApi = RefreshApi(Api(HtmlWithLayout));
export const HtmlAnswer = withRouter(HtmlAnswerWithApi);
